import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { useTranslation } from '@/hooks/useTranslation';
import { identificationFormSchema } from '@/components/PageContent/CartPageContent/Keycard/validation';
import { useNotification } from '@/hooks/useNotification';
import { RegisterKeycardForm } from '@/components/RegisterKeycardForm/RegisterKeycardForm';
import { usePostKeycardMutation } from '@/api/order';
import { isOfTypeRTKAPIError } from '@/models/request';
import { useDispatch } from 'react-redux';
import { checkoutApi } from '@/api/checkout';

type RegisterKeycardFormContainerProps = {
    onKeycardRegistered: () => void;
};

export const RegisterKeycardFormContainer: FunctionComponent<
    PropsWithChildren<RegisterKeycardFormContainerProps>
> = ({ onKeycardRegistered }) => {
    const dispatch = useDispatch();
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const [postKeycard] = usePostKeycardMutation();
    const [keycardMutationError, setKeycardMutationError] = useState<
        'invalidSerialNumber' | 'unknownError' | null
    >(null);

    return (
        <RegisterKeycardForm
            validationSchema={identificationFormSchema({
                forRegistration: true,
            })}
            explanationText={getTranslated('registerKeycardExplanation')}
            errors={{
                invalidSerialNumber:
                    keycardMutationError === 'invalidSerialNumber',
            }}
            onSubmit={(registerKeycard) => {
                postKeycard(registerKeycard)
                    .unwrap()
                    .then(() => {
                        dispatch(
                            checkoutApi.util.invalidateTags(['CHECKOUTS'])
                        );

                        addNotification({
                            type: 'success',
                            message: getTranslated('Keycard.register.success'),
                        });

                        onKeycardRegistered();
                    })
                    .catch((e) => {
                        if (isOfTypeRTKAPIError(e)) {
                            const errorType = e.data.errors[0].error;

                            if (errorType === 'invalid_serial_number') {
                                setKeycardMutationError('invalidSerialNumber');

                                addNotification({
                                    type: 'error',
                                    message: getTranslated(
                                        'keycard.registerNew.error.invalidSerialNumber'
                                    ),
                                });
                            } else {
                                setKeycardMutationError('unknownError');

                                addNotification({
                                    type: 'error',
                                    message: getTranslated(
                                        'keycard.registerNew.error.general'
                                    ),
                                });
                            }
                        }
                    });
            }}
        />
    );
};
