import { IdentificationType } from '@/models/cart';
import {
    BackendType,
    convertDateToBackendString,
    convertDateToFrontend,
    CustomDate,
    FrontendType,
    Gender,
    Money,
    ObjectType,
} from '@/models/general';

export type CheckoutIdentification<T extends ObjectType = FrontendType> = {
    id: string;
    type: IdentificationType;
    cardType: string;
    price: Money;
    depositAmount: Money;
    title: string;
    firstname: string;
    lastname: string;
    email: string;
    gender: Gender;
    birthday: CustomDate<T>;
    imageUrl: string | null;
    extra: Record<string, unknown>;
    serialNumber?: string;
};

export const convertCheckoutIdentificationToFrontend = (
    backend: CheckoutIdentification<BackendType>
): CheckoutIdentification => ({
    ...backend,
    birthday: convertDateToFrontend(backend.birthday),
});

export const mapIdentificationToGAItem = (ident: CheckoutIdentification) => ({
    item_id: ident.id,
    item_name: 'Keycard',
    item_category: `Keycard - ${ident.type}`,
    price: ident.price.amount,
    quantity: 1,
});

export type CreateCheckoutIdentification<T extends ObjectType = FrontendType> =
    {
        assignment?: {
            personId: string;
            ticketId: string;
        };
        birthday: CustomDate<T>;
        cardType: string | null;
        email: string;
        firstname: string;
        gender: Gender;
        image: string | null;
        lastname: string;
        title: string;
        type: IdentificationType;
    };

export type CreateIdentificationFormData<T extends ObjectType = FrontendType> =
    {
        title: string;
        firstname: string;
        lastname: string;
        gender: Gender;
        image: string;
        birthday: CustomDate<T>;
        serialNumber?: string;
    };
export const createNewCheckoutIdentification = (
    formData: CreateIdentificationFormData,
    identificationType: IdentificationType
): CreateCheckoutIdentification => ({
    assignment: {
        personId: '',
        ticketId: '',
    },

    cardType: null,
    type: identificationType,

    birthday: formData.birthday,
    email: '',
    firstname: formData.firstname,
    gender: formData.gender,
    image: formData.image,
    lastname: formData.lastname,
    title: formData.title,
});

export const convertCreateCheckoutIdentificationToBackend = (
    identification: CreateCheckoutIdentification
): CreateCheckoutIdentification<BackendType> => ({
    ...identification,
    birthday: convertDateToBackendString(identification.birthday),
    image: identification.image?.split(',')[1] || null,
});

export type RegisterIdentification<T extends ObjectType = FrontendType> = {
    title: string;
    firstname: string;
    lastname: string;
    gender: Gender;
    image: string;
    birthday: CustomDate<T>;
    serialNumber?: string;
};

export const convertRegisterIdentificationToBackend = (
    identification: RegisterIdentification
): RegisterIdentification<BackendType> => ({
    ...identification,
    birthday: convertDateToBackendString(identification.birthday),
    image: identification.image?.split(',')[1] || null,
});
