import { Row } from './tableConfig';

// defines which property should be taken for which column per item
// idProp: propName that should be used for row-id
// rowProps: array of properties to be used for each column. Can be string or function. The function takes
// the passed item as an argument. rowProps array must be of same length as column array
export type RowConfig<T> = {
    idProp: string;
    rowProps: (React.ReactNode | ((item: T) => React.ReactNode))[];
};

export const convertItemsToRows = <T extends object = Record<string, unknown>>(
    items: T[],
    rowConfig: RowConfig<T>
): Row[] =>
    items.map((item) => ({
        id: item[rowConfig.idProp],
        data: rowConfig.rowProps.map((rowProp) => {
            if (typeof rowProp === 'string') {
                return item[rowProp];
            } else if (typeof rowProp === 'function') {
                return rowProp(item);
            } else {
                return rowProp;
            }
        }),
    }));
