import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Box, BoxProps, CircularProgress, useTheme } from '@mui/material';
import { Theme } from '@/theme/theme';

export type FlexContainerProps = {
    children?: React.ReactNode;
    fullWidth?: boolean;
    column?: boolean;
    center?: boolean;
} & BoxProps;

export const FlexContainer = React.forwardRef<
    unknown,
    PropsWithChildren<FlexContainerProps>
>(
    (
        { center, column, fullWidth, children, flexDirection, ...boxProps },
        ref
    ) => (
        <Box
            ref={ref}
            {...boxProps}
            display='flex'
            flexDirection={column ? 'column' : flexDirection}
            width={fullWidth ? '100%' : boxProps.width}
            alignItems={center ? 'center' : boxProps.alignItems}
            justifyContent={center ? 'center' : boxProps.justifyContent}
            boxSizing='border-box'
        >
            {children}
        </Box>
    )
);

FlexContainer.displayName = 'FlexContainer';

export const SectionContainer: FunctionComponent<
    React.PropsWithChildren<Exclude<FlexContainerProps, 'marginY'>>
> = ({ children, ...rest }) => {
    const theme: Theme = useTheme();

    return (
        <FlexContainer {...rest} marginY={theme.spacing()}>
            {children}
        </FlexContainer>
    );
};

export const LoadingContainer: FunctionComponent<
    React.PropsWithChildren<FlexContainerProps>
> = (props) => (
    <FlexContainer
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        flexGrow={1}
        {...props}
    >
        <CircularProgress />
    </FlexContainer>
);
