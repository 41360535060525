import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { FlexContainer } from '@/components/Layout/Container';
import {
    getPackageProductCollectionPrice,
    ProductCollection,
} from '@/models/collection/productCollection';
import { useTranslation } from '@/hooks/useTranslation';
import { getMoneyAsString } from '@/utils/general';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import {
    useGetCurrentCheckoutSession,
    usePostCheckoutTicketMutation,
} from '@/api/checkout';
import { LoadingButton } from '@mui/lab';
import { DATE_FORMAT_API } from '@/constants';

type ProductCollectionPackageBottomProps = {
    productCollection: ProductCollection;
};

export const ProductCollectionPackageBottom: FunctionComponent<
    ProductCollectionPackageBottomProps
> = ({ productCollection }) => {
    const { getTranslated } = useTranslation();
    const { IS_OEAMTC } = useConfigSlice();
    const [postTicket, { isLoading }] = usePostCheckoutTicketMutation();
    const { data: checkout } = useGetCurrentCheckoutSession();
    return (
        <FlexContainer
            sx={(theme) => ({
                mt: 'auto',
                pt: 4,
                pb: 1,
                px: 1,
                justifyContent: 'space-between',
                alignItems: 'center',

                [theme.breakpoints.down('lg')]: {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'space-between',
                },
            })}
        >
            <Typography
                sx={(theme) => ({
                    fontWeight: 600,
                    mr: 2,

                    [theme.breakpoints.down('lg')]: {
                        mb: 3,
                        mr: 0,
                    },
                })}
            >
                {getTranslated('Cart.total')}&nbsp;
                {getMoneyAsString(
                    getPackageProductCollectionPrice(productCollection)
                )}
            </Typography>

            <LoadingButton
                loading={isLoading}
                variant='contained'
                color='secondary'
                onClick={() => {
                    // exit should not happen - but to be sure
                    if (!checkout) {
                        return;
                    }

                    postTicket({
                        checkoutId: checkout?.id || '',
                        liftOperator: productCollection.liftOperator.id,
                        productId: productCollection.products[0]?.productId,
                        startDate:
                            productCollection.startDay.format(DATE_FORMAT_API),
                    });
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: 3,

                    ...(!IS_OEAMTC && {
                        color: '#fff',
                    }),
                }}
                disabled={false}
            >
                <Typography>{getTranslated('add')}</Typography>

                <AddShoppingCartIcon
                    sx={{
                        ml: 2,
                        mr: 3,
                    }}
                />
            </LoadingButton>
        </FlexContainer>
    );
};
