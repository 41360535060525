import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import {
    Avatar as MuiAvatar,
    Box,
    CardContent,
    Tooltip,
    Typography,
} from '@mui/material';
import { formatDate } from '@/utils/time';
import { styled } from '@mui/material/styles';
import { FlatCard } from '@/components/Card/FlatCard';
import {
    AssignmentPossibility,
    PossibleIdentificationAssignment,
} from '@/models/checkout/possibleIdentificationAssignment';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';

const Content = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(3),
    '&:last-child': {
        paddingBottom: theme.spacing(3),
    },
}));

const Avatar = styled(MuiAvatar)<{
    selected?: boolean;
    disabled?: boolean;
}>(({ theme, selected, disabled }) => ({
    width: 80,
    height: 80,

    ...(selected && {
        borderColor: theme.palette.sjTurquoiseMain,
    }),

    [theme.breakpoints.down('md')]: {
        ...(disabled && {
            width: 120,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }),
    },
}));

const LabelContainer = styled(FlexContainer)(({ theme }) => ({
    alignItems: 'flex-start',
    marginLeft: theme.spacing(6),
}));

type IdentificationBadgeProps = {
    color: string;
    isAssigned: boolean;
    assignmentSuggestion: PossibleIdentificationAssignment;
    isLoading?: boolean;
    onDelete?: () => void;
    onSelect?: () => void;
    tooltipText?: string;
};

export const IdentificationBadge: FunctionComponent<
    IdentificationBadgeProps
> = ({
    assignmentSuggestion,
    isAssigned,
    onSelect,
    onDelete,
    isLoading = false,
}) => {
    const { getTranslated } = useTranslation();
    const { firstname, lastname, birthday, imageUrl, serialNumber } =
        assignmentSuggestion;

    const selectionBlocked =
        assignmentSuggestion.possibility !== AssignmentPossibility.POSSIBLE &&
        assignmentSuggestion.severity === 'ERROR';

    return (
        <FlatCard
            sx={(theme) => ({
                position: 'relative',
                width: '320px',
                m: 2,
                transition: 'all 200ms ease-in-out',

                ...(!isLoading &&
                    !isAssigned &&
                    !selectionBlocked && {
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: theme.design.hover.backgroundColor,
                        },
                    }),

                ...(isLoading && {
                    opacity: '0.4',
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                }),

                ...(isAssigned && {
                    border: `2px solid ${theme.palette.secondary.main}`,
                }),
            })}
            onClick={() => {
                if (isAssigned) {
                    onDelete?.();
                } else {
                    onSelect?.();
                }
            }}
        >
            {selectionBlocked && (
                <Box
                    sx={{
                        backgroundColor: 'rgba(236, 239, 241, 0.75)',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                    }}
                >
                    <Tooltip
                        title={getTranslated(
                            assignmentSuggestion.possibility as TranslationKey
                        )}
                    >
                        <IconButton
                            sx={{
                                top: 4,
                                right: 4,
                                position: 'absolute',
                            }}
                        >
                            <InfoIcon color='primary' />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            {isAssigned && (
                <TaskAltIcon
                    sx={{
                        top: 4,
                        right: 4,
                        position: 'absolute',
                    }}
                    color='secondary'
                />
            )}

            <Content
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                }}
            >
                <Avatar src={imageUrl} selected={isAssigned} />

                <LabelContainer column fullWidth center>
                    <Typography sx={{ fontWeight: 600 }}>
                        {firstname} {lastname}
                    </Typography>

                    {birthday && (
                        <Typography>{formatDate(birthday)}</Typography>
                    )}

                    {serialNumber && (
                        <Typography>
                            SN: {serialNumber.slice(0, 5)} ...{' '}
                            {serialNumber.slice(-5)}
                        </Typography>
                    )}
                </LabelContainer>
            </Content>
        </FlatCard>
    );
};
