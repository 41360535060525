import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@/theme/theme';
import { default as MuiAppBar } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuthentication } from '@/hooks/useAuthentication';
import { FlexContainer } from '@/components/Layout/Container';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import starjackLogo from '@/assets/images/starjackLogo.png';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';
import { Badge, styled, useMediaQuery } from '@mui/material';
import { getPropByCurrentLocale } from '@/utils/locale';
import { IS_DEVELOPMENT } from '@/constants';
import { StagingBanner } from '@/components/AppBar/StagingBanner';
import { DrawerContainer } from '@/components/AppBar/DrawerContainer';
import taelerClaim from '@/assets/images/3taelerLogoClaim.png';
import { getLiftOperatorProductsPageLink } from '@/models/liftOperators';
import { useGetUserDataQuery } from '@/api/user';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { useCheckoutHelpers } from '@/hooks/useCheckoutHelpers';

const Logo = styled('img')(() => ({
    cursor: 'pointer',
}));

const ToolBarButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(2),
    marginTop: 0,
    border: 'none',
    [theme.breakpoints.down('lg')]: {
        width: '40px',
        padding: '0',
        mr: '0',
        ml: 3,
    },
}));

type AppBarProps = {
    showFaqsButton: boolean;
    onFaqsClick?: () => void;
};

export const AppBar: FunctionComponent<AppBarProps> = ({
    showFaqsButton,
    onFaqsClick,
}) => {
    const { isLoggedIn } = useAuthentication();
    const navigate = useNavigate();
    const { numberOfItemsInCart } = useCheckoutHelpers();
    const { data: currentUser } = useGetUserDataQuery();
    const { getTranslated, locale } = useTranslation();
    const { routerConfig, getPagePath } = useCustomRouter();
    const { IS_PORTAL, IS_INTEGRATION, pageConfig, IS_OEAMTC, setLocale } =
        useConfigSlice();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const theme: Theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const noClaim =
        IS_INTEGRATION &&
        pageConfig.configuration.vanityIdentifier !== '3taeler';

    const isStarjackLogo = IS_PORTAL || noClaim;

    let logo;
    let altLabel;
    if (IS_OEAMTC) {
        logo = pageConfig.configuration.logoUrl;
        altLabel = 'ÖAMTC';
    } else if (isStarjackLogo) {
        logo = starjackLogo;
        altLabel = 'Starjack';
    } else {
        logo = taelerClaim;
        altLabel = getPropByCurrentLocale(selectedLiftOperator?.name);
    }

    const color = IS_OEAMTC ? '#000' : '#fff';
    return (
        <MuiAppBar
            sx={(theme) => ({
                height: theme.appBar.height,
                top: 0,
            })}
            position='fixed'
        >
            {IS_DEVELOPMENT && <StagingBanner />}

            <Toolbar>
                <FlexContainer
                    sx={{
                        cursor: 'pointer',
                        alignItems: 'center',
                    }}
                    onClick={() => {
                        if (IS_OEAMTC) {
                            window.open('https://oeamtc.at/skipass');
                        } else if (IS_PORTAL) {
                            navigate(getPagePath(routerConfig.Home));
                        } else {
                            navigate(
                                getPagePath(routerConfig.Products, [
                                    {
                                        param: 'id',
                                        value: getLiftOperatorProductsPageLink(
                                            selectedLiftOperator,
                                            locale
                                        ),
                                    },
                                ])
                            );
                        }
                    }}
                >
                    <Logo
                        sx={{
                            height: '40px',
                            ...(IS_OEAMTC && {
                                height: '52px',
                                borderRadius: '4px',
                                mr: 3,
                                border: '1px solid #fff',
                            }),
                        }}
                        alt={altLabel}
                        src={logo}
                    />
                </FlexContainer>

                <FlexContainer
                    sx={{
                        ml: 'auto',
                        alignItems: 'center',
                    }}
                >
                    {showFaqsButton && (
                        <Button
                            color='inherit'
                            onClick={onFaqsClick}
                            variant='outlined'
                        >
                            {getTranslated('FAQPage.faq')}
                        </Button>
                    )}

                    <ToolBarButton
                        onClick={() => {
                            setLocale(locale === 'de' ? 'en' : 'de');
                        }}
                        color='inherit'
                        variant='outlined'
                    >
                        {matchesMobile
                            ? locale === 'de'
                                ? 'en'
                                : 'de'
                            : locale === 'de'
                            ? 'English'
                            : 'Deutsch'}
                    </ToolBarButton>

                    {isLoggedIn && !!numberOfItemsInCart && (
                        <ToolBarButton
                            onClick={() => {
                                navigate(getPagePath(routerConfig.Cart));
                            }}
                            color='inherit'
                        >
                            <Badge
                                sx={{
                                    color,
                                    right: 2,
                                }}
                                badgeContent={numberOfItemsInCart}
                            >
                                <ShoppingCartIcon
                                    sx={{
                                        fontSize: '1.2rem',
                                        mr: 1,
                                        color,
                                    }}
                                />
                            </Badge>

                            {/*{!matchesMobile && getTranslated('common.basket')}*/}
                        </ToolBarButton>
                    )}

                    <FlexContainer
                        sx={(theme) => ({
                            [theme.breakpoints.down('lg')]: {
                                display: 'none',
                            },
                        })}
                    >
                        {isLoggedIn ? (
                            <Typography
                                sx={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    color: color,
                                }}
                                onClick={() => {
                                    navigate(getPagePath(routerConfig.MyData));
                                }}
                            >
                                <AccountCircleIcon
                                    sx={{
                                        mr: 1,
                                        color,
                                    }}
                                />
                                {currentUser?.email}
                            </Typography>
                        ) : (
                            <>
                                <Button
                                    sx={{
                                        color,
                                    }}
                                    color='inherit'
                                    onClick={() =>
                                        navigate(
                                            getPagePath(routerConfig.Register)
                                        )
                                    }
                                >
                                    {getTranslated('RegisterForm.register')}
                                </Button>
                                <Button
                                    sx={{
                                        color,
                                    }}
                                    onClick={() => {
                                        navigate(
                                            getPagePath(routerConfig.Login)
                                        );
                                    }}
                                >
                                    {getTranslated('MyAccountNavigation.login')}
                                </Button>
                            </>
                        )}
                    </FlexContainer>

                    <FlexContainer
                        sx={() => ({
                            mr: 2,
                            ml: 8,
                            [theme.breakpoints.down('lg')]: {
                                ml: 2,
                            },
                        })}
                    >
                        <DrawerContainer />
                    </FlexContainer>
                </FlexContainer>
            </Toolbar>
        </MuiAppBar>
    );
};
