import { FunctionComponent } from 'react';
import { FlexContainer } from '../Layout/Container';
import CircularProgress from '@mui/material/CircularProgress';

const BaseContainer: FunctionComponent<
    React.PropsWithChildren<{ children: React.ReactNode }>
> = ({ children }) => (
    <FlexContainer
        alignItems={'center'}
        justifyContent={'center'}
        minHeight={'80px'}
        fullWidth
    >
        {children}
    </FlexContainer>
);

export const NoEntries: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => <BaseContainer>Keine Einträge</BaseContainer>;

export const NoEntriesProgress: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => (
    <BaseContainer>
        <CircularProgress />
    </BaseContainer>
);
