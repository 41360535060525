import { FunctionComponent, useMemo } from 'react';
import { CheckoutTicketCollection } from '@/models/collection/checkoutTicketCollection';
import { FlexContainer } from '@/components/Layout/Container';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import { IdentificationType } from '@/models/cart';
import {
    useGetCurrentCheckoutSession,
    usePutCheckoutTicketMutation,
} from '@/api/checkout';
import { updateIdentificationTypeCacheKey } from '@/components/PageContent/CartPageContent/CheckoutItemList/Ticket/TicketIdentTypeSelectionContainer';
import { LoadingButton } from '@mui/lab';

type IdentificationSelectionProps = {
    selectedIdentificationType: IdentificationType;
    supportedIdentificationTypes: IdentificationType[];
} | null;

type PersonAssignmentTitleProps = {
    ticketCollection: CheckoutTicketCollection;
};

export const PersonAssignmentTitle: FunctionComponent<
    PersonAssignmentTitleProps
> = ({ ticketCollection }) => {
    const { getTranslated } = useTranslation();
    const [putTicket, { isLoading }] = usePutCheckoutTicketMutation({
        fixedCacheKey: updateIdentificationTypeCacheKey,
    });
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    const identificationSelectionPropsofPackage: IdentificationSelectionProps =
        useMemo(() => {
            if (!ticketCollection.isPackage) return null;

            return {
                selectedIdentificationType:
                    ticketCollection.checkoutTickets[0].identificationType,
                supportedIdentificationTypes:
                    ticketCollection.checkoutTickets[0]
                        .supportedIdentificationTypes,
            };
        }, [ticketCollection]);

    const showHowToBookYourTicket =
        !identificationSelectionPropsofPackage ||
        !identificationSelectionPropsofPackage.selectedIdentificationType;

    return (
        <Box
            sx={(theme) => ({
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
                p: 3,
            })}
        >
            {showHowToBookYourTicket ? (
                <Typography>{getTranslated('howToBookYourTicket')}</Typography>
            ) : (
                <>
                    <FlexContainer
                        sx={(theme) => ({
                            alignItems: 'center',
                            width: '100%',

                            [theme.breakpoints.down('lg')]: {
                                flexDirection: 'column',
                            },
                        })}
                    >
                        <Typography
                            sx={{
                                mr: 2,
                            }}
                        >
                            {getTranslated('chosenBookingOption')}{' '}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            {getTranslated(
                                identificationSelectionPropsofPackage.selectedIdentificationType
                            )}
                        </Typography>

                        {identificationSelectionPropsofPackage
                            .supportedIdentificationTypes.length > 1 && (
                            <LoadingButton
                                loading={isLoading}
                                sx={(theme) => ({
                                    color: '#fff',
                                    borderColor: '#fff',
                                    ml: 'auto',
                                    '&:hover': {
                                        borderColor: '#fff',
                                    },

                                    [theme.breakpoints.down('lg')]: {
                                        mt: 3,
                                        mr: 'auto',
                                    },
                                })}
                                variant='outlined'
                                onClick={() => {
                                    const [checkoutTicket] =
                                        ticketCollection.checkoutTickets;

                                    if (checkoutSession) {
                                        putTicket({
                                            checkoutId: checkoutSession.id,
                                            id: checkoutTicket.id,
                                            identificationType: null,
                                        });
                                    }
                                }}
                            >
                                {getTranslated('changeBookingOption')}
                            </LoadingButton>
                        )}
                    </FlexContainer>
                    <Divider
                        sx={{
                            width: '100%',
                            my: 5,
                            borderColor: '#fff',
                            backgroundColor: '#fff',
                        }}
                    />
                    <Typography>
                        {getTranslated('pleaseSelectPerson')}
                    </Typography>
                </>
            )}
        </Box>
    );
};
