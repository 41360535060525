import { FunctionComponent, useState } from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DateRange } from '@/models/general';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '@/constants';
import { useTranslation } from '@/hooks/useTranslation';
import { DatePickerToolbar } from '@mui/x-date-pickers/DatePicker/DatePickerToolbar';
import { styled } from '@mui/material/styles';
import { SelectionButton } from '@/components/PageContent/ProductsPageContent/ProductFilter/SelectionButton';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { FlexContainer } from '@/components/Layout/Container';
import * as React from 'react';
import { BaseToolbarProps } from '@mui/x-date-pickers/internals';
import { DateView, PickersLayout } from '@mui/x-date-pickers';
import { DialogActions } from '@mui/material';
import Button from '@mui/material/Button';

type DateSelectionWithPickerProps = {
    value: Moment;
    validDateRange: DateRange;
    onChange: (date: Moment) => void;
};

export const DateSelectionWithPicker: FunctionComponent<
    React.PropsWithChildren<DateSelectionWithPickerProps>
> = ({ validDateRange, value, onChange }) => {
    const { getTranslated } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const { from, to } = validDateRange;

    return (
        <MobileDatePicker
            value={value}
            defaultValue={moment()}
            open={isOpen}
            format={DATE_FORMAT}
            shouldDisableDate={(date: Moment) => {
                return date.isBefore(from) || date.isAfter(to);
            }}
            onChange={(newValue: Moment) => {
                onChange(newValue);
                setIsOpen(false);
            }}
            onClose={() => {
                setIsOpen(false);
            }}
            slots={{
                layout: (props) => (
                    <PickersLayout
                        {...props}
                        sx={{
                            ...props.sx,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    />
                ),
                toolbar: PickerToolbar,
                actionBar: DatePickerActions,
                textField: () => (
                    <SelectionButton
                        isSelected={!!value}
                        onClick={() => {
                            setIsOpen(true);
                        }}
                        adornment={<DateRangeIcon />}
                    >
                        <FlexContainer
                            sx={{
                                justifyContent: 'center',
                                width: '160px',
                            }}
                        >
                            {value
                                ? value.format(DATE_FORMAT)
                                : getTranslated('Core.chooseDate')}
                        </FlexContainer>
                    </SelectionButton>
                ),
            }}
            disablePast
        />
    );
};

const DatePickerToolbarMoment = (
    props: BaseToolbarProps<Moment, DateView> &
        React.RefAttributes<HTMLDivElement>
) => <DatePickerToolbar<Moment> {...props} />;

const PickerToolbar = styled(DatePickerToolbarMoment)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',

    span: {
        color: '#fff',
    },
}));

const DatePickerActions: FunctionComponent<{
    onAccept: () => void;
    onCancel: () => void;
}> = ({ onAccept, onCancel }) => {
    const { getTranslated } = useTranslation();
    return (
        <DialogActions>
            <Button onClick={onCancel}>{getTranslated('Core.close')}</Button>

            <Button variant='outlined' onClick={onAccept}>
                {getTranslated('accept')}
            </Button>
        </DialogActions>
    );
};
