import { FunctionComponent, useState } from 'react';
import { FlexContainer, LoadingContainer } from '@/components/Layout/Container';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useNavigate } from 'react-router';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { useTranslation } from '@/hooks/useTranslation';
import { AddIdentificationFormContainer } from '@/components/AddIdentificationForm/AddIdentificationFormContainer';
import { IdentificationType } from '@/models/cart';
import { useNotification } from '@/hooks/useNotification';
import { useGetCurrentCheckoutSession } from '@/api/checkout';
import { useGetOrderInfoQuery } from '@/api/order';

export const NewKeycardPage: FunctionComponent = () => {
    const { getTranslated } = useTranslation();
    const { isLoading: loadingFetchOrderInfo } = useGetOrderInfoQuery();
    const { addNotification } = useNotification();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const [addedToCart, setAddedToCart] = useState(false);
    const { routerConfig, getPagePath } = useCustomRouter();
    const navigate = useNavigate();

    return (
        <SubPageTemplate
            title={getTranslated('common.createNewCard')}
            widthExtended
        >
            {loadingFetchOrderInfo ? (
                <LoadingContainer />
            ) : (
                <>
                    {addedToCart ? (
                        <FlexContainer
                            sx={{
                                height: '240px',
                            }}
                            alignItems='center'
                            justifyContent='space-between'
                            column
                        >
                            <CheckCircleOutlineIcon
                                color='success'
                                sx={{
                                    width: '64px',
                                    height: '64px',
                                }}
                            />
                            <Typography variant='h6' gutterBottom>
                                {getTranslated('Keycard.added')}
                            </Typography>

                            <FlexContainer
                                fullWidth
                                justifyContent='space-evenly'
                            >
                                <Button
                                    onClick={() => {
                                        navigate(
                                            getPagePath(routerConfig.Cart)
                                        );
                                    }}
                                    isLoading={false}
                                    variant='contained'
                                    color='primary'
                                >
                                    {getTranslated('Core.toCart')}
                                </Button>
                            </FlexContainer>
                        </FlexContainer>
                    ) : (
                        <AddIdentificationFormContainer
                            checkoutId={checkoutSession?.id || ''}
                            identificationType={IdentificationType.KEYCARD}
                            onSuccess={() => {
                                addNotification({
                                    type: 'success',
                                    message: getTranslated('Keycard.added'),
                                });
                                setAddedToCart(true);
                            }}
                            onError={() => {
                                addNotification({
                                    type: 'error',
                                    message:
                                        getTranslated('createKeycardError'),
                                });
                            }}
                        />
                    )}
                </>
            )}
        </SubPageTemplate>
    );
};
