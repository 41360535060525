import { PortalConfig } from '@/slices/configSlice';

let pageConfiguration: PortalConfig = null;

export const setPortalConfig = (config: PortalConfig) => {
    pageConfiguration = config;
};

export const getPortalConfig = () => {
    return pageConfiguration;
};
