import { FunctionComponent, PropsWithChildren } from 'react';
import {
    Box,
    CardActions,
    CardContent,
    CardMedia,
    Fab,
    Tooltip,
    Typography,
} from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { formatDate } from '@/utils/time';
import {
    Keycard,
    KeycardTransfer,
    KeycardTransferStatus,
} from '@/models/order';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import { FlatCard } from '@/components/Card/FlatCard';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

type KeycardCardProps = {
    keycard: Keycard;
    keycardTransfer: KeycardTransfer;
    onKeycardDelete: (keycard: Keycard) => void;
    onTransferKeycard: (keycard: Keycard, stop?: boolean) => void;
    promotionCodeButtonClick: (keycardId: string) => void;
    onEditImage: (keycard: Keycard) => void;
};

export const KeycardCard: FunctionComponent<
    PropsWithChildren<KeycardCardProps>
> = ({
    keycard,
    onKeycardDelete,
    keycardTransfer,
    promotionCodeButtonClick,
    onTransferKeycard,
    onEditImage,
}) => {
    const { getTranslated } = useTranslation();

    return (
        <>
            <FlatCard
                sx={{
                    width: '100%',
                }}
            >
                <CardContent
                    sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            width: '30%',
                        }}
                    >
                        <CardMedia
                            sx={(theme) => ({
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: theme.spacing(35),
                                height: theme.spacing(46),
                                borderRadius: 1,

                                ...(!keycard.image && {
                                    border: `2px solid ${grey['200']}`,
                                }),
                            })}
                            image={keycard.image || ''}
                        >
                            {!keycard.image && (
                                <PersonIcon
                                    color='primary'
                                    sx={{
                                        fontSize: '64px',
                                    }}
                                />
                            )}

                            {!keycard.deletionDate && (
                                <Tooltip
                                    title={getTranslated('changePhotoData')}
                                >
                                    <Fab
                                        color='primary'
                                        sx={{
                                            position: 'absolute',
                                            width: 40,
                                            height: 40,
                                            bottom: 8,
                                            right: 8,
                                        }}
                                        onClick={() => onEditImage(keycard)}
                                    >
                                        <EditIcon />
                                    </Fab>
                                </Tooltip>
                            )}
                        </CardMedia>
                    </Box>

                    <FlexContainer flexGrow={1} justifyContent='space-between'>
                        <FlexContainer column>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {keycard.firstname}, {keycard.lastname}
                            </Typography>
                            <Typography sx={{ mb: '8px' }}>
                                {formatDate(keycard.birthday)}
                            </Typography>

                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {getTranslated('common.status')}:
                            </Typography>

                            {keycard.external ? (
                                <Typography>
                                    {getTranslated('keycard.external')}
                                </Typography>
                            ) : (
                                <Typography gutterBottom>
                                    {getTranslated(
                                        `keycard.status.${keycard.status}` as TranslationKey
                                    )}
                                </Typography>
                            )}
                        </FlexContainer>

                        <FlexContainer column>
                            {!!keycard.serialNumber && (
                                <>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {getTranslated(
                                            'MyCardsPage.serialNumber'
                                        )}
                                        :
                                    </Typography>
                                    <Typography>
                                        {keycard.serialNumber}
                                    </Typography>
                                </>
                            )}

                            {!!keycard.singleCardCodes.length && (
                                <>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {getTranslated(
                                            'MyCardsPage.verifiedCodes'
                                        )}
                                        :
                                    </Typography>
                                    {keycard.singleCardCodes.map(
                                        (codeObj, i) => (
                                            <Typography key={i}>
                                                {codeObj.code}
                                            </Typography>
                                        )
                                    )}
                                </>
                            )}
                        </FlexContainer>
                    </FlexContainer>
                </CardContent>

                {!keycard.deletionDate && (
                    <CardActions
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <ActionBottomLink
                            color='error'
                            onClick={() => onKeycardDelete(keycard)}
                        >
                            {getTranslated('MyCardsPage.delete')}
                        </ActionBottomLink>

                        {keycardTransfer?.status ===
                        KeycardTransferStatus.READY ? (
                            <ActionBottomLink
                                onClick={() => onTransferKeycard(keycard, true)}
                            >
                                {getTranslated('MyCardsPage.cancelTransfer')}
                            </ActionBottomLink>
                        ) : (
                            <ActionBottomLink
                                onClick={() => onTransferKeycard(keycard)}
                            >
                                {getTranslated('MyCardsPage.transfer')}
                            </ActionBottomLink>
                        )}

                        <ActionBottomLink
                            onClick={() => promotionCodeButtonClick(keycard.id)}
                        >
                            {getTranslated('MyCardsPage.verify')}
                        </ActionBottomLink>
                    </CardActions>
                )}
            </FlatCard>
        </>
    );
};

const ActionBottomLink = styled(LoadingButton)(() => ({
    fontSize: '.85rem',
    textTransform: 'uppercase',
    textDecoration: 'none',
}));
