import { Promotion } from '@/models/promotion';

export const promotions: Promotion[] = [
    {
        name: 'ezra',
        date: '30.03.2023',
        color: '#fff',
    },
    {
        name: 'eros',
        date: '30.04.2023',
        color: '#fff',
    },
    {
        name: 'sportfreunde',
        date: '25.03.2023',
        color: '#fff',
    },
    {
        name: 'wanda',
        date: '09.12.2023',
        color: '#fff',
    },
    {
        name: 'kelly',
        date: '16.03.2024',
        color: '#fff',
    },
];
