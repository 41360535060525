import {
    BackendType,
    convertDateToFrontend,
    CustomDate,
    FrontendType,
    Money,
    MultiLangString,
    ObjectType,
} from '@/models/general';
import { ProductType } from '@/models/product';
import {
    CheckoutPerson,
    convertCheckoutPersonToFrontend,
} from '@/models/checkout/checkoutPerson';
import { IdentificationType } from '@/models/cart';
import { CheckoutValidation } from '@/models/checkout/checkoutValidation';

export type CheckoutTicket<T extends ObjectType = FrontendType> = {
    availableContingent: number | undefined | null;
    id: string;
    liftOperatorId: string;
    liftOperatorName: MultiLangString;
    name: MultiLangString;
    type: ProductType;
    duration: number;
    photoRequired: boolean;
    price: Money;
    originalPrice: Money;
    startDate: CustomDate<T>;
    productId: string;
    cancellationBlocked: boolean;
    supportedIdentificationTypes: IdentificationType[];
    shortProductId: string;
    identificationType: IdentificationType;
    persons: CheckoutPerson<T>[];
    validation: CheckoutValidation;
    aonInsurances: CheckoutTicketAonInsurance[];
};

export const convertCheckoutTicketToFrontend = (
    ticket: CheckoutTicket<BackendType>
): CheckoutTicket => ({
    ...ticket,
    startDate: convertDateToFrontend(ticket.startDate),
    persons: ticket.persons.map(convertCheckoutPersonToFrontend),
});

export const getTicketPriceWithInsurance = (ticket: CheckoutTicket): Money => {
    if (ticket.aonInsurances?.[0]?.selected) {
        return {
            ...ticket.price,
            amount: ticket.price.amount + ticket.aonInsurances[0].price.amount,
        };
    }
    return ticket.price;
};

export const ticketHasAHAPromotion = (ticket: CheckoutTicket) => {
    if (ticket.liftOperatorName.de.toLocaleLowerCase().includes('feldberg')) {
        return false;
    }

    const [person] = ticket.persons;

    if (person.name.de.toLocaleLowerCase().includes('paragleiter')) {
        return false;
    }

    if (ticket.persons.length === 1) {
        return person.name.de.toLocaleLowerCase().includes('jugend');
    }

    // package tickets

    return ticket.persons.some(
        (person) =>
            person.name.de.toLocaleLowerCase().includes('jugend') ||
            person.name.de.toLocaleLowerCase().includes('erwachsen')
    );
};

export const mapCheckoutTicketToGAItem = (ticket: CheckoutTicket) => {
    const { productId, name, persons, liftOperatorName, type, price } = ticket;
    const isPackage = persons.length > 1;
    return {
        item_id: productId,
        item_name: isPackage
            ? `${name.de} - ${persons.map((p) => `${p.name.de}`).join(', ')}`
            : `${name.de} - ${persons[0].name.de}`,
        item_brand: liftOperatorName.de,
        item_category: type,
        item_category2: isPackage ? 'package' : 'single',
        item_category3: `${persons.map((p) => `${p.name.de}`).join(', ')}`,
        price: price.amount,
    };
};

type CheckoutTicketAonInsurance = {
    price: Money;
    selected: boolean;
    tariffIdentifier: string;
    tariffName: MultiLangString;
    tariffShortDescription: MultiLangString;
};
