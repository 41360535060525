import { FunctionComponent } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import textile from 'textile-js';

type TicketInfoDialogProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    body: string;
};

export const TicketInfoDialog: FunctionComponent<
    React.PropsWithChildren<TicketInfoDialogProps>
> = ({ open, onClose, title, body }) => {
    return (
        <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'
        >
            <DialogTitle id='alert-dialog-slide-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: textile(body || ''),
                        }}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary'>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
