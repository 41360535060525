import { useGetCurrentCheckoutSession } from '@/api/checkout';
import { useMemo } from 'react';
import { useConfigSlice } from '@/hooks/useConfigSlice';

export const useCheckoutHelpers = () => {
    const { data: checkout } = useGetCurrentCheckoutSession();
    const { IS_PORTAL, IS_OEAMTC } = useConfigSlice();

    const isCheckoutEmpty = useMemo(
        () =>
            checkout?.tickets.length === 0 &&
            checkout?.identifications.length === 0 &&
            checkout?.vouchers.length === 0,
        [checkout]
    );

    const numberOfItemsInCart = useMemo(
        () =>
            checkout
                ? checkout?.tickets.length +
                  checkout?.shopProducts.length +
                  checkout?.vouchers.length +
                  checkout?.identifications.length
                : 0,
        [checkout]
    );

    const insuranceSelectionPossible = useMemo(() => {
        return (
            IS_PORTAL &&
            checkout?.tickets.some((ticket) => ticket.aonInsurances.length > 0)
        );
    }, [checkout]);

    const hasInsuranceSelected = useMemo(() => {
        return checkout?.tickets.some((ticket) =>
            ticket.aonInsurances?.some((insurance) => insurance.selected)
        );
    }, [checkout]);

    const insuranceConsentSelected =
        checkout?.insurance?.mandatoryDocumentsDownloaded;

    const isInsuranceEnabled =
        IS_PORTAL &&
        !IS_OEAMTC &&
        checkout?.tickets.some((t) => t.aonInsurances.length > 0);

    return {
        numberOfItemsInCart,
        isCheckoutEmpty,
        hasInsuranceSelected,
        insuranceConsentSelected,
        insuranceSelectionPossible,
        isInsuranceEnabled,
    };
};
