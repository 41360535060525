import { baseApiAuthenticated } from '.';
import { urlBuilder } from '@/utils/api';
import {
    convertIdentificationSuggestionToFrontend,
    convertKeycardSuggestionToFrontend,
    convertKeycardToFrontend,
    convertTransferKeycardToFrontend,
    IdentificationSuggestion,
    Keycard,
    KeycardSuggestion,
    KeycardTransfer,
    mapOrderToFrontend,
    Order,
    OrderInfo,
} from '@/models/order';
import { BackendType, Money } from '@/models/general';
import {
    convertRegisterIdentificationToBackend,
    RegisterIdentification,
} from '@/models/checkout/checkoutIdentification';
import {
    convertCreditToFrontend,
    convertProfileToFrontend,
    Credit,
    Profile,
} from '@/models/user';
import { VoucherTemplate } from '@/models/voucher';
import { KeycardTransferCollection } from '@/models/keycard';
import { convertUserTicketToFrontend, UserTicket } from '@/models/ticket';

type GetIdentificationsResponseType = {
    keycardSuggestions: KeycardSuggestion[];
    identificationSuggestions: IdentificationSuggestion[];
};

type GetIdentificationsResponseTypeBackend = {
    keycardSuggestions: KeycardSuggestion<BackendType>[];
    genericSuggestions: IdentificationSuggestion<BackendType>[];
};

type GetKeycardTransfersResponseBackend = {
    sent: KeycardTransfer<BackendType>[];
    received: KeycardTransfer<BackendType>[];
};

type PostVoucherPreviewBody = {
    voucherTemplateId: string;
    amount: Money;
    sender: string;
    receiver: string;
    text: string;
    language: string;
};

const orderUrls = () => ({
    orders: urlBuilder('order', 'orders'),
    postKeycard: urlBuilder('order', 'keycards'),
    getSuggestions: urlBuilder('order', 'identification-suggestions'),
    profiles: urlBuilder('order', 'profiles'),
    credit: urlBuilder('order', 'credit'),
    info: urlBuilder('order', 'orders/info'),
    keycardTransfer: urlBuilder('order', 'keycard-transfers'),
    vouchers: urlBuilder('order', 'vouchers'),
    keycards: urlBuilder('order', 'keycards'),
    tickets: urlBuilder('order', '/tickets'),
    cardcodeusage: urlBuilder('order', 'cardcodeusage'),
});

export const orderApi = baseApiAuthenticated
    .enhanceEndpoints({
        addTagTypes: [
            'CREDIT',
            'PROFILE',
            'KEYCARD_TRANSFER',
            'KEYCARDS',
            'USER_TICKETS',
        ],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getOrders: builder.query<Order[], void>({
                query: () => orderUrls().orders,
                transformResponse: (response: {
                    orders: Order<BackendType>[];
                }) => response.orders.map(mapOrderToFrontend),
            }),

            getSuggestions: builder.query<
                GetIdentificationsResponseType,
                unknown
            >({
                query: () => orderUrls().getSuggestions,
                transformResponse: (
                    response: GetIdentificationsResponseTypeBackend
                ) => {
                    return {
                        keycardSuggestions: response.keycardSuggestions.map(
                            convertKeycardSuggestionToFrontend
                        ),
                        identificationSuggestions:
                            response.genericSuggestions.map(
                                convertIdentificationSuggestionToFrontend
                            ),
                    };
                },
            }),

            postKeycard: builder.mutation<Keycard, RegisterIdentification>({
                query: (identification) => ({
                    method: 'POST',
                    url: orderUrls().postKeycard,
                    body: convertRegisterIdentificationToBackend(
                        identification
                    ),
                }),

                transformResponse(response: Keycard<BackendType>) {
                    return convertKeycardToFrontend(response);
                },
            }),

            getProfiles: builder.query<Profile[], void>({
                query: () => `${orderUrls().profiles}?psp=SIX`,
                transformResponse: (response: {
                    profiles: Profile<BackendType>[];
                }) => response.profiles.map(convertProfileToFrontend),

                providesTags: ['PROFILE'],
            }),

            deleteProfile: builder.mutation<void, { id: string }>({
                query: ({ id }) => ({
                    method: 'DELETE',
                    url: `${orderUrls().profiles}/${id}?psp=SIX`,
                }),
                invalidatesTags: ['PROFILE'],
            }),

            getCredit: builder.query<Credit, void>({
                query: () => orderUrls().credit,
                transformResponse: (rsponse: Credit<BackendType>) =>
                    convertCreditToFrontend(rsponse),
                providesTags: ['CREDIT'],
            }),

            getOrderInfo: builder.query<OrderInfo, void>({
                query: () => orderUrls().info,
            }),

            getKeycardTransfers: builder.query<KeycardTransferCollection, void>(
                {
                    query: () => orderUrls().keycardTransfer,
                    transformResponse: (
                        response: GetKeycardTransfersResponseBackend
                    ) => ({
                        sent: response.sent.map(
                            convertTransferKeycardToFrontend
                        ),
                        received: response.received.map(
                            convertTransferKeycardToFrontend
                        ),
                    }),

                    providesTags: ['KEYCARD_TRANSFER'],
                }
            ),

            deleteKeycardTransfer: builder.mutation<
                void,
                { keycardId: string }
            >({
                query: ({ keycardId }) => ({
                    method: 'DELETE',
                    url: `${orderUrls().keycardTransfer}/${keycardId}`,
                }),
                invalidatesTags: ['KEYCARD_TRANSFER'],
            }),

            postKeycardTransfer: builder.mutation<
                void,
                { keycardId: string; targetEmail: string }
            >({
                query: (body) => ({
                    method: 'POST',
                    url: orderUrls().keycardTransfer,
                    body,
                }),
                invalidatesTags: ['KEYCARD_TRANSFER'],
            }),

            postKeycardTranserAccept: builder.mutation<
                void,
                { keycardId: string }
            >({
                query: ({ keycardId }) => ({
                    method: 'POST',
                    url: `${orderUrls().keycardTransfer}/${keycardId}/accept`,
                }),
                invalidatesTags: ['KEYCARD_TRANSFER'],
            }),

            rejectKeycardTransfers: builder.mutation<
                void,
                { keycardId: string }
            >({
                query: ({ keycardId }) => ({
                    method: 'POST',
                    url: `${orderUrls().keycardTransfer}/${keycardId}/accept`,
                }),
                invalidatesTags: ['KEYCARD_TRANSFER'],
            }),

            getVoucherTemplates: builder.query<VoucherTemplate[], void>({
                query: () => orderUrls().vouchers,
                transformResponse: (response: {
                    vouchers: VoucherTemplate[];
                }) => response.vouchers,
            }),

            postVoucherPreview: builder.mutation<Blob, PostVoucherPreviewBody>({
                query: (postBody) => ({
                    method: 'POST',
                    url: `${urlBuilder('order', 'vouchers')}/${
                        postBody.voucherTemplateId
                    }/preview`,
                    body: postBody,
                    responseHandler: (response) => response.blob(),
                }),
            }),

            getKeycards: builder.query<Keycard[], void>({
                query: () => orderUrls().keycards,
                transformResponse: (response: {
                    keycards: Keycard<BackendType>[];
                }) => response.keycards.map(convertKeycardToFrontend),
                providesTags: ['KEYCARDS'],
            }),

            deleteKeycard: builder.mutation<Keycard[], Keycard>({
                query: (body) => ({
                    url: `${orderUrls().keycards}/${body.id}`,
                    method: 'DELETE',
                    body: body,
                }),
                invalidatesTags: ['KEYCARDS'],
            }),

            postCancelDelete: builder.mutation<void, { id: string }>({
                query: ({ id }) => ({
                    url: `${orderUrls().tickets}/${id}/cancel`,
                    method: 'POST',
                    body: { ticketId: id },
                }),

                invalidatesTags: ['USER_TICKETS'],
            }),

            getInvoice: builder.query<string, string>({
                query: (id) => `orderUrls().orders/${id}/invoice`,
                transformResponse: async (response: Response) => {
                    if (!response.ok) {
                        throw await response.json();
                    }
                    const blob = await response.blob();
                    return URL.createObjectURL(blob);
                },
            }),

            postCardCodeUsage: builder.mutation<
                Response,
                { code: string; keycardId: string }
            >({
                query: ({ code, keycardId }) => ({
                    url: orderUrls().cardcodeusage,
                    method: 'POST',
                    body: { code, keycardId },
                }),
            }),

            getUserTickets: builder.query<
                UserTicket[],
                {
                    insuranceTickets: boolean;
                } | void
            >({
                query: (params) => {
                    const hasInsurance =
                        params === undefined
                            ? false
                            : (params as { insuranceTickets?: boolean })
                                  .insuranceTickets;

                    return `${orderUrls().tickets}?size=9999${
                        hasInsurance ? '&hasInsurance=true' : ''
                    }`;
                },

                transformResponse: async (response: {
                    content: UserTicket<BackendType>[];
                }) => {
                    return response.content.map(convertUserTicketToFrontend);
                },

                providesTags: ['USER_TICKETS'],
            }),

            postTicketCancelInsurance: builder.mutation<
                Response,
                { ticketId: string }
            >({
                query: ({ ticketId }) => ({
                    url: `${orderUrls().tickets}/${ticketId}/cancel-insurance`,
                    method: 'POST',
                    body: { ticketId },
                }),

                invalidatesTags: ['USER_TICKETS'],
            }),

            postTicketRevokeInsurance: builder.mutation<
                Response,
                { ticketId: string }
            >({
                query: ({ ticketId }) => ({
                    url: `${orderUrls().tickets}/${ticketId}/revoke-insurance`,
                    method: 'POST',
                    body: { ticketId },
                }),

                invalidatesTags: ['USER_TICKETS'],
            }),

            getVoucherPdfCheck: builder.query<void, string>({
                query: (id) => `${orderUrls().vouchers}/${id}.pdf`,
            }),

            postTransferKeycard: builder.mutation<
                Response,
                { keycardId: string; targetEmail: string }
            >({
                query: ({ keycardId, targetEmail }) => ({
                    url: orderUrls().keycardTransfer,
                    method: 'POST',
                    body: { keycardId, targetEmail },
                }),
            }),

            deleteTransferKeycard: builder.mutation<
                Response,
                { keycardId: string }
            >({
                query: ({ keycardId }) => ({
                    url: `${orderUrls().keycardTransfer}/${keycardId}`,
                    method: 'DELETE',
                }),

                invalidatesTags: ['KEYCARD_TRANSFER'],
            }),

            postAcceptTransfer: builder.mutation<
                Response,
                { transferId: string }
            >({
                query: ({ transferId }) => ({
                    url: `${orderUrls().keycardTransfer}/${transferId}/accept`,
                    method: 'POST',
                }),
            }),

            postRejectTransfer: builder.mutation<
                Response,
                { transferId: string }
            >({
                query: ({ transferId }) => ({
                    url: `${orderUrls().keycardTransfer}/${transferId}/reject`,
                    method: 'POST',
                }),
            }),

            putKeycardImage: builder.mutation<
                boolean,
                { keycardId: string; image: string }
            >({
                query: ({ keycardId, image }) => ({
                    url: `${orderUrls().keycards}/${keycardId}/image`,
                    method: 'PUT',
                    body: { image },
                }),
                transformResponse: (response: Response) => response.ok,
            }),
        }),
    });

export const {
    usePostKeycardMutation,
    useGetCreditQuery,
    useGetProfilesQuery,
    useDeleteProfileMutation,
    useGetOrderInfoQuery,
    useGetVoucherTemplatesQuery,
    useGetOrdersQuery,
    useGetKeycardsQuery,
    useDeleteKeycardMutation,
    useGetKeycardTransfersQuery,

    usePostVoucherPreviewMutation,
    usePostCancelDeleteMutation,

    useLazyGetInvoiceQuery,
    usePostCardCodeUsageMutation,
    useGetUserTicketsQuery,

    useLazyGetVoucherPdfCheckQuery,
    usePostTransferKeycardMutation,
    useDeleteTransferKeycardMutation,
    usePostAcceptTransferMutation,
    usePostRejectTransferMutation,

    usePutKeycardImageMutation,

    usePostTicketCancelInsuranceMutation,
    usePostTicketRevokeInsuranceMutation,
} = orderApi;
