import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';
import { Person } from '@/models/general';
import { getPropByCurrentLocale } from '@/utils/locale';
import makeStyles from '@mui/styles/makeStyles';
import { formatDate } from '@/utils/time';
import { Theme } from '../../../../theme/theme';
import { QuantityCounter } from '@/components/Quantity/QuantityCounter';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        marginTop: theme.spacing(3),
        marginBottom: 0,
        paddingLeft: 6,
    },

    subTitle: {
        fontSize: '1rem',
        padding: 6,
    },
}));

type PackagePersonCountSelectionProps = {
    person: Person;
    onSelect: (personCount: number) => void;
    quantity?: number;
};

export const PackagePersonCountSelection: FunctionComponent<
    React.PropsWithChildren<PackagePersonCountSelectionProps>
> = ({ person, onSelect, quantity }) => {
    const classes = useStyles();

    return (
        <FlexContainer
            sx={{
                alignItems: 'center',
            }}
            fullWidth
        >
            <QuantityCounter
                value={quantity}
                allowNegative={false}
                onCounterChange={(number) => {
                    onSelect(number);
                }}
            />
            <Typography className={classes.subTitle} variant='h6' gutterBottom>
                {getPropByCurrentLocale(person.name)}
                {!!person.birthday && (
                    <>
                        {' | '}
                        {formatDate(person.birthday?.from)} -{' '}
                        {formatDate(person.birthday?.to)}
                    </>
                )}
            </Typography>
        </FlexContainer>
    );
};
