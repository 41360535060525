import { FunctionComponent } from 'react';

import { useField, useFormikContext } from 'formik';
import { mergeProps } from './index';
import { Select, SelectProps } from '../Select';

export const SelectForm: FunctionComponent<
    React.PropsWithChildren<SelectProps>
> = (selectProps) => {
    // check if within formik
    const { name } = selectProps;
    const fieldData = useField({ name, type: 'select' });
    const formikFieldData = !!useFormikContext() ? fieldData : null;

    // error and helperText are not needed in checkboxes
    const props = mergeProps(selectProps, formikFieldData);

    delete props.error;
    delete props.helperText;
    delete props['selected'];

    return <Select {...props} />;
};
