import { TicketVariant } from '@/models/ticket';
import moment, { Moment } from 'moment';
import { Promotion } from '@/models/promotion';
import { DATE_FORMAT } from '@/constants';
import {
    PersonCount,
    ProductsOverview,
    ProductType,
    ProductTypeOverview,
} from '@/models/product';

export type ProductFilter = {
    date: Moment;
    type: ProductType;
    variant: TicketVariant;

    // for package tickets
    persons?: PersonCount[];
    duration?: number;
};

export const initialFilter: ProductFilter = {
    type: null,
    variant: null,
    date: null,
    persons: null,
};

export const isFilterInInitialState = (productFilter: ProductFilter) => {
    return (
        productFilter.type === initialFilter.type &&
        productFilter.variant === initialFilter.variant &&
        productFilter.date === initialFilter.date &&
        productFilter.persons === initialFilter.persons
    );
};

export const isFilterInInitialPromotionState = (
    productFilter: ProductFilter,
    promotion: Promotion
) => {
    if (!promotion) return false;
    return (
        productFilter.type === ProductType.DAY &&
        productFilter.variant === TicketVariant.SINGLE &&
        productFilter.date.isSame(moment(promotion.date, DATE_FORMAT))
    );
};

export const isProductFilterComplete = (productFilter: ProductFilter) => {
    if (
        productFilter.type === ProductType.SEASON &&
        productFilter.variant !== TicketVariant.PACKAGE
    ) {
        return !!productFilter.variant;
    }

    if (productFilter.variant === TicketVariant.PACKAGE) {
        if (productFilter.type === ProductType.SEASON) {
            return (
                !!productFilter.type &&
                !!productFilter.variant &&
                !!productFilter.persons?.length
            );
        } else {
            return (
                !!productFilter.type &&
                !!productFilter.variant &&
                !!productFilter.date &&
                !!productFilter.persons?.length
            );
        }
    }

    return (
        !!productFilter.type && !!productFilter.variant && !!productFilter.date
    );
};

type UpdateFilterArgs<T extends keyof ProductFilter> = {
    filter: ProductFilter;
    productsOverview: ProductsOverview;
    key: T; // key of filter
    value: ProductFilter[T]; // actual value
};

export const updateFilter = <T extends keyof ProductFilter>({
    filter,
    productsOverview,
    key,
    value,
}: UpdateFilterArgs<T>): ProductFilter => {
    let copyFilter = { ...filter };

    if (key === 'type') {
        if (value === copyFilter[key]) return copyFilter;

        // preset filter props
        copyFilter = { ...initialFilter, [key]: value };

        copyFilter.variant = setFilterVariant(
            productsOverview,
            value as ProductFilter['type']
        );

        if (value === ProductType.SEASON) {
            copyFilter = {
                ...copyFilter,
                date: getFilterDateOnTypeSeason({
                    productType: copyFilter.type,
                    productVariant: copyFilter.variant,
                    productsOverview,
                }),
                persons: null,
            };
        }
    } else if (key === 'variant') {
        copyFilter = {
            ...copyFilter,
            date: null,
            persons: null,
        };
    }

    return {
        ...copyFilter,
        [key]: value,
    };
};

export const getQueryUrlFromProductFilter = (filter: ProductFilter) => {
    const query = new URLSearchParams();

    if (!!filter.type) {
        query.set('type', filter.type);
    }

    if (!!filter.variant) {
        query.set('variant', filter.variant);
    }

    if (!!filter.date && filter.type !== ProductType.SEASON) {
        query.set('date', filter.date.format('YYYY-MM-DD'));
    }

    if (filter.persons?.length) {
        const personStringsRepeated = filter.persons
            .map((person) => Array(person.quantity).fill(person.name.de))
            .flat();

        personStringsRepeated.forEach((personName) => {
            query.append('person', personName);
        });
    }

    return query;
};

const setFilterVariant = (
    productsOverview: ProductsOverview,
    filterTypeValue: ProductFilter['type']
) => {
    if (!productsOverview || !filterTypeValue) return null;

    const typeKey = filterTypeValue === ProductType.DAY ? 'day' : 'season';

    const ticketType = productsOverview[typeKey];

    if (ticketType.packageProduct && !ticketType.singleProduct)
        return TicketVariant.PACKAGE;
    else if (!ticketType.packageProduct && ticketType.singleProduct)
        return TicketVariant.SINGLE;
    else return null;
};

type GetFilterDateOnTypeSeaosnParams = {
    productType: ProductType;
    productVariant: TicketVariant | null;
    productsOverview: ProductsOverview | null;
};

export const getFilterDateOnTypeSeason = ({
    productType,
    productVariant,
    productsOverview,
}: GetFilterDateOnTypeSeaosnParams) => {
    if (
        productType !== ProductType.SEASON ||
        !productVariant ||
        !productsOverview
    ) {
        return null;
    }

    const variantKey: keyof ProductTypeOverview =
        productVariant === TicketVariant.SINGLE
            ? 'singleProduct'
            : 'packageProduct';

    const date = productsOverview.season[variantKey].validity?.from || moment();

    return date.isBefore(moment()) ? moment() : date;
};
