import { useState } from 'react';
import { AddressPreview } from '@/components/PageContent/CartPageContent/Payment/AddressBlock/AddressPreview';
import { AddressFormDialog } from '@/components/AddressForm/AddressFormDialog';
import { Address, AddressType } from '@/models/user';
import {
    useGetCurrentCheckoutSession,
    usePostAddressMutation,
} from '@/api/checkout';
import { CheckoutSession } from '@/models/checkout/checkoutSession';
import { useTranslation } from '@/hooks/useTranslation';
import { useNotification } from '@/hooks/useNotification';

export const AddressBlockContainer = () => {
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const { getTranslated } = useTranslation();
    const [postAddress, { isLoading: addressUpdateLoading }] =
        usePostAddressMutation();
    const { addNotification } = useNotification();
    const invoiceSame = checkoutSession
        ? isInvoiceSameAsShipping({
              shippingAddress: checkoutSession.shippingAddress,
              invoiceAddress: checkoutSession.invoiceAddress,
          })
        : true;
    const [shippingSameAsInvoice, setShippingSameAsInvoice] =
        useState(invoiceSame);

    const [addressDialogOpen, setAddressDialogOpen] =
        useState<AddressType>(null);

    return (
        <>
            <AddressPreview
                onChange={(type) => {
                    setAddressDialogOpen(type);
                }}
                checkoutSession={checkoutSession}
                shippingSameAsInvoice={shippingSameAsInvoice}
                onShippingInvoiceChange={setShippingSameAsInvoice}
            />

            <AddressFormDialog
                open={!!addressDialogOpen}
                onClose={() => {
                    setAddressDialogOpen(null);
                }}
                onChange={(address) => {
                    postAddress({
                        checkoutId: checkoutSession.id,
                        type: addressDialogOpen,
                        address,
                    })
                        .unwrap()
                        .then(() => {
                            addNotification({
                                type: 'success',
                                message: getTranslated(
                                    addressDialogOpen === 'invoice'
                                        ? 'invoiceAddressSaved'
                                        : 'shippingAddressSaved'
                                ),
                            });

                            setAddressDialogOpen(null);
                        });
                }}
                initialAddress={
                    addressDialogOpen === 'invoice'
                        ? getInvoiceFromCheckoutSession(checkoutSession)
                        : checkoutSession?.shippingAddress
                }
                saveButtonLoading={addressUpdateLoading}
                type={addressDialogOpen}
            />
        </>
    );
};

const isInvoiceSameAsShipping = ({
    invoiceAddress,
}: {
    shippingAddress: Address;
    invoiceAddress: Address;
}) => invoiceAddress === null;

const getInvoiceFromCheckoutSession = (
    checkoutSession: CheckoutSession | null
) =>
    checkoutSession?.invoiceAddress || checkoutSession?.shippingAddress || null;
