import {
    BackendType,
    convertDateRangeToFrontend,
    convertDateToFrontend,
    convertPersonToFrontend,
    DateRange,
    FrontendType,
    Money,
    MultiLangString,
    ObjectType,
    Person,
} from '@/models/general';
import { Moment } from 'moment/moment';

export type PersonCount<T extends ObjectType = FrontendType> = {
    quantity: number;
} & Person<T>;

export enum ProductType {
    SEASON = 'SEASON',
    DAY = 'DAY',
}

export type Product<T extends ObjectType = FrontendType> = {
    availableContingent: number;
    productId: string;
    shortProductId: string;
    name: MultiLangString;
    liftOperatorName: MultiLangString;
    type: ProductType;
    duration: number;
    photoRequired: boolean;
    persons: PersonCount<T>[];
    price: Money;
    originalPrice: Money;
    cancellationBlocked: boolean;
    issueValidity: DateRange<T>[];
    validity: DateRange<T>;
    tags: string[];
    sortKey: number;
    infoText: MultiLangString;
    filterTags: string[];
    contingentsThreshold: number;
    contingent: number;
    startDay: T extends FrontendType ? Moment : number[];
    externalConditionNames: MultiLangString[];
    maxTicketsPerStartDay: number;
    externalCode: string[];
    keycardCodes: string[];
    portalConfiguration: {
        keycard: boolean;
        barcode: boolean;
        pickup: boolean;
        internalProduction: boolean;
        mobileFlow: boolean;
    };
    integrationConfiguration: {
        keycard: boolean;
        barcode: boolean;
        pickup: boolean;
        internalProduction: boolean;
        mobileFlow: boolean;
    };
    mobileConfiguration: {
        keycard: boolean;
        barcode: boolean;
        pickup: boolean;
        internalProduction: boolean;
        mobileFlow: boolean;
    };
    b2bConfiguration: {
        keycard: boolean;
        barcode: boolean;
        pickup: boolean;
        internalProduction: boolean;
        mobileFlow: boolean;
    };
};
export const convertProductToFrontend = (
    product: Product<BackendType>
): Product => {
    return {
        ...product,
        persons: product.persons.map(convertPersonToFrontend),
        issueValidity: product.issueValidity
            ? product.issueValidity.map(convertDateRangeToFrontend)
            : null,

        validity: product.validity
            ? convertDateRangeToFrontend(product.validity)
            : null,

        startDay: convertDateToFrontend(product.startDay),
    };
};
export const mapProductToGAItem = (product: Product) => {
    const { productId, name, liftOperatorName, type, persons, price } = product;
    const isPackage = persons.length > 1;
    return {
        item_id: productId,
        item_name: isPackage
            ? `${name.de} - ${persons
                  .map((p) => `${p.quantity} x ${p.name.de}`)
                  .join(', ')}`
            : `${name.de} - ${persons[0].name.de}`,
        item_brand: liftOperatorName.de,
        item_category: type,
        item_category2: isPackage ? 'package' : 'single',
        item_category3: `${persons
            .map((p) => `${p.quantity} x ${p.name.de}`)
            .join(', ')}`,
        price: price.amount,
    };
};
type ProductCollectionOverview<T extends ObjectType = FrontendType> = {
    validity: DateRange<T>;
    durations: number[];
    persons: Person<T>[];
};
const convertProductCollectionOverviewToFrontend = (
    pO: ProductCollectionOverview<BackendType>
): ProductCollectionOverview => ({
    ...pO,
    validity: convertDateRangeToFrontend(pO.validity),
    persons: pO.persons
        .sort((a, b) => a.name.de.localeCompare(b.name.de))
        .map((p) => ({
            ...p,
            birthday: p.birthday
                ? convertDateRangeToFrontend(p.birthday)
                : null,
        })),
});
export type ProductTypeOverview<T extends ObjectType = FrontendType> = {
    singleProduct: ProductCollectionOverview<T>;
    packageProduct: ProductCollectionOverview<T>;
};
const convertProductTypeOverviewToFrontend = ({
    singleProduct,
    packageProduct,
}: ProductTypeOverview<BackendType>): ProductTypeOverview => ({
    singleProduct: singleProduct
        ? convertProductCollectionOverviewToFrontend(singleProduct)
        : null,
    packageProduct: packageProduct
        ? convertProductCollectionOverviewToFrontend(packageProduct)
        : null,
});
export type ProductsOverview<T extends ObjectType = FrontendType> = {
    liftOperatorId: string;
    season: ProductTypeOverview<T>;
    day: ProductTypeOverview<T>;
};
// Models for all kind of products
export const convertProductsOverviewToFrontend = (
    pO: ProductsOverview<BackendType>
): ProductsOverview => ({
    ...pO,
    season: pO.season ? convertProductTypeOverviewToFrontend(pO.season) : null,
    day: pO.day ? convertProductTypeOverviewToFrontend(pO.day) : null,
});
