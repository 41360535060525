import { useSelector } from 'react-redux';
import { Store } from '../store';
import LocaleDe from '../assets/locales/localeDe';
import LocaleEn from '../assets/locales/localeEn';
import { Locale } from '@/models/general';

type ReplaceWith = { [k: string]: string | number };

export type TranslationKey = keyof typeof LocaleDe['messages'];

export type GetTranslated = (
    key: TranslationKey,
    replaceWith?: ReplaceWith
) => string;

export const translateFunction = (
    toBeTranslated: string,
    locale: Locale,
    replaceWith?: ReplaceWith
) => {
    const messages = locale === 'de' ? LocaleDe.messages : LocaleEn.messages;
    let translated = messages[toBeTranslated] || toBeTranslated;

    if (replaceWith) {
        for (const key in replaceWith) {
            if (replaceWith.hasOwnProperty(key)) {
                translated = translated.replace(`{{${key}}}`, replaceWith[key]);
            }
        }
    }

    return translated;
};

export const useTranslation = () => {
    const config = useSelector((store: Store) => store.config);
    const getTranslated = (key: TranslationKey, replaceWith?: ReplaceWith) =>
        translateFunction(key, config.locale, replaceWith);

    return {
        locale: config.locale,
        getTranslated,
    };
};
