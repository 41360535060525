import { FlexContainer } from '@/components/Layout/Container';
import { getPropByCurrentLocale } from '@/utils/locale';
import { CollectionCardPriceLine } from '@/components/CollectionCard/common/CollectionCardPriceLine';
import { DateRange, Money, multiplyMoneyByFactor } from '@/models/general';
import { FunctionComponent } from 'react';
import { CheckoutTicketCollection } from '@/models/collection/checkoutTicketCollection';
import { CheckoutPerson } from '@/models/checkout/checkoutPerson';
import moment from 'moment/moment';
import { DATE_FORMAT } from '@/constants';
import { CollectionCardPersonCountLine } from '@/components/CollectionCard/common/CollectionCardPersonCountLine';
import { TicketNotRefundableLine } from '@/components/Ticket/TicketNotRefundableLine';
import { useTranslation } from '@/hooks/useTranslation';
import { Box, Typography } from '@mui/material';

type TicketEntity = CheckoutPerson & {
    price: Money;
    originalPrice: Money;
    cancellationBlocked: boolean;
    availableContingent: number;
};

type CheckoutTicketQuantityListProps = {
    ticketCollection: CheckoutTicketCollection;
};

export const CheckoutTicketCollectionQuantityList: FunctionComponent<
    CheckoutTicketQuantityListProps
> = ({ ticketCollection }) => {
    const { getTranslated } = useTranslation();
    const personsMap: Record<string, TicketEntity> =
        ticketCollection.checkoutTickets.reduce((personsMap, ticket) => {
            ticket.persons.forEach((person) => {
                personsMap[person.name.de] = {
                    ...person,
                    price: ticket.price,
                    cancellationBlocked: ticket.cancellationBlocked,
                    originalPrice: ticket.originalPrice,
                    availableContingent: ticket.availableContingent,
                };
            });
            return personsMap;
        }, {});
    const personCounter: Record<string, number> =
        ticketCollection.checkoutTickets.reduce((personCounter, ticket) => {
            ticket.persons.forEach((person) => {
                personCounter[person.name.de] =
                    (personCounter[person.name.de] || 0) + 1;
            });
            return personCounter;
        }, {});
    const personIdsSortedByAge = Object.values(personsMap)
        .sort((personA, personB) => {
            const { birthdate: birthdayA } = personA;
            const { birthdate: birthdayB } = personB;

            if (birthdayA?.from && birthdayB?.from) {
                return birthdayA.from.unix() - birthdayB.from.unix();
            }

            return 0;
        })
        .map((p) => p.name.de);

    const getAvailableContingentString = (availableContingent = 0) => {
        if (availableContingent === null || availableContingent === undefined) {
            return '';
        }

        if (availableContingent === 0)
            return getTranslated('Core.noContingentLeft');

        return getTranslated('Core.contingent', {
            tickets: availableContingent,
        });
    };

    return (
        <FlexContainer column>
            {personIdsSortedByAge.map((personName) => {
                const {
                    price,
                    cancellationBlocked,
                    originalPrice,
                    availableContingent,
                    ...person
                } = personsMap[personName];
                const count = personCounter[personName];

                return ticketCollection.isPackage ? (
                    <CollectionCardPersonCountLine
                        key={person.name.de}
                        count={count}
                        personName={person.name}
                        birthday={person.birthdate}
                    />
                ) : (
                    <CollectionCardPriceLine
                        key={personName}
                        title={`${count} x ${getPropByCurrentLocale(
                            person.name
                        )}`}
                        subtitle={getBirthdayAsString(person.birthdate)}
                        subtitle3={
                            <Box>
                                {cancellationBlocked && (
                                    <TicketNotRefundableLine />
                                )}
                                <Typography
                                    sx={{
                                        mt: 1,
                                        fontSize: '0.85rem',
                                    }}
                                    color='error'
                                >
                                    {getAvailableContingentString(
                                        availableContingent
                                    )}
                                </Typography>
                            </Box>
                        }
                        price={multiplyMoneyByFactor(price, count)}
                        originalPrice={multiplyMoneyByFactor(
                            originalPrice,
                            count
                        )}
                    />
                );
            })}
        </FlexContainer>
    );
};

const getBirthdayAsString = (dateRange: DateRange) => {
    if (!!dateRange?.from && !!dateRange?.to) {
        return `(${moment(dateRange?.from).format(DATE_FORMAT)} - ${moment(
            dateRange.to
        ).format(DATE_FORMAT)})`;
    } else if (!!dateRange?.from && !dateRange?.to) {
        return `(${moment(dateRange.from).format(DATE_FORMAT)})`;
    } else return '';
};
