import { FunctionComponent } from 'react';
import moment, { Moment } from 'moment';
import { FlexContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import { DateRange } from '@/models/general';
import { SelectionButton } from '@/components/PageContent/ProductsPageContent/ProductFilter/SelectionButton';
import { Typography } from '@mui/material';
import { DateSelectionWithPicker } from '@/components/PageContent/ProductsPageContent/ProductFilter/DateSelectionWithPicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { usePromotion } from '@/hooks/usePromotion';

const compareToToday = (date: Moment): 'today' | 'tomorrow' | 'diff' => {
    if (date === null) return null;

    const clone = date.clone();

    if (clone.startOf('day').unix() === moment().startOf('day').unix())
        return 'today';

    if (
        clone.startOf('day').unix() ===
        moment().add(1, 'day').startOf('day').unix()
    )
        return 'tomorrow';

    return 'diff';
};

type DateSelectionContainerProps = {
    value: Moment;
    onDateSelect: (date: Moment) => void;
    validity?: DateRange;
};

export const DateSelectionContainer: FunctionComponent<
    React.PropsWithChildren<DateSelectionContainerProps>
> = ({ value, onDateSelect, validity }) => {
    const { getTranslated } = useTranslation();

    const onDateChange = (date: Moment) => {
        onDateSelect(date);
    };

    const todayEnabled = moment(validity.from)
        .startOf('day')
        .isSameOrBefore(moment().startOf('day'));
    const tomorrow = moment().add(1, 'day').startOf('day');
    const tomorrowEnabled = moment(validity.from)
        .startOf('day')
        .isSameOrBefore(tomorrow);
    const promotion = usePromotion();

    return (
        <FlexContainer
            sx={(theme) => ({
                alignItems: 'center',

                [theme.breakpoints.down('lg')]: {
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            })}
            fullWidth
        >
            {!!promotion ? (
                <SelectionButton
                    disabled={!todayEnabled}
                    isSelected={true}
                    onClick={() => {
                        onDateChange(moment(promotion.date, 'DD.MM.YYYY'));
                    }}
                    adornment={<DateRangeIcon />}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            ...(promotion.color && {
                                color: '#fff',
                            }),
                        }}
                    >
                        {promotion.date}
                    </Typography>
                </SelectionButton>
            ) : (
                <>
                    <SelectionButton
                        disabled={!todayEnabled}
                        isSelected={compareToToday(value) === 'today'}
                        onClick={() => {
                            onDateChange(moment());
                        }}
                        adornment={
                            <Typography
                                sx={{
                                    lineHeight: '1.3',
                                    fontWeight: 600,
                                }}
                            >
                                {moment().format('dd')} <br />{' '}
                                {moment().format('DD.MM.')}
                            </Typography>
                        }
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            {getTranslated('today')}
                        </Typography>
                    </SelectionButton>

                    <SelectionButton
                        disabled={!tomorrowEnabled}
                        isSelected={compareToToday(value) === 'tomorrow'}
                        onClick={() => {
                            onDateChange(moment().add(1, 'day'));
                        }}
                        adornment={
                            <Typography
                                sx={{
                                    lineHeight: '1.3',
                                    fontWeight: 600,
                                }}
                            >
                                {moment().add(1, 'day').format('dd')} <br />{' '}
                                {moment().add(1, 'day').format('DD.MM.')}
                            </Typography>
                        }
                    >
                        {getTranslated('tomorrow')}
                    </SelectionButton>

                    <DateSelectionWithPicker
                        value={compareToToday(value) !== 'diff' ? null : value}
                        validDateRange={{
                            from: validity?.from || moment(),
                            to: validity?.to || moment().add(6, 'months'),
                        }}
                        onChange={(nextVal) => {
                            onDateChange(nextVal);
                        }}
                    />
                </>
            )}
        </FlexContainer>
    );
};
