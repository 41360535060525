import { FunctionComponent } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import { useTranslation } from '@/hooks/useTranslation';

type ConfirmationDialogProps = {
    onConfirmation: () => void;
    onCancel: () => void;
    confirmationButtonLoading?: boolean;
    confirmationTextBody?: string;
    confirmationTextTitle?: string;
    buttonText?: string;
};

export const ConfirmationDialog: FunctionComponent<
    React.PropsWithChildren<ConfirmationDialogProps>
> = ({
    onConfirmation,
    onCancel,
    confirmationButtonLoading,
    confirmationTextBody,
    confirmationTextTitle,
    buttonText,
}) => {
    const { getTranslated } = useTranslation();

    return (
        <>
            <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
                {confirmationTextTitle || getTranslated('MyCardsPage.delete')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {confirmationTextBody ||
                        getTranslated('MyCardsPage.confirmKeycardDeletion')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button isLoading={false} onClick={onCancel} color='primary'>
                    {getTranslated('Core.cancel')}
                </Button>
                <Button
                    isLoading={!!confirmationButtonLoading}
                    onClick={onConfirmation}
                    formType='error'
                >
                    {buttonText || getTranslated('MyCardsPage.deleteIt')}
                </Button>
            </DialogActions>
        </>
    );
};
