import { FunctionComponent } from 'react';
import { Avatar, Typography } from '@mui/material';
import { getGenderTranslation } from '@/utils/string';
import { formatDate } from '@/utils/time';
import {
    CardLeftContainer,
    CardRightContainer,
    CheckoutCardContent,
    PriceTag,
} from '@/components/PageContent/CartPageContent/CheckoutItemList/CheckoutCard';
import { getMoneyAsString } from '@/utils/general';
import { styled } from '@mui/material/styles';
import { Money } from '@/models/general';
import { DeleteButton } from '@/components/Layout/Button/DeleteButton';
import { FlexContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import { FlatCard } from '@/components/Card/FlatCard';
import { CheckoutIdentification } from '@/models/checkout/checkoutIdentification';

const PriceContainer = styled(CardRightContainer)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    flexDirection: 'column',
}));

const DisplayPrice: FunctionComponent<{ price: Money; label: string }> = ({
    label,
    price,
}) => (
    <>
        <PriceTag>{getMoneyAsString(price)}</PriceTag>
        <Typography
            variant='caption'
            sx={{
                color: '#fff',
            }}
        >
            {label}
        </Typography>
    </>
);
type KeycardEntryProps = {
    identification: CheckoutIdentification;
    onDelete: () => void;
    deleteLoading?: boolean;
};

export const KeycardEntry: FunctionComponent<KeycardEntryProps> = ({
    identification,
    onDelete,
}) => {
    const { getTranslated } = useTranslation();

    const { price, depositAmount } = identification;

    const showPriceTag = price?.amount > 0 || depositAmount?.amount > 0;

    return (
        <FlatCard
            sx={{
                display: 'flex',
                width: '100%',
            }}
        >
            <CardLeftContainer
                sx={{
                    ...(!showPriceTag && {
                        width: '100%',
                    }),
                }}
                column
            >
                <CheckoutCardContent
                    sx={{
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        sx={{
                            width: '80px',
                            height: '80px',
                            mr: 4,
                        }}
                        src={identification.imageUrl}
                    />

                    <FlexContainer column>
                        <Typography
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            {identification.firstname} {identification.lastname}
                        </Typography>

                        <Typography>
                            {formatDate(identification.birthday)},{' '}
                            {getGenderTranslation(identification.gender)}
                        </Typography>
                    </FlexContainer>
                    <DeleteButton
                        sx={{
                            ml: 'auto',
                        }}
                        onClick={onDelete}
                    />
                </CheckoutCardContent>
            </CardLeftContainer>

            {showPriceTag && (
                <PriceContainer center>
                    {!!identification.price?.amount && (
                        <DisplayPrice
                            price={identification.price}
                            label={getTranslated('keycardPrice')}
                        />
                    )}

                    {!!identification.depositAmount?.amount && (
                        <DisplayPrice
                            price={identification.depositAmount}
                            label={getTranslated('depositCosts')}
                        />
                    )}
                </PriceContainer>
            )}
        </FlatCard>
    );
};
