import { FunctionComponent } from 'react';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { pink } from '@mui/material/colors';

export const FavoriteIcon: FunctionComponent<
    React.PropsWithChildren<{
        isFavorite: boolean;
    }>
> = ({ isFavorite }) => {
    const { IS_OEAMTC } = useConfigSlice();

    const color = !IS_OEAMTC ? pink['A700'] : '#000';
    return isFavorite ? (
        <Favorite
            sx={(theme) => ({
                margin: theme.spacing(0, 2),
                color,
            })}
        />
    ) : (
        <FavoriteBorderIcon
            sx={(theme) => ({
                margin: theme.spacing(0, 2),
                color,
            })}
        />
    );
};
