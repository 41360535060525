import { FunctionComponent } from 'react';
import { Field, FormikHelpers } from 'formik';
import { MenuItem, Select, SelectProps as MuiSelectProps } from '@mui/material';
import { FieldInputProps } from 'formik/dist/types';

type MaterialSelectProps = {
    form: FormikHelpers<string>;
    field: FieldInputProps<string>;
} & MuiSelectProps;

const MaterialSelect: FunctionComponent<MaterialSelectProps> = ({
    children,
    form,
    field,
    ...props
}) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
        <Select
            {...props}
            name={name}
            value={value}
            onChange={(e) => {
                setFieldValue(name, e.target.value);
            }}
        >
            {children}
        </Select>
    );
};

type SelectFieldProps = {
    name: string;
    options: {
        label: string;
        value: string;
    }[];
} & MuiSelectProps;

export const SelectField: FunctionComponent<SelectFieldProps> = ({
    name,
    options,
    ...selectProps
}) => {
    return (
        <Field name={name}>
            {({ field, form }) => (
                <MaterialSelect field={field} form={form} {...selectProps}>
                    {options.map(({ value, label }, index) => (
                        <MenuItem key={index} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </MaterialSelect>
            )}
        </Field>
    );
};
