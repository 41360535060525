import { Store } from '@/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseApiAuthenticated = createApi({
    reducerPath: 'baseApiAuthenticated',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: (headers, { getState }) => {
            const { auth, config } = getState() as Store;
            const { token } = auth;
            const { pageConfig, isOeamtc, locale } = config;

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', `bearer ${token}`);
            }

            if (pageConfig && !isOeamtc) {
                const { url, configuration } = pageConfig;
                headers.set(
                    'X-IntegrationToken',
                    configuration.integrationToken
                );
                headers.set('X-IntegrationUrl', url);
            }

            headers.set('Content-Type', 'application/json');
            headers.set('X-Starjack-Language', locale);

            return headers;
        },
    }),
    endpoints: () => ({}),
});

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: (headers, { getState }) => {
            const { config } = getState() as Store;
            const { pageConfig, isOeamtc, locale } = config;

            if (pageConfig && !isOeamtc) {
                const { url, configuration } = pageConfig;
                headers.set(
                    'X-IntegrationToken',
                    configuration.integrationToken
                );
                headers.set('X-IntegrationUrl', url);
            }

            headers.set('Content-Type', 'application/json');
            headers.set('X-Starjack-Language', locale);

            return headers;
        },
    }),
    endpoints: () => ({}),
});
