import { FunctionComponent } from 'react';

export const toJSX = function <T extends object>(
    Component: FunctionComponent<React.PropsWithChildren<T>>,
    props?: T
) {
    return <Component {...props} />;
};

// TODO: create factory for different components
export const renderAsHtml = (input: string): React.ReactNode => (
    <span dangerouslySetInnerHTML={{ __html: input }} />
);
