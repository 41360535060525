import { FunctionComponent, ReactNode } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { PageTemplate } from '@/pages/PageTemplate';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../theme/theme';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        alignSelf: 'center',
        margin: theme.spacing(16, 0),
        fontWeight: 'bold',
        textTransform: 'uppercase',

        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(8, 0),
        },
    },
}));

type SubPageTemplateProps = {
    children: ReactNode;
    title?: string;
    widthExtended?: boolean;
};

export const SubPageTemplate: FunctionComponent<
    React.PropsWithChildren<SubPageTemplateProps>
> = ({ title, children, widthExtended }) => {
    const classes = useStyles();
    const theme: Theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const width = matchesMobile ? '100%' : widthExtended ? '80%' : '50%';
    return (
        <PageTemplate>
            <FlexContainer
                alignItems='center'
                flexGrow={1}
                width={width}
                marginX='auto'
                column
            >
                {title && (
                    <Typography variant='h4' className={classes.title}>
                        {title}
                    </Typography>
                )}
                {children}
            </FlexContainer>
        </PageTemplate>
    );
};
