import { FunctionComponent } from 'react';
import { Faq } from '@/models/faq';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';
import { FaqsList } from '@/components/Faqs/FaqsList';

export const FaqsListWithTitle: FunctionComponent<{
    title: string;
    faqs: Faq[];
    accordionColor: 'primary' | 'grey';
}> = ({ title, faqs, accordionColor }) => {
    if (!faqs) return null;
    return (
        <FlexContainer column fullWidth>
            <Typography
                sx={{
                    my: 2,
                }}
                variant='h6'
                gutterBottom
            >
                {title}
            </Typography>
            <FlexContainer
                sx={{
                    flexDirection: 'column',
                    ml: 4,
                    mb: 8,
                    width: '100%',
                }}
            >
                <FaqsList faqs={faqs} accordionColor={accordionColor} />
            </FlexContainer>
        </FlexContainer>
    );
};
