type RequestError = {
    error: string;
    field?: string;
};

type BackendError = {
    status: number;
    errors: RequestError[];
    timestamp: number;
    cid: string;
};

type RTKAPIError = {
    status: number;
    data: BackendError;
};

export const isOfTypeRTKAPIError = (error: unknown): error is RTKAPIError => {
    return (
        typeof error === 'object' && error?.['data']?.['errors'] !== undefined
    );
};
