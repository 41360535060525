import React, { FunctionComponent, useState } from 'react';
import { Snackbar, Alert as MuiAlert, AlertProps } from '@mui/material';
import { useNotification } from '@/hooks/useNotification';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const Notification: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => {
    const { currentNotification, consumeNext } = useNotification();
    const [localNotification, setLocalNotification] =
        useState(currentNotification);

    if (
        currentNotification &&
        currentNotification?.message !== localNotification?.message
    ) {
        setLocalNotification(currentNotification);
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            autoHideDuration={10000}
            open={!!currentNotification}
            onClose={() => {
                consumeNext();
            }}
            TransitionProps={{
                onExited: () => {
                    setLocalNotification(null);
                },
            }}
        >
            <Alert
                sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '1.1rem',

                    [theme.breakpoints.down('xl')]: {
                        padding: 2,
                    },
                })}
                severity={localNotification?.type}
            >
                {localNotification?.message}
            </Alert>
        </Snackbar>
    );
};
