import { FunctionComponent } from 'react';
import { VoucherTemplate } from '@/models/voucher';
import { Grid } from '@mui/material';

import { VoucherTemplateGridItem } from '@/components/PageContent/VouchersPageContent/VoucherGridItem/VoucherTemplateGridItem';

type VoucherListProps = {
    vouchers: VoucherTemplate[];
    onVoucherSelect: (voucher: VoucherTemplate) => void;
    selected: VoucherTemplate;
};

export const VoucherTemplateGridList: FunctionComponent<
    React.PropsWithChildren<VoucherListProps>
> = ({ vouchers, onVoucherSelect, selected }) => {
    return (
        <Grid
            sx={{
                width: '100%',
            }}
            spacing={3}
            container
        >
            {vouchers.map((voucher) => (
                <Grid key={voucher.id} xs={12} md={3} item>
                    <VoucherTemplateGridItem
                        voucher={voucher}
                        selected={selected?.id === voucher.id}
                        onSelect={(voucherTemplate) => {
                            onVoucherSelect(voucherTemplate);
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
