import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Container } from '@mui/material';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { IS_DEVELOPMENT } from '@/constants';
import { useNotification } from '@/hooks/useNotification';
import { LoadingContainer } from '@/components/Layout/Container';
import ErrorIcon from '@mui/icons-material/Error';
import {
    useGetCurrentCheckoutSession,
    usePostCheckoutTicketMutation,
} from '@/api/checkout';
import { useLazyGetProductDetailsQuery } from '@/api/liftOperator';
import moment from 'moment';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { Product, ProductType } from '@/models/product';

type TransferData = {
    startDay: string;
    type: ProductType;
    productIds: string[];
    tags: string[];
};

const isOfTypeTransferData = (data: unknown): data is TransferData => {
    return (
        typeof data['startDay'] === 'string' &&
        moment(data['startDay'], 'YYYY-MM-DD').isValid() &&
        typeof data['type'] === 'string' &&
        Array.isArray(data['productIds'])
    );
};

const isOfTypeTransferDataArray = (data: unknown): data is TransferData[] => {
    return Array.isArray(data) && data.every(isOfTypeTransferData);
};

export const CartTransferPage = () => {
    const { id } = useParams();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { configuration } = useConfigSlice();
    const navigate = useNavigate();
    const { addNotification } = useNotification();
    const [error, setError] = useState(false);
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const [getProductDetails] = useLazyGetProductDetailsQuery();
    const [postCheckoutTicket] = usePostCheckoutTicketMutation();
    const productsFetchExecuted = useRef(false);

    const fetchProducts = async (transferData: TransferData[]) => {
        for (let i = 0; i < transferData.length; i++) {
            const data = transferData[i];
            const response = await getProductDetails({
                liftOperatorId: selectedLiftOperator.id,
                productFilter: {
                    type: data.type,
                    date: moment(data.startDay),
                },
                configuration,
                tags: data.tags || [],
            }).unwrap();

            const transferBlockMap = data.productIds.reduce<
                Record<string, { quantity: number; product: Product }>
            >((acc, transferProductId) => {
                if (acc[transferProductId]) {
                    acc[transferProductId].quantity += 1;
                } else {
                    acc[transferProductId] = {
                        quantity: 1,
                        product: response.products.find(
                            (p) => p.productId === transferProductId
                        ),
                    };
                }

                return acc;
            }, {});

            for (const [productId, transferBlock] of Object.entries(
                transferBlockMap
            )) {
                for (let j = 0; j < transferBlock.quantity; j++) {
                    await postCheckoutTicket({
                        checkoutId: checkoutSession.id,
                        liftOperator: selectedLiftOperator.id,
                        productId: productId,
                        startDate: data.startDay,
                    });
                }
            }
        }

        navigate('/cart');
    };

    useEffect(() => {
        if (!checkoutSession || productsFetchExecuted.current) return;

        const data = atob(id as string);

        try {
            const dataParsed = JSON.parse(data);

            if (isOfTypeTransferDataArray(dataParsed)) {
                productsFetchExecuted.current = true;
                fetchProducts(dataParsed);
            } else {
                throw Error('Invalid data format');
            }
        } catch (e) {
            addNotification({
                message:
                    'Fehler beim Laden der Produkte. Bitte überprüfen Sie die URL und versuchen Sie es erneut.',
                type: 'error',
            });

            if (IS_DEVELOPMENT) {
                console.error(
                    `
                Invalid data format. Expected:\n
                {
                    startDay: string,
                    type: "DAY" | "SEASON",
                  
                    productIds: string[]
                }[]\n 
            `,
                    e
                );
            }

            setError(true);
        }
    }, [checkoutSession]);

    return (
        <Container
            sx={{
                height: 'calc(100vh - 56px)',
            }}
        >
            {error ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <ErrorIcon
                        color='error'
                        sx={{
                            fontSize: '6rem',
                        }}
                    />
                </Box>
            ) : (
                <LoadingContainer
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            )}
        </Container>
    );
};
