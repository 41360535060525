import { Store } from '@/store';
import { useSelector } from 'react-redux';
import { Slice } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import {
    DispatchedActions,
    useDispatchSliceActions,
} from '@/hooks/useDispatchedActions';

export const useSlice = <S extends Slice, SKey extends keyof Store>(
    slice: S,
    storeKey: SKey
): {
    state: Store[SKey];
    actions: DispatchedActions<S>;
} => {
    const storeSlice = useSelector((store: Store) => store[storeKey]);
    const dispatchActions = useDispatchSliceActions<typeof slice>();

    const dispatchedActions = useMemo(
        () => dispatchActions(slice.actions),
        [slice.name]
    );

    return {
        state: {
            ...storeSlice,
        },

        actions: {
            ...dispatchedActions,
        },
    };
};
