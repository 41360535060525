import { FunctionComponent, useEffect, useRef } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { useSlice } from '@/hooks/useSlice';
import { ordersSlice } from '@/slices/ordersSlice';
import {
    useGetCurrentCheckoutSession,
    useLazyGetCheckoutsQuery,
} from '@/api/checkout';
import { checkoutSlice } from '@/slices/checkoutSlice';
import TagManager from 'react-gtm-module';
import { createDataLayerEventObjectFromSuccess } from '@/models/payment';
import { cartSlice } from '@/slices/cartSlice';

export const SuccessPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { routerConfig, getPagePath } = useCustomRouter();
    const { getTranslated } = useTranslation();
    const {
        state: { paymentResponse },
    } = useSlice(ordersSlice, 'orders');
    const {
        actions: { setCheckoutId },
    } = useSlice(checkoutSlice, 'checkout');
    const {
        actions: { removeOeamtcCode },
    } = useSlice(cartSlice, 'cart');
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const [getCheckouts] = useLazyGetCheckoutsQuery();
    const checkoutRefreshed = useRef(false);

    useEffect(() => {
        if (paymentResponse && checkoutSession && !checkoutRefreshed.current) {
            TagManager.dataLayer({
                dataLayer: { event: 'checkout_success' },
            });

            TagManager.dataLayer(
                createDataLayerEventObjectFromSuccess(
                    paymentResponse,
                    checkoutSession
                )
            );

            checkoutSession.tickets.forEach((ticket) => {
                removeOeamtcCode({ ticketId: ticket.id });
            });

            checkoutRefreshed.current = true;
            getCheckouts()
                .unwrap()
                .then((res) => {
                    setCheckoutId(res.id);
                });
        }
    }, [checkoutSession, paymentResponse]);

    return (
        <SubPageTemplate>
            <FlexContainer column center>
                <CheckCircleOutlineIcon
                    sx={{
                        color: 'success.main',
                        fontSize: 64,
                        marginBottom: 2,
                    }}
                />
                <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ textAlign: 'center', marginBottom: 8 }}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getTranslated(
                                'CheckoutSuccessPage.headline'
                            ),
                        }}
                    />
                </Typography>
                <Typography variant='body1' gutterBottom>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getTranslated('CheckoutSuccessPage.text'),
                        }}
                    />
                </Typography>
                <Button
                    isLoading={false}
                    color='primary'
                    variant='contained'
                    sx={{ maxWidth: '250px', marginTop: 8 }}
                    onClick={() =>
                        navigate(getPagePath(routerConfig.LiftOperators))
                    }
                >
                    {getTranslated('Core.bookMoreTickets')}
                </Button>
            </FlexContainer>
        </SubPageTemplate>
    );
};
