import { ThemeOptions } from '@mui/material/styles';
import { themePalette } from '@/theme/themeBuilder';
import { blueGrey } from '@mui/material/colors';

const appBarHeight = 64;
const splitBase = 60;

export const starjackBaseTheme: ThemeOptions = {
    cartPage: {
        splitWidthLeft: `${splitBase}%`,
        splitWidthRight: `${100 - splitBase}%`,

        bottomNavigation: {
            height: 80,
        },
    },

    searchBar: {
        debounceTime: 200,
        valueLength: 3,
    },

    appBar: {
        height: appBarHeight,
    },

    stepper: {
        height: 90,
    },

    onePageHeader: {
        height: 400,
    },

    sideBar: {
        width: 256,
        logoContainer: {
            height: 184,
        },
    },

    page: {
        fullHeight: `calc(100vh - ${appBarHeight}px)`,
        template: {
            base: {
                paddingX: 5,
                paddingY: 1,
            },
        },
    },

    table: {
        regular: {
            defaultRowsPerPage: 15,
            rowsPerPageOptions: [15, 25, 35, { label: 'All', value: -1 }],
        },
        async: {
            itemsPerRequest: 200,
            defaultRowsPerPage: 15,
        },
    },

    layout: {
        textual: {
            bold: 600,
            formControl: {
                fontSize: {
                    small: '0.9rem',
                },
            },
        },
    },

    snackbar: {
        autoHideDuration: 5000,
    },

    button: {
        custom: {
            backgroundColor: themePalette.sjMain,
            color: themePalette.black,
        },
    },

    design: {
        general: {
            boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            blur: 'blur(5px)',
        },
        hover: {
            backgroundColor: blueGrey[50],
        },
    },

    spacing: 4,

    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    textDecoration: 'none',

                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    backgroundColor: '#fff',
                    padding: '0 8px',
                },
            },
        },

        MuiCardContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    [theme.breakpoints.down('lg')]: {
                        padding: theme.spacing(3),
                        '&:last-child': {
                            paddingBottom: theme.spacing(3),
                        },
                    },
                }),
            },
        },

        MuiChip: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.color === 'success' && {
                        color: '#fff',
                    }),
                }),
            },
        },
    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 320,
            md: 640,
            lg: 1024,
            xl: 1200,
        },
    },

    palette: {
        ...themePalette,
        primary: {
            light: '#638DAC',
            main: 'rgb(60, 113, 152)',
            dark: '#2A4F6A',
        },

        secondary: {
            light: '#9CCBCB',
            main: '#84BEBF',
            dark: '#5C8585',
        },

        success: {
            main: '#4caf50',
        },
    },

    transitions: {
        easing: {
            bounce: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
        },
    },
};
