import { Typography, CardContent } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { FlatCard } from '@/components/Card/FlatCard';
import { styled } from '@mui/material/styles';

export const CheckoutCard = styled(FlatCard, {
    shouldForwardProp: (prop) => prop !== 'hasError',
})<{
    hasError?: boolean;
}>(({ theme, hasError }) => ({
    maxWidth: 560,
    ...(hasError && {
        borderColor: theme.palette.error.main,
    }),
}));

export const CheckoutCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(4),
    '&:last-child': {
        paddingBottom: theme.spacing(4),
    },
}));

export const CardLeftContainer = styled(FlexContainer)(() => ({
    width: '75%',
}));

export const CardRightContainer = styled(FlexContainer)(() => ({
    flexGrow: 1,
}));

export const PriceTag = styled(Typography)(() => ({
    fontWeight: 600,
}));
