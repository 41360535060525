import { CURRENT_LOCALE } from '@/constants/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Configuration } from '@/models/configuration';
import { Locale } from '@/models/general';
import { ProductEnvironment } from '@/models/order';

export type PageConfig = {
    tags: string[];
    url: string;
    configuration: Configuration;
    customizedTheme?: Record<string, unknown>;
};

export enum PortalConfig {
    INTEGRATION_CONFIGURATION = 'integrationConfiguration',
    PORTAL_CONFIGURATION = 'portalConfiguration',
}

export type ConfigState = {
    locale: Locale;
    googleMapsLoaded: boolean;
    configuration: PortalConfig;
    isOeamtc: boolean;
    pageConfig: PageConfig;
    productEnvironment: ProductEnvironment;
};

const initialState: ConfigState = {
    locale: CURRENT_LOCALE,
    configuration: PortalConfig.PORTAL_CONFIGURATION,
    productEnvironment: ProductEnvironment.PORTAL,
    isOeamtc: false,
    googleMapsLoaded: false,
    pageConfig: null,
};

export const configSlice = createSlice({
    name: 'configSlice',
    initialState,
    reducers: {
        setProductEnvironment(
            state: ConfigState,
            action: PayloadAction<ProductEnvironment>
        ) {
            state.productEnvironment = action.payload;

            return state;
        },

        setConfiguration(
            state: ConfigState,
            action: PayloadAction<PortalConfig>
        ) {
            state.configuration = action.payload;

            return state;
        },

        setLocale: (state: ConfigState, action: PayloadAction<'de' | 'en'>) => {
            state.locale = action.payload;

            return state;
        },

        setPageConfig(state: ConfigState, action: PayloadAction<PageConfig>) {
            state.pageConfig = action.payload;

            return state;
        },

        setGoogleMapsLoaded(
            state: ConfigState,
            action: PayloadAction<boolean>
        ) {
            state.googleMapsLoaded = action.payload;

            return state;
        },

        setIsOeamtc(state: ConfigState, action: PayloadAction<boolean>) {
            state.isOeamtc = action.payload;

            return state;
        },
    },
});

export const configSliceActions = {
    ...configSlice.actions,
};
