import { FunctionComponent, PropsWithChildren } from 'react';
import { fullPageMinHeight } from '@/theme/theme';
import { FlexContainer } from '@/components/Layout/Container';
import { PaymentColumnContainer } from '@/components/PageContent/CartPageContent/PaymentColumnContainer';
import { CheckoutListContainer } from '@/components/PageContent/CartPageContent/CheckoutItemList/CheckoutListContainer';
import { CartPageTitleContainer } from '@/components/PageContent/CartPageContent/CartPageTitleContainer';
import { CheckoutBottomControlBar } from '@/components/PageContent/CartPageContent/CheckoutBottomControlBar';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { OeamtcBannerContainer } from '@/components/OeamtcBanner/OeamtcBanner';

export const CartPageContent: FunctionComponent<PropsWithChildren> = () => {
    const { IS_PORTAL } = useConfigSlice();

    return (
        <FlexContainer
            sx={(theme) => ({
                minHeight: fullPageMinHeight,
                marginTop: `${theme.appBar.height}px`,
            })}
            fullWidth
        >
            <FlexContainer
                sx={(theme) => ({
                    alignItems: 'center',
                    width: theme.cartPage.splitWidthLeft,
                    pb: '80px',

                    [theme.breakpoints.down('lg')]: {
                        pb: 4,
                        width: '100%',
                    },
                })}
                column
            >
                <OeamtcBannerContainer />
                <CartPageTitleContainer />

                <CheckoutListContainer />

                {IS_PORTAL && <CheckoutBottomControlBar />}
            </FlexContainer>

            <FlexContainer
                sx={(theme) => ({
                    width: theme.cartPage.splitWidthRight,
                    backgroundColor: theme.palette.sjLight,
                    px: 4,
                    pb: 4,
                    justifyContent: 'center',
                })}
                column
            >
                <PaymentColumnContainer />
            </FlexContainer>
        </FlexContainer>
    );
};
