import { FunctionComponent, RefObject, useRef } from 'react';
import { ProductType } from '@/models/product';
import { ProductTypeSelectionContainer } from '@/components/PageContent/ProductsPageContent/ProductFilter/ProductTypeSelectionContainer';
import { ProductVariantSelectionContainer } from '@/components/PageContent/ProductsPageContent/ProductFilter/ProductVariantSelectionContainer';
import { DateSelectionContainer } from '@/components/PageContent/ProductsPageContent/ProductFilter/DateSelectionContainer';
import { PersonCountSelectionContainer } from '@/components/PageContent/ProductsPageContent/ProductFilter/PackagePersonCountSelectionContainer';
import { TicketVariant } from '@/models/ticket';
import { FlexContainer } from '@/components/Layout/Container';
import { ProductCatalogStep } from '@/components/PageContent/ProductsPageContent/ProductCatalogStepper/ProductCatalogStep';
import {
    CatalogStepToShow,
    ProductFilterUpdateHandler,
} from '@/components/PageContent/ProductsPageContent/ProductCatalogContainer';
import { isProductFilterComplete, ProductFilter } from '@/models/productFilter';
import { useTranslation } from '@/hooks/useTranslation';
import { Fade, Typography } from '@mui/material';
import { ProductSelection } from '@/components/PageContent/ProductsPageContent/ProductSelection';
import {
    useGetProductCollectionsQuery,
    useGetProductDetailsQueryWrapper,
} from '@/api/liftOperator';
import { ProductsOverview } from '@/models/product';

const scrollToElement = (top: number) => {
    window.setTimeout(() => {
        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    }, 250);
};

const getIndexOfLastStep = (showCatalogStep: CatalogStepToShow) =>
    Object.values(showCatalogStep).filter((show) => show).length;

type ProductCatalogStepperProps = {
    productFilter: ProductFilter;
    productsOverview: ProductsOverview;
    onFilterUpdate: ProductFilterUpdateHandler;
    typeButtonDisabled: ProductFilter['type'];
    showCatalogStep: CatalogStepToShow;
};

export const ProductCatalogStepper: FunctionComponent<
    ProductCatalogStepperProps
> = ({
    productsOverview,
    productFilter,
    onFilterUpdate,
    typeButtonDisabled,
    showCatalogStep,
}) => {
    const { getTranslated } = useTranslation();
    const refTypeSelection = useRef(null);
    const refVariantSelection = useRef(null);
    const refDateSelection = useRef(null);
    const refPersonCount = useRef(null);
    const refProductCollection = useRef(null);
    const refNoProducts = useRef(null);

    const { data: productsDetails } = useGetProductDetailsQueryWrapper();
    const { productCollections = [] } = useGetProductCollectionsQuery();

    const typeKey = productFilter.type === ProductType.DAY ? 'day' : 'season';
    const variantKey = !!productFilter.variant
        ? productFilter.variant === TicketVariant.SINGLE
            ? 'singleProduct'
            : 'packageProduct'
        : null;

    const onEntered = (ref: RefObject<HTMLDivElement>) => () => {
        const topPostion =
            ref?.current?.getBoundingClientRect()?.top + window.scrollY - 80;

        if (topPostion) {
            scrollToElement(topPostion);
        }
    };

    return (
        <FlexContainer column fullWidth>
            <ProductCatalogStep
                ref={refTypeSelection}
                show={showCatalogStep.typeSelection}
                showConnector={false}
                iconDisplay={1}
                onEntered={onEntered(refTypeSelection)}
                title={getTranslated('selectTicketType')}
            >
                <ProductTypeSelectionContainer
                    value={productFilter.type}
                    onTypeSelect={onFilterUpdate('type')}
                    disabled={typeButtonDisabled}
                />
            </ProductCatalogStep>

            <ProductCatalogStep
                ref={refVariantSelection}
                show={showCatalogStep.variantSelection}
                showConnector={false}
                iconDisplay={2}
                onEntered={onEntered(refVariantSelection)}
                title={getTranslated('chooseBetweenSingleOrPackage')}
            >
                <ProductVariantSelectionContainer
                    value={productFilter.variant}
                    onVariantSelect={onFilterUpdate('variant')}
                />
            </ProductCatalogStep>

            <ProductCatalogStep
                ref={refDateSelection}
                show={showCatalogStep.dateSelection}
                showConnector={false}
                iconDisplay={showCatalogStep.variantSelection ? 3 : 2}
                onEntered={onEntered(refDateSelection)}
                title={getTranslated('chooseDate')}
            >
                {!!productsOverview?.[typeKey]?.[variantKey]?.validity && (
                    <DateSelectionContainer
                        value={productFilter.date}
                        validity={
                            productsOverview?.[typeKey]?.[variantKey]?.validity
                        }
                        onDateSelect={onFilterUpdate('date')}
                    />
                )}
            </ProductCatalogStep>

            <ProductCatalogStep
                ref={refPersonCount}
                showConnector={false}
                show={showCatalogStep.personCount}
                iconDisplay={showCatalogStep.dateSelection ? 4 : 3}
                onEntered={onEntered(refPersonCount)}
                title={getTranslated('chooseGroup')}
            >
                <PersonCountSelectionContainer
                    persons={
                        productsOverview?.[typeKey]?.packageProduct?.persons ||
                        null
                    }
                    onPersonSelect={onFilterUpdate('persons')}
                    productFilter={productFilter}
                />
            </ProductCatalogStep>

            <ProductCatalogStep
                ref={refProductCollection}
                show={isProductFilterComplete(productFilter)}
                showConnector={false}
                iconDisplay={getIndexOfLastStep(showCatalogStep)}
                onEntered={onEntered(refProductCollection)}
                title={getTranslated('chooseAndAddTicket')}
            >
                <ProductSelection />
            </ProductCatalogStep>

            {isProductFilterComplete(productFilter) &&
                !productCollections.length && (
                    <Fade
                        in={
                            !!productFilter?.persons?.length &&
                            !productsDetails?.products.length
                        }
                        onEntered={onEntered(refNoProducts)}
                    >
                        <FlexContainer
                            ref={refNoProducts}
                            sx={{
                                width: '100%',
                                height: '160px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography>
                                {getTranslated('productFilterNoTickets')}
                            </Typography>
                        </FlexContainer>
                    </Fade>
                )}
        </FlexContainer>
    );
};
