import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ArticleIcon from '@mui/icons-material/Article';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import SpreadShop from '@/assets/images/merch/spreadshop_logo_black.png';
import List from '@mui/material/List';
import { useTranslation } from '@/hooks/useTranslation';
import { Theme } from '@/theme/theme';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { useNavigate } from 'react-router';
import { useGetVoucherTemplatesQuery } from '@/api/order';
import { Box } from '@mui/material';
import { useGetUserDataQuery } from '@/api/user';
import { Image } from '@/components/Image/Image';
import { usePageConfig } from '@/hooks/useConfig';

type SidebarListProps = {
    onClose: () => void;
};

export const SidebarList: FunctionComponent<
    React.PropsWithChildren<SidebarListProps>
> = ({ onClose }) => {
    const { getTranslated } = useTranslation();
    const { IS_PORTAL, pageConfig, IS_OEAMTC } = useConfigSlice();
    const { isPortal } = usePageConfig();
    const { isLoggedIn, logout } = useAuthentication();
    const { data: vouchers = [] } = useGetVoucherTemplatesQuery();
    const { data: currentUser } = useGetUserDataQuery();

    const { getPagePath, routerConfig } = useCustomRouter();
    const navigate = useNavigate();

    const navigateHandler = (path: string) => () => {
        navigate(path);
        onClose();
    };

    const isVoucherEnabled =
        !IS_OEAMTC &&
        (IS_PORTAL ||
            pageConfig.configuration.vanityIdentifier === 'feldberg' ||
            (!IS_PORTAL && vouchers.length > 0));

    const isInsuranceEnabled = !IS_OEAMTC && IS_PORTAL;

    return (
        <FlexContainer
            sx={(theme) => ({
                width: 450,
                p: 8,
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            })}
            column
            fullWidth
        >
            <List>
                <Box
                    sx={(theme) => ({
                        display: 'none',
                        mb: 8,
                        borderBottom: `2px solid ${theme.palette.black}`,
                        pb: 2,
                        [theme.breakpoints.down('md')]: {
                            display: 'block',
                        },
                    })}
                >
                    {isLoggedIn ? (
                        <>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                    marginBottom: (theme: Theme) =>
                                        theme.spacing(4),
                                }}
                            >
                                {currentUser?.email}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    textTransform: 'uppercase',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                    mb: 4,
                                }}
                                onClick={navigateHandler(
                                    getPagePath(routerConfig.Register)
                                )}
                            >
                                {getTranslated('MyAccountNavigation.register')}
                            </Typography>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    textTransform: 'uppercase',
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                    mb: 4,
                                }}
                                onClick={navigateHandler(
                                    getPagePath(routerConfig.Login)
                                )}
                            >
                                {getTranslated('MyAccountNavigation.login')}
                            </Typography>
                        </>
                    )}
                </Box>

                {IS_OEAMTC || IS_PORTAL ? (
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            mb: 8,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={navigateHandler(
                            getPagePath(routerConfig.LiftOperators)
                        )}
                    >
                        <BookOnlineIcon
                            sx={{
                                marginRight: (theme: Theme) => theme.spacing(2),
                            }}
                        />
                        {getTranslated('MyAccountNavigation.buyTickets')}
                    </Typography>
                ) : null}

                <Typography
                    sx={{
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        mb: 8,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={navigateHandler(getPagePath(routerConfig.MyData))}
                >
                    <AccountCircleIcon
                        sx={{ marginRight: (theme: Theme) => theme.spacing(2) }}
                    />
                    {getTranslated('MyAccountNavigation.myData')}
                </Typography>
                <Typography
                    sx={{
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        mb: 8,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={navigateHandler(
                        getPagePath(routerConfig.MyTickets)
                    )}
                >
                    <ConfirmationNumberIcon
                        sx={{ marginRight: (theme: Theme) => theme.spacing(2) }}
                    />
                    {getTranslated('Header.myTickets')}
                </Typography>
                <Typography
                    sx={{
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        mb: 8,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={navigateHandler(
                        getPagePath(routerConfig.MyKeycards)
                    )}
                >
                    <RecentActorsIcon sx={{ mr: 2 }} />
                    {getTranslated('MyAccountNavigation.myCards')}
                </Typography>

                {isVoucherEnabled && (
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            mb: 8,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={navigateHandler(
                            getPagePath(routerConfig.MyVouchersPage)
                        )}
                    >
                        <CardMembershipIcon
                            sx={{
                                mr: 2,
                            }}
                        />
                        {getTranslated('myVouchers')}
                    </Typography>
                )}

                <Typography
                    sx={{
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        mb: 8,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={navigateHandler(
                        getPagePath(routerConfig.MyOrders)
                    )}
                >
                    <BookmarkBorderIcon sx={{ mr: 2 }} />
                    {getTranslated('MyAccountNavigation.myOrders')}
                </Typography>

                {isInsuranceEnabled && (
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            mb: 8,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={navigateHandler(
                            getPagePath(routerConfig.MyInsurancesPage)
                        )}
                    >
                        <SafetyCheckIcon sx={{ mr: 2 }} />
                        {getTranslated('MyAccountNavigation.myInsurances')}
                    </Typography>
                )}

                {isInsuranceEnabled && (
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            mb: 16,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={navigateHandler(
                            getPagePath(routerConfig.InsuranceProductPage)
                        )}
                    >
                        <SafetyCheckIcon sx={{ mr: 2 }} />
                        {getTranslated('MyAccountNavigation.insuranceProduct')}
                    </Typography>
                )}

                {isVoucherEnabled && (
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            mb: isInsuranceEnabled ? 8 : 16,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={navigateHandler(
                            getPagePath(routerConfig.Vouchers)
                        )}
                    >
                        <CardGiftcardIcon
                            sx={{
                                mr: 2,
                            }}
                        />
                        {getTranslated('VoucherRedeemPage.headline')}
                    </Typography>
                )}
                <Typography
                    sx={{
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        mb: 8,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={navigateHandler(
                        getPagePath(routerConfig.RedeemVouchers)
                    )}
                >
                    <CardMembershipIcon
                        sx={{ marginRight: (theme: Theme) => theme.spacing(2) }}
                    />
                    {getTranslated('MyAccountNavigation.redeemVoucher')}
                </Typography>

                {isPortal && (
                    <Typography
                        component='a'
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            mb: 8,
                            display: 'flex',
                            alignItems: 'center',
                            color: '#000',
                        }}
                        href='https://starjackmerch.myspreadshop.de/'
                    >
                        <Image
                            src={SpreadShop}
                            sx={{
                                width: 24,
                                height: 24,
                                marginRight: (theme: Theme) => theme.spacing(2),
                            }}
                        />
                        {getTranslated('sidebar.merch.title')}
                    </Typography>
                )}

                {isInsuranceEnabled && (
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            mb: 8,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={navigateHandler(
                            getPagePath(routerConfig.ReportClaimPage)
                        )}
                    >
                        <ArticleIcon
                            sx={{
                                marginRight: (theme: Theme) => theme.spacing(2),
                            }}
                        />
                        {getTranslated('MyAccountNavigation.reportClaim')}
                    </Typography>
                )}

                <Typography
                    sx={{
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        mb: 8,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={navigateHandler(getPagePath(routerConfig.FAQ))}
                >
                    <LiveHelpIcon
                        sx={{ marginRight: (theme: Theme) => theme.spacing(2) }}
                    />
                    {getTranslated('MyAccountNavigation.faq')}
                </Typography>
                {isLoggedIn && (
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            mb: 8,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            logout();
                            onClose();
                        }}
                    >
                        <ExitToAppIcon
                            sx={{
                                mr: 2,
                            }}
                        />
                        {getTranslated('MyAccountNavigation.logout')}
                    </Typography>
                )}
            </List>
        </FlexContainer>
    );
};
