import { FunctionComponent } from 'react';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';
import { useConfigSlice } from '@/hooks/useConfigSlice';

const ControlButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(0, 2),
}));

export const CheckoutBottomControlBar: FunctionComponent = () => {
    const { getPagePath, routerConfig } = useCustomRouter();
    const { isOeamtc } = useConfigSlice();
    const { getTranslated } = useTranslation();
    const navigate = useNavigate();

    return (
        <Paper
            variant='outlined'
            sx={(theme) => ({
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
                borderBottomRightRadius: 0,
                bottom: 0,
                display: 'flex',
                height: 64,
                justifyContent: 'center',
                left: 0,
                position: 'fixed',
                p: 3,
                width: theme.cartPage.splitWidthLeft,
            })}
        >
            <ControlButton
                onClick={() => {
                    navigate(getPagePath(routerConfig.LiftOperators));
                }}
                variant='contained'
            >
                {getTranslated('Core.bookMoreTickets')}
            </ControlButton>

            {!isOeamtc && (
                <ControlButton
                    onClick={() => {
                        navigate(getPagePath(routerConfig.Vouchers));
                    }}
                    variant='outlined'
                >
                    {getTranslated('buyStarjackVoucher')}
                </ControlButton>
            )}
        </Paper>
    );
};
