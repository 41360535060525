import { FunctionComponent } from 'react';
import { Chip, ChipProps, CircularProgress, Box } from '@mui/material';

interface LoadingChipProps extends ChipProps {
    loading?: boolean;
}

export const LoadingChip: FunctionComponent<LoadingChipProps> = ({
    loading = false,
    ...props
}) => {
    return (
        <Chip
            {...props}
            deleteIcon={loading ? undefined : props.deleteIcon}
            icon={
                loading ? (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            color: 'palette.disabled',
                        }}
                    >
                        <CircularProgress
                            sx={{
                                color: 'rgba(0, 0, 0, 0.26)',
                            }}
                            size={16}
                        />
                    </Box>
                ) : (
                    props.icon
                )
            }
            sx={{
                ...props.sx,
                ...(loading && {
                    position: 'relative',
                    color: 'transparent',

                    backgroundColor: 'rgba(0, 0, 0, 0.12)',

                    transition: 'all 100ms',
                    '& .MuiChip-deleteIcon': {
                        color: 'transparent',
                    },
                }),
            }}
        />
    );
};
