import { Moment } from 'moment';
import { Identification } from '@/models/order';
import {
    BackendType,
    convertDateToFrontend,
    CustomDate,
    FrontendType,
    Money,
    MultiLangString,
    ObjectType,
} from '@/models/general';
import { IdentificationType } from '@/models/cart';
import { ProductType } from '@/models/product';

export enum TicketVariant {
    SINGLE = 'SINGLE',
    PACKAGE = 'PACKAGE',
}

export type Ticket = {
    liftOperator: string;
    identifications: Identification[];
    price: Money;
    productId: string;
    startDate: Moment;
};

export enum TicketStatus {
    REFUNDED = 'REFUNDED',
    NEW = 'NEW',
    PAID = 'PAID',
    PRODUCED = 'PRODUCED',
    ORDERED = 'ORDERED',
}

type TicketIdentification<T extends ObjectType = FrontendType> = {
    id: string;
    title: string;
    firstname: string;
    lastname: string;
    gender: string;
    birthday: CustomDate<T>;
};

// final ticket that represent a bought product
export type UserTicket<T extends ObjectType = FrontendType> = {
    cancelable: boolean;
    cancellationDate: CustomDate<T>;
    cancelableCondition: boolean;
    depositAmount: Money;
    duration: number;
    id: string;
    identificationType: IdentificationType;
    identifications: TicketIdentification<T>[];
    liftOperator: MultiLangString;
    liftOperatorId: string;
    name: MultiLangString;
    orderDate: CustomDate<T>;
    orderDisplayId: string;
    orderId: string;
    permissionNumber: number;
    personName: MultiLangString;
    price: Money;
    startDate: CustomDate<T>;
    status: TicketStatus;
    type: ProductType;
    pdfUrl: string;
    insurance: {
        bookedAt: CustomDate<T>;
        canceledAt: CustomDate<T>;
        createdAt: CustomDate<T>;
        policyNumber: string;
        price: Money;
        revokedAt: CustomDate<T>;
        tariffName: MultiLangString;
        status: InsuranceStatus;
        revocable: boolean;
        cancelable: boolean;
    } | null;
};

export const convertUserTicketToFrontend = (
    ticket: UserTicket<BackendType>
): UserTicket => ({
    ...ticket,
    cancellationDate: convertDateToFrontend(ticket.cancellationDate),
    orderDate: convertDateToFrontend(ticket.orderDate),
    startDate: convertDateToFrontend(ticket.startDate),
    identifications: ticket.identifications.map((i) => ({
        ...i,
        birthday: convertDateToFrontend(i.birthday),
    })),
    insurance: ticket.insurance
        ? {
              ...ticket.insurance,
              bookedAt: convertDateToFrontend(ticket.insurance.bookedAt),
              canceledAt: convertDateToFrontend(ticket.insurance.canceledAt),
              createdAt: convertDateToFrontend(ticket.insurance.createdAt),
              revokedAt: convertDateToFrontend(ticket.insurance.revokedAt),
          }
        : null,
});

export enum InsuranceStatus {
    CREATED = 'CREATED',
    PAID = 'PAID',
    REFUNDED = 'REFUNDED',
    CANCELED = 'CANCELED',
    BOOKED = 'BOOKED',
    REVOKED = 'REVOKED',
    PERMISSION_BLOCKED = 'PERMISSION_BLOCKED',
}
