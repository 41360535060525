import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from '@/hooks/useAuthentication';
import { ConfigState } from '@/slices/configSlice';
import { useConfigSlice } from '@/hooks/useConfigSlice';

const getValidPath = (
    path: string | string[],
    locale: ConfigState['locale']
) => {
    if (!Array.isArray(path)) return path;

    if (locale === 'en') {
        return path.find((p) => p.includes('en')) || path[0];
    }

    return path.find((p) => !p.includes('en')) || path[0];
};

export const ProtectedRoute: FunctionComponent<
    React.PropsWithChildren<{ from: string; isProtected: boolean }>
> = ({ children, from, isProtected }) => {
    const { isLoggedIn } = useAuthentication();
    const { locale } = useConfigSlice();

    return !isProtected || isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate
            to='/login'
            state={{
                from: getValidPath(from, locale),
            }}
        />
    );
};
