import React, { useEffect, useState } from 'react';
import { Fade, Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import IconButton from '@mui/material/IconButton';
import { useConfigSlice } from '@/hooks/useConfigSlice';

type ProductCatalogStepProps = {
    iconDisplay: string | number;
    title: string;
    show: boolean;
    showConnector: boolean;
    children: React.ReactNode;
    onEntered?: () => void;
};

export const ProductCatalogStep = React.forwardRef<
    HTMLDivElement,
    ProductCatalogStepProps
>(({ iconDisplay, title, show, children, onEntered }, ref) => {
    const [localShow, setLocalShow] = useState(false);
    const [showChildren, setShowChildren] = useState(show);
    const { IS_OEAMTC } = useConfigSlice();

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                setLocalShow(show);
            }, 120);
        } else {
            setLocalShow(show);
        }
    }, [show]);

    const color = IS_OEAMTC ? '#000' : '#fff';
    return (
        <Fade
            in={localShow}
            onEnter={() => {
                setShowChildren(true);
            }}
            onExited={() => {
                setShowChildren(false);
            }}
            onEntered={() => {
                if (onEntered) {
                    onEntered();
                }
            }}
            timeout={{
                appear: 350,
                enter: 350,
                exit: 80,
            }}
        >
            <FlexContainer
                ref={ref}
                sx={{
                    my: localShow ? 1 : 0,
                    position: 'relative',
                }}
            >
                {showChildren && (
                    <FlexContainer
                        sx={{
                            minHeight: '112px',
                        }}
                        column
                        fullWidth
                    >
                        <>
                            <FlexContainer
                                sx={{
                                    alignItems: 'center',
                                    my: 4,
                                }}
                            >
                                <IconButton
                                    sx={(theme) => ({
                                        width: '40px',
                                        height: '40px',
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color,
                                        '&:hover': {
                                            backgroundColor:
                                                theme.palette.primary.main,
                                        },
                                    })}
                                    color={'primary'}
                                    size='medium'
                                >
                                    {iconDisplay}
                                </IconButton>

                                <Typography
                                    sx={{
                                        ml: 4,
                                    }}
                                >
                                    {title}
                                </Typography>
                            </FlexContainer>
                            <FlexContainer
                                sx={(theme) => ({
                                    width: '100%',
                                    ml: 10,

                                    [theme.breakpoints.down('lg')]: {
                                        width: '100%',
                                        px: 1,
                                        mx: 'auto',
                                    },
                                })}
                            >
                                {children}
                            </FlexContainer>
                        </>
                    </FlexContainer>
                )}
            </FlexContainer>
        </Fade>
    );
});

ProductCatalogStep.displayName = 'ProductCatalogStep';
