import { FunctionComponent } from 'react';
import { getPropByCurrentLocale } from '@/utils/locale';
import { Chip, Typography } from '@mui/material';
import { formatDateWithWeekDay } from '@/utils/time';
import { useTranslation } from '@/hooks/useTranslation';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { FlexContainer } from '@/components/Layout/Container';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { MultiLangString } from '@/models/general';
import { Moment } from 'moment';
import { CollectionCardContent } from '@/components/CollectionCard/common/CollectionCardContent';
import { Product, ProductType } from '@/models/product';
import { useSlice } from '@/hooks/useSlice';
import { infoSlice } from '@/slices/infoSlice';

type TicketCollectionHeaderProps = {
    productName: MultiLangString;
    productType: ProductType;
    startDay: Moment;
    duration: number;
    liftOperatorName: MultiLangString;
    onRemove?: () => void;
    infoText?: MultiLangString;
};

export const CollectionCardHeader: FunctionComponent<
    TicketCollectionHeaderProps
> = ({
    onRemove,
    startDay,
    infoText,
    productType,
    productName,
    duration,
    liftOperatorName,
}) => {
    const { IS_OEAMTC } = useConfigSlice();
    const { getTranslated } = useTranslation();
    const {
        actions: { addInfo },
    } = useSlice(infoSlice, 'info');
    const validityString =
        productType === ProductType.SEASON
            ? `${getTranslated('Core.validFrom')}`
            : duration === 1
            ? getTranslated('Core.validAt')
            : getTranslated('Core.validFrom');
    const color = IS_OEAMTC ? '#000' : '#fff';

    const getDurationString = (duration: number, type: Product['type']) => {
        if (type === ProductType.SEASON) {
            return `${getTranslated('duration')}: 1 ${getTranslated('season')}`;
        }

        return `${getTranslated('duration')}: ${duration} ${getTranslated(
            duration > 1 ? 'Core.days' : 'Core.day'
        )}`;
    };

    return (
        <CollectionCardContent
            sx={(theme) => ({
                position: 'relative',
                backgroundColor: theme.palette.primary.main,
                color,
            })}
        >
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: '1.1rem',
                    width: '80%',
                }}
            >
                {getPropByCurrentLocale(productName)}
            </Typography>
            <Typography
                sx={() => ({
                    mt: 1,
                    width: '80%',
                    color,
                })}
            >
                {getPropByCurrentLocale(liftOperatorName)}
            </Typography>

            <FlexContainer
                sx={{
                    mt: 4,
                    alignItems: 'center',
                    height: '40px',
                }}
            >
                <Chip
                    sx={(theme) => ({
                        backgroundColor: color,
                        color: theme.palette.primary.main,
                    })}
                    variant='filled'
                    label={
                        <Typography variant='subtitle2'>
                            {validityString} {formatDateWithWeekDay(startDay)}
                        </Typography>
                    }
                    size='small'
                />

                {!!getPropByCurrentLocale(infoText) && (
                    <IconButton
                        onClick={() => {
                            addInfo({
                                message: getPropByCurrentLocale(infoText),
                            });
                        }}
                        sx={{
                            ml: 'auto',
                            '&:hover': {
                                backgroundColor: 'rgba(255,255,255,0.10)',
                            },
                        }}
                    >
                        <InfoIcon
                            sx={() => ({
                                color,
                            })}
                        />
                    </IconButton>
                )}
            </FlexContainer>

            {!hideDurationString(productType, duration) && (
                <Typography
                    variant='body2'
                    sx={() => ({
                        textTransform: 'capitalize',
                        color,
                    })}
                >
                    {getDurationString(duration, productType)}
                </Typography>
            )}

            {!!onRemove && (
                <IconButton
                    onClick={onRemove}
                    sx={() => ({
                        position: 'absolute',
                        top: '16px',
                        right: '16px',
                        width: '40px',
                        height: '40px',
                        color,
                    })}
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </CollectionCardContent>
    );
};

const hideDurationString = (
    productType: Product['type'],
    duration: Product['duration']
) => {
    if (productType) {
        return true;
    } else {
        return duration === 1;
    }
};
