import { FunctionComponent, useEffect } from 'react';
import {
    BrowserRouter as ReactRouter,
    Route,
    Routes,
    useLocation,
    Navigate,
} from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { RouteConfigEntry } from './routerConfig';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { KeycardTransferBar } from '@/components/AppBar/KeycardTransferBar';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { FooterContainer } from '@/components/Footer/FooterContainer';
import { AppBarContainer } from '@/components/AppBar/AppBarContainer';
import { Seo } from '@/components/Seo/Seo';

const PageRouting: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { entriesWithAppBar, entriesWithoutAppBar, defaultRoutePathname } =
        useCustomRouter();
    const { pathname } = useLocation();
    const { setLocale } = useConfigSlice();

    if (pathname.includes('/en')) {
        setLocale('en');
    }

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [pathname]);

    return (
        <Routes>
            {entriesWithoutAppBar.map(
                ({ children, footer, path, ...rest }: RouteConfigEntry, i) => {
                    const customPath = Array.isArray(path) ? path : [path];
                    return customPath.map((stringPath) => (
                        <Route
                            key={i}
                            {...rest}
                            path={stringPath}
                            element={
                                <>
                                    <ProtectedRoute
                                        isProtected={rest.protectedRoute}
                                        from={stringPath}
                                    >
                                        {children}
                                    </ProtectedRoute>
                                    <FooterContainer type={footer} />
                                </>
                            }
                        />
                    ));
                }
            )}

            {entriesWithAppBar.map(
                ({ children, footer, path, ...rest }: RouteConfigEntry, i) => {
                    const customPath = Array.isArray(path) ? path : [path];
                    return customPath.map((stringPath) => (
                        <Route
                            key={i}
                            {...rest}
                            path={stringPath}
                            element={
                                <>
                                    <AppBarContainer />
                                    <KeycardTransferBar />
                                    <ProtectedRoute
                                        isProtected={rest.protectedRoute}
                                        from={stringPath}
                                    >
                                        {children}
                                        <FooterContainer type={footer} />
                                    </ProtectedRoute>
                                </>
                            }
                        />
                    ));
                }
            )}

            <Route path='*' element={<Navigate to={defaultRoutePathname} />} />
        </Routes>
    );
};

export const Router: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => {
    return (
        <ReactRouter>
            <Seo />
            <PageRouting />
        </ReactRouter>
    );
};
