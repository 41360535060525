import { FunctionComponent, useMemo, useState } from 'react';

import { FavoriteIcon } from '@/components/Favorites/FavoriteIcon';

import IconButton from '@mui/material/IconButton';
import { useGetFavoritesQuery } from '@/api/liftOperator';
import {
    useDeleteFavoriteMutation,
    usePostFavoriteMutation,
} from '@/api/liftOperator';
import { keyframes } from '@mui/material';

export const AddToFavoriteButton: FunctionComponent<
    React.PropsWithChildren<{
        liftOperatorId: string;
    }>
> = ({ liftOperatorId }) => {
    const [postFavorite] = usePostFavoriteMutation();
    const [deleteFavorite] = useDeleteFavoriteMutation();
    const { data: favorites = [] } = useGetFavoritesQuery();
    const isLiftOperatorInFavorites = useMemo(
        () => !!favorites.find((lO) => lO.liftOperatorId === liftOperatorId),
        [liftOperatorId, favorites.length]
    );
    const [clicked, setClicked] = useState(false);

    const isFavorite = useMemo(
        () => isLiftOperatorInFavorites,
        [liftOperatorId, favorites.length]
    );
    return (
        <IconButton
            onClick={() => {
                const action = isFavorite ? deleteFavorite : postFavorite;
                action({ liftOperatorId });

                setClicked(true);

                setTimeout(() => {
                    setClicked(false);
                }, 500);
            }}
            sx={{
                width: '48px',
                height: '48px',

                ...(clicked && {
                    animation: `${scaleEffect} 500ms`,
                }),
            }}
        >
            <FavoriteIcon isFavorite={isFavorite} />
        </IconButton>
    );
};

const scaleEffect = keyframes`
  0% {
    transform: scale(1);
  }
    
  50% {
    transform: scale(1.5);
  }  
    
  100% {
    transform: scale(1);
  }
`;
