import { useEffect, useMemo, useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import Typography from '@mui/material/Typography';
import { useTranslation } from '@/hooks/useTranslation';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { getPropByCurrentLocale } from '@/utils/locale';
import { FaqsListWithTitle } from '@/components/Faqs/FaqsListWithTitle';
import { FlatCard } from '@/components/Card/FlatCard';
import { styled } from '@mui/material/styles';
import { CardContent, TextField } from '@mui/material';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { useGetFaqsQuery } from '@/api/faq';
import { transformLocaleToLanguage } from '@/models/general';
import throttle from 'lodash/throttle';
import { FaqGroupIndex } from '@/models/faq';

const FaqHelptext = styled(Typography)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
}));

const termsContained = (terms: string[], text: string) => {
    return terms.every((term) =>
        text.toLowerCase().includes(term.toLocaleLowerCase())
    );
};

export const FaqPage = () => {
    const { locale } = useConfigSlice();
    const { getTranslated } = useTranslation();
    const { pageConfig, IS_PORTAL } = useConfigSlice();
    const { data: faqResponse } = useGetFaqsQuery({
        language: transformLocaleToLanguage(locale),
        liftOperatorId: pageConfig?.configuration?.liftOperatorId || '',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [faqGroupIndex, setFaqGroupIndex] = useState<FaqGroupIndex>(
        faqResponse?.faqGroupIndex || {}
    );

    const throttledSearch = useMemo(
        () =>
            throttle((e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                setSearchTerm(value);
            }, 500),
        []
    );

    useEffect(() => {
        if (searchTerm.length > 0 && !!faqResponse?.faqGroupIndex) {
            // build new faqGroupIndex
            const newIndex: FaqGroupIndex = {};
            Object.entries(faqResponse?.faqGroupIndex).forEach(
                ([groupId, faqs]) => {
                    const filteredFaqs = faqs.filter((faq) => {
                        const searchTermSplitted = searchTerm.split(' ');

                        return (
                            termsContained(searchTermSplitted, faq.title) ||
                            termsContained(searchTermSplitted, faq.text)
                        );
                    });

                    if (filteredFaqs.length) {
                        newIndex[groupId] = filteredFaqs;
                    }
                }
            );
            setFaqGroupIndex(newIndex);
        } else {
            setFaqGroupIndex(faqResponse?.faqGroupIndex || {});
        }
    }, [searchTerm]);

    useEffect(() => {
        if (faqResponse?.faqGroupIndex) {
            setFaqGroupIndex(faqResponse.faqGroupIndex);
        }
    }, [faqResponse]);

    const { groups } = faqResponse || {};
    return (
        <SubPageTemplate title={getTranslated('FAQPage.faq')}>
            {!!faqResponse && (
                <FlexContainer
                    sx={{
                        flexDirection: 'column',
                        width: '100%',
                        mx: 'auto',
                        mb: '40px',
                    }}
                    center
                >
                    <FlexContainer
                        sx={{
                            p: 2,
                            width: '100%',
                        }}
                    >
                        <TextField
                            sx={(theme) => ({
                                ml: 'auto',
                                width: '360px',

                                [theme.breakpoints.down('lg')]: {
                                    width: '100%',
                                    mb: 2,
                                },
                            })}
                            label={getTranslated('searchFaqs')}
                            onChange={throttledSearch}
                        />
                    </FlexContainer>

                    {!!faqGroupIndex?.general?.length && (
                        <FaqsListWithTitle
                            title={
                                IS_PORTAL ? '' : getTranslated('generalFaqs')
                            }
                            faqs={faqGroupIndex.general}
                            accordionColor={'grey'}
                        />
                    )}

                    <FlexContainer
                        sx={{
                            flexDirection: 'column',
                            mx: 'auto',
                            mb: '40px',
                            width: '100%',
                        }}
                    >
                        {!!groups.length && !IS_PORTAL && (
                            <Typography
                                sx={() => ({
                                    color: 'primary.main',
                                    mr: 'auto',
                                    fontSize: '1.3rem',
                                    mt: 4,
                                    mb: 2,
                                })}
                                variant='h6'
                            >
                                {getTranslated('customFaqTitle')}
                            </Typography>
                        )}

                        <FlexContainer
                            sx={{
                                ...(!IS_PORTAL && {
                                    ml: 2,
                                }),
                            }}
                            fullWidth
                            column
                        >
                            {groups.map((group, index) => {
                                if (!faqGroupIndex[group.id]) {
                                    return null;
                                }

                                return (
                                    <FaqsListWithTitle
                                        key={index}
                                        title={getPropByCurrentLocale(
                                            group.name
                                        )}
                                        faqs={
                                            faqResponse?.faqGroupIndex[group.id]
                                        }
                                        accordionColor={
                                            IS_PORTAL ? 'grey' : 'primary'
                                        }
                                    />
                                );
                            })}
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            )}

            <FlatCard
                sx={{
                    mr: 'auto',
                    mb: 8,
                    width: '400px',
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            mb: 3,
                        }}
                    >
                        {getTranslated('SupportPage.help')}
                    </Typography>

                    <FaqHelptext>
                        {getTranslated('SupportPage.contactEmail')}
                        <a href='mailto:support@starjack.com'>
                            {' '}
                            support@starjack.com
                        </a>
                    </FaqHelptext>
                    <FaqHelptext>
                        {getTranslated('SupportPage.contactPhone')}{' '}
                        <a href='tel:+43624673430999'>+43.6246.73430 999</a>
                    </FaqHelptext>
                </CardContent>
            </FlatCard>
        </SubPageTemplate>
    );
};
