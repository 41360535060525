import {
    Coordinates,
    Link,
    Locale,
    Money,
    MultiLangString,
} from '@/models/general';
import { Address } from '@/models/user';
import { SeoLiftOperatorMetaData } from '@/models/seo';
import { ProductEnvironment } from '@/models/order';

export type Logo = {
    type: string;
    href: string;
};

type LiftOperatorAddress = {
    coordinates?: Coordinates;
    viewportNE?: Coordinates;
    viewportSW?: Coordinates;
} & Address;

export type LiftOperator = {
    id: string;
    name: MultiLangString;
    alternativeNames: string;
    active: boolean;
    payPerUse: boolean;
    phone: string;
    fax: string;
    homepage: string;
    email: string;
    shopEmail: string;
    incertActive: boolean;
    address: LiftOperatorAddress;
    slopeKm: number;
    slopesBlue: number;
    slopesRed: number;
    slopesBlack: number;
    kmBlue: number;
    kmRed: number;
    kmBlack: number;
    routes: number;
    routesKm: number;
    draglifts: number;
    chairlifts: number;
    gondolas: number;
    aerialTramways: number;
    funiculars: number;
    conveyors: number;
    slopingTunnelRails: number;
    username: string;
    facilities: {
        address: LiftOperatorAddress;
        id: string;
        name: string;
        metaData: LiftOperator['metaData'];
    }[];
    logos: Logo[];
    infoText: MultiLangString;
    _links: Link[];
    pickupDepositAmount: Money;
    internalProductionDepositAmount: Money;
    internalProductionShippingCosts: Money;

    disablePortal?: boolean;
    facilityId?: string;

    // only set on ÖAMTC shop
    noOffersText?: string;
    listLabel?: string;
    metaData: SeoLiftOperatorMetaData[];
    products: {
        environment: ProductEnvironment;
        count: number;
    }[];
    dayTicketsButtonText: MultiLangString;
    seasonTicketsButtonText: MultiLangString;
};

export const getLiftOperatorProductsPageLink = (
    liftOperator: LiftOperator,
    locale: Locale
) => {
    const metaData = liftOperator.metaData.find(
        (md) => md.language.toLocaleLowerCase() === locale
    );

    if (!metaData) return liftOperator.id;

    return metaData.slug;
};

export const getAllLiftsOfOperator = (liftOperator: LiftOperator) => {
    if (!liftOperator) return 0;

    return (
        liftOperator.gondolas +
        liftOperator.chairlifts +
        liftOperator.draglifts +
        liftOperator.aerialTramways +
        liftOperator.funiculars
    );
};
