import { FunctionComponent, useState } from 'react';
import { FlatCardWithTitle } from '@/components/Card/FlatCardWithTitle';
import {
    checkoutApi,
    useGetCurrentCheckoutSession,
    usePostConsultationProtocolMutation,
} from '@/api/checkout';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { downloadBlob } from '@/utils/download';
import { useDispatch } from 'react-redux';
import { useNotification } from '@/hooks/useNotification';
import { useTranslation } from '@/hooks/useTranslation';
import Button from '@mui/material/Button';
import { useCheckoutHelpers } from '@/hooks/useCheckoutHelpers';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { getPropByCurrentLocale } from '@/utils/locale';

export const InsuranceConsentContainer: FunctionComponent = () => {
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const { insuranceConsentSelected } = useCheckoutHelpers();
    const dispatch = useDispatch();
    const [checkedConsents, setCheckedConsents] = useState<string[]>([]);
    const [postConsents] = usePostConsultationProtocolMutation();
    const { addNotification } = useNotification();
    const { getTranslated, locale } = useTranslation();
    const [buttonLoading, setButtonLoading] = useState(false);

    if (
        insuranceConsentSelected &&
        checkedConsents.length !== checkoutSession?.insurance?.consents.length
    ) {
        setCheckedConsents(checkoutSession.insurance.consents.map((c) => c.id));
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const consentId = event.target.name;
        if (event.target.checked) {
            setCheckedConsents([...checkedConsents, consentId]);
        } else {
            setCheckedConsents(
                checkedConsents.filter((id) => id !== consentId)
            );
        }
    };

    const onDownloadConsentPDF = () => {
        setButtonLoading(true);
        postConsents({
            checkoutId: checkoutSession.id,
            consents: checkedConsents,
        })
            .unwrap()
            .then((blob) => {
                downloadBlob(
                    blob,
                    locale === 'de'
                        ? 'Vorvertragliche Dokumente.pdf'
                        : 'pre-contractual documents.pdf'
                );

                dispatch(
                    checkoutApi.util.invalidateTags([
                        { type: 'CHECKOUTS', id: checkoutSession.id },
                    ])
                );
            })
            .catch(() => {
                addNotification({
                    message:
                        'Fehler beim Herunterladen der Zustimmungserklärung. Bitte versuche es später noch einmal',
                    type: 'error',
                });
            })
            .finally(() => {
                setButtonLoading(false);
            });
    };

    const allConsentsChecked = checkoutSession.insurance?.consents.every(
        (consent) => checkedConsents.includes(consent.id)
    );

    return (
        <FlatCardWithTitle
            title={getTranslated('Checkoutpage.insurance.consent.title')}
        >
            {checkoutSession.insurance.consents.map((consent) => (
                <FormControlLabel
                    key={consent.id}
                    control={
                        <Checkbox
                            onChange={handleChange}
                            name={consent.id}
                            color='primary'
                            checked={checkedConsents.includes(consent.id)}
                        />
                    }
                    label={
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: getPropByCurrentLocale(consent.text),
                            }}
                        />
                    }
                />
            ))}

            {allConsentsChecked && (
                <FlexContainer
                    sx={{
                        mt: 2,
                        p: 2,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    fullWidth
                >
                    <Button
                        onClick={onDownloadConsentPDF}
                        loading={buttonLoading}
                        color={insuranceConsentSelected ? 'success' : 'warning'}
                        variant='contained'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 10,

                            ...(insuranceConsentSelected && {
                                color: '#fff',
                            }),
                        }}
                        disableElevation
                        disableRipple={insuranceConsentSelected}
                    >
                        <TaskAltIcon
                            sx={{
                                mr: 2,
                            }}
                        />
                        {getTranslated(
                            insuranceConsentSelected
                                ? 'CheckoutPage.consent.downloadButton.label.downloaded'
                                : 'CheckoutPage.consent.downloadButton.label'
                        )}
                    </Button>
                </FlexContainer>
            )}
        </FlatCardWithTitle>
    );
};
