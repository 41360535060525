import { Fragment, FunctionComponent, useState } from 'react';
import {
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { Column, Order } from './tableConfig';

type SortableTableHeaderCell = {
    headCell: Column;
    orderByColumn: string;
    order: Order;
    onRequestSort: (property: Column['label']) => void;
};

const SortableTableHeaderCell: FunctionComponent<
    React.PropsWithChildren<SortableTableHeaderCell>
> = ({ headCell, orderByColumn, order, onRequestSort }) => (
    <TableCell
        align={
            headCell.isNumeric || headCell.isDate || headCell.isPrice
                ? 'right'
                : 'left'
        }
        // padding={headCell?.styles?.padding ? 'default' : 'none'}
        sortDirection={orderByColumn === headCell.label ? order : false}
    >
        <TableSortLabel
            active={orderByColumn === headCell.label}
            direction={orderByColumn === headCell.label ? order : 'asc'}
            onClick={() => onRequestSort(headCell.label)}
        >
            {headCell.label}
        </TableSortLabel>
    </TableCell>
);

const TableHeaderCell: FunctionComponent<
    React.PropsWithChildren<Pick<SortableTableHeaderCell, 'headCell'>>
> = ({ headCell }) => {
    const [sort, setSort] = useState<'asc' | 'desc'>('asc');

    return (
        <TableCell
            style={{
                cursor: headCell.onClick ? 'pointer' : 'cursor',
            }}
            onClick={() => {
                if (headCell.onClick) {
                    headCell.onClick(sort);
                    setSort(sort === 'asc' ? 'desc' : 'asc');
                }
            }}
            key={headCell.label}
            align={
                headCell.isNumeric || headCell.isDate || headCell.isPrice
                    ? 'right'
                    : 'left'
            }
        >
            {headCell.label}
            {headCell.onClick && (
                <TableSortLabel
                    active={true}
                    direction={sort}
                    onClick={() => {
                        if (headCell.onClick) {
                            headCell.onClick(sort);
                            setSort(sort === 'asc' ? 'desc' : 'asc');
                        }
                    }}
                />
            )}
        </TableCell>
    );
};

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort?: (property: Column['label']) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: number;
    rowCount: number;
    headCells: Column[];
    rowsSelectable: boolean;
    sortable: boolean;
}

export const TableHeader: FunctionComponent<
    React.PropsWithChildren<EnhancedTableProps>
> = ({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    rowsSelectable,
    sortable,
}) => {
    const orderByColumnLabel = headCells[orderBy].label;

    return (
        <TableHead>
            <TableRow>
                {rowsSelectable && (
                    <TableCell padding='checkbox'>
                        <Checkbox
                            indeterminate={
                                numSelected > 0 && numSelected < rowCount
                            }
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                    </TableCell>
                )}

                {headCells.map((headCell) => (
                    <Fragment key={headCell.label}>
                        {sortable ? (
                            <SortableTableHeaderCell
                                headCell={headCell}
                                orderByColumn={orderByColumnLabel}
                                order={order}
                                onRequestSort={onRequestSort}
                            />
                        ) : (
                            <TableHeaderCell headCell={headCell} />
                        )}
                    </Fragment>
                ))}
            </TableRow>
        </TableHead>
    );
};
