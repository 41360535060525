import { FunctionComponent } from 'react';
import {
    CheckoutCard,
    CheckoutCardContent,
} from '@/components/PageContent/CartPageContent/CheckoutItemList/CheckoutCard';
import { KeycardProductionTypeIcon } from '@/components/PageContent/CartPageContent/CheckoutItemList/KeycardProductionTypeIcon';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import { FlexContainer } from '@/components/Layout/Container';
import { TicketIdentVariantInfoAccordion } from '@/components/PageContent/CartPageContent/TicketIdentVariant/TicketIdentVariantInfoAccordion';
import { IdentificationType } from '@/models/cart';
import { LoadingButton } from '@mui/lab';
import { useGetCurrentCheckoutSession } from '@/api/checkout';

const VariantCard = styled(CheckoutCard)(() => ({
    width: '100%',
    borderBottom: 'unset',
}));

const ContentContainer = styled(CheckoutCardContent)(() => ({
    alignItems: 'center',
}));

const IconContainer = styled(FlexContainer)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    width: 64,
    height: 64,
}));

const VariantIcon = styled(KeycardProductionTypeIcon)(() => ({
    transform: 'scale(0.75)',
}));

const TicketVariantLabel = styled(Typography)(() => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
}));

export const TicketIdentVariantTileMobile: FunctionComponent<{
    onClick: (variant: IdentificationType) => void;
    variant: IdentificationType;
    isLoading: boolean;
}> = ({ variant, onClick, isLoading }) => {
    const { getTranslated } = useTranslation();
    const { isLoading: checkoutLoading } = useGetCurrentCheckoutSession();

    return (
        <VariantCard>
            <ContentContainer>
                <IconContainer>
                    <VariantIcon variant={variant} />
                </IconContainer>

                <TicketVariantLabel>
                    {getTranslated(variant)}
                </TicketVariantLabel>
                <LoadingButton
                    variant='outlined'
                    onClick={() => {
                        onClick(variant);
                    }}
                    loading={isLoading}
                    disabled={checkoutLoading}
                >
                    {getTranslated('select')}
                </LoadingButton>
            </ContentContainer>
            <TicketIdentVariantInfoAccordion variant={variant} />
        </VariantCard>
    );
};
