import { useTranslation } from '@/hooks/useTranslation';

export const useRedeemVoucherErrorCode = () => {
    const { getTranslated } = useTranslation();

    const getTranslateKeyFromRedeemErrorCode = (errorCode: string) => {
        switch (errorCode) {
            case 'incert_voucher_not_redeemable':
                return getTranslated(
                    'redeem_voucher.incert_voucher_not_redeemable'
                );
            case 'incert_voucher_not_partly_redeemable':
                return getTranslated(
                    'redeem_voucher.incert_voucher_not_partly_redeemable'
                );
            case 'incert_voucher_depleted':
                return getTranslated('redeem_voucher.incert_voucher_depleted');
            case 'incert_only_possible_with_integration_token':
                return getTranslated(
                    'redeem_voucher.incert_only_possible_with_integration_token'
                );
            case 'incert_not_active':
                return getTranslated('redeem_voucher.incert_not_active');
            case 'invalid_currency':
                return getTranslated('redeem_voucher.invalid_currency');
            case 'invalid_amount':
                return getTranslated('redeem_voucher.invalid_amount');
            case 'min':
                return getTranslated('redeem_voucher.min');
            case 'voucher_not_paid':
                return getTranslated('redeem_voucher.voucher_not_paid');
            case 'coupon_already_used':
                return getTranslated('CouponPage.coupon_already_used');
            case 'coupon_code_expired':
                return getTranslated('CouponPage.coupon_code_expired');
            case 'coupon_code_not_yet_valid':
                return getTranslated('CouponPage.coupon_code_not_yet_valid');
            case 'incert_not_supported_in_portal':
                return getTranslated(
                    'redeem_voucher.incert_not_supported_in_portal'
                );
            case 'voucher_already_exists':
                return getTranslated('redeem_voucher.voucher_already_exists');
            default:
                return getTranslated('CouponPage.coupon_code_not_found');
        }
    };

    const getErrorMessage = (errorCode: string) => {
        return getTranslated(getTranslateKeyFromRedeemErrorCode(errorCode));
    };

    return {
        getErrorMessage,
    };
};
