import { isArray, isPlainObject } from 'lodash';
import moment from 'moment';

const isMoment = (value) => moment.isMoment(value);

const extractType = (value) => {
    // complex data first
    if (isMoment(value)) {
        return {
            value: value.toISOString(),
            dataType: 'moment',
        };
    } else if (isPlainObject(value)) {
        return {
            value: serializer(value),
            dataType: 'plainObject',
        };
    } else if (isArray(value)) {
        return {
            value: value.map((val) => extractType(val)),
            dataType: 'array',
        };
    } else {
        return {
            value,
            dataType: typeof value,
        };
    }
};

const serializer = (baseObject: object) => {
    // convert every concrete value to an object with value & type
    // except some specific classes like Moment -> this will be converted to value & type = 'Moment'

    if (!isPlainObject(baseObject)) return '';

    const intermediate = {};
    for (const key in baseObject) {
        if (baseObject.hasOwnProperty(key)) {
            const value = baseObject[key];

            intermediate[key] = extractType(value);
        }
    }

    return intermediate;
};

const serializeObject = (baseObject: object) => {
    if (!baseObject) return '';
    return JSON.stringify(serializer(baseObject));
};

const extractFromType = (typeObject) => {
    const { value, dataType } = typeObject;
    if (dataType === 'moment') {
        return moment(value);
    } else if (dataType === 'plainObject') {
        return { ...deSerializer(value) };
    } else if (dataType === 'array') {
        return value.map((val) => extractFromType(val));
    } else {
        return value;
    }
};

const deSerializer = (baseObject: object) => {
    const parsed = {};
    for (const key in baseObject) {
        if (baseObject.hasOwnProperty(key)) {
            parsed[key] = extractFromType(baseObject[key]);
        }
    }

    return parsed;
};

const deSerializeObject = (baseObject: string | object, defaultValue = {}) => {
    if (!baseObject) return defaultValue;
    const parsed =
        typeof baseObject === 'string' ? JSON.parse(baseObject) : baseObject;
    return deSerializer(parsed);
};

export { serializeObject, deSerializeObject };
