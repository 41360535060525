import React, { RefObject, useEffect, useState } from 'react';
import {
    FlexContainer,
    FlexContainerProps,
} from '@/components/Layout/Container';
import { Dropzone } from '@/components/Image/Dropzone';
import { ACCEPTED_FILES_IMAGE } from '@/constants/index';
import { Cropper } from '@/components/Image/Cropper';
import { Button } from '@/components/Layout/Button/Button';
import { useTranslation } from '@/hooks/useTranslation';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { compressImage } from '@/utils/image';
import { useNotification } from '@/hooks/useNotification';

const PreviewImage = styled('img')(({ theme }) => ({
    margin: theme.spacing(4, 0),
    height: '300px',
    objectFit: 'contain',
    aspectRatio: '13 / 20',
    [theme.breakpoints.down('lg')]: {
        maxHeight: '100%',
        maxWidth: '100%',
        // maxWidth: '120px',
    },
}));

type UploadAndCropperProps = {
    onCrop: (data: string) => void;
    onImageUploaded?: (original: string) => void;
    onImageDelete?: () => void;
    defaultImage?: string;
};

export const UploadAndCropper = React.forwardRef<
    FlexContainerProps,
    UploadAndCropperProps
>(({ onCrop, defaultImage, onImageDelete, onImageUploaded }, ref) => {
    const { addNotification } = useNotification();
    const [image, setImage] = useState<string>(defaultImage);
    const [cropState, setCropState] = useState<'upload' | 'crop' | 'complete'>(
        'upload'
    );
    const [imageData, setImageData] = useState<{
        imgRef: RefObject<HTMLImageElement>;
        crop: string;
    }>({
        imgRef: null,
        crop: null,
    });
    const [imageCropped, setImageCropped] = useState('');

    const { getTranslated } = useTranslation();

    useEffect(() => {
        setImage(defaultImage);
    }, [defaultImage]);

    return (
        <FlexContainer
            ref={ref}
            sx={(theme) => ({
                width: '100%',
                height: '100%',

                [theme.breakpoints.down('lg')]: {
                    minWidth: 'unset',
                },
            })}
        >
            {cropState === 'upload' && (
                <Dropzone
                    accept={ACCEPTED_FILES_IMAGE}
                    onDrop={async (acceptedFiles) => {
                        const [file] = acceptedFiles;

                        if (file) {
                            const objectUrl = URL.createObjectURL(file);

                            try {
                                const compressed = await compressImage(
                                    objectUrl
                                );

                                setImage(compressed);
                                setCropState('crop');
                            } catch (e) {
                                addNotification({
                                    message:
                                        'Fehler beim Hochladen und Komprimieren vom Foto. Bitte versuchen Sie es erneut oder verwenden Sie ein anderes.',
                                    type: 'error',
                                });

                                return;
                            }

                            if (onImageUploaded) {
                                onImageUploaded(objectUrl);
                            }
                        }
                    }}
                />
            )}

            {cropState === 'crop' && (
                <FlexContainer
                    sx={{
                        width: '100%',
                        height: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    column
                >
                    <Cropper
                        src={image}
                        onCrop={(crop, imgRef) => {
                            setImageData({
                                imgRef,
                                crop,
                            });
                        }}
                        aspect={13 / 20}
                    />
                    <FlexContainer
                        sx={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            py: 2,
                        }}
                    >
                        <>
                            <Button
                                sx={{
                                    mx: 1,
                                }}
                                variant='outlined'
                                onClick={() => {
                                    setImageData(null);
                                    setCropState('upload');
                                }}
                            >
                                {getTranslated(
                                    'CheckoutKeyCardForm.fotoDelete'
                                )}
                            </Button>
                            <LoadingButton
                                sx={{
                                    mx: 1,
                                }}
                                variant='contained'
                                disabled={!imageData}
                                onClick={() => {
                                    setImageCropped(imageData.crop);
                                    setCropState('complete');
                                    onCrop(imageData.crop);
                                }}
                            >
                                {getTranslated('crop')}
                            </LoadingButton>
                        </>
                    </FlexContainer>
                </FlexContainer>
            )}

            {cropState === 'complete' && !!imageCropped && (
                <FlexContainer
                    sx={{
                        width: '100%',
                        height: '100%',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <FlexContainer
                        sx={(theme) => ({
                            width: '100%',
                            height: '400px',
                            bgcolor: theme.palette.grey[200],
                            justifyContent: 'center',
                            alignItems: 'center',

                            [theme.breakpoints.down('lg')]: {
                                height: '300px',
                            },
                        })}
                    >
                        <PreviewImage src={imageCropped} />
                    </FlexContainer>

                    <FlexContainer
                        sx={{
                            width: '100%',
                            py: 2,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant='outlined'
                            color='error'
                            onClick={() => {
                                setImageData(null);
                                setCropState('upload');

                                if (onImageDelete) {
                                    onImageDelete();
                                }
                            }}
                        >
                            {getTranslated('CheckoutKeyCardForm.fotoDelete')}
                        </Button>
                    </FlexContainer>
                </FlexContainer>
            )}
        </FlexContainer>
    );
});

UploadAndCropper.displayName = 'UploadAndCropper';
