import { Fragment, FunctionComponent, useMemo, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    GridProps,
    InputLabel,
    Link,
} from '@mui/material';
import { Address, createEmptyAddress } from '@/models/user';
import { DialogTitle } from '@/components/Dialog/DialogTite';
import { Button } from '@/components/Layout/Button/Button';
import { Form, Formik } from 'formik';
import { Dialog } from '@/components/Dialog/Dialog';
import { useTranslation } from '@/hooks/useTranslation';
import { GoogleAddressAutoComplete } from '@/components/AddressForm/GoogleAddressAutoComplete';
import { countryOptionsByLocale } from '@/constants/countries';
import { TextFieldForm } from '@/components/Layout/Form/fields/TextFieldForm';
import { SelectField } from '@/components/Layout/Form/fields/SelectField';
import { LoadingButton } from '@mui/lab';

const buildValues = (address: Partial<Address>): Address => {
    if (!address) {
        return createEmptyAddress();
    }

    return {
        ...createEmptyAddress(),
        ...address,
    };
};

type AddressFormDialogProps = {
    open: boolean;
    onClose: () => void;
    onChange: (address: Address) => void;
    type: 'invoice' | 'shipping';
    initialAddress: Address | null;
    saveButtonLoading?: boolean;
};

export const AddressFormDialog: FunctionComponent<AddressFormDialogProps> = ({
    open,
    onClose,
    onChange,
    initialAddress,
    saveButtonLoading = false,
    type,
}) => {
    const [isOpen, setIsOpen] = useState(open);
    const [enterManual, setEnterManual] = useState(false);
    const { getTranslated, locale } = useTranslation();
    const countryOptions = useMemo(
        () => [
            ...countryOptionsByLocale(locale).map(([cca2, name]) => ({
                label: name,
                value: cca2,
            })),
        ],
        [locale]
    );

    const closeDialog = () => {
        setIsOpen(false);
        onClose();
    };

    if (open !== isOpen) {
        setIsOpen(open);
    }

    return (
        <Dialog maxWidth='md' open={isOpen} onClose={closeDialog}>
            <Formik<Address>
                initialValues={buildValues(initialAddress)}
                onSubmit={(values) => {
                    onChange(values);
                }}
            >
                {({ handleBlur, setValues, values }) => (
                    <Form
                        onKeyDown={(keyEvent) => {
                            if (keyEvent.key === 'Enter') {
                                keyEvent.preventDefault();
                            }
                        }}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <DialogTitle
                            title={getTranslated(
                                type === 'invoice'
                                    ? 'addressDialogInvoiceTitle'
                                    : 'addressDialogShippingTitle'
                            )}
                            onClose={closeDialog}
                        />
                        <DialogContent>
                            <Grid container spacing={4}>
                                <FormField
                                    xs={12}
                                    md={6}
                                    label={getTranslated('Address.firstname')}
                                    onBlur={handleBlur}
                                    name='firstname'
                                />
                                <FormField
                                    xs={12}
                                    md={6}
                                    label={getTranslated('Address.lastname')}
                                    name='lastname'
                                />
                                <FormField
                                    xs={12}
                                    label={getTranslated('Address.company')}
                                    name='company'
                                />

                                {enterManual ? (
                                    <Fragment>
                                        <FormField
                                            xs={12}
                                            label={getTranslated(
                                                'Address.street'
                                            )}
                                            name='street'
                                        />

                                        <FormField
                                            xs={12}
                                            md={6}
                                            label={getTranslated(
                                                'Address.zipcode'
                                            )}
                                            name='zipcode'
                                        />
                                        <FormField
                                            xs={12}
                                            md={6}
                                            label={getTranslated(
                                                'Address.city'
                                            )}
                                            name='city'
                                        />

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    {getTranslated(
                                                        'Address.country'
                                                    )}
                                                </InputLabel>
                                                <SelectField
                                                    name='country'
                                                    options={countryOptions}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Fragment>
                                ) : (
                                    <Grid xs={12} item>
                                        <GoogleAddressAutoComplete
                                            initialAddress={values}
                                            onAddressChange={(address) => {
                                                setValues(address);
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            <Link
                                onClick={() => {
                                    setEnterManual((prevState) => !prevState);
                                }}
                                sx={{
                                    display: 'block',
                                    mt: 3,
                                    ml: 1,
                                }}
                            >
                                {enterManual
                                    ? getTranslated('searchAddress')
                                    : getTranslated('enterManually')}
                            </Link>
                        </DialogContent>
                        <DialogActions
                            sx={(theme) => ({
                                [theme.breakpoints.down('lg')]: {
                                    height: theme.appBar.height,
                                    bottom: 0,
                                    position: 'fixed',
                                    width: '100%',
                                    boxShadow: theme.design.general.boxShadow,
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    zIndex: 10,
                                },
                            })}
                        >
                            <Button variant='outlined' onClick={closeDialog}>
                                {getTranslated('Core.cancel')}
                            </Button>

                            <LoadingButton
                                variant='contained'
                                type='submit'
                                loading={saveButtonLoading}
                            >
                                {getTranslated('common.save')}
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

const FormField: FunctionComponent<
    { name: string; label: string } & GridProps
> = ({ name, label, ...gridProps }) => (
    <Grid item {...gridProps}>
        <TextFieldForm
            name={name}
            textFieldProps={{
                label,
                variant: 'outlined',
                fullWidth: true,
            }}
        />
    </Grid>
);
