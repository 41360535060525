import { FunctionComponent, useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Theme } from '@/theme/theme';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { useTranslation } from '@/hooks/useTranslation';
import { RegistrationForm } from '@/components/PageContent/RegisterPageContent/RegistrationForm';
import { LoginType, RegistrationData } from '@/models/user';
import { RegistrationSuccess } from '@/components/PageContent/RegisterPageContent/RegistrationSuccess';
import { RegistrationError } from '@/components/PageContent/RegisterPageContent/RegistrationError';
import { animateScroll as scroll } from 'react-scroll';
import { useAuthentication } from '@/hooks/useAuthentication';
import { Typography } from '@mui/material';
import { FacebookButton } from '@/components/Layout/Button/FacebookButton';
import { GoogleButton } from '@/components/Layout/Button/GoogleButton';
import { usePostCustomersMutation } from '@/api/authentication';
import { isOfTypeRTKAPIError } from '@/models/request';

type RegistrationState = {
    state: 'open' | 'success' | 'error';
    data?: {
        [k: string]: string;
    };
};

export const RegisterPage: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => {
    const { getTranslated } = useTranslation();
    const { oAuthAuthentication } = useAuthentication();

    const [registrationState, setRegistrationState] =
        useState<RegistrationState>({ state: 'open' });
    const [postCustomers, { isLoading: formIsLoading }] =
        usePostCustomersMutation();

    const onFormSubmit = (registrationData: RegistrationData) => {
        postCustomers(registrationData)
            .unwrap()
            .then(() => {
                setRegistrationState({
                    state: 'success',
                    data: {
                        email: registrationData.email,
                    },
                });
            })
            .catch((e) => {
                if (isOfTypeRTKAPIError(e)) {
                    setRegistrationState({
                        state: 'error',
                        data: {
                            error: e.data.errors[0].error,
                        },
                    });
                }
            });

        scroll.scrollToTop();
    };

    const { state, data } = registrationState;

    return (
        <SubPageTemplate title={getTranslated('MyAccountNavigation.register')}>
            {state === 'open' && (
                <>
                    <FlexContainer fullWidth center>
                        <FacebookButton
                            onClick={() => {
                                oAuthAuthentication({
                                    providerUrl: LoginType.FACEBOOK,
                                });
                            }}
                        >
                            {getTranslated('Registration.registerWithFacebook')}
                        </FacebookButton>

                        <GoogleButton
                            onClick={() => {
                                oAuthAuthentication({
                                    providerUrl: LoginType.GOOGLE,
                                });
                            }}
                        >
                            {getTranslated('Registration.registerWithGoogle')}
                        </GoogleButton>
                    </FlexContainer>

                    <FlexContainer
                        sx={{ marginTop: (theme: Theme) => theme.spacing(7) }}
                        fullWidth
                        center
                    >
                        <Typography>{getTranslated('Core.or')}</Typography>
                    </FlexContainer>
                </>
            )}

            <FlexContainer
                column
                fullWidth
                sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                        display: 'block',
                    },
                })}
            >
                <FlexContainer
                    sx={{ margin: (theme: Theme) => theme.spacing(6, 0) }}
                    fullWidth
                    column
                >
                    {state === 'open' && (
                        <RegistrationForm
                            onFormSubmit={onFormSubmit}
                            isLoading={formIsLoading}
                        />
                    )}

                    {state === 'success' && (
                        <RegistrationSuccess email={data.email} />
                    )}

                    {state === 'error' && (
                        <RegistrationError
                            errorKey={data.error}
                            onErrorBack={() => {
                                setRegistrationState({
                                    state: 'open',
                                });
                            }}
                        />
                    )}
                </FlexContainer>
            </FlexContainer>
        </SubPageTemplate>
    );
};
