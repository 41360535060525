import { FunctionComponent, useState } from 'react';
import { RedeemCodeInput } from '@/components/RedeemCodeInput/RedeemCodeInput';
import {
    useGetCurrentCheckoutSession,
    usePostIncertVoucherMutation,
} from '@/api/checkout';
import { useNotification } from '@/hooks/useNotification';
import { useTranslation } from '@/hooks/useTranslation';
import { useRedeemVoucherErrorCode } from '@/hooks/useRedeemVoucherErrorCode';
import { isOfTypeRTKAPIError } from '@/models/request';

export const RedeemIncertVoucher: FunctionComponent = () => {
    const [incertCode, setIncertCode] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [postIncert, { isLoading }] = usePostIncertVoucherMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const { addNotification } = useNotification();
    const { getErrorMessage } = useRedeemVoucherErrorCode();
    const { getTranslated } = useTranslation();

    const handleIconButtonClick = () => {
        postIncert({ code: incertCode, checkoutId: checkoutSession.id })
            .unwrap()
            .then(() => {
                addNotification({
                    type: 'success',
                    message: getTranslated('IncertVoucherForm.success'),
                });
            })
            .catch((e) => {
                if (isOfTypeRTKAPIError(e)) {
                    if (e.status === 500) {
                        addNotification({
                            type: 'error',
                            message: getTranslated('Request.error.internal'),
                        });

                        return;
                    }

                    const error = getErrorMessage(e.data.errors[0].error);

                    setError(error);
                    addNotification({
                        type: 'error',
                        message: getErrorMessage(e.data.errors[0].error),
                    });
                }
            });
    };

    return (
        <RedeemCodeInput
            inputLabel={getTranslated(
                'IncertVoucherRedemption.incertVoucherCode'
            )}
            value={incertCode}
            onChange={(e) => {
                const { value } = e.target;
                setError('');

                setIncertCode(value);
            }}
            handleIconButtonClick={handleIconButtonClick}
            isLoading={isLoading}
            error={error}
        />
    );
};
