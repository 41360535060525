import { FunctionComponent, PropsWithChildren } from 'react';
import { IdentificationType } from '@/models/cart';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { identificationFormSchema } from '@/components/PageContent/CartPageContent/Keycard/validation';
import { AddIdentificationForm } from '@/components/AddIdentificationForm/AddIdentificationForm';
import { CheckoutTicket } from '@/models/checkout/checkoutTicket';
import { CheckoutPerson } from '@/models/checkout/checkoutPerson';
import { createNewCheckoutIdentification } from '@/models/checkout/checkoutIdentification';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { usePostIdentificationMutation } from '@/api/checkout';

type AddIdentificationFormContainerProps = {
    checkoutId: string;
    identificationType: IdentificationType;
    onSuccess?: () => void;
    onError?: () => void;
    person?: CheckoutPerson;
    ticket?: CheckoutTicket;
};

export const AddIdentificationFormContainer: FunctionComponent<
    PropsWithChildren<AddIdentificationFormContainerProps>
> = ({
    ticket,
    identificationType,
    person,
    checkoutId,
    onSuccess,
    onError,
}) => {
    const { getTranslated } = useTranslation();
    const { getCardType } = useConfigSlice();
    const [postIdentification, { isLoading }] = usePostIdentificationMutation();

    let explanationText: TranslationKey;

    if (identificationType === IdentificationType.PICKUP) {
        explanationText = 'Keycard.pickup';
    } else if (identificationType === IdentificationType.KEYCARD) {
        explanationText = 'NewKeycard.explanation';
    } else if (identificationType === IdentificationType.INTERNAL_PRODUCTION) {
        explanationText = 'NewKeycard.explanation';
    } else if (identificationType === IdentificationType.BARCODE) {
        explanationText = 'Keycard.barcode';
    } else if (identificationType === IdentificationType.E_TICKET) {
        explanationText = 'Keycard.eTicket';
    }

    const validationSchema =
        person && ticket
            ? identificationFormSchema({
                  birthday: person.birthdate,
                  photoRequired: ticket.photoRequired,
              })
            : null;
    return (
        <AddIdentificationForm
            validationSchema={validationSchema}
            identification={null}
            isInternalProduction={
                identificationType === IdentificationType.INTERNAL_PRODUCTION
            }
            explanationText={getTranslated(explanationText)}
            loading={isLoading}
            onSubmit={(keycardData) => {
                const identification = createNewCheckoutIdentification(
                    keycardData,
                    identificationType
                );

                if (person && ticket) {
                    identification.assignment = {
                        personId: person.id,
                        ticketId: ticket.id,
                    };
                }

                if (identificationType === IdentificationType.KEYCARD) {
                    const standardCardType = getCardType();

                    identification.cardType = standardCardType?.id || null;
                }

                postIdentification({
                    ...identification,
                    checkoutId: checkoutId,
                })
                    .unwrap()
                    .then(() => {
                        onSuccess?.();
                    })
                    .catch(() => {
                        onError?.();
                    });
            }}
        />
    );
};
