import { FunctionComponent, useEffect, useState } from 'react';

import { LocationAutoComplete } from '@/components/AutoComplete/LocationAutoComplete';
import { Box, Card, Fade } from '@mui/material';
import { LiftOperator } from '@/models/liftOperators';
import { isPointWithinRadius, getDistance } from 'geolib';
import { LiftOperatorList } from '@/components/LiftOperatorSidebar/LiftOperatorList';
import { LiftOperatorLocationState } from '@/components/PageContent/LiftOperatorsPageContent/LiftOperatorsPageContainer';
import { useTranslation } from '@/hooks/useTranslation';
import {
    useGetFavoritesQuery,
    useGetLiftOperatorsQuery,
} from '@/api/liftOperator';

export type LiftOperatorListType = 'fullList' | 'selected' | 'closest';

let closestLocationAddress = null;

type LiftOperatorSidebarContainerProps = {
    selectedLiftOperator: LiftOperator;
    onLiftOperatorListUpdate: (newLiftOperators: LiftOperator[]) => void;
    defaultLocation?: LiftOperatorLocationState;
};

export const LiftOperatorSidebarContainer: FunctionComponent<
    LiftOperatorSidebarContainerProps
> = ({ onLiftOperatorListUpdate, selectedLiftOperator, defaultLocation }) => {
    const { data: favoriteIds = [] } = useGetFavoritesQuery();

    const isLiftOperatorInFavorites = (liftOperatorId: string) =>
        !!favoriteIds.find((lO) => lO.liftOperatorId === liftOperatorId);

    const { data: liftOperators = [] } = useGetLiftOperatorsQuery();

    const { getTranslated } = useTranslation();
    const [showLiftOperators, setShowLiftOperators] =
        useState<LiftOperatorListType>('fullList');
    const [tempLiftOperators, setTempLiftOperators] = useState<LiftOperator[]>(
        []
    );

    // split liftOperators in favorites and rest
    const favorites = liftOperators.filter((liftOperator) => {
        return isLiftOperatorInFavorites(liftOperator.id);
    });

    const filterByClosestLocation = (placeId: string) => {
        if (window.google) {
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ placeId: placeId }, (results) => {
                const { formatted_address } = results[0];
                const { lat, lng } = results[0].geometry.location;

                closestLocationAddress = formatted_address;

                const liftOperatorsCloseBy = liftOperators.filter(
                    (liftOperator) => {
                        return isPointWithinRadius(
                            liftOperator.address.coordinates,
                            { lat: lat(), lng: lng() },
                            25000
                        );
                    }
                );

                const sortedByDistance = liftOperatorsCloseBy.sort(
                    (la, lb) =>
                        getDistance(
                            { lat: lat(), lng: lng() },
                            la.address.coordinates
                        ) -
                        getDistance(
                            { lat: lat(), lng: lng() },
                            lb.address.coordinates
                        )
                );

                setTempLiftOperators(sortedByDistance);
            });
        }
    };

    const getLiftOperators = () => {
        switch (showLiftOperators) {
            case 'fullList':
                return liftOperators;
            default:
                return tempLiftOperators;
        }
    };

    if (
        selectedLiftOperator &&
        tempLiftOperators[0]?.id !== selectedLiftOperator.id
    ) {
        setTempLiftOperators([selectedLiftOperator]);
        setShowLiftOperators('selected');
    }

    useEffect(() => {
        onLiftOperatorListUpdate(getLiftOperators());
    }, [showLiftOperators, liftOperators, tempLiftOperators]);

    useEffect(() => {
        if (defaultLocation && window.google) {
            setShowLiftOperators('closest');
            filterByClosestLocation(defaultLocation.placeId);
        } else {
            setShowLiftOperators('fullList');
        }
    }, [window.google]);

    return (
        <Card
            sx={{
                p: 1,
                zIndex: 100,
                overflowY: 'scroll',
                width: '100%',
            }}
        >
            {!!liftOperators?.length && (
                <>
                    <Box
                        sx={{
                            my: 3,
                        }}
                    >
                        <LocationAutoComplete
                            liftOperators={liftOperators}
                            defaultInput={
                                !!window.google
                                    ? defaultLocation?.description
                                    : ''
                            }
                            onFocus={() => {
                                setShowLiftOperators(null);
                            }}
                            onBlur={(inputValue) => {
                                if (!inputValue) {
                                    setShowLiftOperators('fullList');
                                }
                            }}
                            onSelect={(nextValue) => {
                                if (!nextValue) {
                                    setShowLiftOperators('fullList');
                                    setTempLiftOperators([]);
                                } else if (nextValue.type === 'placeType') {
                                    setShowLiftOperators('closest');
                                    filterByClosestLocation(nextValue.place_id);
                                } else {
                                    setTempLiftOperators([
                                        nextValue.liftOperator,
                                    ]);
                                    setShowLiftOperators('selected');
                                }
                            }}
                        />
                    </Box>

                    <Fade in={!!showLiftOperators}>
                        <div>
                            <LiftOperatorList
                                favorites={favorites}
                                listTitle={
                                    showLiftOperators === 'closest'
                                        ? getTranslated('lifOperatorsNearby', {
                                              location: closestLocationAddress,
                                          })
                                        : getTranslated(
                                              getLiftOperators().length > 1
                                                  ? 'liftOperators'
                                                  : 'LocationSearch.liftoperator'
                                          )
                                }
                                liftOperators={getLiftOperators()}
                                resultType={
                                    showLiftOperators === 'fullList'
                                        ? 'fullList'
                                        : 'search'
                                }
                            />
                        </div>
                    </Fade>
                </>
            )}
        </Card>
    );
};
