import { FunctionComponent, PropsWithChildren } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import Dialog from '@mui/material/Dialog';

import Button from '@mui/material/Button';
import { InsuranceStatus, UserTicket } from '@/models/ticket';
import { useNotification } from '@/hooks/useNotification';
import { usePostCancelDeleteMutation } from '@/api/order';

type DeleteTicketWithInsuranceDialogProps = {
    open: boolean;
    onClose: () => void;
    ticket: UserTicket;
    onDialogClosed?: () => void;
};

export const DeleteTicketWithInsuranceDialog: FunctionComponent<
    PropsWithChildren<DeleteTicketWithInsuranceDialogProps>
> = ({ open, onClose, onDialogClosed, ticket }) => {
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const [postCancelDelete, { isLoading: cancelLoading }] =
        usePostCancelDeleteMutation();

    const ticketHasInsurance =
        !!ticket.insurance &&
        ticket.insurance?.status === InsuranceStatus.BOOKED;

    const onDelete = () => {
        postCancelDelete({ id: ticket.id })
            .unwrap()
            .then(() => {
                addNotification({
                    message: getTranslated(
                        'MyTicketsPage.cancelDialog.success'
                    ),
                    type: 'success',
                });

                onClose();
            })
            .catch(() => {
                addNotification({
                    message: getTranslated('Core.ticket.cancel.error'),
                    type: 'error',
                });
            });
    };

    return (
        <Dialog
            maxWidth='md'
            open={open}
            onClose={onClose}
            slotProps={{
                transition: {
                    onExited: () => {
                        onDialogClosed?.();
                    },
                },
            }}
        >
            <DialogTitle>
                {getTranslated(
                    ticketHasInsurance
                        ? 'MyTicketsPage.cancelDialog.ticketWithInsurance'
                        : 'MyTicketsPage.cancelDialog.ticketWithoutInsurance'
                )}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {getTranslated(
                        ticketHasInsurance
                            ? 'MyTicketsPage.cancelDialog.body.ticketWithInsurance'
                            : 'MyTicketsPage.cancelDialog.body.ticketWithoutInsurance'
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary'>
                    {getTranslated('Core.cancel')}
                </Button>
                <Button
                    loading={cancelLoading}
                    onClick={onDelete}
                    color='error'
                    variant='contained'
                >
                    {getTranslated('Core.ticket.cancel.button.label')}
                </Button>
            </DialogActions>

            {ticketHasInsurance && (
                <DialogContentText
                    sx={{
                        display: 'block',
                        p: 4,
                        color: 'text.secondary',
                        fontSize: '0.85rem',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: getTranslated(
                            'MyTicketsPage.cancelDialog.caption.ticketWithInsurance'
                        ),
                    }}
                />
            )}
        </Dialog>
    );
};
