import { get, set } from 'lodash';
import { LOCAL_STORAGE_KEY } from '@/constants/index';
import { deSerializeObject, serializeObject } from '@/utils/serialize';

export const saveToLocalStorage = (path: string, payload: unknown) => {
    const storageObject = deSerializeObject(
        localStorage.getItem(LOCAL_STORAGE_KEY) || ''
    );

    set(storageObject, path, payload);

    localStorage.setItem(LOCAL_STORAGE_KEY, serializeObject(storageObject));
};

export const getFromLocalStorage = <T>(path: string, defaultValue?: T): T => {
    const storageObject = deSerializeObject(
        localStorage.getItem(LOCAL_STORAGE_KEY)
    );

    return get(storageObject, path, defaultValue) as T;
};

export const deleteFromLocalStorage = (path: string) => {
    const storageObject = deSerializeObject(
        localStorage.getItem(LOCAL_STORAGE_KEY) || ''
    );

    const deleted = delete storageObject[path];

    if (deleted) {
        localStorage.setItem(LOCAL_STORAGE_KEY, serializeObject(storageObject));
    }
};
