import { urlBuilder } from '@/utils/api';
import { PortalConfig } from '@/slices/configSlice';
import {
    CheckoutSession,
    convertCheckoutToFrontend,
} from '@/models/checkout/checkoutSession';
import { BackendType, Money } from '@/models/general';
import { useSlice } from '@/hooks/useSlice';
import { checkoutSlice } from '@/slices/checkoutSlice';
import { useEffect, useRef } from 'react';
import { buildCheckoutTicketCollections } from '@/models/collection/checkoutTicketCollection';
import { getPortalConfig } from '@/pageConfiguration';
import { IdentificationType } from '@/models/cart';

import { Address } from '@/models/user';
import {
    convertCreateCheckoutIdentificationToBackend,
    CreateCheckoutIdentification,
} from '@/models/checkout/checkoutIdentification';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useDispatch } from 'react-redux';
import { CreateBackendVoucher } from '@/models/voucher';
import { ThunkDispatch } from 'redux-thunk';
import { PostPaymentResponse } from '@/models/payment';
import { Discount } from '@/models/discount';
import { baseApiAuthenticated } from '@/api/index';
import { Moment } from 'moment';
import { mapMomentToBackendStirng } from '@/utils/time';

const updateCheckoutCache = async (
    { checkoutId }: { checkoutId: string },
    {
        dispatch,
        queryFulfilled,
    }: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dispatch: ThunkDispatch<unknown, unknown, any>;
        queryFulfilled: Promise<{ data: CheckoutSession }>;
    }
) => {
    const { data: updatedCheckoutSession } = await queryFulfilled;

    try {
        dispatch(
            checkoutApi.util.updateQueryData(
                'getCheckoutById',
                {
                    checkoutId: checkoutId,
                },
                (draft) => {
                    Object.assign(draft, updatedCheckoutSession);
                }
            )
        );
    } catch (e) {}
};

export enum CheckoutMuationCacheKeys {
    UPDATE_CHECKOUT_SESSION = 'UPDATE_CHECKOUT_SESSIONS',
    PUT_CHECKOUT_SESSION = 'PUT_CHECKOUT_SESSION',
    DELETE_FROM_CHECKOUT_SESSION = 'DELETE_FROM_CHECKOUT_SESSION',
}

const BASE_ENDPOINT = 'checkout';
const BASE_ENDPOINT_ORDER = 'order';

type PostCheckoutTicketParams = {
    checkoutId: string;
    liftOperator: string;
    productId: string;
    startDate: string; // format: 'YYYY-MM-DD'
};

type PutCheckoutTicketBody = {
    id: string;
    identificationType?: IdentificationType;
    tariffIdentifier?: string | null;
    customization?: {
        discardContingentConditions: boolean;
        discardPriceConditions: boolean;
        discounts: Discount[];
        price: Money;
        priceCode: string;
        priceReason?: string;
    };
};

type PostPaymentRequestBody = {
    email: string | null;
    errorUrl: string;
    paymentMethod: string;
    profile: string;
    saveProfile: boolean;
    successUrl: string;
    totalAfterCredits: Money;
    internalLetterType?: string;
};

export const checkoutApi = baseApiAuthenticated
    .enhanceEndpoints<string>({
        addTagTypes: ['CHECKOUTS'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getCheckouts: builder.query<CheckoutSession, void>({
                query: () => ({
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts?environment=${getEnvironment(
                            getPortalConfig()
                        )}`
                    ),
                }),

                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                    const { data: createdCheckout } = await queryFulfilled;

                    try {
                        dispatch(
                            checkoutApi.util.upsertQueryData(
                                'getCheckoutById',
                                {
                                    checkoutId: createdCheckout.id,
                                },
                                createdCheckout
                            )
                        );
                    } catch (e) {}
                },
            }),

            getCheckoutById: builder.query<
                CheckoutSession,
                { checkoutId: string }
            >({
                query: ({ checkoutId }) =>
                    urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}?environment=${getEnvironment(
                            getPortalConfig()
                        )}`
                    ),
                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                providesTags: (_1, _2, { checkoutId }) => [
                    { type: 'CHECKOUTS', id: checkoutId },
                ],
            }),

            postCheckoutTicket: builder.mutation<
                CheckoutSession,
                PostCheckoutTicketParams
            >({
                query: ({ checkoutId, ...reqBody }) => ({
                    method: 'POST',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/tickets`
                    ),
                    body: {
                        ...reqBody,
                    },
                }),
                transformResponse: (
                    response: CheckoutSession<BackendType>
                ) => ({
                    ...convertCheckoutToFrontend(response),
                }),

                onQueryStarted: async (
                    { checkoutId },
                    { dispatch, queryFulfilled }
                ) => {
                    const { data: updatedCheckoutSession } =
                        await queryFulfilled;

                    try {
                        dispatch(
                            checkoutApi.util.updateQueryData(
                                'getCheckoutById',
                                {
                                    checkoutId: checkoutId,
                                },
                                (draft) => {
                                    Object.assign(
                                        draft,
                                        updatedCheckoutSession
                                    );
                                }
                            )
                        );
                    } catch (e) {}
                },
            }),

            deleteCheckoutTicket: builder.mutation<
                CheckoutSession,
                { ticketId: string; checkoutId: string }
            >({
                query: ({ ticketId, checkoutId }) => ({
                    method: 'DELETE',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/tickets/${ticketId}`
                    ),
                }),
                transformResponse: (
                    response: CheckoutSession<BackendType>
                ) => ({
                    ...convertCheckoutToFrontend(response),
                }),

                onQueryStarted: async (
                    { checkoutId },
                    { dispatch, queryFulfilled }
                ) => {
                    const { data: updatedCheckoutSession } =
                        await queryFulfilled;

                    try {
                        dispatch(
                            checkoutApi.util.updateQueryData(
                                'getCheckoutById',
                                {
                                    checkoutId: checkoutId,
                                },
                                (draft) => {
                                    Object.assign(
                                        draft,
                                        updatedCheckoutSession
                                    );
                                }
                            )
                        );
                    } catch (e) {}
                },
            }),

            deleteCheckoutTickets: builder.mutation<
                CheckoutSession,
                { ticketIds: string[]; checkoutId: string }
            >({
                query: ({ ticketIds, checkoutId }) => ({
                    method: 'DELETE',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/tickets`
                    ),
                    body: {
                        ids: ticketIds,
                    },
                }),
                transformResponse: (
                    response: CheckoutSession<BackendType>
                ) => ({
                    ...convertCheckoutToFrontend(response),
                }),

                onQueryStarted: async (
                    { checkoutId },
                    { dispatch, queryFulfilled }
                ) => {
                    const { data: updatedCheckoutSession } =
                        await queryFulfilled;

                    try {
                        dispatch(
                            checkoutApi.util.updateQueryData(
                                'getCheckoutById',
                                {
                                    checkoutId: checkoutId,
                                },
                                (draft) => {
                                    Object.assign(
                                        draft,
                                        updatedCheckoutSession
                                    );
                                }
                            )
                        );
                    } catch (e) {}
                },
            }),

            postAssignPersonToTicket: builder.mutation<
                CheckoutSession,
                {
                    possibleAssignmentId: string;
                    checkoutId: string;
                    ticketId: string;
                    personId: string;
                    cardType: string | null;
                }
            >({
                query: ({
                    possibleAssignmentId,
                    checkoutId,
                    ticketId,
                    personId,
                    cardType,
                }) => ({
                    method: 'POST',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `/checkouts/${checkoutId}/tickets/${ticketId}/persons/${personId}`
                    ),
                    body: {
                        possibleAssignmentId,
                        ticketId,
                        personId,
                        cardType,
                    },
                }),
                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                invalidatesTags: (_1, _2, { checkoutId }) => [
                    { type: 'CHECKOUTS', id: checkoutId },
                ],
            }),

            putCheckoutTicket: builder.mutation<
                CheckoutSession,
                PutCheckoutTicketBody & { checkoutId: string }
            >({
                query: ({ checkoutId, ...putTicketBody }) => ({
                    method: 'PUT',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/tickets/${putTicketBody.id}`
                    ),
                    body: { ...putTicketBody },
                }),

                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),
                onQueryStarted: updateCheckoutCache,
            }),

            postOrder: builder.mutation<
                PostPaymentResponse,
                PostPaymentRequestBody & { checkoutId: string }
            >({
                query: ({ checkoutId, ...requestBody }) => ({
                    method: 'POST',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/payments`
                    ),
                    body: requestBody,
                }),
            }),

            postAddress: builder.mutation<
                CheckoutSession,
                {
                    checkoutId: string;
                    type: 'shipping' | 'invoice';
                    address: Address;
                }
            >({
                query: ({ checkoutId, type, address }) => ({
                    method: 'POST',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/${type}-address`
                    ),
                    body: address || undefined,
                }),

                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                invalidatesTags: (_1, _2, { checkoutId }) => [
                    { type: 'CHECKOUTS', id: checkoutId },
                ],
            }),

            deleteInvoiceAddress: builder.mutation<
                CheckoutSession,
                { checkoutId: string }
            >({
                query: ({ checkoutId }) => ({
                    method: 'DELETE',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/invoice-address`
                    ),
                }),

                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                invalidatesTags: (_1, _2, { checkoutId }) => [
                    { type: 'CHECKOUTS', id: checkoutId },
                ],
            }),

            postIdentification: builder.mutation<
                CheckoutSession,
                CreateCheckoutIdentification & { checkoutId: string }
            >({
                query: ({ checkoutId, ...createIdentification }) => {
                    if (
                        !createIdentification.assignment.ticketId ||
                        !createIdentification.assignment.personId
                    ) {
                        delete createIdentification.assignment;
                    }

                    return {
                        method: 'POST',
                        url: urlBuilder(
                            BASE_ENDPOINT,
                            `checkouts/${checkoutId}/identifications`
                        ),
                        body: convertCreateCheckoutIdentificationToBackend(
                            createIdentification
                        ),
                    };
                },

                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                invalidatesTags: (_1, _2, { checkoutId }) => [
                    { type: 'CHECKOUTS', id: checkoutId },
                ],
            }),

            deleteIdentification: builder.mutation<
                CheckoutSession,
                { checkoutId: string; identificationId: string }
            >({
                query: ({ checkoutId, identificationId }) => {
                    return {
                        method: 'DELETE',
                        url: urlBuilder(
                            BASE_ENDPOINT,
                            `checkouts/${checkoutId}/identifications/${identificationId}`
                        ),
                    };
                },

                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                invalidatesTags: (_1, _2, { checkoutId }) => [
                    { type: 'CHECKOUTS', id: checkoutId },
                ],
            }),

            postVoucher: builder.mutation<
                CheckoutSession,
                CreateBackendVoucher & { checkoutId: string }
            >({
                query: ({ checkoutId, ...backendVoucher }) => {
                    return {
                        method: 'POST',
                        url: urlBuilder(
                            BASE_ENDPOINT,
                            `checkouts/${checkoutId}/vouchers`
                        ),
                        body: backendVoucher,
                    };
                },

                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                onQueryStarted: updateCheckoutCache,
            }),

            deleteVoucher: builder.mutation<
                CheckoutSession,
                { checkoutId: string; voucherId: string }
            >({
                query: ({ checkoutId, voucherId }) => {
                    return {
                        method: 'DELETE',
                        url: urlBuilder(
                            BASE_ENDPOINT,
                            `checkouts/${checkoutId}/vouchers/${voucherId}`
                        ),
                    };
                },

                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                invalidatesTags: (_1, _2, { checkoutId }) => [
                    { type: 'CHECKOUTS', id: checkoutId },
                ],
            }),

            postIncertVoucher: builder.mutation<
                CheckoutSession,
                { checkoutId: string; code: string }
            >({
                query: ({ checkoutId, code }) => ({
                    method: 'POST',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/incert-vouchers`
                    ),
                    body: {
                        code,
                    },
                }),
                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),
                invalidatesTags: (_1, _2, { checkoutId }) => [
                    { type: 'CHECKOUTS', id: checkoutId },
                ],
            }),

            postRedeemCoupon: builder.mutation<
                void,
                {
                    type: 'vouchers' | 'coupons';
                    code: string;
                    checkoutId: string;
                }
            >({
                query: ({ code, type }) => ({
                    method: 'POST',
                    url: urlBuilder(BASE_ENDPOINT_ORDER, `${type}/redeem`),
                    body: {
                        code,
                    },
                }),
            }),

            postConsultationProtocol: builder.mutation<
                Blob,
                { checkoutId: string; consents: string[] }
            >({
                query: ({ checkoutId, consents }) => ({
                    headers: {
                        'Content-Type': 'application/pdf',
                    },
                    method: 'POST',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/insurances/mandatory-documents`
                    ),
                    body: {
                        consents: consents.map((id) => ({
                            given: true,
                            id,
                        })),
                    },
                    responseHandler: (response) => response.blob(),
                }),
            }),

            postCheckoutCustomer: builder.mutation<
                CheckoutSession,
                { checkoutId: string; phone: string; birthdate: Moment }
            >({
                query: ({ phone, birthdate, checkoutId }) => ({
                    method: 'POST',
                    url: urlBuilder(
                        BASE_ENDPOINT,
                        `checkouts/${checkoutId}/customer`
                    ),
                    body: {
                        phone,
                        birthdate: mapMomentToBackendStirng(birthdate),
                    },
                }),
                transformResponse: (response: CheckoutSession<BackendType>) =>
                    convertCheckoutToFrontend(response),

                onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                    const { data: createdCheckout } = await queryFulfilled;

                    try {
                        dispatch(
                            checkoutApi.util.upsertQueryData(
                                'getCheckoutById',
                                {
                                    checkoutId: createdCheckout.id,
                                },
                                createdCheckout
                            )
                        );
                    } catch (e) {}
                },
            }),
        }),
    });

const getEnvironment = (configuration: PortalConfig) =>
    configuration === PortalConfig.INTEGRATION_CONFIGURATION
        ? 'INTEGRATION'
        : 'PORTAL';

export const {
    useLazyGetCheckoutsQuery,
    useDeleteCheckoutTicketMutation,
    useDeleteCheckoutTicketsMutation,
    usePostCheckoutTicketMutation,
    usePostAssignPersonToTicketMutation,
    usePutCheckoutTicketMutation,
    usePostOrderMutation,
    usePostAddressMutation,
    useDeleteInvoiceAddressMutation,
    usePostIdentificationMutation,
    useDeleteIdentificationMutation,
    usePostVoucherMutation,
    useDeleteVoucherMutation,
    usePostRedeemCouponMutation,
    usePostIncertVoucherMutation,
    usePostConsultationProtocolMutation,
    usePostCheckoutCustomerMutation,
} = checkoutApi;

export const useGetCurrentCheckoutSession = () => {
    // re-execute on authentication status
    const { isLoggedIn } = useAuthentication();
    const dispatch = useDispatch();
    const {
        state: { checkoutId },
        actions: { setCheckoutId },
    } = useSlice(checkoutSlice, 'checkout');
    const loginStatusOnInitalExecute = useRef(isLoggedIn);

    const getCheckoutRequest = checkoutApi.useGetCheckoutByIdQuery(
        { checkoutId },
        {
            skip: !checkoutId,
        }
    );

    useEffect(() => {
        const { error } = getCheckoutRequest;

        // checkout id in some way faulty
        if (error) {
            setCheckoutId(null);
        }
    }, [getCheckoutRequest.error]);

    // this has to be refactored
    useEffect(() => {
        if (isLoggedIn !== loginStatusOnInitalExecute.current) {
            dispatch(checkoutApi.util.invalidateTags(['CHECKOUTS']));
            loginStatusOnInitalExecute.current = isLoggedIn;
        }
    }, [isLoggedIn]);

    return getCheckoutRequest;
};

export const useGetCheckoutTicketCollections = () => {
    const { data } = useGetCurrentCheckoutSession();

    return buildCheckoutTicketCollections(data?.tickets || []);
};

export const useInitializeCheckoutSession = (skip: boolean) => {
    const {
        actions: { setCheckoutId },
        state: { checkoutId },
    } = useSlice(checkoutSlice, 'checkout');
    const { useLazyGetCheckoutsQuery } = checkoutApi;
    const [getCheckouts] = useLazyGetCheckoutsQuery();
    const responseCheckoutById = checkoutApi.useGetCheckoutByIdQuery(
        {
            checkoutId,
        },
        {
            skip: !checkoutId || skip,
        }
    );

    if (responseCheckoutById.isError) {
        setCheckoutId(null);
    }

    useEffect(() => {
        if (!checkoutId && !skip) {
            getCheckouts()
                .unwrap()
                .then((checkout) => {
                    setCheckoutId(checkout.id);
                });
        }
    }, [checkoutId, skip]);
};
