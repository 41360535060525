import { Address } from '@/models/user';

import { Gender } from '@/models/general';
import { translateFunction } from '@/hooks/useTranslation';
import { getCurrentLocale } from '@/utils/locale';

export const getAddressStreet = (address: Address) => {
    if (!address.street || !address.zipcode || !address.city) return '';

    return `${address.street}, ${address.zipcode} ${address.city}`;
};

export const getGenderTranslation = (gender: Gender): string =>
    translateFunction(gender, getCurrentLocale());
