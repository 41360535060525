import { FunctionComponent, ReactNode } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@/components/Layout/Button/Button';
import { useTranslation } from '@/hooks/useTranslation';
import { Typography } from '@mui/material';

type SingleTicketPersonToSelectRowProps = {
    onAddClick: () => void;
    label: ReactNode;
};

export const SingleTicketPersonToSelectRow: FunctionComponent<
    SingleTicketPersonToSelectRowProps
> = ({ onAddClick, label }) => {
    const { getTranslated } = useTranslation();
    return (
        <FlexContainer
            sx={() => ({
                alignItems: 'center',
                justifyContent: 'flex-start',
                px: 3,
                py: 4,
            })}
        >
            <AddIcon
                sx={{
                    width: '24px',
                    height: '24px',
                    mr: 2,
                }}
            />

            {label}

            <Button
                sx={{
                    ml: 'auto',
                }}
                onClick={onAddClick}
                variant='outlined'
            >
                {getTranslated('Core.chose')}
            </Button>
        </FlexContainer>
    );
};

export const PersonToSelectLabel: FunctionComponent<{ label: string }> = ({
    label,
}) => <Typography>{label}</Typography>;
