import { store } from './store';

import { Router } from '@/components/Router/Router';
import { AuthContainer } from '@/components/Auth/AuthContainer';
import { Initialize } from './Initialize';
import { Notification } from '@/components/Notification/Notification';
import { CookiesConfirmationDialog } from '@/components/Cookies/CookiesConfirmationDialog';
import { InfoDialog } from '@/components/InfoDialog/InfoDialog';
import { Provider } from 'react-redux';

export const Application = () => {
    return (
        <Provider store={store}>
            <AuthContainer>
                <Initialize>
                    <Router />
                    <Notification />
                    <InfoDialog />
                    <CookiesConfirmationDialog />
                </Initialize>
            </AuthContainer>
        </Provider>
    );
};
