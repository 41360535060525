const getImageTypeFromBase64 = (base64: string) =>
    base64.split(';')[0].split(':')[1];

export const compressImage = (data: string): Promise<string> => {
    const canvas = document.createElement('canvas');
    const img = document.createElement('img');

    const imageType = getImageTypeFromBase64(data);

    img.src = data;

    return new Promise((resolve, reject) => {
        img.onload = function () {
            let width = img.width;
            let height = img.height;
            const maxHeight = 400;
            const maxWidth = 400;

            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height *= maxWidth / width));
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width *= maxHeight / height));
                    height = maxHeight;
                }
            }
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            resolve(canvas.toDataURL(imageType, 0.55));
        };

        img.onerror = function (err) {
            reject(err);
        };
    });
};
