import { FunctionComponent } from 'react';
import { Dialog, DialogProps } from '@mui/material';

export type BaseDialogProps = { onClose: () => void } & Exclude<
    DialogProps,
    'onClose'
>;

export const BaseDialog: FunctionComponent<
    React.PropsWithChildren<BaseDialogProps>
> = ({ onClose, ...props }) => {
    return (
        <Dialog
            onClose={() => {
                onClose();
            }}
            {...props}
        />
    );
};
