import { useMemo } from 'react';
import { SubPageTemplate } from '@/pages/SubPageTemplate';

import { TransformTable } from '@/components/Table/TransformTable';
import { tableConfig } from '@/components/PageContent/MyOrdersPageContent/tableConfig';
import { NoEntriesProgress } from '@/components/Table/NoEntriesComponent';
import { Order } from '@/models/order';
import { DATE_FORMAT } from '@/constants';
import { getMoneyAsString } from '@/utils/general';
import { Link } from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import { useGetOrdersQuery, useLazyGetInvoiceQuery } from '@/api/order';

export const MyOrdersPage = () => {
    const { data: orders = [], isLoading } = useGetOrdersQuery();
    const [getInvoice] = useLazyGetInvoiceQuery();
    const sortOrders = useMemo(() => {
        return [...orders].sort((a, b) => {
            return b.creationDate.unix() - a.creationDate.unix();
        });
    }, [orders]);
    const { getTranslated } = useTranslation();

    const itemString = (amount: number, label: string, addDash = false) =>
        !!amount ? `${addDash ? ' - ' : ''}${amount}x ${label}` : '';

    return (
        <SubPageTemplate title={getTranslated('MyAccountNavigation.myOrders')}>
            <TransformTable<Order>
                {...tableConfig(getTranslated)}
                count={sortOrders.length}
                items={sortOrders.length ? sortOrders : []}
                sortable={false}
                LoadingComponent={NoEntriesProgress}
                rowConfig={{
                    idProp: 'id',
                    rowProps: [
                        (order: Order) => {
                            return order.creationDate.format(`${DATE_FORMAT}`);
                        },
                        (order: Order) => {
                            const merch = order.shopProducts.length;
                            const tickets = order.tickets.length;
                            const keycards = order.keycards.length;
                            const vouchers = order.vouchers.length;

                            return `${itemString(
                                merch,
                                getTranslated('ordersMerchandise')
                            )}${itemString(
                                tickets,
                                getTranslated('ordersTickets'),
                                !!merch
                            )}${itemString(
                                keycards,
                                getTranslated('ordersKeycards'),
                                !!tickets
                            )}${itemString(
                                vouchers,
                                getTranslated('ordersVouchers'),
                                !!keycards
                            )}`;
                        },

                        'displayId',
                        (order: Order) => {
                            return order.free
                                ? 'FREE'
                                : getMoneyAsString(order.total);
                        },
                        (order: Order) => {
                            return (
                                <Link
                                    onClick={() => {
                                        getInvoice(order.id)
                                            .unwrap()
                                            .then((url) => {
                                                const a =
                                                    document.createElement('a');
                                                a.href = url;
                                                a.download = `Receipt_${order.id}.pdf`;
                                                document.body.appendChild(a);
                                                a.click();
                                                a.remove();
                                            });
                                    }}
                                >
                                    {getTranslated(
                                        'MyOrdersPage.receiptSAsPdf'
                                    )}
                                </Link>
                            );
                        },
                    ],
                }}
                isAsyncPaginated={false}
                isLoading={isLoading}
            />
        </SubPageTemplate>
    );
};
