import { FunctionComponent } from 'react';
import { Faq } from '@/models/faq';
import { DialogWithTitle } from '@/components/Dialog/DialogWithTitle';
import { FaqsList } from '@/components/Faqs/FaqsList';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';

type FaqsDialogProps = {
    open: boolean;
    onClose: () => void;
    faqs: Faq[];
};

export const FaqsDialog: FunctionComponent<FaqsDialogProps> = ({
    faqs,
    open,
    onClose,
}) => {
    const { getTranslated } = useTranslation();
    return (
        <DialogWithTitle
            title={getTranslated('FAQPage.faq')}
            maxWidth='md'
            open={open}
            onClose={onClose}
            fullWidth
        >
            <DialogContent>
                <FaqsList faqs={faqs} accordionColor='grey' />
            </DialogContent>
            <DialogActions>
                <Button variant={'outlined'} onClick={onClose}>
                    Schließen
                </Button>
            </DialogActions>
        </DialogWithTitle>
    );
};
