import { FunctionComponent } from 'react';
import { TicketVariant } from '@/models/ticket';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from '@/hooks/useTranslation';
import { SelectionButton } from '@/components/PageContent/ProductsPageContent/ProductFilter/SelectionButton';
import { FlexContainer } from '@/components/Layout/Container';

type ProductVariantSelectionContainerProps = {
    value: TicketVariant;
    onVariantSelect: (variant: TicketVariant) => void;
};

export const ProductVariantSelectionContainer: FunctionComponent<
    React.PropsWithChildren<ProductVariantSelectionContainerProps>
> = ({ value, onVariantSelect }) => {
    const { getTranslated } = useTranslation();
    const onTicketVariantClick = (variant: TicketVariant) => () => {
        onVariantSelect(variant);
    };

    return (
        <FlexContainer
            sx={(theme) => ({
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    flexDirection: 'column',
                },
            })}
            fullWidth
        >
            <SelectionButton
                isSelected={value === TicketVariant.SINGLE}
                onClick={onTicketVariantClick(TicketVariant.SINGLE)}
                adornment={<PersonIcon />}
            >
                {getTranslated('Core.singleTicket')}
            </SelectionButton>

            <SelectionButton
                isSelected={value === TicketVariant.PACKAGE}
                onClick={onTicketVariantClick(TicketVariant.PACKAGE)}
                adornment={<GroupIcon />}
            >
                {getTranslated('Core.packageTicket')}
            </SelectionButton>
        </FlexContainer>
    );
};
