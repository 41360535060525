import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { OeamtcBannerContainer } from '@/components/OeamtcBanner/OeamtcBanner';
import { useTheme } from '@mui/material/styles';

type PageTemplateProps = {
    noPadding?: boolean;
    children: React.ReactNode;
};

export const PageTemplate: FunctionComponent<
    React.PropsWithChildren<PageTemplateProps>
> = ({ children, noPadding }) => {
    const theme = useTheme();
    return (
        <FlexContainer
            sx={(theme) => ({
                minHeight: `calc(100vh - ${theme.appBar.height * 2}px)`,
                marginTop: `${theme.appBar.height}px`,
            })}
            paddingY={
                !noPadding
                    ? theme.spacing(theme.page.template.base.paddingY)
                    : 0
            }
            paddingX={!noPadding ? theme.spacing() : 0}
            fullWidth
            column
        >
            <OeamtcBannerContainer />
            {children}
        </FlexContainer>
    );
};
