import { FunctionComponent } from 'react';
import Button from '@mui/material/Button';
import { FlexContainer } from '@/components/Layout/Container';

type SelectionButtonProps = {
    isSelected: boolean;
    onClick: () => void;
    disabled?: boolean;
    adornment: React.ReactNode;
    children?: React.ReactNode;
};

export const SelectionButton: FunctionComponent<SelectionButtonProps> = ({
    isSelected,
    onClick,
    disabled,
    adornment,
    children,
}) => {
    return (
        <Button
            sx={(theme) => ({
                mr: 4,
                p: 2,
                fontWeight: 600,
                height: '60px',
                minWidth: '180px',

                [theme.breakpoints.down('lg')]: {
                    width: '100%',
                    my: 1,
                },
            })}
            variant={isSelected ? 'contained' : 'outlined'}
            disabled={disabled}
            onClick={onClick}
        >
            <FlexContainer
                sx={{
                    width: '72px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // color: theme.palette.primary.main,
                    // bgcolor: theme.palette.primary.main,

                    transition:
                        'background 200ms ease-in-out, color 200ms ease-in-out',
                    // ...(isSelected && {
                    //     bgcolor: theme.palette.primary.main,
                    //     color: '#fff',
                    // }),
                }}
            >
                {adornment}
            </FlexContainer>
            <FlexContainer
                sx={{
                    minWidth: '120px',
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    mx: 2,
                }}
            >
                {children}
            </FlexContainer>
        </Button>
    );
};
