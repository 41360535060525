import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { getPropByCurrentLocale } from '@/utils/locale';
import { DateRange, MultiLangString } from '@/models/general';
import moment from 'moment';
import { DATE_FORMAT } from '@/constants';

type CollectionCardPersonCountLineProps = {
    count: number;
    personName: MultiLangString;
    birthday: DateRange;
};

export const CollectionCardPersonCountLine: FunctionComponent<
    CollectionCardPersonCountLineProps
> = ({ count, personName, birthday }) => {
    return (
        <FlexContainer>
            <Typography>{count} x</Typography>{' '}
            <FlexContainer column>
                <Typography
                    sx={{
                        ml: 1,
                        fontWeight: 600,
                    }}
                    gutterBottom
                >
                    {getPropByCurrentLocale(personName)}
                </Typography>
                <Typography>{getBirthdayAsString(birthday)}</Typography>
            </FlexContainer>
        </FlexContainer>
    );
};

const getBirthdayAsString = (dateRange: DateRange) => {
    if (!!dateRange?.from && !!dateRange?.to) {
        return `(${moment(dateRange?.from).format(DATE_FORMAT)} - ${moment(
            dateRange.to
        ).format(DATE_FORMAT)})`;
    } else if (!!dateRange?.from && !dateRange?.to) {
        return `(${moment(dateRange.from).format(DATE_FORMAT)})`;
    } else return '';
};
