import { FunctionComponent } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import { useTranslation } from '@/hooks/useTranslation';
import {
    BaseDialog,
    BaseDialogProps,
} from '@/components/Dialogs/v2/BaseDialog';

export type ConfirmationDialogProps = {
    onConfirmation: () => void;
    onCancel: () => void;

    confirmationTextBody?: string;
    confirmationTextTitle?: string;
    confirmationButtonLabel: string;
    confirmationButtonLoading?: boolean;
} & BaseDialogProps;

export const ConfirmationDialog: FunctionComponent<
    React.PropsWithChildren<ConfirmationDialogProps>
> = ({
    onConfirmation,
    onCancel,
    confirmationButtonLoading,
    confirmationTextBody,
    confirmationTextTitle,
    confirmationButtonLabel,
    ...baseDialogProps
}) => {
    const { getTranslated } = useTranslation();

    return (
        <BaseDialog {...baseDialogProps}>
            <DialogTitle>{confirmationTextTitle}</DialogTitle>

            <DialogContent>
                <DialogContentText>{confirmationTextBody}</DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button isLoading={false} onClick={onCancel} color='primary'>
                    {getTranslated('Core.cancel')}
                </Button>

                <Button
                    isLoading={!!confirmationButtonLoading}
                    onClick={onConfirmation}
                    formType='error'
                >
                    {confirmationButtonLabel}
                </Button>
            </DialogActions>
        </BaseDialog>
    );
};
