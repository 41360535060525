import { useState } from 'react';
import { PageContentContainer } from '@/components/Layout/PageContentContainer';
import { FlexContainer } from '@/components/Layout/Container';
import { BottomNavigationContainerMobile } from '@/components/PageContent/CartPageContent/mobile/BottomNavigation/BottomNavigationContainerMobile';
import { CartViewMobile } from '@/components/PageContent/CartPageContent/mobile/CheckoutView/CartViewMobile';
import { PaymentViewContainerMobile } from '@/components/PageContent/CartPageContent/Payment/PaymentViewContainerMobile';
import { OeamtcBannerContainer } from '@/components/OeamtcBanner/OeamtcBanner';

export type CheckoutView = 'list' | 'payment';

export const CartPageContainerMobile = () => {
    const [currentView, setCurrentView] = useState<CheckoutView>('list');

    return (
        <PageContentContainer>
            <FlexContainer
                sx={(theme) => ({
                    pb: `${theme.appBar.height}px`,
                })}
                column
                fullWidth
            >
                <OeamtcBannerContainer />
                {currentView === 'list' ? (
                    <CartViewMobile />
                ) : (
                    <PaymentViewContainerMobile />
                )}
            </FlexContainer>

            <BottomNavigationContainerMobile
                currentView={currentView}
                nextView={setCurrentView}
            />
        </PageContentContainer>
    );
};
