import { FunctionComponent } from 'react';
import {
    Checkbox as MuiCheckbox,
    CheckboxProps as MuiCheckboxProps,
    FormControlLabel,
} from '@mui/material';

export type CheckboxProps = {
    label: React.ReactNode;
} & MuiCheckboxProps;

export const Checkbox: FunctionComponent<
    React.PropsWithChildren<CheckboxProps>
> = ({ label, ...props }) => {
    return (
        <FormControlLabel control={<MuiCheckbox {...props} />} label={label} />
    );
};
