import { CheckoutSession } from '@/models/checkout/checkoutSession';
import { mapCheckoutTicketToGAItem } from '@/models/checkout/checkoutTicket';
import { mapIdentificationToGAItem } from '@/models/checkout/checkoutIdentification';
import { mapBackendVoucherToGAItem } from '@/models/voucher';
import { DataLayerArgs } from 'react-gtm-module';

/**
 * just an abstract version of the actual response. Just type what you actually need from it
 */
export type PostPaymentResponse = {
    id: string;

    order: {
        displayId: string;
        location: string | null;
        orderId: string;
        status: 'NO_PAYMENT_REQUIRED' | 'OK' | 'REDIRECT';
    };

    status: 'ORDERED' | 'NEW';
};

export const createDataLayerEventObjectFromSuccess = (
    paymentResponse: PostPaymentResponse | undefined | null,
    checkoutSession: CheckoutSession | undefined | null
): DataLayerArgs => {
    if (
        !paymentResponse ||
        !checkoutSession ||
        !paymentResponse?.order?.orderId
    )
        return;

    const {
        totals: { totalAfterCredits, shippingCosts },
        tickets,
        identifications,
        vouchers,
    } = checkoutSession;
    const {
        order: { orderId },
    } = paymentResponse;

    return {
        dataLayer: {
            event: 'purchase',
            ecommerce: {
                transaction_id: orderId,
                value: totalAfterCredits.amount,
                tax: 0,
                shipping: shippingCosts.amount,
                currency: 'EUR',
                items: [
                    ...tickets.map(mapCheckoutTicketToGAItem),
                    ...identifications.map(mapIdentificationToGAItem),
                    ...vouchers.map(mapBackendVoucherToGAItem),
                ],
            },
        },
    };
};
