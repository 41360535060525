import { CheckoutViewMobile } from '@/components/PageContent/CartPageContent/mobile/CheckoutView/CheckoutViewMobile';
import { CheckoutListContainer } from '@/components/PageContent/CartPageContent/CheckoutItemList/CheckoutListContainer';

export const CartViewMobile = () => {
    return (
        <CheckoutViewMobile title='Dein Warenkorb'>
            <CheckoutListContainer />
        </CheckoutViewMobile>
    );
};
