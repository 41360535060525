import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import oeamtcLogo from '@/assets/images/oeamtc-logo.png';
import { useConfigSlice } from '@/hooks/useConfigSlice';

export const oeamtcBannerHeight = 44;

export const OeamtcBanner: FunctionComponent = () => {
    return (
        <FlexContainer
            sx={{
                my: 0.5,
                bgcolor: '#fff',
                display: 'flex',
                alignItems: 'center',
                p: 2,
                height: `${oeamtcBannerHeight}px`,
                borderRadius: '4px',
                cursor: 'pointer',
                transition: 'background-color 0.2s',
                width: '100%',
            }}
        >
            <a
                href='https://www.oeamtc.at/skipass'
                target='_blank'
                rel='noreferrer'
            >
                <img
                    style={{
                        height: '1.2rem',
                        width: 'auto',
                    }}
                    src={oeamtcLogo}
                />
            </a>{' '}
            <Typography
                sx={{
                    ml: 2,
                }}
            >
                Skipassaktion powered - by starjack!
            </Typography>
        </FlexContainer>
    );
};

export const OeamtcBannerContainer: FunctionComponent = () => {
    const { IS_OEAMTC } = useConfigSlice();

    if (!IS_OEAMTC) return null;

    return (
        <Box
            sx={{
                width: '378px',
                mr: 'auto',
            }}
        >
            <OeamtcBanner />
        </Box>
    );
};
