import { LiftOperator } from '@/models/liftOperators';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { getPropByCurrentLocale } from '@/utils/locale';
import { useSlice } from '@/hooks/useSlice';
import { configSlice } from '@/slices/configSlice';
import { MultiLangString } from '@/models/general';

type UseGetButtonLabelParams = {
    liftOperator: LiftOperator;
};

type UseGetButtonLabelReturnType = {
    dayTicketButtonText: string;
    seasonTicketButtonText: string;
};

type ButtonTextSource = {
    dayTicketsButtonText: MultiLangString;
    seasonTicketsButtonText: MultiLangString;
};

export const useGetButtonLabel = ({
    liftOperator,
}: UseGetButtonLabelParams): UseGetButtonLabelReturnType => {
    const { getTranslated } = useTranslation();
    const {
        state: { pageConfig },
    } = useSlice(configSlice, 'config');

    const buttonTextSource: ButtonTextSource =
        pageConfig?.configuration || liftOperator;

    return {
        dayTicketButtonText: getPropByCurrentLocale(
            buttonTextSource?.dayTicketsButtonText,
            getTranslated(`Core.dayTickets` as TranslationKey)
        ),
        seasonTicketButtonText: getPropByCurrentLocale(
            buttonTextSource?.seasonTicketsButtonText,
            getTranslated(`Core.seasonTicket` as TranslationKey)
        ),
    };
};
