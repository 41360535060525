import { FunctionComponent } from 'react';
import { DialogTitle as MuiDialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { FlexContainer } from '@/components/Layout/Container';

const DialogTitleContainer = styled(FlexContainer)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    position: 'relative',
    width: '100%',
}));

const CloseButton = styled(IconButton)(() => ({
    color: '#fff',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 24,
}));

type DialogTitleProps = {
    title: string;
    onClose?: () => void;
};

export const DialogTitle: FunctionComponent<DialogTitleProps> = ({
    title,
    onClose,
}) => {
    return (
        <DialogTitleContainer>
            <MuiDialogTitle>{title}</MuiDialogTitle>

            {onClose && (
                <CloseButton onClick={onClose}>
                    <CloseIcon />
                </CloseButton>
            )}
        </DialogTitleContainer>
    );
};
