import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { TicketVariant } from '@/models/ticket';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import { PopoverContent } from '@/components/ProductSelectionFilter/PopoverContent/PopoverContent';
import { useTranslation } from '@/hooks/useTranslation';

type TicketTypeSelectionContentProps = {
    onSelect: (type: TicketVariant) => void;
};

export const VariantSelectionContent: FunctionComponent<
    TicketTypeSelectionContentProps
> = ({ onSelect }) => {
    const { getTranslated } = useTranslation();
    return (
        <PopoverContent
            sx={{
                width: '400px',
            }}
        >
            <SelectionContainer
                onClick={() => onSelect(TicketVariant.SINGLE)}
                Icon={PersonIcon}
                label={getTranslated('Core.singleTicket')}
            />

            <SelectionContainer
                onClick={() => onSelect(TicketVariant.PACKAGE)}
                Icon={GroupIcon}
                label={getTranslated('Core.packageTicket')}
            />
        </PopoverContent>
    );
};

type SelectionContainerProps = {
    onClick: () => void;
    Icon: OverridableComponent<SvgIconTypeMap>;
    label: string;
};

const SelectionContainer: FunctionComponent<SelectionContainerProps> = ({
    label,
    Icon,
    onClick,
}) => (
    <Box
        onClick={onClick}
        sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            height: '80px',
            padding: 4,
            transition: 'background-color 100ms linear',
            borderRadius: '4px',

            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        }}
    >
        <Icon
            sx={{
                fontSize: '2rem',
                mr: 3,
            }}
        />

        <Typography variant='h6'>{label}</Typography>
    </Box>
);
