import { FunctionComponent } from 'react';

import { useField, useFormikContext } from 'formik';
import { mergeProps } from './index';
import { Checkbox, CheckboxProps } from '../Checkbox';

export const CheckboxForm: FunctionComponent<
    React.PropsWithChildren<CheckboxProps>
> = ({ ...checkboxProps }) => {
    // check if within formik
    const { name } = checkboxProps;
    const formikField = useField({ name, type: 'checkbox' });
    const formikFieldData = !!useFormikContext() ? formikField : null;

    // error and helperText are not needed in checkboxes
    const props = mergeProps(checkboxProps, formikFieldData);

    delete props.error;
    delete props.helperText;

    return <Checkbox {...props} />;
};
