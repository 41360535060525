import { FunctionComponent, useEffect } from 'react';
import { FormikErrors, useFormikContext } from 'formik';

type ErrorFormContainerProps = {
    onError: (errors: FormikErrors<unknown>) => void;
};

export const ErrorFormContainer: FunctionComponent<
    React.PropsWithChildren<ErrorFormContainerProps>
> = ({ children, onError }) => {
    const { errors } = useFormikContext();

    useEffect(() => {
        onError(errors);
    }, [errors]);

    return <>{children}</>;
};
