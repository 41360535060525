import { FunctionComponent, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { SeoHtmlDoc } from '@/models/seo';
import { useTranslation } from '@/hooks/useTranslation';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';

const defaultSeoTags: SeoHtmlDoc = {
    title: 'Skipass online kaufen - starjack.com',
    description:
        'Skiticket bei über 100 Bergbahnen online buchen und sofort ins Skigebiet eintreten. Jederzeit stornierbar.',
};

export const Seo: FunctionComponent = () => {
    const { currentPageConfig } = useCustomRouter();
    const { locale } = useTranslation();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const [seoInfo, setSeoInf] = useState<SeoHtmlDoc & { id: string }>({
        title: defaultSeoTags.title,
        description: defaultSeoTags.description,
        id: null,
    });

    if (selectedLiftOperator && seoInfo?.id !== selectedLiftOperator?.id) {
        setSeoInf(() => {
            const seo = currentPageConfig?.seo || defaultSeoTags;

            const metaData =
                typeof seo === 'function'
                    ? seo({
                          liftOperator: selectedLiftOperator,
                          locale,
                      })
                    : seo;

            return {
                ...metaData,
                id: `${selectedLiftOperator?.id}`,
            };
        });
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>{seoInfo.title}</title>
                <meta name='description' content={seoInfo.description} />
            </Helmet>
        </HelmetProvider>
    );
};
