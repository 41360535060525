import { FunctionComponent } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
} from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { styled } from '@mui/material/styles';
import {
    getAllLiftsOfOperator,
    getLiftOperatorProductsPageLink,
    LiftOperator,
} from '@/models/liftOperators';
import { getPropByCurrentLocale } from '@/utils/locale';
import { getAddressStreet } from '@/utils/string';
import { getCountryNameByCode } from '@/constants/countries';
import { lightBlue, red } from '@mui/material/colors';
import HeightIcon from '@mui/icons-material/Height';
import BarChartIcon from '@mui/icons-material/BarChart';
import ApprovalIcon from '@mui/icons-material/Approval';
import { LiftOperatorLogo } from '@/components/LiftOperatorLogo/LiftOperatorLogo';
import { useTranslation } from '@/hooks/useTranslation';
import { KeyboardArrowRight } from '@mui/icons-material';
import { AddToFavoriteButton } from '@/components/Favorites/AddToFavoriteButton';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useSlice } from '@/hooks/useSlice';
import { configSlice } from '@/slices/configSlice';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';

const Dot = styled(FlexContainer, {
    shouldForwardProp: (props) => props !== 'color',
})<{ color: string }>(({ color, theme }) => ({
    backgroundColor: color,
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
}));

const DotLine = styled(FlexContainer)(({ theme }) => ({
    alignItems: 'center',
    marginRight: theme.spacing(3),
}));

const AddressLabel = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    color: theme.palette.grey[700],
}));

const PisteKmLabel = styled(Typography)(() => ({
    fontSize: '0.85rem',
}));

type LiftOperatorCardProps = {
    liftOperator: LiftOperator;
    onLiftOperatorSelect?: (liftOperator: LiftOperator) => void;
    noBorders?: boolean;
};

export const LiftOperatorCard: FunctionComponent<LiftOperatorCardProps> = ({
    liftOperator,
    onLiftOperatorSelect,
    noBorders = false,
}) => {
    const { locale, getTranslated } = useTranslation();
    const { IS_PORTAL, IS_OEAMTC } = useConfigSlice();
    const { isLoggedIn } = useAuthentication();
    const {
        state: { productEnvironment },
    } = useSlice(configSlice, 'config');
    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();

    const allLifts = getAllLiftsOfOperator(liftOperator);

    const ticketsAvailable =
        liftOperator.products.find((p) => p.environment === productEnvironment)
            ?.count || 0;

    return (
        <Card
            sx={{
                width: '100%',
                my: 1,
            }}
            variant={noBorders ? 'elevation' : 'outlined'}
            elevation={0}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FlexContainer
                    sx={{
                        alginItems: 'center',
                    }}
                >
                    <FlexContainer
                        sx={{
                            alignItems: 'center',
                            width: '120px',
                            mr: 4,
                        }}
                    >
                        <LiftOperatorLogo
                            logoType={'100w'}
                            liftOperator={liftOperator}
                        />
                    </FlexContainer>

                    <FlexContainer column>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                            onClick={() => {
                                navigate(
                                    getPagePath(routerConfig.Products, [
                                        {
                                            param: 'id',
                                            value: getLiftOperatorProductsPageLink(
                                                liftOperator,
                                                locale
                                            ),
                                        },
                                    ])
                                );
                            }}
                            gutterBottom
                        >
                            {getPropByCurrentLocale(liftOperator.name)}
                        </Typography>
                        <AddressLabel>
                            {getAddressStreet(liftOperator.address)}
                        </AddressLabel>
                        <AddressLabel>
                            {getCountryNameByCode(
                                locale,
                                liftOperator.address.country
                            )}
                        </AddressLabel>
                    </FlexContainer>
                </FlexContainer>

                <Divider
                    sx={{
                        width: '100%',
                        height: '1px',
                        bgcolor: 'grey[200]',
                        my: 5,
                    }}
                />

                {!!allLifts && (
                    <FlexContainer sx={{ alignItems: 'center', mb: 2 }}>
                        <ApprovalIcon
                            sx={{
                                mr: 2,
                            }}
                        />
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 600,
                            }}
                            variant='subtitle2'
                        >
                            {getTranslated('numberOfLifts')}:{' '}
                        </Typography>
                        <PisteKmLabel
                            sx={{
                                ml: 1,
                            }}
                        >
                            {allLifts} {getTranslated('common.cablecars')}
                        </PisteKmLabel>
                    </FlexContainer>
                )}

                {!!liftOperator.slopeKm && (
                    <FlexContainer sx={{ mb: 2, alignItems: 'center' }}>
                        <HeightIcon
                            sx={{
                                mr: 2,
                                transform: 'rotateZ(45deg)',
                            }}
                        />
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 600,
                            }}
                            variant='subtitle2'
                        >
                            {getTranslated('common.pistenkilometer')}:{' '}
                        </Typography>
                        <PisteKmLabel
                            sx={{
                                ml: 1,
                            }}
                        >
                            {liftOperator.slopeKm} {getTranslated('kilometers')}
                        </PisteKmLabel>
                    </FlexContainer>
                )}

                {!!(
                    liftOperator.kmBlack ||
                    liftOperator.kmRed ||
                    liftOperator.kmRed
                ) && (
                    <FlexContainer
                        sx={(theme) => ({
                            alignItems: 'center',
                            mb: 2,

                            [theme.breakpoints.down('lg')]: {
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                            },
                        })}
                    >
                        <FlexContainer>
                            <BarChartIcon
                                sx={{
                                    mr: 2,
                                }}
                            />
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 600,
                                }}
                                variant='subtitle2'
                            >
                                {getTranslated('slopeOverview')}:{' '}
                            </Typography>
                        </FlexContainer>

                        <FlexContainer
                            sx={(theme) => ({
                                ml: 1,

                                [theme.breakpoints.down('lg')]: {
                                    mt: 1,
                                    ml: 2,
                                },
                            })}
                            fullWidth
                        >
                            {!!liftOperator.kmBlue && (
                                <DotLine>
                                    <Dot color={lightBlue[400]} />
                                    <PisteKmLabel>
                                        {liftOperator.kmBlue}{' '}
                                        {getTranslated('kilometers')}
                                    </PisteKmLabel>
                                </DotLine>
                            )}

                            {!!liftOperator.kmRed && (
                                <DotLine>
                                    <Dot color={red[400]} />
                                    <PisteKmLabel>
                                        {liftOperator.kmRed}{' '}
                                        {getTranslated('kilometers')}
                                    </PisteKmLabel>
                                </DotLine>
                            )}

                            {!!liftOperator.kmBlack && (
                                <DotLine>
                                    <Dot color='#000' />
                                    <PisteKmLabel>
                                        {liftOperator.kmBlack}{' '}
                                        {getTranslated('kilometers')}
                                    </PisteKmLabel>
                                </DotLine>
                            )}
                        </FlexContainer>
                    </FlexContainer>
                )}

                {!!liftOperator.listLabel && (
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '1.1rem',
                            my: 2,
                            ml: 'auto',
                        }}
                    >
                        {liftOperator.listLabel}
                    </Typography>
                )}
            </CardContent>

            {!!onLiftOperatorSelect && (
                <CardActions
                    sx={(theme) => ({
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mt: 3,

                        [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column',
                            alignItems: 'center',
                        },
                    })}
                >
                    <FlexContainer
                        sx={{
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        {IS_PORTAL && isLoggedIn && (
                            <AddToFavoriteButton
                                liftOperatorId={liftOperator.id}
                            />
                        )}

                        <FlexContainer
                            sx={{
                                alignItems: 'center',
                                ml: 'auto',
                            }}
                        >
                            <Button
                                sx={(theme) => ({
                                    display: 'flex',
                                    alignItems: 'center',

                                    [theme.breakpoints.down('lg')]: {
                                        width: '100%',
                                    },
                                })}
                                variant='outlined'
                                onClick={() => {
                                    onLiftOperatorSelect(liftOperator);
                                }}
                                disabled={!IS_OEAMTC && ticketsAvailable === 0}
                            >
                                {IS_OEAMTC && !!liftOperator.noOffersText
                                    ? getTranslated(
                                          'oeamtc.toProductsSelection.noOffers'
                                      )
                                    : ticketsAvailable === 0
                                    ? getTranslated('noProductsAvailable')
                                    : getTranslated('toProductsSelection')}

                                <KeyboardArrowRight />
                            </Button>
                        </FlexContainer>
                    </FlexContainer>
                </CardActions>
            )}
        </Card>
    );
};
