import { Moment } from 'moment';
import { toMomentFromDateArray, toMomentFromString } from '@/utils/time';
import { v4 as uuidv4 } from 'uuid';
import { DATE_FORMAT_API } from '@/constants';
import { PersonCount } from '@/models/product';

export type Locale = 'de' | 'en';

export type FrontendType = 'frontendType';

export type BackendType = 'backendType';

export type ObjectType = BackendType | FrontendType;

export type Money = {
    amount: number;
    currency: string;
};

export const getEmptyMoneyValue = (): Money => ({
    amount: 0,
    currency: 'EUR',
});

export const multiplyMoneyByFactor = (price: Money, factor: number) => ({
    ...price,
    amount: price.amount * factor,
});

export type Coordinates = {
    lng: number;
    lat: number;
};

export type Link = {
    rel: string;
    href: string;
};

export type MultiLangString<T extends string | string[] = string> = {
    de: T;
    en?: T;
};

export enum Language {
    DE = 'DE',
    EN = 'EN',
}

export const transformLocaleToLanguage = (locale: Locale): Language =>
    locale.toUpperCase() as Language;

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export type CustomDate<T extends ObjectType = FrontendType> =
    T extends FrontendType ? Moment : number[] | string;

export type DateRange<T extends ObjectType = FrontendType> = {
    from: T extends FrontendType ? Moment : number[];
    to: T extends FrontendType ? Moment : number[];
};

export const convertDateRangeToFrontend = (
    dateRange: DateRange<BackendType>
): DateRange => ({
    from: dateRange.from ? toMomentFromDateArray(dateRange.from) : null,
    to: dateRange.to ? toMomentFromDateArray(dateRange.to) : null,
});

export const convertDateToFrontend = (
    date: CustomDate<BackendType>
): CustomDate =>
    typeof date === 'string'
        ? toMomentFromString(date)
        : toMomentFromDateArray(date);

export const convertDateToBackendString = (
    date: CustomDate
): CustomDate<BackendType> => date.format(DATE_FORMAT_API);

export type Person<T extends ObjectType = FrontendType> = {
    id: T extends FrontendType ? string : never;
    name: MultiLangString;
    birthday: DateRange<T>;
};

export const convertPersonToFrontend = (
    person: PersonCount<BackendType>
): PersonCount => ({
    ...person,
    id: uuidv4(),
    birthday: person.birthday
        ? convertDateRangeToFrontend(person.birthday)
        : null,
});
