import { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router';
import { FlexContainer } from '@/components/Layout/Container';
import { LoginForm } from '@/components/LoginForm/LoginForm';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { FacebookButton } from '@/components/Layout/Button/FacebookButton';
import { useTranslation } from '@/hooks/useTranslation';
import { Typography } from '@mui/material';
import { LoginType } from '@/models/user';
import { GoogleButton } from '@/components/Layout/Button/GoogleButton';
import { PageTemplate } from '@/pages/PageTemplate';

export const LoginPage: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => {
    const { isLoggedIn, oAuthAuthentication } = useAuthentication();
    const { getTranslated } = useTranslation();

    const { defaultRoutePathname } = useCustomRouter();
    const { state } = useLocation() as { state: { from: string } };

    if (isLoggedIn) {
        if (state?.from) {
            return <Navigate to={state.from} />;
        } else {
            return <Navigate to={defaultRoutePathname} />;
        }
    }

    return (
        <PageTemplate>
            <FlexContainer
                sx={{
                    flexGrow: 1,
                }}
                fullWidth
                column
                center
            >
                <FlexContainer
                    sx={(theme) => ({
                        marginBottom: theme.spacing(2),

                        [theme.breakpoints.down('md')]: {
                            mt: 2,
                            flexDirection: 'column',
                            gap: '12px',
                        },
                    })}
                    fullWidth
                    center
                >
                    <FacebookButton
                        onClick={() =>
                            oAuthAuthentication({
                                providerUrl: LoginType.FACEBOOK,
                                from: state?.from,
                            })
                        }
                    >
                        {getTranslated('Login.loginWithFacebook')}
                    </FacebookButton>

                    <GoogleButton
                        onClick={() =>
                            oAuthAuthentication({
                                providerUrl: LoginType.GOOGLE,
                                from: state?.from,
                            })
                        }
                    >
                        {getTranslated('Login.loginWithGoogle')}
                    </GoogleButton>
                </FlexContainer>

                <FlexContainer
                    sx={(theme) => ({
                        margin: theme.spacing(4, 0),
                        [theme.breakpoints.down('md')]: {
                            margin: theme.spacing(2, 0),
                        },
                    })}
                >
                    <Typography>{getTranslated('Core.or')}</Typography>
                </FlexContainer>
                <LoginForm />
            </FlexContainer>
        </PageTemplate>
    );
};
