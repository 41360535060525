import { FunctionComponent, PropsWithChildren } from 'react';
import { IdentificationType } from '@/models/cart';
import { useTranslation } from '@/hooks/useTranslation';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { FlexContainer } from '@/components/Layout/Container';
import { IdentificationFormFabButton } from '@/components/PageContent/CartPageContent/IdentificationSelection/IdentificationFormFabButton';

type SelectionControlContainerProps = {
    type: IdentificationType;
    onFabButtonClick: (formType: 'add' | 'register') => void;
    showButtons: boolean;
};

export const SelectionControlContainer: FunctionComponent<
    PropsWithChildren<SelectionControlContainerProps>
> = ({ type, onFabButtonClick, showButtons }) => {
    const { isWebshop } = useConfigSlice();
    const { getTranslated } = useTranslation();

    const addLinkText =
        type === IdentificationType.KEYCARD ||
        type === IdentificationType.INTERNAL_PRODUCTION
            ? 'IdentificationSelect.addKeycard'
            : 'IdentificationSelect.addSuggestion';

    return (
        <FlexContainer
            sx={(theme) => ({
                position: 'absolute',
                justifyContent: 'flex-end',
                bottom: '36px',
                right: '36px',
                zIndex: 10,

                [theme.breakpoints.down('lg')]: {
                    height: '112px',
                    flexDirection: 'column',
                    width: '90%',
                    bottom: '8px',
                    left: '50%',
                    transform: 'translateX(-50%)',

                    ...(!showButtons && { display: 'none' }),
                },
            })}
        >
            {showButtons && (
                <>
                    <IdentificationFormFabButton
                        onClick={() => onFabButtonClick('add')}
                    >
                        {getTranslated(addLinkText)}
                    </IdentificationFormFabButton>

                    {type === IdentificationType.KEYCARD &&
                        !isWebshop('skipluscity') && (
                            <IdentificationFormFabButton
                                onClick={() => {
                                    onFabButtonClick('register');
                                }}
                            >
                                {getTranslated('My.keycards.order.register')}
                            </IdentificationFormFabButton>
                        )}
                </>
            )}
        </FlexContainer>
    );
};
