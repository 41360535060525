import { useEffect, useState } from 'react';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { useNavigate, useParams } from 'react-router';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { FlexContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch } from 'react-redux';
import { usePostEmailConfirmationMutation, userApi } from '@/api/user';

export const EmailConfirmationPage = () => {
    const { code } = useParams<{ code: string }>();
    const dispatch = useDispatch();
    const { getTranslated } = useTranslation();
    const { getPagePath, routerConfig } = useCustomRouter();
    const navigate = useNavigate();
    const [postEmailConfirmation] = usePostEmailConfirmationMutation();
    const [confirmationInProgress, setConfirmationInProgress] = useState(true);
    const [confirmationSuccess, setConfirmationSuccess] = useState(false);

    useEffect(() => {
        if (!code) {
            setConfirmationInProgress(false);
            setConfirmationSuccess(false);
        } else {
            postEmailConfirmation(code)
                .unwrap()
                .then(() => {
                    setConfirmationSuccess(true);
                    dispatch(userApi.util.invalidateTags(['USER']));
                })
                .finally(() => {
                    setConfirmationInProgress(false);
                });
        }
    }, []);

    return (
        <SubPageTemplate title={getTranslated('ConfirmEmailPage.headline')}>
            {confirmationInProgress ? null : (
                <FlexContainer flexGrow={1} column alignItems='center'>
                    {confirmationSuccess ? (
                        <>
                            <CheckCircleOutlineIcon
                                sx={{
                                    color: 'success.main',
                                    fontSize: '3rem',
                                    mb: 2,
                                }}
                            />
                            <Typography variant={'h6'} gutterBottom>
                                {getTranslated('ConfirmEmailPage.success')}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <ErrorIcon
                                sx={{
                                    color: 'error.main',
                                    fontSize: '3rem',
                                    mb: 2,
                                }}
                            />
                            <Typography variant={'h6'} gutterBottom>
                                {getTranslated('ConfirmEmailPage.error')}
                            </Typography>
                        </>
                    )}

                    <Button
                        color='primary'
                        onClick={() => {
                            navigate(getPagePath(routerConfig.Home));
                        }}
                    >
                        {getTranslated('Core.backToHome')}
                    </Button>
                </FlexContainer>
            )}
        </SubPageTemplate>
    );
};
