import { FunctionComponent } from 'react';
import { FlatCard } from '@/components/Card/FlatCard';
import { CardContent, Typography } from '@mui/material';

export const FlatCardWithTitle: FunctionComponent<
    React.PropsWithChildren<{ title: string; error?: boolean }>
> = ({ children, title, error = false }) => {
    return (
        <FlatCard
            sx={{
                width: '100%',
                maxWidth: '600px',
            }}
            error={error}
        >
            <CardContent>
                <Typography sx={{ fontWeight: 600 }} gutterBottom>
                    {title}
                </Typography>
                {children}
            </CardContent>
        </FlatCard>
    );
};
