import { FlexContainer } from '@/components/Layout/Container';
import makeStyles from '@mui/styles/makeStyles';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { useTranslation } from '@/hooks/useTranslation';

import { ReactComponent as GtcDe } from '@/assets/gtc/gtc_de.md';
import { ReactComponent as GtcEn } from '@/assets/gtc/gtc_en.md';

const useStyles = makeStyles(() => ({
    flexContainer: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5',
        letterSpacing: '0.00938em',
        '& h1': {
            fontSize: 24,
        },
        '& h2': {
            fontSize: 16,
        },
    },
}));
export const GTCPage = () => {
    const classes = useStyles();
    const { locale } = useTranslation();

    const GtcContent = locale === 'en' ? GtcEn : GtcDe;

    return (
        <SubPageTemplate>
            <FlexContainer
                column
                width='80%'
                marginX='auto'
                className={classes.flexContainer}
            >
                <GtcContent />
            </FlexContainer>
        </SubPageTemplate>
    );
};
