import { FunctionComponent } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { ChangeKeycardImageForm } from '@/components/Identification/ChangeImageIdentificationForm';
import { Keycard } from '@/models/order';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { FlexContainer } from '@/components/Layout/Container';

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '90%',
        [theme.breakpoints.up('xl')]: {
            width: '65%',
            justifyContent: 'center',
        },
        maxWidth: '100%',
        borderRadius: '0',
        backgroundColor: theme.palette.sjLight,
    },

    titleContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        height: 40,
        padding: theme.spacing(3),
    },

    icon: {
        fontSize: '2rem',
        cursor: 'pointer',
    },

    innerContainer: {
        padding: theme.spacing(4),
    },
}));

type ChangeImageDialogProps = {
    isOpen: boolean;
    onClose: (withReload: boolean) => void;
    keycard: Keycard;
};

export const ChangeKeycardImageDialog: FunctionComponent<
    React.PropsWithChildren<ChangeImageDialogProps>
> = ({ isOpen, onClose, keycard }) => {
    const classes = useStyles();

    return (
        <Dialog
            id='changeImageDialog'
            open={isOpen}
            onClose={() => {
                onClose(false);
            }}
            PaperProps={{
                className: classes.dialogContainer,
            }}
        >
            <DialogTitle className={classes.titleContainer}>
                <CloseIcon
                    className={classes.icon}
                    onClick={() => onClose(false)}
                />
            </DialogTitle>
            <FlexContainer className={classes.innerContainer}>
                {!!keycard && (
                    <ChangeKeycardImageForm
                        keycard={keycard}
                        onClose={() => onClose(true)}
                    />
                )}
            </FlexContainer>
        </Dialog>
    );
};
