import { FunctionComponent } from 'react';
import {
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    OutlinedInputProps,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import { LoadingButton } from '@mui/lab';

type RedeemCodeInputProps = {
    inputLabel: string;
    value: string;
    onChange: OutlinedInputProps['onChange'];
    handleIconButtonClick: (value: string) => void;
    error?: string;
    isLoading?: boolean;
};

export const RedeemCodeInput: FunctionComponent<
    React.PropsWithChildren<RedeemCodeInputProps>
> = ({
    inputLabel,
    handleIconButtonClick,
    value,
    onChange,
    error = '',
    isLoading = false,
}) => {
    const { getTranslated } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const onKeyDown = (e) => {
        // key "enter" pressed
        if (e.keyCode === 13) {
            handleIconButtonClick(value);
        }
    };

    return (
        <FormControl
            onKeyDown={onKeyDown}
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 4,
            }}
            variant='outlined'
            error={!!error}
            fullWidth
        >
            <InputLabel>{inputLabel}</InputLabel>
            <OutlinedInput
                sx={{
                    display: 'flex',
                }}
                type='text'
                onChange={onChange}
                disabled={isLoading}
                error={!!error}
                autoComplete='voucher-redeem-input'
                endAdornment={
                    <InputAdornment
                        position='end'
                        onClick={() => handleIconButtonClick(value)}
                    >
                        <LoadingButton
                            variant='contained'
                            color='secondary'
                            loading={isLoading}
                            sx={{
                                textTransform: 'unset',
                                borderRadius: 5,
                                color: '#fff',
                            }}
                        >
                            {getTranslated(
                                isMobile
                                    ? 'CouponPage.redeem'
                                    : 'CheckoutTicketsPage.redeemVoucherButton'
                            )}
                        </LoadingButton>
                    </InputAdornment>
                }
                fullWidth
            />
            {error && (
                <FormHelperText
                    sx={{
                        mr: 'auto',
                    }}
                    id='component-error-text'
                >
                    {error}
                </FormHelperText>
            )}
        </FormControl>
    );
};
