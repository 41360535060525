import { ProductEnvironment } from '@/models/order';
import {
    BackendType,
    CustomDate,
    FrontendType,
    Money,
    ObjectType,
} from '@/models/general';
import {
    CheckoutTicket,
    convertCheckoutTicketToFrontend,
} from '@/models/checkout/checkoutTicket';
import { Address } from '@/models/user';
import { CheckoutValidation } from '@/models/checkout/checkoutValidation';
import {
    CheckoutIdentification,
    convertCheckoutIdentificationToFrontend,
} from '@/models/checkout/checkoutIdentification';
import { BackendVoucher } from '@/models/voucher';
import { CheckoutInsuraneConsent } from '@/models/checkout/checkoutInsuranceConsent';
import { toMomentFromDateArray } from '@/utils/time';

enum CheckoutStatus {
    NEW = 'NEW',
}

export type CheckoutSession<T extends ObjectType = FrontendType> = {
    id: string;
    status: CheckoutStatus;
    environment: ProductEnvironment;
    liftOperatorId: string;
    userId: string;
    shippingCosts: Money;
    tickets: CheckoutTicket<T>[];
    identifications: CheckoutIdentification<T>[];
    vouchers: BackendVoucher[];
    shopProducts: [];
    totals: {
        depositAmounts: Money;
        totalAfterDiscounts: Money;
        totalBeforeDiscounts: Money;
        subtotalInsurances: Money;
        incertVoucherDiscounts: Money;
        shippingCosts: Money;
        subtotalKeycards: Money;
        subtotalShoproducts: Money;
        subtotalSum: Money;
        subtotalTickets: Money;
        subtotalVouchers: Money;
        totalAfterCredits: Money;
        usedCreditBalance: Money;
        totalCreditBalance: Money;
    };
    phone: string | null;
    birthdate: CustomDate<T> | null;
    invoiceAddress: Address;
    shippingAddress: Address;
    validation: CheckoutValidation;
    insurance?: {
        consents: CheckoutInsuraneConsent[];
        mandatoryDocumentsDownloaded: boolean;
    };
};

export const convertCheckoutToFrontend = (
    checkout: CheckoutSession<BackendType>
): CheckoutSession => ({
    ...checkout,
    identifications: checkout.identifications.map(
        convertCheckoutIdentificationToFrontend
    ),
    birthdate: toMomentFromDateArray(checkout.birthdate as number[]),
    tickets: checkout.tickets.map(convertCheckoutTicketToFrontend),
});
