import { FunctionComponent } from 'react';
import { CustomDate } from '@/models/general';
import { StaticDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { PopoverContent } from '@/components/ProductSelectionFilter/PopoverContent/PopoverContent';

type DateSelectionContentProps = {
    onDateSelect: (date: CustomDate) => void;
    dateDefaultValue?: CustomDate | null;
};

export const DateSelectionContent: FunctionComponent<
    DateSelectionContentProps
> = ({ onDateSelect, dateDefaultValue = null }) => {
    return (
        <PopoverContent
            sx={{
                width: '400px',
            }}
        >
            <StaticDatePicker<CustomDate>
                slots={{
                    toolbar: null,
                }}
                onChange={(value) => {
                    onDateSelect(value);
                }}
                defaultValue={dateDefaultValue || moment()}
                disablePast={true}
            />
        </PopoverContent>
    );
};
