import { FunctionComponent, useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { Button } from '@/components/Layout/Button/Button';
import { useTranslation } from '@/hooks/useTranslation';
import { useNotification } from '@/hooks/useNotification';
import { Keycard } from '@/models/order';
import { usePostTransferKeycardMutation } from '@/api/order';

type TransferKeycardDialogProps = {
    onCancel: () => void;
    onTransferConfirmed: () => void;
    isOpen: boolean;
    keycard: Keycard;
};

export const TransferKeycardDialog: FunctionComponent<
    React.PropsWithChildren<TransferKeycardDialogProps>
> = ({ onCancel, onTransferConfirmed, isOpen, keycard }) => {
    const [open, setOpen] = useState(isOpen);
    const { addNotification } = useNotification();
    const { getTranslated } = useTranslation();
    const [email, setEmail] = useState('');
    const [postTransferKeycard] = usePostTransferKeycardMutation();

    const onClose = () => {
        setOpen(false);
        onCancel();
    };

    const transferKeycard = ({ keycardId, targetEmail }) => {
        postTransferKeycard({ keycardId, targetEmail })
            .unwrap()
            .then(() => {
                addNotification({
                    type: 'success',
                    message: getTranslated('MyCardsPage.transferSuccessful'),
                });
                onTransferConfirmed();
            })
            .catch((response) => {
                if (response.status === 404) {
                    addNotification({
                        type: 'error',
                        message: getTranslated(
                            'MyCardsPage.transferErrorEmailNotFound'
                        ),
                    });
                } else {
                    addNotification({
                        type: 'error',
                        message: getTranslated('MyCardsPage.transferError'),
                    });
                }
            });
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth='md'>
            <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
                {getTranslated('MyCardsPage.transfer')}
            </DialogTitle>
            <DialogContent>
                <FlexContainer>
                    <TextField
                        label={getTranslated('CardTransferPage.targetEmail')}
                        variant='outlined'
                        fullWidth
                        onChange={(e) => {
                            const { value } = e.target;
                            setEmail(value);
                        }}
                    />
                </FlexContainer>
            </DialogContent>
            <DialogActions>
                <Button isLoading={false} onClick={onClose} color='primary'>
                    {getTranslated('Core.cancel')}
                </Button>
                <Button
                    isLoading={false}
                    variant='contained'
                    onClick={() =>
                        transferKeycard({
                            keycardId: keycard.id,
                            targetEmail: email,
                        })
                    }
                    color='primary'
                >
                    {getTranslated('MyCardsPage.transfer')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
