import { Store } from '../store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { saveToLocalStorage } from '@/utils/storage';
import { omit } from 'lodash';
import moment from 'moment';

export type StoreLocalStorageEntity<T extends keyof Store> = {
    timestamp: number;
    storeNode: Partial<Store[T]>;
};

export const useLocalStorageCopy = (
    storeNode: keyof Store,
    omitPaths: string[] = []
) => {
    const storeObject = useSelector((store: Store) => store[storeNode]);

    useEffect(() => {
        const preparedStoreObject = omit(storeObject, omitPaths);

        saveToLocalStorage(storeNode, {
            storeNode: preparedStoreObject,
            timestamp: moment().unix(),
        });
    }, [storeObject]);
};
