import { FunctionComponent, useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { TextField, Typography } from '@mui/material';
import { UploadAndCropper } from '@/components/Image/UploadAndCropper';
import { InputBirthday } from '@/components/Layout/Input/InputBirthday';
import { Button } from '@/components/Layout/Button/Button';
import { Keycard } from '@/models/order';
import { useTranslation } from '@/hooks/useTranslation';
import { useNotification } from '@/hooks/useNotification';
import { usePutKeycardImageMutation } from '@/api/order';

type ChangeKeycardImageFormProp = {
    keycard: Keycard;
    onClose: () => void;
};

export const ChangeKeycardImageForm: FunctionComponent<
    React.PropsWithChildren<ChangeKeycardImageFormProp>
> = ({ keycard, onClose }) => {
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const [image, setImage] = useState(null);
    const [postKeycardImage, { isLoading }] = usePutKeycardImageMutation();

    const onChangeImage = () => {
        postKeycardImage({ keycardId: keycard.id, image })
            .unwrap()
            .then((ok) => {
                if (ok) {
                    addNotification({
                        type: 'success',
                        message: getTranslated('photoUpdateSuccessful'),
                    });

                    onClose();
                }
            });
    };

    return (
        <FlexContainer center column fullWidth>
            <Typography
                sx={{
                    marginBottom: (theme) => theme.spacing(3),
                    padding: (theme) => theme.spacing(3),
                    fontWeight: 'bold',
                }}
                gutterBottom
            >
                {getTranslated('changeKeycardImageExplanation')}
            </Typography>
            <FlexContainer
                sx={(theme) => ({
                    display: 'flex',
                    flexWrap: 'wrap',
                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column',
                    },
                })}
            >
                <FlexContainer
                    sx={(theme) => ({
                        width: 350,
                        minHeight: 250,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    })}
                    column
                    center
                >
                    <UploadAndCropper defaultImage={''} onCrop={setImage} />
                </FlexContainer>

                <FlexContainer
                    sx={(theme) => ({
                        width: '60%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-around',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    })}
                >
                    <FlexContainer
                        sx={{
                            p: 3,
                            boxSizing: 'border-box',
                            minWidth: '50%',
                            flexGrow: 1,
                        }}
                    >
                        <TextField
                            sx={{ width: '100%' }}
                            label={getTranslated('Address.gender')}
                            variant='outlined'
                            defaultValue={keycard.gender}
                            disabled
                        />
                    </FlexContainer>

                    <FlexContainer
                        sx={{
                            p: 3,
                            boxSizing: 'border-box',
                            minWidth: '50%',
                            flexGrow: 1,
                        }}
                    >
                        <TextField
                            variant='outlined'
                            label={getTranslated('Address.title')}
                            sx={{ width: '100%' }}
                            defaultValue={keycard.title}
                            disabled
                        />
                    </FlexContainer>
                    <FlexContainer
                        sx={{
                            p: 3,
                            boxSizing: 'border-box',
                            minWidth: '50%',
                            flexGrow: 1,
                        }}
                    >
                        <TextField
                            variant='outlined'
                            label={getTranslated('Address.firstname')}
                            sx={{ width: '100%' }}
                            defaultValue={keycard.firstname}
                            disabled
                        />
                    </FlexContainer>
                    <FlexContainer
                        sx={{
                            p: 3,
                            boxSizing: 'border-box',
                            minWidth: '50%',
                            flexGrow: 1,
                        }}
                    >
                        <TextField
                            variant='outlined'
                            label={getTranslated('Address.lastname')}
                            sx={{ width: '100%' }}
                            defaultValue={keycard.lastname}
                            disabled
                        />
                    </FlexContainer>
                    <FlexContainer
                        sx={{
                            p: 3,
                            boxSizing: 'border-box',
                            minWidth: '50%',
                            flexGrow: 1,
                        }}
                    >
                        <InputBirthday
                            value={
                                !!keycard.birthday
                                    ? keycard.birthday.format('DD.MM.YYYY')
                                    : ''
                            }
                            displayOnly
                        />
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>

            <FlexContainer fullWidth center column>
                <Button
                    sx={{ mx: 2 }}
                    color='primary'
                    variant='contained'
                    type='submit'
                    isLoading={isLoading}
                    disabled={!image}
                    onClick={onChangeImage}
                >
                    {getTranslated('common.save')}
                </Button>
            </FlexContainer>
        </FlexContainer>
    );
};
