import { FunctionComponent } from 'react';
import Paper from '@mui/material/Paper';

export const ProductsBottomNavigationMobile: FunctionComponent<{
    children: React.ReactNode;
}> = ({ children }) => {
    return (
        <Paper
            sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                top: 'auto',
                bottom: 0,
                left: 0,
                right: 0,
                height: theme.appBar.height,
                justifyContent: 'center',
                padding: theme.spacing(1, 2),
                position: 'fixed',

                width: '100%',
                zIndex: '10',
            })}
            elevation={2}
            square
        >
            {children}
        </Paper>
    );
};
