import {
    RouteConfigEntry,
    RouterConfig,
} from '@/components/Router/routerConfig';

// return a tuple. [withAppBar: RouteConfigEntry[],  withoutAppBar: RouteConfigEntry[]].
// Let's wait for TS 4 release with labeled tuples yay :)
export const getEntriesByAppBar = (
    routerConfig: RouterConfig
): [RouteConfigEntry[], RouteConfigEntry[]] =>
    Object.values(routerConfig).reduce<
        [RouteConfigEntry[], RouteConfigEntry[]]
    >(
        (accumulator, currentValue) => {
            if (currentValue.hideAppBar) {
                accumulator[1].push(currentValue);
            } else {
                accumulator[0].push(currentValue);
            }

            return accumulator;
        },
        [[], []]
    );
