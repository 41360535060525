import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { styled } from '@mui/material/styles';
import { FlatCard } from '@/components/Card/FlatCard';
import { Typography } from '@mui/material';
import { getPropByCurrentLocale } from '@/utils/locale';
import textile from 'textile-js';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';

const HeaderLogo = styled('img')(({ theme }) => ({
    position: 'absolute',
    width: '150px',
    height: 'auto',
    left: theme.spacing(8),
    top: theme.spacing(8),
}));

export const OnePageHeader: FunctionComponent = () => {
    const infoBoxRef = useRef<HTMLDivElement>();
    const { pageConfig } = useConfigSlice();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');

    const onePageLiftOperatorLogo = pageConfig.configuration.logoUrl;
    const onePageHeaderImg = pageConfig.configuration.headerImageUrl;
    const [infoBoxHeight, setInfoBoxHeight] = useState(
        infoBoxRef.current?.clientHeight
    );

    if (
        infoBoxRef.current &&
        infoBoxHeight !== infoBoxRef.current.clientHeight
    ) {
        setInfoBoxHeight(infoBoxRef.current.clientHeight);
    }

    useEffect(() => {
        setInfoBoxHeight(infoBoxRef.current?.clientHeight);

        window.addEventListener('resize', () => {
            setInfoBoxHeight(infoBoxRef.current?.clientHeight);
        });
    }, [infoBoxRef]);

    return (
        <FlexContainer
            sx={(theme) => ({
                backgroundImage: `url(${onePageHeaderImg})`,
                position: 'relative',
                height: '480px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                mb: infoBoxRef?.current
                    ? `${infoBoxRef.current.clientHeight * 0.75}px`
                    : 0,

                [theme.breakpoints.down('lg')]: {
                    maxHeight: '200px',
                    zIndex: 3,
                },
            })}
            fullWidth
            center
        >
            {onePageLiftOperatorLogo && (
                <HeaderLogo alt={'logo'} src={onePageLiftOperatorLogo} />
            )}

            {!!selectedLiftOperator.infoText?.de && (
                <FlatCard
                    ref={infoBoxRef}
                    sx={(theme) => ({
                        position: 'absolute',
                        bottom: 0,
                        minHeight: 80,
                        width: '80%',
                        backgroundColor: theme.palette.primary.main,
                        padding: 3,
                        transform: 'translateY(75%)',
                    })}
                >
                    <FlexContainer center fullWidth>
                        <Typography style={{ color: '#fff' }}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: textile(
                                        getPropByCurrentLocale(
                                            selectedLiftOperator.infoText
                                        ) || ''
                                    ),
                                }}
                            />
                        </Typography>
                    </FlexContainer>
                </FlatCard>
            )}
        </FlexContainer>
    );
};
