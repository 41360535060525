import React, { FunctionComponent, useEffect, useState } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button } from '@/components/Layout/Button/Button';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useNavigate } from 'react-router';
import { useTranslation } from '@/hooks/useTranslation';
import { IdentificationType } from '@/models/cart';
import { AddIdentificationFormContainer } from '@/components/AddIdentificationForm/AddIdentificationFormContainer';
import { useGetCurrentCheckoutSession } from '@/api/checkout';
import { useNotification } from '@/hooks/useNotification';
import { DialogTitle } from '@/components/Dialog/DialogTite';

type AddNewKeycardDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const AddNewKeycardDialog: FunctionComponent<
    React.PropsWithChildren<AddNewKeycardDialogProps>
> = ({ isOpen, onClose }) => {
    const { routerConfig, getPagePath } = useCustomRouter();
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const navigate = useNavigate();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    const [dialogOpen, setDialogOpen] = useState(isOpen);
    const [addedToCart, setAddedToCart] = useState(false);

    useEffect(() => {
        setDialogOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!dialogOpen) onClose();
    }, [dialogOpen]);

    return (
        <Dialog
            open={dialogOpen}
            onClose={() => {
                setDialogOpen(false);
            }}
            maxWidth='xl'
        >
            <DialogTitle
                title={getTranslated('common.createNewCard')}
                onClose={() => {
                    setDialogOpen(false);
                }}
            />
            <DialogContent>
                {addedToCart ? (
                    <FlexContainer
                        sx={{
                            height: '240px',
                        }}
                        alignItems='center'
                        justifyContent='space-between'
                        column
                    >
                        <CheckCircleOutlineIcon
                            color='success'
                            sx={{
                                width: '64px',
                                height: '64px',
                            }}
                        />
                        <Typography variant='h6' gutterBottom>
                            {getTranslated('Keycard.added')}
                        </Typography>

                        <FlexContainer
                            sx={(theme) => ({
                                [theme.breakpoints.down('md')]: {
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                },
                            })}
                            fullWidth
                        >
                            <FlexContainer
                                sx={(theme) => ({
                                    minWidth: 440,
                                    marginRight: theme.spacing(3),
                                    justifyContent: 'space-evenly',

                                    [theme.breakpoints.down('md')]: {
                                        width: '100%',
                                        minWidth: 'auto',
                                        justifyContent: 'center',
                                        marginBottom: theme.spacing(2),
                                        '& button': {
                                            margin: theme.spacing(0, 2),
                                        },
                                    },
                                })}
                            >
                                <Button
                                    onClick={() => {
                                        setAddedToCart(false);
                                    }}
                                    isLoading={false}
                                    variant='contained'
                                    color='primary'
                                >
                                    {getTranslated(
                                        'CheckoutPage.createAnotherCard'
                                    )}
                                </Button>

                                <Button
                                    onClick={() => {
                                        navigate(
                                            getPagePath(routerConfig.Cart)
                                        );
                                        onClose();
                                    }}
                                    isLoading={false}
                                    variant='contained'
                                    color='primary'
                                >
                                    {getTranslated('Core.toCart')}
                                </Button>
                            </FlexContainer>

                            <Button
                                onClick={() => {
                                    setDialogOpen(false);
                                    setAddedToCart(false);
                                }}
                                isLoading={false}
                                variant='outlined'
                            >
                                {getTranslated('Core.close')}
                            </Button>
                        </FlexContainer>
                    </FlexContainer>
                ) : (
                    <AddIdentificationFormContainer
                        checkoutId={checkoutSession?.id || ''}
                        identificationType={IdentificationType.KEYCARD}
                        onSuccess={() => {
                            addNotification({
                                type: 'success',
                                message: getTranslated('Keycard.added'),
                            });
                            setAddedToCart(true);
                        }}
                        onError={() => {
                            addNotification({
                                type: 'error',
                                message: getTranslated('createKeycardError'),
                            });
                        }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
