import { Card, CardContent } from '@mui/material';
import { LocationAutoComplete } from '@/components/AutoComplete/LocationAutoComplete';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import Button from '@mui/material/Button';
import { useTranslation } from '@/hooks/useTranslation';
import { getLiftOperatorProductsPageLink } from '@/models/liftOperators';
import { useGetLiftOperatorsQuery } from '@/api/liftOperator';

export const HomeSearchCard = () => {
    const { data: liftOperators } = useGetLiftOperatorsQuery();
    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();
    const { getTranslated, locale } = useTranslation();

    return (
        <Card
            sx={() => ({
                width: '520px',
            })}
        >
            <CardContent
                sx={() => ({
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 8,
                })}
            >
                <LocationAutoComplete
                    liftOperators={liftOperators}
                    onSelect={(suggestion) => {
                        if (suggestion.type === 'placeType') {
                            navigate(getPagePath(routerConfig.LiftOperators), {
                                state: {
                                    placeId: suggestion.place_id,
                                    description: suggestion.description,
                                },
                            });
                        } else {
                            navigate(
                                getPagePath(routerConfig.Products, [
                                    {
                                        param: 'id',
                                        value: getLiftOperatorProductsPageLink(
                                            suggestion.liftOperator,
                                            locale
                                        ),
                                    },
                                ])
                            );
                        }
                    }}
                />

                <Button
                    variant='contained'
                    sx={{
                        mt: 3,
                    }}
                    onClick={() => {
                        navigate(getPagePath(routerConfig.LiftOperators));
                    }}
                >
                    {getTranslated('Home.show.allOffers')}
                </Button>
            </CardContent>
        </Card>
    );
};
