import { FunctionComponent } from 'react';
import { HomePageSection } from '@/components/PageContent/HomePageContent/HomePageSection';
import { Box, ListItem, Typography } from '@mui/material';
import TshirtWomen from '@/assets/images/merch/tshirt_female.png';
import TshirtMen from '@/assets/images/merch/tshirt_men.png';
import { Image } from '@/components/Image/Image';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { useTranslation } from '@/hooks/useTranslation';

export const MerchSection: FunctionComponent = () => {
    const { getTranslated } = useTranslation();
    return (
        <HomePageSection
            sx={(theme) => ({
                display: 'flex',
                minHeight: '640px',
                py: '40px',
                width: '90%',

                [theme.breakpoints.down(1350)]: {
                    width: '100%',
                },

                [theme.breakpoints.down(750)]: {
                    px: '20px',
                    minHeight: 'unset',
                },
            })}
        >
            <Box
                sx={(theme) => ({
                    position: 'relative',
                    flexGrow: 1,
                    p: 2,

                    minWidth: 360,

                    [theme.breakpoints.down(750)]: {
                        display: 'none',
                    },
                })}
            >
                <Image
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-70%, -65%)',
                        width: '100%',
                        maxWidth: 400,
                    }}
                    src={TshirtWomen}
                />
                <Image
                    sx={(theme) => ({
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '100%',
                        maxWidth: 400,
                        transform: 'translate(-25%, -30%)',

                        [theme.breakpoints.down(850)]: {
                            transform: 'translate(-35%, -30%)',
                        },
                    })}
                    src={TshirtMen}
                />
            </Box>

            <Box
                sx={(theme) => ({
                    p: 2,
                    display: 'flex',
                    maxWidth: 800,
                    pl: '32px',

                    [theme.breakpoints.down(1200)]: {
                        width: 600,
                    },

                    [theme.breakpoints.down(700)]: {
                        pl: '0',
                    },
                })}
            >
                <Box
                    sx={(theme) => ({
                        mt: 12,

                        [theme.breakpoints.down(750)]: {
                            mt: 0,
                        },
                    })}
                >
                    <Typography
                        sx={{
                            mb: 2,
                            color: 'primary.main',
                            fontWeight: 600,
                        }}
                        variant='h6'
                    >
                        {getTranslated('home.merch.title')}
                    </Typography>

                    <Typography
                        sx={{
                            mb: 6,
                        }}
                    >
                        {getTranslated('home.merch.description')}
                    </Typography>

                    <List
                        sx={{
                            listStyleType: 'disc',
                            pl: 8,
                        }}
                        dense
                    >
                        <ListItem
                            sx={{
                                display: 'list-item',
                                pl: 0,
                            }}
                        >
                            <Typography>
                                <Typography
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                    component='span'
                                >
                                    {getTranslated(
                                        'home.merch.list.material.hightlight'
                                    )}
                                    :
                                </Typography>{' '}
                                {getTranslated('home.merch.list.material.text')}
                            </Typography>
                        </ListItem>

                        <ListItem
                            sx={{
                                display: 'list-item',
                                pl: 0,
                            }}
                        >
                            <Typography>
                                <Typography
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                    component='span'
                                >
                                    {getTranslated(
                                        'home.merch.list.sustainable.hightlight'
                                    )}
                                    :
                                </Typography>{' '}
                                {getTranslated(
                                    'home.merch.list.sustainable.text'
                                )}
                            </Typography>
                        </ListItem>
                    </List>

                    <Box
                        sx={() => ({
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                            mt: 6,
                        })}
                    >
                        <Button
                            sx={{
                                mr: 2,
                            }}
                            href='https://starjackmerch.myspreadshop.de/'
                            variant='outlined'
                        >
                            {getTranslated('home.merch.button')}
                        </Button>

                        <Typography
                            sx={(theme) => ({
                                [theme.breakpoints.down('md')]: {
                                    display: 'none',
                                },
                            })}
                        >
                            {getTranslated('home.merch.caption')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </HomePageSection>
    );
};
