import * as Yup from 'yup';
import { ValidationError } from 'yup';
import moment, { Moment } from 'moment';
import { DateRange } from '@/models/general';
import { formatDate } from '@/utils/time';
import { store } from '@/store';
import { translateFunction } from '@/hooks/useTranslation';
import { DATE_FORMAT } from '@/constants';

const dateWithinRange = (value: Moment, dateRange: DateRange) =>
    value.isSameOrAfter(dateRange.from) && value.isSameOrBefore(dateRange.to);

type IdentificationFormSchemaParams = {
    birthday?: DateRange;
    photoRequired?: boolean;
    forRegistration?: boolean;
};

export const identificationFormSchema = (
    params: IdentificationFormSchemaParams = {}
) => {
    // no need for function re-exectuion -> we can directly access the state
    const state = store.getState();
    const { locale } = state.config;

    return Yup.object().shape({
        birthday: Yup.string().test('birthday', '', (value) => {
            if (!value) {
                return new ValidationError(
                    translateFunction(
                        'validation.error.birthday.missing',
                        locale
                    ),
                    1,
                    'birthday'
                );
            }

            if (!params.birthday) return true;

            const { birthday } = params;

            const withinRange = dateWithinRange(
                moment(value, DATE_FORMAT),
                birthday
            );
            const { from, to } = birthday;

            const translated = translateFunction('identificationForm', locale, {
                from: formatDate(from),
                to: formatDate(to),
            });

            return (
                withinRange || new ValidationError(translated, 1, 'birthday')
            );
        }),
        image: Yup.string().test(
            'image',
            translateFunction('RenderImageField.instructions', locale),
            (value) => {
                return params.photoRequired ? !!value : true;
            }
        ),

        firstname: Yup.string().required(
            translateFunction(
                'validation.error.keycard.firstname.required',
                locale
            )
        ),

        lastname: Yup.string().required(
            translateFunction(
                'validation.error.keycard.lastname.required',
                locale
            )
        ),

        gender: Yup.string().test(
            'gender',
            'Bitte wähle die passende Anrede aus',
            (value) => {
                if (!value) return false;

                return value === 'male' || value === 'female';
            }
        ),

        serialNumber: Yup.string().test('serialNumber', '', (value) => {
            if (!params.forRegistration) {
                return true;
            }

            if (!value || value?.length < 23) {
                return new ValidationError(
                    translateFunction('validation.error.serialNumber', locale),
                    1,
                    'serialNumber'
                );
            }

            return true;
        }),
    });
};
