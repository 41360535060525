import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CartPageEntryWrapper = styled(Box)(({ theme }) => ({
    width: 680,

    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));
