import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Button } from '@/components/Layout/Button/Button';
import { useTranslation } from '@/hooks/useTranslation';
import { useAccountRegisterStyles } from '@/components/PageContent/OAuthMergeCreatePageContent/styles';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme/theme';

const useStyles = makeStyles((theme: Theme) => ({
    description: {
        marginBottom: theme.spacing(4),
    },
}));

export const MergeAccount: FunctionComponent<
    React.PropsWithChildren<{ onSubmit: () => void }>
> = ({ onSubmit }) => {
    const classes = useAccountRegisterStyles();
    const ownClasses = useStyles();
    const { getTranslated } = useTranslation();

    return (
        <FlexContainer column fullWidth center>
            <FlexContainer className={classes.sectionBlock} column fullWidth>
                <FlexContainer
                    className={classes.sectionBlockBtn}
                    column
                    fullWidth
                >
                    <Typography className={ownClasses.description}>
                        {getTranslated('Oauth.mergeAccountExplanation')}
                    </Typography>
                    <Button
                        isLoading={false}
                        onClick={onSubmit}
                        color='primary'
                        variant='contained'
                        className={classes.button}
                        fullWidth
                    >
                        {getTranslated('Oauth.mergeAccountButtonLabel')}
                    </Button>
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    );
};
