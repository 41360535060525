import { Box, Skeleton } from '@mui/material';

export const AddressPreviewLoading = () => (
    <Box
        sx={{
            width: '100%',
        }}
    >
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
    </Box>
);
