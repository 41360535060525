import { CheckoutTicket } from '@/models/checkout/checkoutTicket';
import { FrontendType, MultiLangString, ObjectType } from '@/models/general';
import { ProductType } from '@/models/product';
import { Moment } from 'moment';
import { formatDate } from '@/utils/time';

export type CheckoutTicketCollection<T extends ObjectType = FrontendType> = {
    id: string;
    checkoutTickets: CheckoutTicket<T>[];
    type: ProductType;
    name: MultiLangString;
    startDay: Moment;
    duration: number;
    liftOperatorName: MultiLangString;
    isPackage: boolean;
    aonInsuranceEnabled: boolean;
    photoRequired: boolean;
    hasDiscount: boolean;
};

export const getCheckoutTicketCollectionId = (checkoutTicket: CheckoutTicket) =>
    `${checkoutTicket.shortProductId}-${formatDate(checkoutTicket.startDate)}`;

export const buildCheckoutTicketCollections = (
    checkoutTickets: CheckoutTicket[]
): CheckoutTicketCollection[] => {
    const checkoutTicketCollectionMap = checkoutTickets.reduce<
        Record<string, CheckoutTicketCollection>
    >((collectionsMap, ticket) => {
        const ticketId = getCheckoutTicketCollectionId(ticket);

        if (!collectionsMap[ticketId]) {
            collectionsMap[ticketId] = {
                id: ticketId,
                checkoutTickets: [ticket],

                type: ticket.type,
                name: ticket.name,
                startDay: ticket.startDate,
                duration: ticket.duration,
                liftOperatorName: ticket.liftOperatorName,

                isPackage: ticket.persons.length > 1,
                aonInsuranceEnabled: ticket.aonInsurances?.length > 0,
                photoRequired: ticket.photoRequired,
                hasDiscount:
                    ticket.price.amount !== ticket.originalPrice.amount,
            };
        } else {
            collectionsMap[ticketId].checkoutTickets.push(ticket);

            if (
                !collectionsMap[ticketId].isPackage &&
                !collectionsMap[ticketId].aonInsuranceEnabled
            ) {
                collectionsMap[ticketId].aonInsuranceEnabled =
                    ticket.aonInsurances?.length > 0;
            }
        }

        if (!collectionsMap[ticketId].hasDiscount) {
            collectionsMap[ticketId].hasDiscount =
                ticket.price.amount !== ticket.originalPrice.amount;
        }

        return collectionsMap;
    }, {});

    return Object.values(checkoutTicketCollectionMap);
};

export const ticketCollectionReadyForPayment = (
    ticketCollection: CheckoutTicketCollection
) =>
    ticketCollection.checkoutTickets.every(
        (ticket) => ticket.validation.errors.length === 0
    );
