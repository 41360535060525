import {
    FieldHelperProps,
    FieldInputProps,
    FieldMetaProps,
} from 'formik/dist/types';

type MergePropsReturn<T> = { error: boolean; helperText: string } & T &
    FieldInputProps<unknown>;

export const mergeProps = <T>(
    textFieldProps: T,
    formikFieldProps:
        | [
              FieldInputProps<unknown>,
              FieldMetaProps<unknown>,
              FieldHelperProps<unknown>
          ]
        | null
): MergePropsReturn<T> => {
    let formikInputProps = {};
    let error = '';

    if (formikFieldProps) {
        formikInputProps = formikFieldProps[0];
        error = formikFieldProps[1].error;
    }

    return {
        ...(textFieldProps as T),
        ...(formikInputProps as FieldInputProps<unknown>),
        error: !!error,
        helperText: error,
    };
};
