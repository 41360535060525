import { FunctionComponent, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { Link, Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { Button } from '@/components/Layout/Button/Button';
import { useNotification } from '@/hooks/useNotification';
import { useTranslation } from '@/hooks/useTranslation';
import { KeycardTransferStatus } from '@/models/order';
import { formatDate } from '@/utils/time';
import {
    useGetKeycardTransfersQuery,
    usePostAcceptTransferMutation,
    usePostRejectTransferMutation,
} from '@/api/order';

export const KeycardTransferPage: FunctionComponent<
    React.PropsWithChildren<unknown>
> = ({}) => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const { data: keycardsTransfer } = useGetKeycardTransfersQuery();
    const [postAccetTransfer] = usePostAcceptTransferMutation();
    const [postRejectTransfer] = usePostRejectTransferMutation();

    const keycardToReceive = useMemo(() => {
        return keycardsTransfer?.received.find(
            (kT) => kT.id === id && kT.status === KeycardTransferStatus.READY
        );
    }, [keycardsTransfer, id]);

    if (!id) {
        // return to home
        navigate(getPagePath(routerConfig.Home));
    }

    const acceptTransfer = () => {
        postAccetTransfer({ transferId: id })
            .unwrap()
            .then(() => {
                addNotification({
                    type: 'success',
                    message: getTranslated('MyCardsPage.transfer'),
                });
            })
            .catch(() => {
                addNotification({
                    type: 'error',
                    message: getTranslated('MyCardsPage.transferError'),
                });
            });
    };

    const rejectTransfer = () => {
        postRejectTransfer({ transferId: id })
            .then(() => {
                addNotification({
                    type: 'error',
                    message: getTranslated('CardTransfer.accept'),
                });
            })
            .catch(() => {
                addNotification({
                    type: 'error',
                    message: getTranslated('CardTransfer.rejected'),
                });
            });
    };

    return (
        <SubPageTemplate title={getTranslated('CardTransferPage.headline')}>
            {keycardToReceive ? (
                <>
                    <FlexContainer sx={{ alignItems: 'center' }} column>
                        <Typography gutterBottom>
                            <Typography
                                component='span'
                                sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {getTranslated('Core.from')}:
                            </Typography>{' '}
                            {keycardToReceive.email}
                        </Typography>
                        <Typography gutterBottom></Typography>

                        <Typography gutterBottom>
                            <Typography
                                component='span'
                                sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {getTranslated('CardTransfer.firstname')}:
                            </Typography>{' '}
                            {keycardToReceive.firstname}
                        </Typography>

                        <Typography gutterBottom>
                            <Typography
                                component='span'
                                sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {getTranslated('CardTransfer.lastname')}:
                            </Typography>{' '}
                            {keycardToReceive.lastname}
                        </Typography>

                        <Typography gutterBottom>
                            <Typography
                                component='span'
                                sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {getTranslated('CardTransfer.birthday')}:
                            </Typography>{' '}
                            {formatDate(keycardToReceive.birthday)}
                        </Typography>
                    </FlexContainer>

                    <FlexContainer
                        sx={{ width: 240, justifyContent: 'space-between' }}
                    >
                        <Button
                            variant='outlined'
                            isLoading={false}
                            onClick={rejectTransfer}
                        >
                            {getTranslated('CardTransfer.reject')}
                        </Button>

                        <Button
                            variant='contained'
                            color='primary'
                            isLoading={false}
                            onClick={acceptTransfer}
                        >
                            {getTranslated('CardTransfer.accept')}
                        </Button>
                    </FlexContainer>
                </>
            ) : (
                <>
                    <Typography>
                        {getTranslated('KeycardTransfer.noTransfers')}
                    </Typography>
                    <Link
                        onClick={() => {
                            navigate(getPagePath(routerConfig.Home));
                        }}
                    >
                        {getTranslated('Link.backToHome')}
                    </Link>
                </>
            )}
        </SubPageTemplate>
    );
};
