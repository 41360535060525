import { FunctionComponent } from 'react';
import { BackendVoucher } from '@/models/voucher';
import { VoucherEntry } from '@/components/PageContent/CartPageContent/CheckoutItemList/Voucher/VoucherEntry';
import { ItemEntryContainer } from '@/components/PageContent/CartPageContent/CheckoutItemList/ItemEntryContainer';
import {
    useDeleteVoucherMutation,
    useGetCurrentCheckoutSession,
} from '@/api/checkout';

type VoucherEntryContainerProps = {
    voucher: BackendVoucher;
};

export const VoucherEntryContainer: FunctionComponent<
    React.PropsWithChildren<VoucherEntryContainerProps>
> = ({ voucher }) => {
    const [deleteVoucher] = useDeleteVoucherMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    return (
        <ItemEntryContainer
            onRemove={() => {
                deleteVoucher({
                    voucherId: voucher.id,
                    checkoutId: checkoutSession?.id,
                });
            }}
        >
            <VoucherEntry voucher={voucher} />
        </ItemEntryContainer>
    );
};
