import { FunctionComponent } from 'react';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { FlexContainer } from '@/components/Layout/Container';
import { styled } from '@mui/material/styles';
import { FlatCard } from '@/components/Card/FlatCard';
import { Typography } from '@mui/material';
import { getPropByCurrentLocale } from '@/utils/locale';
import textile from 'textile-js';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';

const IntegrationLogo = styled('img')(() => ({
    position: 'absolute',
    width: '80px',
    height: 'auto',
    left: 4,
    top: 4,
}));

export const OnePageHeaderMobile: FunctionComponent = () => {
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { pageConfig } = useConfigSlice();

    const onePageLiftOperatorLogo = pageConfig.configuration.logoUrl;
    const onePageHeaderImg = pageConfig.configuration.headerImageUrl;

    return (
        <FlexContainer fullWidth column>
            <FlexContainer
                sx={() => ({
                    width: '100%',
                    height: '200px',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundImage: `url(${onePageHeaderImg})`,
                    position: 'relative',
                })}
                fullWidth
                center
            >
                {onePageLiftOperatorLogo && (
                    <IntegrationLogo
                        alt={'logo'}
                        src={onePageLiftOperatorLogo}
                    />
                )}
            </FlexContainer>
            {!!selectedLiftOperator.infoText?.de && (
                <FlatCard
                    sx={(theme) => ({
                        minHeight: 80,
                        width: '100%',
                        backgroundColor: theme.palette.primary.main,
                        padding: 3,
                        mt: -6,
                        zIndex: 10,
                    })}
                >
                    <FlexContainer center fullWidth>
                        <Typography style={{ color: '#fff' }}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: textile(
                                        getPropByCurrentLocale(
                                            selectedLiftOperator.infoText
                                        ) || ''
                                    ),
                                }}
                            />
                        </Typography>
                    </FlexContainer>
                </FlatCard>
            )}
        </FlexContainer>
    );
};
