import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Link, Typography } from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme/theme';
import { useNavigate } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';

const getTranslatedErrorMessageKey = (errorMessage: string) => {
    switch (errorMessage) {
        case 'customer_already_exists':
            return 'Registration.error.customerAlreadyExists';
        default:
            return 'Registration.error.general';
    }
};

const useStyles = makeStyles((theme: Theme) => ({
    errorIcon: {
        color: theme.palette.error.main,
        fontSize: 64,
        marginBottom: theme.spacing(2),
    },

    buttonContainer: {
        marginTop: theme.spacing(6),
        width: 500,
        justifyContent: 'space-evenly',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            width: 'auto',
        },
    },

    loginNavigationContainer: {
        width: 360,
        justifyContent: 'space-evenly',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: 'auto',
            marginBottom: theme.spacing(4),
        },
    },

    button: {
        maxWidth: 250,
    },

    errorTitle: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
}));

export const RegistrationError: FunctionComponent<
    React.PropsWithChildren<{
        errorKey: string;
        onErrorBack: () => void;
    }>
> = ({ errorKey, onErrorBack }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { routerConfig, getPagePath } = useCustomRouter();
    const { getTranslated } = useTranslation();

    return (
        <FlexContainer column center>
            <ErrorIcon className={classes.errorIcon} />

            <Typography
                className={classes.errorTitle}
                variant={'h6'}
                gutterBottom
            >
                {getTranslated(getTranslatedErrorMessageKey(errorKey))}
            </Typography>

            {
                <FlexContainer className={classes.buttonContainer}>
                    {errorKey === 'customer_already_exists' ? (
                        <>
                            <Link onClick={onErrorBack}>
                                {getTranslated('Registration.navigation.back')}
                            </Link>

                            <FlexContainer
                                className={classes.loginNavigationContainer}
                                alignItems='center'
                            >
                                <Button
                                    isLoading={false}
                                    color='primary'
                                    variant='contained'
                                    className={classes.button}
                                    onClick={() =>
                                        navigate(
                                            getPagePath(routerConfig.Login)
                                        )
                                    }
                                >
                                    {getTranslated(
                                        'Registration.navigation.toLogin'
                                    )}
                                </Button>

                                <Typography>
                                    {getTranslated('Core.or')}
                                </Typography>
                                <Link
                                    onClick={() => {
                                        navigate(
                                            getPagePath(
                                                routerConfig.ForgotPassword
                                            )
                                        );
                                    }}
                                >
                                    {getTranslated('common.passwordForgot')}
                                </Link>
                            </FlexContainer>
                        </>
                    ) : (
                        <Button
                            isLoading={false}
                            color='primary'
                            variant='contained'
                            className={classes.button}
                            onClick={onErrorBack}
                        >
                            {getTranslated('Registration.navigation.back')}
                        </Button>
                    )}
                </FlexContainer>
            }
        </FlexContainer>
    );
};
