import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import {
    FlexContainer,
    LoadingContainer,
    SectionContainer,
} from '@/components/Layout/Container';
import { Chip, Link, Tab, Tabs, Typography } from '@mui/material';
import { formatDate } from '@/utils/time';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { getPropByCurrentLocale } from '@/utils/locale';
import { InsuranceStatus, TicketStatus, UserTicket } from '@/models/ticket';
import moment from 'moment';
import { TabPanel } from '@/components/Tabs/TabPanel';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useNavigate } from 'react-router-dom';

import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { useGetUserTicketsQuery } from '@/api/order';
import Button from '@mui/material/Button';
import SecurityIcon from '@mui/icons-material/Security';
import { DeleteTicketWithInsuranceDialog } from '@/components/PageContent/MyTicketsPageContent/DeleteTicketWithInsuranceDialog';

const TicketLabel = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0, 2),
}));

const Bold = styled('span')({
    fontWeight: 600,
});

const TextSeparators = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

const TicketRow: FunctionComponent<
    React.PropsWithChildren<{
        ticket: UserTicket;
        onDelete: (ticket: UserTicket) => void;
    }>
> = ({ ticket, onDelete }) => {
    const { getTranslated } = useTranslation();

    const ticketNames = ticket.identifications.map((identification, index) => {
        let owner = '';
        if (index > 0) {
            owner += ', ';
        }
        owner +=
            identification.firstname +
            ' ' +
            identification.lastname +
            ' (' +
            identification.birthday.format('DD.MM.YYYY') +
            ')';
        return owner;
    });

    const ticketCanceled = ticket.status === TicketStatus.REFUNDED;
    return (
        <FlexContainer
            sx={(theme) => ({
                borderBottom: `2px solid ${theme.palette.black}`,
                p: 2,
                mb: 4,

                [theme.breakpoints.down('lg')]: {
                    px: 3,
                },

                ...(!!ticketCanceled && {
                    backgroundColor: 'rgba(244, 67, 54, 0.1)',
                }),
            })}
            fullWidth
            column
        >
            <Typography
                sx={(theme) => ({
                    mb: 4,
                    [theme.breakpoints.down('lg')]: {
                        margin: theme.spacing(0, 4),
                    },
                })}
                variant='h6'
            >
                {formatDate(ticket.startDate)}
            </Typography>
            <FlexContainer
                sx={{
                    ml: 2,
                }}
            >
                <FlexContainer
                    sx={{
                        justifyContent: 'center',
                    }}
                    column
                >
                    <FlexContainer
                        sx={(theme) => ({
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column',
                                marginBottom: theme.spacing(4),
                            },
                        })}
                    >
                        <TicketLabel>
                            <Bold>
                                {getPropByCurrentLocale(ticket.liftOperator)}
                            </Bold>
                        </TicketLabel>

                        <TextSeparators>-</TextSeparators>

                        <TicketLabel>
                            {getPropByCurrentLocale(ticket.personName)}
                        </TicketLabel>

                        <TextSeparators>/</TextSeparators>

                        <TicketLabel>
                            {getPropByCurrentLocale(ticket.name)}
                        </TicketLabel>
                    </FlexContainer>
                    <FlexContainer>
                        <TicketLabel>
                            <Bold>
                                {getTranslated('Ticket.identificationNames')}:
                            </Bold>{' '}
                            {ticketNames}
                        </TicketLabel>
                    </FlexContainer>

                    <FlexContainer
                        sx={{
                            mt: 2,
                        }}
                    >
                        <TicketLabel
                            color={ticketCanceled ? 'error' : 'initial'}
                        >
                            <Bold>Status:</Bold>{' '}
                            {getTranslated(
                                `ticketStatus.${ticket.identificationType}.${ticket.status}` as TranslationKey
                            )}
                        </TicketLabel>
                    </FlexContainer>

                    {!ticket.cancellationDate &&
                        ticket?.insurance?.status ===
                            InsuranceStatus.BOOKED && (
                            <FlexContainer
                                sx={{
                                    mt: 2,
                                }}
                            >
                                <Chip
                                    color={
                                        ticket.insurance !== null
                                            ? 'success'
                                            : 'error'
                                    }
                                    icon={<SecurityIcon />}
                                    label={
                                        ticket.insurance !== null
                                            ? getTranslated(
                                                  'MyTicketsPage.insuranceAdded.chip.label'
                                              )
                                            : getTranslated(
                                                  'MyTicketsPage.insuranceNotAdded.chip.label'
                                              )
                                    }
                                />
                            </FlexContainer>
                        )}

                    {ticket.pdfUrl && !ticketCanceled && (
                        <FlexContainer
                            sx={{
                                mt: 2,
                            }}
                        >
                            <Link
                                sx={{
                                    fontSize: '1rem',
                                    mx: 2,
                                }}
                                href={ticket.pdfUrl}
                                target='_blank'
                            >
                                Download
                            </Link>{' '}
                        </FlexContainer>
                    )}
                </FlexContainer>
                <FlexContainer
                    sx={{
                        p: 1,
                        flexGrow: 1,
                    }}
                    column
                >
                    {ticket.cancelable && (
                        <Button
                            sx={{
                                display: 'flex',
                                ml: 'auto',
                            }}
                            variant='outlined'
                            onClick={() => onDelete(ticket)}
                        >
                            {getTranslated('Core.ticket.cancel.title')}
                        </Button>
                    )}

                    {ticket.cancelableCondition && (
                        <FlexContainer
                            sx={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CancelIcon
                                sx={(theme) => ({
                                    mb: 2,
                                    color: theme.palette.error.main,
                                    fontSize: 40,
                                })}
                            />
                            <Typography
                                variant='caption'
                                sx={(theme) => ({
                                    textAlign: 'center',
                                    color: theme.palette.error.main,
                                })}
                            >
                                {getTranslated(
                                    'MyTicketsPage.ticket_not_cancelable'
                                )}
                            </Typography>
                        </FlexContainer>
                    )}
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    );
};

const TicketContainer: FunctionComponent<
    React.PropsWithChildren<{ children: React.ReactNode[] }>
> = ({ children }) => {
    const { getPagePath, routerConfig } = useCustomRouter();
    const navigate = useNavigate();
    const { getTranslated } = useTranslation();

    return (
        <>
            {children.length ? (
                children
            ) : (
                <FlexContainer>
                    <Link
                        sx={{
                            textTransform: 'uppercase',
                            mt: 8,
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(getPagePath(routerConfig.LiftOperators));
                        }}
                    >
                        {getTranslated('MyTickets.toTicketsOffer')}
                    </Link>
                </FlexContainer>
            )}
        </>
    );
};

export const MyTicketsPage: FunctionComponent = () => {
    const [tickets, setTickets] = useState<{
        ticketsExpired: UserTicket[];
        ticketsCurrent: UserTicket[];
    }>({
        ticketsExpired: [],
        ticketsCurrent: [],
    });
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [ticketToDelete, setTicketToDelete] = useState<UserTicket | null>(
        null
    );
    const [tabValue, setTabValue] = useState(0);
    const { data: ticketsData, isFetching: isLoading } =
        useGetUserTicketsQuery();
    const { getTranslated } = useTranslation();

    const handleTabValueChange = (_, newValue: number) => {
        setTabValue(newValue);
    };

    const ticketsCurrentToRender = useMemo(() => {
        return tickets.ticketsCurrent.map((ticket, i) => (
            <TicketRow
                ticket={ticket}
                key={i}
                onDelete={(ticket) => {
                    setCancelDialogOpen(true);
                    setTicketToDelete(ticket);
                }}
            />
        ));
    }, [tickets.ticketsCurrent]);

    const ticketsExpiredToRender = useMemo(() => {
        return tickets.ticketsExpired.map((ticket, i) => (
            <TicketRow
                ticket={ticket}
                key={i}
                onDelete={(ticket) => {
                    setCancelDialogOpen(true);
                    setTicketToDelete(ticket);
                }}
            />
        ));
    }, [tickets.ticketsExpired]);

    useEffect(() => {
        if (ticketsData?.length) {
            const newTickets = ticketsData.filter(
                (t) => t.status !== TicketStatus.NEW
            );

            newTickets.sort((a, b) => {
                if (a.startDate.isBefore(b.startDate)) {
                    return 1;
                }

                if (a.startDate.isAfter(b.startDate)) {
                    return -1;
                }

                return 0;
            });

            // separate by past and current
            const ticketState = newTickets.reduce(
                (acc, curr) => {
                    if (
                        curr.startDate.isSameOrAfter(
                            moment().subtract(7, 'days')
                        )
                    ) {
                        acc.ticketsCurrent.push(curr);
                    } else {
                        acc.ticketsExpired.push(curr);
                    }

                    return acc;
                },
                {
                    ticketsExpired: [],
                    ticketsCurrent: [],
                }
            );

            setTickets(ticketState);
        }
    }, [ticketsData]);

    return (
        <SubPageTemplate title={getTranslated('Header.myTickets')}>
            <SectionContainer column center fullWidth>
                {isLoading ? (
                    <LoadingContainer />
                ) : (
                    <>
                        <Tabs
                            value={tabValue}
                            sx={{
                                display: 'flex',
                                mb: 4,
                                justifyContent: 'center',
                            }}
                            onChange={handleTabValueChange}
                            indicatorColor='primary'
                        >
                            <Tab
                                label={getTranslated(
                                    'MyTickets.ticketsCurrent'
                                )}
                            />
                            <Tab
                                label={getTranslated(
                                    'MyTickets.ticketsExpired'
                                )}
                            />
                        </Tabs>
                        <TabPanel
                            sx={{
                                mt: 3,
                                width: '100%',
                            }}
                            value={tabValue}
                            index={0}
                        >
                            <TicketContainer>
                                {ticketsCurrentToRender}
                            </TicketContainer>
                        </TabPanel>
                        <TabPanel
                            sx={{
                                mt: 3,
                                width: '100%',
                            }}
                            value={tabValue}
                            index={1}
                        >
                            <TicketContainer>
                                {ticketsExpiredToRender}
                            </TicketContainer>
                        </TabPanel>
                    </>
                )}
            </SectionContainer>

            {ticketToDelete && (
                <DeleteTicketWithInsuranceDialog
                    onClose={() => setCancelDialogOpen(false)}
                    onDialogClosed={() => setTicketToDelete(null)}
                    ticket={ticketToDelete}
                    open={cancelDialogOpen}
                />
            )}
        </SubPageTemplate>
    );
};
