import { FunctionComponent, ReactNode } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Typography,
} from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import { FlexContainer } from '@/components/Layout/Container';
import { Dialog } from '@/components/Dialog/Dialog';

export type InformationDialogProps = {
    open: boolean;
    onConfirm: () => void;
    title: string;
    body: ReactNode;
    confirmationButtonLabel: string;
} & DialogProps;

export const InformationDialog: FunctionComponent<
    React.PropsWithChildren<InformationDialogProps>
> = ({ onConfirm, title, body, confirmationButtonLabel, ...dialogProps }) => {
    return (
        <Dialog {...dialogProps}>
            <FlexContainer
                id='identification_dialog_title'
                sx={(theme) => ({
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary.main,
                    color: '#fff',
                    position: 'relative',
                    width: '100%',
                })}
            >
                <DialogTitle
                    sx={(theme) => ({
                        width: '100%',

                        [theme.breakpoints.down('lg')]: {
                            width: '80%',
                        },
                    })}
                >
                    {title}
                </DialogTitle>
            </FlexContainer>

            <DialogContent>
                <Typography>{body}</Typography>
            </DialogContent>

            <DialogActions
                sx={(theme) => ({
                    [theme.breakpoints.down('lg')]: {
                        height: theme.appBar.height,
                        bottom: 0,
                        position: 'fixed',
                        width: '100%',
                        boxShadow: theme.design.general.boxShadow,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        zIndex: 10,
                    },
                })}
            >
                <Button
                    isLoading={false}
                    onClick={onConfirm}
                    variant='contained'
                    color='primary'
                >
                    {confirmationButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
