import { FunctionComponent, useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Typography } from '@mui/material';

type QuantityCounterProps = {
    onCounterChange: (next: number, action?: 'add' | 'subtract') => void;
    initial?: number;
    allowNegative?: boolean;
    min?: number;
    max?: number;
    value?: number;
    disabled?: boolean;
    disabledMinus?: boolean;
    disabledPlus?: boolean;
};

export const QuantityCounter: FunctionComponent<QuantityCounterProps> = ({
    initial = 0,
    allowNegative = true,
    onCounterChange,
    min = null,
    max = null,
    value = null,
    disabled,
    disabledPlus = null,
    disabledMinus = null,
}) => {
    const [counter, setCounter] = useState(value || initial);
    const [localDisabledMinus, setLocalDisabledMinus] = useState(disabledMinus);
    const [localDisabledPlus, setLocalDisabledPlus] = useState(disabledPlus);

    const onChange = (action: 'add' | 'subtract') => () => {
        let nextValue = action === 'add' ? counter + 1 : counter - 1;

        if (min !== null && nextValue < min) nextValue = min;
        if (max !== null && nextValue > max) nextValue = max;

        if (nextValue < 0 && !allowNegative) {
            return;
        }

        // TODO: fix this logic
        // if (min) {
        //     setLocalDisabledMinus(nextValue === min);
        // }
        //
        // if (max) {
        //     setLocalDisabledPlus(nextValue === max);
        // }

        setCounter(nextValue);

        onCounterChange(nextValue, action);
    };

    if (value !== null && value !== counter) {
        setCounter(value);
    }

    if (disabledMinus !== null && disabledMinus !== localDisabledMinus) {
        setLocalDisabledMinus(disabledMinus);
    }

    if (disabledPlus !== null && disabledPlus !== localDisabledPlus) {
        setLocalDisabledPlus(disabledPlus);
    }

    return (
        <FlexContainer center>
            <IconButton
                onClick={onChange('subtract')}
                disabled={localDisabledMinus || disabled}
            >
                <RemoveIcon />
            </IconButton>

            <Typography
                sx={{
                    mx: 2,
                }}
            >
                {counter}
            </Typography>

            <IconButton
                onClick={onChange('add')}
                disabled={localDisabledPlus || disabled}
            >
                <AddIcon />
            </IconButton>
        </FlexContainer>
    );
};
