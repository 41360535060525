import { FunctionComponent } from 'react';
import {
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
    DialogTitle,
} from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';

type DialogWithTitleProps = {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
} & MuiDialogProps;

export const DialogWithTitle: FunctionComponent<DialogWithTitleProps> = ({
    title,
    children,
    subtitle = null,
    ...rest
}) => {
    return (
        <MuiDialog {...rest}>
            <DialogTitle
                sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.primary.main,
                    color: '#fff',
                })}
            >
                {title}
                <br />
                {subtitle}
            </DialogTitle>
            <FlexContainer
                sx={(theme) => ({
                    flexDirection: 'column',
                    backgroundColor: theme.palette.grey['100'],
                })}
            >
                {children}
            </FlexContainer>
        </MuiDialog>
    );
};
