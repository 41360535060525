import { useDispatch } from 'react-redux';
import { Slice } from '@reduxjs/toolkit';
import { useCallback } from 'react';

export type DispatchedActions<slice extends Slice> = {
    [key in keyof slice['actions']]?: Parameters<
        slice['actions'][key]
    > extends infer ParamsDefinition
        ? ParamsDefinition extends never
            ? () => void
            : ParamsDefinition extends Array<unknown>
            ? (...params: ParamsDefinition) => void
            : (params: ParamsDefinition) => void
        : () => void;
};

export function useDispatchSliceActions<slice extends Slice>() {
    const dispatch = useDispatch();

    return useCallback((actions: slice['actions']) => {
        const dispatchedActions: DispatchedActions<slice> = {};
        for (const key in actions) {
            // @ts-expect-error this generic function will call a type error. However, it works like this
            dispatchedActions[key] = (params) => {
                dispatch(actions[key](params));
            };
        }

        return dispatchedActions;
    }, []);
}
