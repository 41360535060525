import { FunctionComponent } from 'react';
import { VoucherTemplate } from '@/models/voucher';
import { FlexContainer } from '@/components/Layout/Container';
import { Checkbox, Typography } from '@mui/material';
import { urlBuilder } from '@/utils/api';

const IMG_URL = urlBuilder('order', 'vouchers');

type VoucherGridItemProps = {
    voucher: VoucherTemplate;
    selected: boolean;
    onSelect: (voucher: VoucherTemplate) => void;
};

export const VoucherTemplateGridItem: FunctionComponent<
    React.PropsWithChildren<VoucherGridItemProps>
> = ({ voucher, selected, onSelect }) => {
    return (
        <FlexContainer
            sx={{
                cursor: 'pointer',
                position: 'relative',
                backgroundColor: 'sjMain.main',
                padding: 2,
                margin: 'auto',
                paddingBottom: 9,
            }}
            column
            fullWidth
        >
            <img
                alt='voucher-preview'
                src={`${IMG_URL}/${voucher.id}/preview`}
                style={{ width: '100%', height: 'auto' }}
            />

            <FlexContainer
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bottom: 0,
                    right: 0,
                    height: 56,
                    padding: 2,
                    backgroundColor: 'rgba(0,0,0,0.8)',
                }}
                fullWidth
            >
                <Typography sx={{ color: 'white' }}>{voucher.name}</Typography>

                <Checkbox
                    sx={{
                        color: 'white',
                        '&.Mui-checked': {
                            color: 'white',
                        },
                    }}
                    color='default'
                    checked={selected}
                    onClick={() => onSelect(voucher)}
                />
            </FlexContainer>
        </FlexContainer>
    );
};
