import { API, DEFAULT_API_VERSION } from '@/constants';

const dropFirstSlash = (path: string) =>
    path.startsWith('/') ? path.slice(1) : path;

export const urlBuilder = (
    service: string,
    endpoint: string,
    version: string = DEFAULT_API_VERSION
) => `${API}${dropFirstSlash(service)}/${version}${dropFirstSlash(endpoint)}`;
