import { FunctionComponent } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import {
    BaseDialog,
    BaseDialogProps,
} from '@/components/Dialogs/v2/BaseDialog';
import { useTranslation } from '@/hooks/useTranslation';

export type DeleteConfirmationDialogProps = {
    title: string;
    onDelete: () => void;
    deleteButtonLabel: string;
    deleteButtonLoading?: boolean;
    cancelButtonLabel?: string;
} & BaseDialogProps;

export const DeleteConfirmationDialog: FunctionComponent<
    React.PropsWithChildren<DeleteConfirmationDialogProps>
> = ({
    title,
    onDelete,
    deleteButtonLabel,
    cancelButtonLabel,
    deleteButtonLoading = false,
    children,
    ...baseDialogProps
}) => {
    const { getTranslated } = useTranslation();
    return (
        <BaseDialog {...baseDialogProps}>
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>{children}</DialogContent>

            <DialogActions>
                <Button
                    isLoading={false}
                    variant='text'
                    color='primary'
                    onClick={baseDialogProps.onClose}
                >
                    {cancelButtonLabel || getTranslated('Core.cancel')}
                </Button>
                <Button
                    isLoading={deleteButtonLoading}
                    onClick={onDelete}
                    variant='contained'
                    formType='error'
                >
                    {deleteButtonLabel}
                </Button>
            </DialogActions>
        </BaseDialog>
    );
};
