import {
    BackendType,
    convertDateRangeToFrontend,
    DateRange,
    FrontendType,
    MultiLangString,
    ObjectType,
} from '@/models/general';
import {
    CheckoutIdentification,
    convertCheckoutIdentificationToFrontend,
} from '@/models/checkout/checkoutIdentification';
import {
    PossibleIdentificationAssignment,
    convertPossibleIdentificationAssignmentToFrontend,
} from '@/models/checkout/possibleIdentificationAssignment';

export type CheckoutPerson<T extends ObjectType = FrontendType> = {
    id: string;
    name: MultiLangString;
    birthdate: DateRange<T>;
    assignedIdentification: CheckoutIdentification<T>;
    possibleAssignments: PossibleIdentificationAssignment<T>[];
};

export const convertCheckoutPersonToFrontend = (
    person: CheckoutPerson<BackendType>
): CheckoutPerson => ({
    ...person,
    birthdate: person.birthdate
        ? convertDateRangeToFrontend(person.birthdate)
        : null,
    assignedIdentification: person.assignedIdentification
        ? convertCheckoutIdentificationToFrontend(person.assignedIdentification)
        : null,
    possibleAssignments: person.possibleAssignments.map(
        convertPossibleIdentificationAssignmentToFrontend
    ),
});
