import CancelIcon from '@mui/icons-material/Cancel';
import { Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import { memo } from 'react';

const Component = () => {
    const { getTranslated } = useTranslation();

    return (
        <FlexContainer
            sx={{
                alignItems: 'center',
            }}
        >
            {' '}
            <CancelIcon
                sx={(theme) => ({
                    fontSize: '0.85rem',
                    color: theme.palette.error.main,
                    mr: 1,
                })}
            />
            <Typography
                sx={{
                    fontSize: '0.85rem',
                }}
            >
                {getTranslated('MyTicketsPage.ticket_not_cancelable')}
            </Typography>
        </FlexContainer>
    );
};

export const TicketNotRefundableLine = memo(Component);
