import { FunctionComponent } from 'react';
import { ProductsPageMobileView } from '@/components/PageContent/ProductsPageContent/ProductsPageContainerMobile';
import { ProductsBottomNavigationMobile } from '@/components/PageContent/ProductsPageContent/ProductsBottomNavigationMobile';
import Button from '@mui/material/Button';
import { FlexContainer } from '@/components/Layout/Container';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from '@mui/material/styles';
import { useSlice } from '@/hooks/useSlice';
import { configSlice } from '@/slices/configSlice';
import { LoadingButton } from '@mui/lab';
import {
    CheckoutMuationCacheKeys,
    useDeleteCheckoutTicketMutation,
    useGetCurrentCheckoutSession,
    usePostCheckoutTicketMutation,
} from '@/api/checkout';

const IconRight = styled(KeyboardArrowRightIcon)(() => ({
    color: '#fff',
    marginLeft: 'auto',
}));

const IconLeft = styled(KeyboardArrowLeftIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginRight: 'auto',
}));

type ProductsBottomNavigationContainerMobileProps = {
    onNavigationClick: (nextView: ProductsPageMobileView) => void;
    currentNavigation: ProductsPageMobileView;
};

export const ProductsBottomNavigationContainerMobile: FunctionComponent<
    ProductsBottomNavigationContainerMobileProps
> = ({ onNavigationClick, currentNavigation }) => {
    const navigate = useNavigate();
    const { getTranslated } = useTranslation();
    const { getPagePath, routerConfig } = useCustomRouter();
    const { data: checkoutSession, isLoading: getLoading } =
        useGetCurrentCheckoutSession();
    const [, { isLoading: putLoading }] = usePostCheckoutTicketMutation({
        fixedCacheKey: CheckoutMuationCacheKeys.PUT_CHECKOUT_SESSION,
    });
    const [, { isLoading: deleteLoading }] = useDeleteCheckoutTicketMutation({
        fixedCacheKey: CheckoutMuationCacheKeys.DELETE_FROM_CHECKOUT_SESSION,
    });

    const {
        state: { isOeamtc },
    } = useSlice(configSlice, 'config');

    const color = isOeamtc ? '#000' : '#fff';
    const updateLoading = getLoading || putLoading || deleteLoading;
    return (
        <ProductsBottomNavigationMobile>
            {currentNavigation === 'productsOverview' ? (
                <FlexContainer
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Button
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        variant='outlined'
                        onClick={() => {
                            navigate(getPagePath(routerConfig.LiftOperators));
                        }}
                    >
                        <IconLeft
                            sx={{
                                color: isOeamtc
                                    ? '#000'
                                    : 'palette.primary.main',
                            }}
                        />
                        {getTranslated('toLiftOperators')}
                    </Button>
                    <LoadingButton
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'transform 250ms linear',
                        }}
                        variant='contained'
                        onClick={() => {
                            onNavigationClick('cartOverview');
                        }}
                        disabled={!checkoutSession?.tickets.length}
                        loading={updateLoading}
                    >
                        {getTranslated('yourCart')}{' '}
                        {checkoutSession?.tickets.length || '0'}
                        <IconRight
                            sx={{
                                ...(!updateLoading && {
                                    color,
                                }),
                            }}
                        />
                    </LoadingButton>
                </FlexContainer>
            ) : (
                <FlexContainer
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Button
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        variant='outlined'
                        onClick={() => {
                            onNavigationClick('productsOverview');
                        }}
                    >
                        <IconLeft
                            sx={{
                                color,
                            }}
                        />
                        {getTranslated('ProductsPage.productOverview')}
                    </Button>
                    <Button
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        variant='contained'
                        onClick={() => {
                            navigate(getPagePath(routerConfig.Cart));
                        }}
                    >
                        {getTranslated('checkout')}
                        <IconRight
                            sx={{
                                ...(!updateLoading && {
                                    color,
                                }),
                            }}
                        />
                    </Button>
                </FlexContainer>
            )}
        </ProductsBottomNavigationMobile>
    );
};
