import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { FlexContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import { getMoneyAsString } from '@/utils/general';
import { RedeemCodeInputContainer } from '@/components/RedeemCodeInput/RedeemCodeInputContainer';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useGetCreditQuery, useGetVoucherTemplatesQuery } from '@/api/order';
import { useConfigSlice } from '@/hooks/useConfigSlice';

export const RedeemCouponsPage = () => {
    const { getTranslated } = useTranslation();
    const { getPagePath, routerConfig } = useCustomRouter();
    const navigate = useNavigate();
    const { data: credit } = useGetCreditQuery();
    const { IS_PORTAL, pageConfig, IS_OEAMTC } = useConfigSlice();
    const { data: vouchers = [] } = useGetVoucherTemplatesQuery();

    const isVoucherEnabled =
        !IS_OEAMTC &&
        (IS_PORTAL ||
            pageConfig.configuration.vanityIdentifier === 'feldberg' ||
            (!IS_PORTAL && vouchers.length > 0));

    return (
        <SubPageTemplate title={getTranslated('Header.redeemCoupon')}>
            <FlexContainer
                sx={(theme) => ({
                    width: '380px',

                    mx: 2,
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    },
                })}
            >
                <RedeemCodeInputContainer />
            </FlexContainer>

            {!!credit && (
                <Typography>
                    {getTranslated('Core.accountWithBalance', {
                        val: getMoneyAsString(credit?.balance),
                    })}
                </Typography>
            )}

            {isVoucherEnabled && (
                <Card
                    sx={(theme) => ({
                        mt: 10,
                        width: '100%',
                        bgcolor: 'primary.main',
                        color: theme.palette.getContrastText(
                            theme.palette.primary.main
                        ),
                        [theme.breakpoints.up('lg')]: {
                            width: '560px',
                            transition: 'transform 300ms ease-in-out',

                            '&:hover': {
                                transform: 'scale(1.015)',
                            },
                        },
                    })}
                    elevation={1}
                >
                    <CardContent>
                        <Typography variant='h6' gutterBottom>
                            {getTranslated('voucherTeaserTitle')}
                        </Typography>

                        <Typography>
                            {getTranslated('voucherTeaserText')}
                        </Typography>
                    </CardContent>
                    <CardActions
                        sx={{
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            sx={() => ({
                                display: 'flex',
                                alignItems: 'center',
                                color: '#fff',
                            })}
                            color='secondary'
                            variant='contained'
                            onClick={() => {
                                navigate(getPagePath(routerConfig.Vouchers));
                            }}
                        >
                            {getTranslated('voucherTeaserButtonText')}
                            <KeyboardArrowRightIcon />
                        </Button>
                    </CardActions>
                </Card>
            )}
        </SubPageTemplate>
    );
};
