import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Typography, AutocompleteProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { debounce } from 'lodash';

type QueryAutocompletePrediction =
    google.maps.places.QueryAutocompletePrediction;

const searchForLocations = (
    value: string
): Promise<QueryAutocompletePrediction[]> => {
    return new Promise((resolve, reject) => {
        if (!value || !window.google) reject([]);

        const service = new google.maps.places.AutocompleteService();

        service.getQueryPredictions({ input: value }, (predictions) => {
            if (predictions) {
                resolve(predictions);
            }
        });
    });
};

const ListItem = styled('li')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(3),
}));

type AddressAutoCompleteProps = AutocompleteProps<
    string,
    undefined,
    undefined,
    undefined
>;

type AutoCompleteBaseContainerProps = {
    onSelect: (selection: QueryAutocompletePrediction) => void;
    renderInput: AddressAutoCompleteProps['renderInput'];
    onInputChange?: AddressAutoCompleteProps['onInputChange'];
};

export const AddressAutoComplete: FunctionComponent<
    React.PropsWithChildren<AutoCompleteBaseContainerProps>
> = ({ renderInput, onInputChange, onSelect }) => {
    const [value, setValue] = useState<QueryAutocompletePrediction>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState<
        QueryAutocompletePrediction[]
    >([]);

    const debouncedSearch = useMemo(
        () =>
            debounce(
                (
                    searchTerm: string,
                    cb: (result: QueryAutocompletePrediction[]) => void
                ) => {
                    searchForLocations(searchTerm).then(cb);
                },
                350
            ),
        []
    );

    useEffect(() => {
        if (!searchTerm) {
            setSuggestions([]);
            return;
        }

        debouncedSearch(searchTerm.trimEnd(), (results) => {
            setSuggestions([...results]);
        });
    }, [searchTerm]);

    return (
        <Autocomplete
            sx={{
                width: '100%',
            }}
            value={value}
            onChange={(_, nextVal) => {
                if (typeof nextVal === 'string') return;

                setSuggestions(
                    nextVal ? [nextVal, ...suggestions] : suggestions
                );

                setValue(nextVal);

                onSelect(nextVal);
            }}
            onInputChange={(event, newInputValue, reason) => {
                setSearchTerm(newInputValue);
                onInputChange?.(event, newInputValue, reason);
            }}
            inputValue={searchTerm}
            options={suggestions}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }

                return option.description;
            }}
            renderInput={renderInput}
            filterOptions={(options) => options}
            renderOption={(props, suggestion: QueryAutocompletePrediction) => (
                <ListItem key={suggestion.place_id} {...props}>
                    <Typography gutterBottom>
                        {suggestion.description}
                    </Typography>
                </ListItem>
            )}
            freeSolo
        />
    );
};
