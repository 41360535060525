import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFromLocalStorage, saveToLocalStorage } from '@/utils/storage';

export type CheckoutState = {
    checkoutId: string;
    paymentSubmitTries: number;
};

const initialState: CheckoutState = {
    checkoutId: getFromLocalStorage('checkoutId') || '',
    paymentSubmitTries: 0,
};

export const checkoutSlice = createSlice({
    name: 'checkoutSlice',
    initialState,
    reducers: {
        setCheckoutId(state: CheckoutState, action: PayloadAction<string>) {
            state.checkoutId = action.payload;

            saveToLocalStorage('checkoutId', action.payload);

            return state;
        },

        addPaymentSubmitTries(state: CheckoutState) {
            state.paymentSubmitTries += 1;

            return state;
        },
    },
});
