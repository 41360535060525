import { CheckoutTicketCollection } from '@/models/collection/checkoutTicketCollection';
import { Fragment, FunctionComponent } from 'react';
import { PersonAssignmentTitle } from '@/components/CollectionCard/CheckoutTicketCollectionCard/PersonAssignmentTitle';
import { Box } from '@mui/material';
import {
    PersonToSelectLabel,
    SingleTicketPersonToSelectRow,
} from '@/components/CollectionCard/CheckoutTicketCollectionCard/SingleTicketPersonToSelectRow';
import { useSlice } from '@/hooks/useSlice';
import { cartSlice } from '@/slices/cartSlice';
import { PersonAssignedRow } from '@/components/CollectionCard/CheckoutTicketCollectionCard/PersonAssignedRow';
import Button from '@mui/material/Button';
import { useTranslation } from '@/hooks/useTranslation';
import { FlexContainer } from '@/components/Layout/Container';
import { CheckoutTicket } from '@/models/checkout/checkoutTicket';
import { PromotionButtons } from '@/components/CollectionCard/CheckoutTicketCollectionCard/PromotionButtons';

type PersonAssignmentContainerProps = {
    ticketCollection: CheckoutTicketCollection;
    onBookingOptionSelection: (ticket: CheckoutTicket) => void;
};

export const PersonAssignmentContainer: FunctionComponent<
    PersonAssignmentContainerProps
> = ({ ticketCollection, onBookingOptionSelection }) => {
    const { getTranslated } = useTranslation();
    const {
        actions: { setPersonAssignmentDialogControls },
    } = useSlice(cartSlice, 'cart');

    const isPackageAndHasToSelectBookingOption =
        ticketCollection.isPackage &&
        !ticketCollection.checkoutTickets[0].identificationType;

    return (
        <Box>
            <PersonAssignmentTitle ticketCollection={ticketCollection} />

            {isPackageAndHasToSelectBookingOption ? (
                <FlexContainer
                    sx={{
                        p: 4,
                    }}
                    center
                >
                    <Button
                        onClick={() => {
                            onBookingOptionSelection(
                                ticketCollection.checkoutTickets[0]
                            );
                        }}
                        variant='outlined'
                    >
                        {getTranslated('selectWayOfBookingOption')}
                    </Button>
                </FlexContainer>
            ) : (
                <>
                    {ticketCollection.isPackage && (
                        <FlexContainer
                            sx={(theme) => ({
                                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: 64,
                                p: 2,
                            })}
                        >
                            <PromotionButtons
                                checkoutTicket={
                                    ticketCollection.checkoutTickets[0]
                                }
                            />
                        </FlexContainer>
                    )}

                    {ticketCollection.checkoutTickets.map((ticket) => {
                        return ticket.persons.map((person) => (
                            <Fragment key={person.id}>
                                {!!person.assignedIdentification ? (
                                    <PersonAssignedRow
                                        checkoutTicket={ticket}
                                        onChangeClick={() => {
                                            setPersonAssignmentDialogControls({
                                                personId: person.id,
                                                ticket: ticket,
                                            });
                                        }}
                                        assignedPerson={
                                            person.assignedIdentification
                                        }
                                    />
                                ) : (
                                    <SingleTicketPersonToSelectRow
                                        onAddClick={() => {
                                            setPersonAssignmentDialogControls({
                                                personId: person.id,
                                                ticket: ticket,
                                            });
                                        }}
                                        label={
                                            <PersonToSelectLabel
                                                label={person.name.de}
                                            />
                                        }
                                    />
                                )}
                            </Fragment>
                        ));
                    })}
                </>
            )}
        </Box>
    );
};
