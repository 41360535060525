import { FunctionComponent } from 'react';
import {
    Button,
    Grid2,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { FlexContainer } from '../Layout/Container';

import { useNavigate } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';
import * as Yup from 'yup';
import { usePostLoginMutation } from '@/api/authentication';
import { useNotification } from '@/hooks/useNotification';
import { LoadingButton } from '@mui/lab';
import { StyledForm } from '../Form/StyledForm';
import { useDispatch } from 'react-redux';
import { userApi } from '@/api/user';

const loginFormConfig = {
    fields: {
        email: 'email',
        password: 'password',
    },

    validationSchema: Yup.object().shape({
        email: Yup.string().required('Bitte gib deine E-Mail Adresse ein.'),
        password: Yup.string().required('Bitte gib dein Passwort ein.'),
    }),
};

export const LoginForm: FunctionComponent<React.PropsWithChildren> = () => {
    const { getTranslated } = useTranslation();
    const { getPagePath, routerConfig } = useCustomRouter();
    const navigate = useNavigate();
    const [postLogin, { isLoading }] = usePostLoginMutation();
    const { addNotification } = useNotification();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required('Bitte gib deine E-Mail Adresse ein.'),
            password: Yup.string().required('Bitte gib dein Passwort ein.'),
        }),
        onSubmit: ({ email, password }) => {
            postLogin({ email, password })
                .unwrap()
                .then(() => {
                    dispatch(userApi.util.invalidateTags(['USER']));
                })
                .catch(() => {
                    addNotification({
                        type: 'error',
                        message: getTranslated('Request.error.login'),
                    });
                });
        },
    });

    return (
        <FlexContainer
            sx={{
                padding: 4,
            }}
            fullWidth
            column
        >
            <StyledForm
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit(e);
                }}
            >
                <Grid2
                    sx={(theme) => ({
                        width: '75%',

                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    })}
                    container
                >
                    <Grid2
                        sx={(theme) => ({
                            width: '100%',
                            [theme.breakpoints.up('md')]: {
                                px: 12,
                            },
                        })}
                        size={{
                            md: 12,
                            lg: 6,
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                width: '100%',
                                mb: 4,
                            }}
                        >
                            {getTranslated('common.login')}
                        </Typography>

                        <Stack
                            sx={{
                                width: '100%',
                                mb: 3,
                            }}
                            spacing={4}
                        >
                            <TextField
                                name={loginFormConfig.fields.email}
                                id='login-email'
                                sx={{ margin: 3 }}
                                label={getTranslated('Address.email')}
                                autoCorrect={'off'}
                                autoCapitalize={'none'}
                                autoComplete={'current-email'}
                                variant={'outlined'}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                                fullWidth={true}
                            />

                            <TextField
                                name={loginFormConfig.fields.password}
                                id={'login-password'}
                                sx={{ margin: 3 }}
                                label={getTranslated('Address.password')}
                                autoCorrect={'off'}
                                autoCapitalize={'none'}
                                autoComplete={'current-password'}
                                variant={'outlined'}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                }
                                helperText={
                                    formik.touched.password &&
                                    formik.errors.password
                                }
                                fullWidth={true}
                                type={'password'}
                            />
                        </Stack>

                        <FlexContainer
                            sx={(theme) => ({
                                py: 3,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',

                                [theme.breakpoints.down('md')]: {
                                    flexDirection: 'column',
                                    gap: '12px',
                                },
                            })}
                        >
                            <LoadingButton
                                loading={isLoading}
                                sx={(theme) => ({
                                    width: '50%',

                                    [theme.breakpoints.down('md')]: {
                                        marginBottom: 2,
                                        width: '100%',
                                    },
                                })}
                                variant='contained'
                                type='submit'
                            >
                                {getTranslated('common.login')}
                            </LoadingButton>
                            <Link
                                onClick={() => {
                                    navigate(
                                        getPagePath(routerConfig.ForgotPassword)
                                    );
                                }}
                            >
                                {getTranslated('common.passwordForgot')}
                            </Link>
                        </FlexContainer>
                    </Grid2>

                    <Grid2
                        size={{
                            md: 12,
                            lg: 6,
                        }}
                        sx={(theme) => ({
                            [theme.breakpoints.up('md')]: {
                                px: 12,
                            },
                        })}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                mb: 4,
                                '@media (max-width: 960px)': {},
                            }}
                        >
                            {getTranslated('LoginPage.notRegistered')}
                        </Typography>

                        <Typography
                            sx={{
                                marginBottom: 10,
                                marginTop: 2,
                            }}
                        >
                            {getTranslated('LoginPage.notRegisteredText')}
                        </Typography>

                        <Button
                            onClick={() =>
                                navigate(getPagePath(routerConfig.Register))
                            }
                            sx={{
                                width: '50%',
                                mx: 'auto',
                                '@media (max-width: 960px)': {
                                    marginBottom: 2,
                                    width: '100%',
                                },
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {getTranslated('MyAccountNavigation.register')}
                        </Button>
                    </Grid2>
                </Grid2>
            </StyledForm>
        </FlexContainer>
    );
};
