import { FunctionComponent } from 'react';
import { FlatCard } from '@/components/Card/FlatCard';
import { TicketCardContent } from '@/components/Ticket/TicketCardContent';
import { TicketExtraData } from '@/components/Ticket/TicketExtraData';
import { ProductCollection } from '@/models/collection/productCollection';
import { ProductQuantityControl } from '@/components/CollectionCard/ProductCollectionCard/ProductQuantityControl';
import { CollectionCardHeader } from '@/components/CollectionCard/common/CollectionCardHeader';
import { ProductCollectionPersonQuantityList } from '@/components/CollectionCard/ProductCollectionCard/ProductCollectionPersonQuantityList';
import { ProductCollectionPackageBottom } from '@/components/CollectionCard/ProductCollectionCard/ProductCollectionPackageBottom';

export const ProductCollectionCard: FunctionComponent<{
    productCollection: ProductCollection;
}> = ({ productCollection }) => {
    const { isPackage } = productCollection;

    return (
        <FlatCard
            sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                m: 2,
                width: '100%',

                [theme.breakpoints.down('lg')]: {
                    mx: 1,
                    my: 2,
                    width: '100%',
                },
            })}
        >
            <CollectionCardHeader
                productType={productCollection.type}
                productName={productCollection.name}
                duration={productCollection.duration}
                startDay={productCollection.startDay}
                infoText={productCollection.infoText}
                liftOperatorName={productCollection.liftOperator.name}
            />

            <TicketExtraData collection={productCollection} />

            <TicketCardContent
                sx={{
                    height: '100%',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                {isPackage ? (
                    <>
                        <ProductCollectionPersonQuantityList
                            productCollection={productCollection}
                        />
                        <ProductCollectionPackageBottom
                            productCollection={productCollection}
                        />
                    </>
                ) : (
                    <ProductQuantityControl
                        productCollection={productCollection}
                    />
                )}
            </TicketCardContent>
        </FlatCard>
    );
};
