import { FunctionComponent, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { SidebarList } from '@/components/AppBar/SidebarList';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { FlexContainer } from '@/components/Layout/Container';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '../../theme/theme';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    iconContainer: {
        cursor: 'pointer',
        justifyContent: 'flex-end',
        padding: theme.spacing(4, 4, 0),
    },

    closeIcon: {
        fontSize: '2rem',
    },
}));

export const DrawerContainer: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const onClose = () => setOpen(false);

    return (
        <>
            <IconButton
                edge='start'
                color='inherit'
                aria-label='menu'
                onClick={() => setOpen(true)}
                size='large'
            >
                <MenuIcon />
            </IconButton>

            <Drawer anchor={'right'} open={open} onClose={onClose}>
                <FlexContainer column>
                    <FlexContainer className={classes.iconContainer}>
                        <CloseIcon
                            onClick={onClose}
                            className={classes.closeIcon}
                        />
                    </FlexContainer>

                    <SidebarList onClose={onClose} />
                </FlexContainer>
            </Drawer>
        </>
    );
};
