import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { Page } from '@/models/page';
import {
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
    Typography,
} from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import List from '@mui/material/List';
import CheckIcon from '@mui/icons-material/Check';

export const InsuranceProductsPage: Page = () => {
    const { getTranslated } = useTranslation();
    return (
        <SubPageTemplate
            title={getTranslated('insuranceProductPage.title')}
            widthExtended
        >
            <FlexContainer column gap={2} fullWidth>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: getTranslated('insuranceProductPage.content'),
                    }}
                />

                <List dense>
                    {listTranslations.map((item: ListTranslations) => (
                        <ListItem
                            sx={{
                                pl: 1,
                            }}
                            key={item}
                        >
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        dangerouslySetInnerHTML={{
                                            __html: getTranslated(item),
                                        }}
                                    />
                                }
                            />
                        </ListItem>
                    ))}
                </List>

                <Typography>
                    {getTranslated('insuranceProductPage.supportCenter.help')}
                </Typography>

                <FlexContainer
                    sx={{
                        mr: 'auto',
                    }}
                    column
                >
                    <ContactInfoLink href='tel:+43 6246 73430 999'>
                        +43 6246 73430 999
                    </ContactInfoLink>

                    <ContactInfoLink href='mailto:support@starjack.com'>
                        support@starjack.com
                    </ContactInfoLink>
                    <Typography
                        sx={{
                            color: 'text.secondary',
                            fontSize: '0.95rem',
                        }}
                    >
                        Mo . - Fr. 8:00 bis 16:00 Uhr
                    </Typography>
                </FlexContainer>
            </FlexContainer>
        </SubPageTemplate>
    );
};

InsuranceProductsPage.path = '/insurance-products';

const ContactInfoLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '0.95rem',
    marginBottom: theme.spacing(2),
}));

const listTranslations = [
    'insuranceProductPage.list.item0',
    'insuranceProductPage.list.item1',
    'insuranceProductPage.list.item2',
    'insuranceProductPage.list.item3',
    'insuranceProductPage.list.item4',
] as const;

type ListTranslations = typeof listTranslations[number];
