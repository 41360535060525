import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { Button } from '@/components/Layout/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../theme/theme';
import { Form, Formik } from 'formik';
import { FormikTextField } from '@/components/Layout/Form/Field';
import { translateFunction, useTranslation } from '@/hooks/useTranslation';
import { store } from '../store';
import { IS_MOBILE_APP } from '@/constants/index';
import { usePostActivationMutation } from '@/api/authentication';

const initialValues = {
    password: '',
    password2: '',
};

const useStyles = makeStyles((theme: Theme) => ({
    row: {
        margin: theme.spacing(2, 0),
    },
    button: {
        width: '250px',
    },

    field: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },

    form: {
        width: '100%',
    },
}));

const validate = (values) => {
    const config = store.getState().config;
    const locale = config.locale;

    const errors: Partial<typeof initialValues> = {};

    if (!values.password) {
        errors.password = translateFunction(
            'validation.error.password.requiredShort',
            locale
        );
    }

    if (values.password?.length < 6) {
        errors.password = translateFunction(
            'validation.error.password.required',
            locale
        );
    }

    if (!values.password2) {
        errors.password2 = 'Passwort wiederholen';
    }

    if (
        values.password &&
        values.password2 &&
        values.password !== values.password
    ) {
        errors.password2 = 'Die Passwörter stimmen nicht überein';
    }

    return errors;
};

export const ActivatePage = () => {
    const [postActivate] = usePostActivationMutation();

    const { getTranslated } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const { routerConfig, getPagePath } = useCustomRouter();
    const classes = useStyles();

    if (!id) {
        navigate(getPagePath(routerConfig.Home));
    }

    return (
        <SubPageTemplate title='Aktivierung'>
            <FlexContainer flexGrow={1} column alignItems='center'>
                <Typography className={classes.row} variant='h6'>
                    {getTranslated('ActivationPage.activate')}
                </Typography>
                <Formik
                    initialValues={{ password: '', password2: '' }}
                    validate={validate}
                    validateOnChange={false}
                    onSubmit={(values) => {
                        // update password
                        postActivate({
                            activationCode: id,
                            password: values.password,
                            rememberMe: IS_MOBILE_APP,
                        })
                            .unwrap()
                            .then(() => {
                                navigate(getPagePath(routerConfig.Home));
                            });
                    }}
                >
                    <Form
                        className={classes.form}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <FlexContainer column center fullWidth>
                            <FormikTextField
                                name={'password'}
                                textFieldProps={{
                                    className: classes.field,
                                    label: 'Passwort',
                                    variant: 'outlined',
                                    type: 'password',
                                }}
                            />
                            <FormikTextField
                                name={'password2'}
                                textFieldProps={{
                                    className: classes.field,
                                    label: 'Passwort wiederholen',
                                    variant: 'outlined',
                                    type: 'password',
                                }}
                            />

                            <Button
                                className={classes.field}
                                type='submit'
                                isLoading={false}
                                color='primary'
                                variant='contained'
                            >
                                {getTranslated('ActivationPage.activation')}
                            </Button>
                        </FlexContainer>
                    </Form>
                </Formik>
            </FlexContainer>
        </SubPageTemplate>
    );
};
