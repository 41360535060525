import { FlexContainer } from '@/components/Layout/Container';

import { FunctionComponent } from 'react';

import { ProductCollection } from '@/models/collection/productCollection';
import { CollectionCardPersonCountLine } from '@/components/CollectionCard/common/CollectionCardPersonCountLine';

import { PersonCount } from '@/models/product';

type CheckoutTicketQuantityListProps = {
    productCollection: ProductCollection;
};

export const ProductCollectionPersonQuantityList: FunctionComponent<
    CheckoutTicketQuantityListProps
> = ({ productCollection }) => {
    const personsMap: Record<string, PersonCount> =
        productCollection.products.reduce((personsMap, products) => {
            products.persons.forEach((person) => {
                personsMap[person.name.de] = {
                    ...person,
                };
            });
            return personsMap;
        }, {});

    const personCounter: Record<string, number> =
        productCollection.products.reduce((personCounter, ticket) => {
            ticket.persons.forEach((person) => {
                if (!personCounter[person.name.de]) {
                    personCounter[person.name.de] =
                        personsMap[person.name.de].quantity || 1;
                } else {
                    personCounter[person.name.de] += 1;
                }
            });
            return personCounter;
        }, {});
    const personIdsSortedByAge = Object.values(personsMap)
        .sort((personA, personB) => {
            const { birthday: birthdayA } = personA;
            const { birthday: birthdayB } = personB;

            if (birthdayA?.from && birthdayB?.from) {
                return birthdayA.from.unix() - birthdayB.from.unix();
            }

            return 0;
        })
        .map((p) => p.name.de);

    return (
        <FlexContainer column>
            {personIdsSortedByAge.map((personName) => {
                const person = personsMap[personName];
                const count = personCounter[personName];

                return (
                    <CollectionCardPersonCountLine
                        key={person.name.de}
                        count={count}
                        personName={person.name}
                        birthday={person.birthday}
                    />
                );
            })}
        </FlexContainer>
    );
};
