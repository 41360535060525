import { FunctionComponent } from 'react';
import { Card, CardContent } from '@mui/material';
import { Form, Formik } from 'formik';
import Typography from '@mui/material/Typography';
import { FormikTextField } from '@/components/Layout/Form/Field';
import { Button } from '@/components/Layout/Button/Button';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@/theme/theme';
import { GetTranslated, useTranslation } from '@/hooks/useTranslation';
import { Voucher, VoucherTemplate } from '@/models/voucher';
import * as Yup from 'yup';
import { VoucherSelectState } from '@/pages/VouchersPage';
import { FlexContainer } from '@/components/Layout/Container';
import { useConfigSlice } from '@/hooks/useConfigSlice';

const formSchema = (getTranslated: GetTranslated) =>
    Yup.object().shape({
        amount: Yup.number()
            .required(getTranslated('VoucherPage.minamount'))
            .min(5, getTranslated('VoucherPage.minamount')),
        from: Yup.string().required(
            getTranslated('VoucherPage.notemptysender')
        ),

        to: Yup.string().required(
            getTranslated('VoucherPage.notemptyreceiver')
        ),

        text: Yup.string().required(getTranslated('VoucherPage.notemptytext')),
    });

const initial = {
    name: '',
    id: '',
    amount: 100,
    language: '',
    from: '',
    to: '',
    title: '',
    text: '',
};

const useStyles = makeStyles((theme: Theme) => ({
    addressCardContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(4),
        width: '100%',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
    },

    textField: {
        margin: theme.spacing(4, 0),
    },

    form: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        paddingBottom: 48,
    },

    bookBtn: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 48,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },

    bookBtnPreviewContainer: {
        position: 'absolute',
        display: 'flex',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 48,
    },

    bookBtnPreview: {
        width: '50%',
    },

    addToCartBtn: {
        height: '48px',
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

type VoucherDataFormProps = {
    selectedTemplate: VoucherTemplate;
    onFormSubmit: (voucher: Voucher) => void;
    isFormSubmitting?: boolean;
    voucherSelectState: VoucherSelectState;
    addToCart: () => void;
    cancel: () => void;
};

export const VoucherDataForm: FunctionComponent<
    React.PropsWithChildren<VoucherDataFormProps>
> = ({
    selectedTemplate,
    onFormSubmit,
    isFormSubmitting = false,
    voucherSelectState,
    cancel,
    addToCart,
}) => {
    const { getTranslated } = useTranslation();
    const { IS_PORTAL } = useConfigSlice();
    const classes = useStyles();

    const initialValues = {
        ...initial,
    };
    const buttonDisabled = !selectedTemplate;
    const textFieldDisabled = voucherSelectState === 'preview';

    const descriptionListTitleText = IS_PORTAL
        ? getTranslated('VoucherPage.descriptionListTitlePortal')
        : getTranslated('VoucherPage.descriptionListTitleWebshop');

    const descriptionListText = IS_PORTAL
        ? getTranslated('VoucherPage.descriptionListPortal')
        : getTranslated('VoucherPage.descriptionListWebshop');

    return (
        <Formik
            initialValues={{
                ...initialValues,
                ...selectedTemplate,
            }}
            validateOnChange={false}
            validationSchema={formSchema(getTranslated)}
            onSubmit={(values) => {
                const voucher: Voucher = {
                    ...values,
                    language: selectedTemplate.language,
                    name: selectedTemplate.name,
                    amount: {
                        amount:
                            typeof values.amount === 'string'
                                ? parseInt(values.amount)
                                : values.amount,
                        currency: 'EUR',
                    },
                };
                onFormSubmit(voucher);
            }}
            enableReinitialize
        >
            <Form
                className={classes.form}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                <Card>
                    <CardContent className={classes.addressCardContent}>
                        <Typography>
                            {getTranslated('VoucherPage.description')}{' '}
                        </Typography>
                        <br />
                        <br />
                        <Typography>{descriptionListTitleText} </Typography>
                        <br />
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: descriptionListText,
                            }}
                        />
                        <br />
                        <br />
                        <FormikTextField
                            name='amount'
                            textFieldProps={{
                                variant: 'outlined',
                                label: getTranslated('VoucherPage.amount'),
                                type: 'number',
                                className: classes.textField,
                                disabled: textFieldDisabled,
                            }}
                        />
                        <FormikTextField
                            name='from'
                            textFieldProps={{
                                variant: 'outlined',
                                label: getTranslated('VoucherPage.sender'),
                                className: classes.textField,
                                disabled: textFieldDisabled,
                            }}
                        />
                        <FormikTextField
                            name='to'
                            textFieldProps={{
                                variant: 'outlined',
                                label: getTranslated('VoucherPage.receiver'),
                                className: classes.textField,
                                disabled: textFieldDisabled,
                            }}
                        />
                        <FormikTextField
                            name='text'
                            textFieldProps={{
                                variant: 'outlined',
                                label: getTranslated('VoucherPage.text'),
                                multiline: true,
                                rows: 4,
                                className: classes.textField,
                                disabled: textFieldDisabled,
                            }}
                        />
                    </CardContent>
                </Card>

                {voucherSelectState === 'select' ? (
                    <Button
                        type='submit'
                        formType='regular'
                        color='primary'
                        disabled={buttonDisabled}
                        isLoading={isFormSubmitting}
                        variant='outlined'
                        className={classes.bookBtn}
                    >
                        {getTranslated('VoucherPage.preview')}
                    </Button>
                ) : (
                    <FlexContainer className={classes.bookBtnPreviewContainer}>
                        <Button
                            className={classes.bookBtnPreview}
                            disabled={buttonDisabled}
                            isLoading={isFormSubmitting}
                            onClick={cancel}
                            variant='text'
                        >
                            {getTranslated('VoucherPage.editVoucher')}
                        </Button>
                        <Button
                            className={classes.bookBtnPreview}
                            type='submit'
                            color='primary'
                            disabled={buttonDisabled}
                            isLoading={isFormSubmitting}
                            onClick={addToCart}
                            variant='contained'
                        >
                            {getTranslated('Core.addToCart')}
                        </Button>
                    </FlexContainer>
                )}
            </Form>
        </Formik>
    );
};
