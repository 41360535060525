import {
    BackendType,
    convertDateToFrontend,
    CustomDate,
    FrontendType,
    Money,
    ObjectType,
} from '@/models/general';

export type VoucherTemplate = {
    id: string;
    language: string;
    from: string;
    to: string;
    name: string;
    text: string;
    title: string;
};

export type Voucher = {
    amount: Money;
} & VoucherTemplate;

export type BackendVoucher = {
    id: string;
    voucherTemplateId: string;
    language: string;
    sender: string;
    receiver: string;
    name: string;
    text: string;
    title: string;
    amount: Money;
};

export type CreateBackendVoucher = Omit<BackendVoucher, 'id'>;

export const mapBackendVoucherToGAItem = (voucher: BackendVoucher) => ({
    item_id: voucher.id,
    item_name: voucher.name,
    item_category: 'Voucher',
    price: voucher.amount,
    quantity: 1,
});

export const convertVoucherToBackendVoucher = (
    voucher: Voucher
): CreateBackendVoucher => ({
    voucherTemplateId: voucher.id,
    language: voucher.language,
    sender: voucher.from,
    receiver: voucher.to,
    name: voucher.name,
    text: voucher.text,
    title: voucher.title,
    amount: voucher.amount,
});

export type OrderedVoucher<T extends ObjectType = FrontendType> = {
    id: string;
    price: Money;
    redemptionDate: CustomDate<T>;
    status: 'NEW' | 'PAID' | 'REDEEMED';
    text: string;
    title: string;
};

export const mapOrderedVoucherToFrontend = (
    voucher: OrderedVoucher<BackendType>
): OrderedVoucher<FrontendType> => ({
    ...voucher,
    redemptionDate: convertDateToFrontend(voucher.redemptionDate),
});
