import React, { forwardRef, MouseEvent, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Tooltip, Typography } from '@mui/material';
import { withAlphas } from '@/theme/themeHelpers';
import { useTranslation } from '@/hooks/useTranslation';

type SelectionButtonPostion = 'start' | 'center' | 'end';

type ButtonComponentProps = {
    onClick: (ref: HTMLDivElement) => void;
    title: string;
    label: React.ReactNode;
    containerFocused?: boolean; // if one of the buttons of the filter container is focused
    selected?: boolean;
    disabled?: boolean;
    position?: SelectionButtonPostion;
    adornment?: React.ReactNode;
    onMouseEnter?: () => void | null;
    onMouseLeave?: () => void | null;
    grow?: number;
};

export const SelectionButton = forwardRef<HTMLDivElement, ButtonComponentProps>(
    function ButtonComponent(
        {
            onClick,
            title,
            label,
            containerFocused = false,
            selected = false,
            position = 'center',
            disabled = false,
            adornment = null,
            onMouseEnter = null,
            onMouseLeave = null,
            grow = 1,
        },
        ref
    ) {
        const { getTranslated } = useTranslation();
        const [hoverAdornment, setHoverAdornment] = useState(false);
        return (
            <Tooltip
                title={
                    disabled && !hoverAdornment ? (
                        <Box
                            sx={{
                                p: 2,
                            }}
                        >
                            <Typography>
                                {getTranslated(
                                    'productSelectionFilterNoMoreOptions'
                                )}
                            </Typography>
                        </Box>
                    ) : null
                }
            >
                <Box
                    onMouseEnter={!disabled ? onMouseEnter : undefined}
                    onMouseLeave={!disabled ? onMouseLeave : undefined}
                    sx={{
                        display: 'flex',
                        flexGrow: grow,
                        position: 'relative',
                        alignItems: 'center',
                    }}
                >
                    <StyledButton
                        ref={ref}
                        onClick={(event: MouseEvent<HTMLDivElement>) => {
                            if (!disabled) {
                                const target = event.currentTarget;

                                onClick(target);
                            }
                        }}
                        selected={selected}
                        inContainerPosition={position}
                        disabled={disabled}
                        outerContainerFocus={containerFocused}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {title}
                            </Typography>

                            <Box
                                sx={{
                                    ml: 1,
                                    mt: 1,
                                }}
                            >
                                {label}
                            </Box>
                        </Box>
                    </StyledButton>

                    <Box
                        onMouseEnter={() => setHoverAdornment(true)}
                        onMouseLeave={() => setHoverAdornment(false)}
                        sx={{
                            position: 'absolute',
                            right: '8px',
                        }}
                    >
                        {adornment}
                    </Box>
                </Box>
            </Tooltip>
        );
    }
);

const StyledButton = styled(Box, {
    shouldForwardProp: (prop: string) =>
        prop !== 'inContainerPosition' && prop !== 'outerContainerFocus',
})<{
    selected: boolean;
    inContainerPosition: SelectionButtonPostion;
    outerContainerFocus: boolean;
    disabled?: boolean;
}>(
    ({
        theme,
        selected,
        disabled,
        inContainerPosition,
        outerContainerFocus,
    }) => {
        const borderRadius = {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        };

        if (inContainerPosition === 'start') {
            borderRadius.borderTopLeftRadius = 44;
            borderRadius.borderBottomLeftRadius = 44;
        } else if (inContainerPosition === 'end') {
            borderRadius.borderTopRightRadius = 44;
            borderRadius.borderBottomRightRadius = 44;
        }

        return {
            ...borderRadius,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            paddingLeft: theme.spacing(6),
            position: 'relative',
            paddingRight: theme.spacing(2),
            height: '100%',
            width: '100%',

            transition: theme.transitions.create(['background-color'], {
                duration: theme.transitions.duration.shortest,
                easing: 'linear',
            }),

            backgroundColor:
                outerContainerFocus && !selected ? withAlphas().main : '#fff',

            '&:hover': {
                backgroundColor: selected
                    ? '#fff'
                    : outerContainerFocus
                    ? withAlphas().dark
                    : withAlphas().main,
            },

            ...(disabled && {
                pointerEvents: 'none',
                cursor: 'unset',
            }),
        };
    }
);
