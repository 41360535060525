import { FunctionComponent, ReactNode } from 'react';
import { SvgIcon, Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';

type AdvantageCardProps = {
    Icon: ReactNode;
    title: string;
    description: string;
};

export const AdvantageCard: FunctionComponent<AdvantageCardProps> = ({
    Icon,
    title,
    description,
}) => {
    return (
        <FlexContainer
            sx={(theme) => ({
                bgcolor: '#fff',
                px: 7,
                py: 8,
                width: 400,
                height: 540,
                borderRadius: '4px',
                alignItems: 'center',

                [theme.breakpoints.down('sm')]: {
                    height: 'auto',
                },
            })}
            column
        >
            <SvgIcon
                sx={{
                    fontSize: '8rem',
                    mb: 6,
                    width: 152,
                }}
            >
                {Icon}
            </SvgIcon>

            <Typography
                variant='h6'
                sx={{
                    fontSize: '2rem',
                    lineHeight: '2.2rem',
                    fontWeight: 900,
                    color: '#4d8ebe',
                    mb: 3,
                }}
            >
                {title}
            </Typography>

            <Typography
                sx={{
                    color: '#3a4856',
                    // fontSize: '1.05rem',
                    lineHeight: '1.65rem',
                }}
            >
                {description}
            </Typography>
        </FlexContainer>
    );
};
