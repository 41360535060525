import { Language } from '@/models/general';
import { Faq, FaqGroup, FaqGroupIndex } from '@/models/faq';
import { urlBuilder } from '@/utils/api';
import { baseApiAuthenticated } from '@/api/index';

type GetFaqsResponse = {
    faqs: Faq[];
    groups: FaqGroup[];

    // list of pages that have faqs to show
    pages: string[];
    faqGroupIndex: FaqGroupIndex;
};

const sortByPriority = <T extends { priority: number }[]>(list: T): T =>
    list.sort((a, b) => a.priority - b.priority);

const buildFaqGroupIndex = (faqs: Faq[]) => {
    const faqIndex = faqs.reduce<FaqGroupIndex>(
        (index, faq) => {
            if (faq.groupId) {
                if (index[faq.groupId]) {
                    index[faq.groupId].push(faq);
                } else {
                    index[faq.groupId] = [faq];
                }
            } else {
                index['general'].push(faq);
            }

            return index;
        },
        {
            general: [],
        }
    );

    // sort all arrays
    Object.entries(faqIndex).forEach(([groupId, faqs]) => {
        faqIndex[groupId] = sortByPriority(faqs);
    });

    return faqIndex;
};

const faqUrls = (id?: string, language?: Language) => ({
    getFaqs: urlBuilder(
        'order',
        `faqs?${id ? `liftOperatorId=${id}&` : ''}&language=${language}`
    ),
});

const faqsApi = baseApiAuthenticated
    .enhanceEndpoints({ addTagTypes: [] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getFaqs: builder.query<
                GetFaqsResponse,
                {
                    liftOperatorId?: string;
                    language: Language;
                }
            >({
                query: ({ liftOperatorId, language }) =>
                    faqUrls(liftOperatorId, language).getFaqs,

                transformResponse(response: GetFaqsResponse) {
                    const { faqs, groups } = response;

                    const pages = Array.from(new Set(faqs.map((f) => f.page)));

                    return {
                        groups: sortByPriority(groups),
                        faqs: sortByPriority(faqs),
                        faqGroupIndex: buildFaqGroupIndex(faqs),
                        pages,
                    };
                },
                providesTags: ['FAQS'],
            }),
        }),
    });

export const { useGetFaqsQuery } = faqsApi;
