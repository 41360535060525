import { FunctionComponent } from 'react';
import { default as ReactDropzone, DropzoneProps } from 'react-dropzone';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../theme/theme';
import Typography from '@mui/material/Typography';
import { FlexContainer } from '../Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme: Theme) => ({
    explanationText: {
        padding: theme.spacing(2),
    },
}));

export const Dropzone: FunctionComponent<
    React.PropsWithChildren<DropzoneProps>
> = (dropzoneProps) => {
    const classes = useStyles();
    const { getTranslated } = useTranslation();

    return (
        <ReactDropzone {...dropzoneProps}>
            {({ getRootProps, getInputProps }) => {
                const { ...rootProps } = getRootProps();

                return (
                    <>
                        <FlexContainer
                            {...rootProps}
                            sx={(theme) => ({
                                flexDirection: 'column',
                                border: `1px dashed ${theme.palette.grey[200]}`,
                                width: '100%',
                                cursor: 'pointer',
                                textAlign: 'center',
                            })}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <input {...getInputProps()} />
                            <Typography className={classes.explanationText}>
                                {getTranslated('CheckoutKeyCardForm.foto')}
                            </Typography>

                            <Button
                                sx={{
                                    mx: 'auto',
                                    mt: 2,
                                }}
                                variant='outlined'
                            >
                                {getTranslated('choosePhoto')}
                            </Button>
                        </FlexContainer>
                    </>
                );
            }}
        </ReactDropzone>
    );
};
