import { CheckoutTicket } from '@/models/checkout/checkoutTicket';

export enum PromotionType {
    OEAMTC,
    AHA,
}

export type PromotionDialogInput = {
    checkoutTicket: CheckoutTicket;
    promotionType: PromotionType;
};
