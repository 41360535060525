import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Button } from '@/components/Layout/Button/Button';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@/theme/theme';

const rightContainerWidth = {
    md: 64,
    sm: 24,
};

const useStyles = makeStyles((theme: Theme) => ({
    itemEntryContainer: {
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(4),
        marginBottom: 16,
    },
    itemEntryInnerContainer: {
        padding: theme.spacing(6),
        backgroundColor: theme.palette.sjMain,
    },

    additionalContentContainer: {
        padding: theme.spacing(0, 6, 6),
        backgroundColor: theme.palette.sjMain,
        marginRight: rightContainerWidth.md,

        [theme.breakpoints.down('md')]: {
            marginRight: rightContainerWidth.sm,
        },
    },

    contentContainer: {
        backgroundColor: theme.palette.sjMain,
    },

    rightContainer: {
        // take button width
        width: rightContainerWidth.md,
        [theme.breakpoints.down('md')]: {
            width: rightContainerWidth.sm,
        },
    },
}));

type ItemEntryContainerProps = {
    onRemove: () => void;
    additionalComponent?: React.ReactNode;
};

export const ItemEntryContainer: FunctionComponent<
    React.PropsWithChildren<ItemEntryContainerProps>
> = ({ children, onRemove, additionalComponent = null }) => {
    const classes = useStyles();
    return (
        <FlexContainer className={classes.itemEntryContainer}>
            <FlexContainer fullWidth>
                <FlexContainer
                    className={classes.itemEntryInnerContainer}
                    fullWidth
                >
                    {children}
                </FlexContainer>

                <FlexContainer center className={classes.rightContainer}>
                    <Button isLoading={false} onClick={onRemove}>
                        <CloseIcon />
                    </Button>
                </FlexContainer>
            </FlexContainer>

            {additionalComponent && (
                <FlexContainer fullWidth>
                    <FlexContainer
                        className={classes.additionalContentContainer}
                        fullWidth
                        column
                    >
                        {additionalComponent}
                    </FlexContainer>
                </FlexContainer>
            )}
        </FlexContainer>
    );
};
