import { FunctionComponent } from 'react';
import { KeycardEntry } from '@/components/PageContent/CartPageContent/CheckoutItemList/KeycardEntry/KeycardEntry';
import { CheckoutIdentification } from '@/models/checkout/checkoutIdentification';
import {
    useDeleteIdentificationMutation,
    useGetCurrentCheckoutSession,
} from '@/api/checkout';

type KeycardEntryContainerProps = {
    identification: CheckoutIdentification;
};

export const KeycardEntryContainer: FunctionComponent<
    React.PropsWithChildren<KeycardEntryContainerProps>
> = ({ identification }) => {
    const [deleteIdentification, { isLoading }] =
        useDeleteIdentificationMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    return (
        <KeycardEntry
            identification={identification}
            onDelete={() => {
                deleteIdentification({
                    identificationId: identification.id,
                    checkoutId: checkoutSession?.id,
                });
            }}
            deleteLoading={isLoading}
        />
    );
};
