import { FunctionComponent } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, IconButtonProps } from '@mui/material';

type DeleteButtonProps = {
    onClick: () => void;
} & IconButtonProps;

export const DeleteButton: FunctionComponent<DeleteButtonProps> = ({
    onClick,
    ...rest
}) => (
    <IconButton {...rest} color='error' onClick={onClick}>
        <DeleteIcon />
    </IconButton>
);
