import { FunctionComponent, useState } from 'react';
import { BottomNavigationMobile } from '@/components/PageContent/CartPageContent/mobile/BottomNavigation/BottomNavigationMobile';
import { CheckoutView } from '@/components/PageContent/CartPageContent/mobile/CartPageContainerMobile';
import { postPaymentOrderKey, useSubmitOrder } from '@/hooks/useSubmitOrder';
import { useTranslation } from '@/hooks/useTranslation';

import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useSlice } from '@/hooks/useSlice';
import { checkoutSlice } from '@/slices/checkoutSlice';
import {
    useGetCurrentCheckoutSession,
    usePostOrderMutation,
} from '@/api/checkout';
import { isnGeneralCheckoutError } from '@/models/checkout/checkoutValidation';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { CheckoutInsuranceContactDialog } from '@/components/PageContent/CartPageContent/Payment/CheckoutInsuranceContactDialog';
import { useCheckoutHelpers } from '@/hooks/useCheckoutHelpers';

type BottomNavigationContainerMobileProps = {
    currentView: CheckoutView;
    nextView: (nextView: CheckoutView) => void;
};

export const BottomNavigationContainerMobile: FunctionComponent<
    BottomNavigationContainerMobileProps
> = ({ currentView, nextView }) => {
    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();
    const [insuranceContactDialog, setInsuranceContactDialog] = useState(false);
    const { submitOrder } = useSubmitOrder();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { getTranslated } = useTranslation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const { hasInsuranceSelected } = useCheckoutHelpers();
    const {
        actions: { addPaymentSubmitTries },
    } = useSlice(checkoutSlice, 'checkout');
    const [, { isLoading }] = usePostOrderMutation({
        fixedCacheKey: postPaymentOrderKey,
    });

    const labelNext =
        currentView === 'payment'
            ? getTranslated('placeOrder')
            : getTranslated('toPayment');
    const labelBack =
        currentView === 'payment'
            ? getTranslated('Core.back')
            : getTranslated('lookFurther');

    return (
        <>
            <BottomNavigationMobile
                onBack={() => {
                    if (currentView === 'payment') {
                        nextView('list');
                    } else {
                        if (!!selectedLiftOperator) {
                            navigate(
                                getPagePath(routerConfig.Products, [
                                    {
                                        param: 'id',
                                        value: selectedLiftOperator.id,
                                    },
                                ])
                            );
                        } else {
                            navigate(getPagePath(routerConfig.LiftOperators));
                        }
                    }
                }}
                onNext={() => {
                    if (currentView === 'list') {
                        addPaymentSubmitTries();

                        const onlyGlobal =
                            checkoutSession?.validation.errors.every(
                                isnGeneralCheckoutError
                            ) || null;

                        // if there are only general checkout errors (e.g. no payment method selected) we can move on to the payment input page
                        if (onlyGlobal) {
                            nextView('payment');
                        }
                    } else {
                        if (
                            hasInsuranceSelected &&
                            (!checkoutSession.phone ||
                                !checkoutSession.birthdate)
                        ) {
                            setInsuranceContactDialog(true);
                            return;
                        }
                        submitOrder();
                    }
                }}
                nextIsLoading={currentView === 'payment' && isLoading}
                labelBack={labelBack}
                labelNext={labelNext}
            />

            <CheckoutInsuranceContactDialog
                open={insuranceContactDialog}
                onClose={(reason) => {
                    setInsuranceContactDialog(false);
                    if (reason === 'submit') {
                        submitOrder();
                    }
                }}
            />
        </>
    );
};
