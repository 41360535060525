import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { LiftOperator } from '@/models/liftOperators';
import { LiftOperatorLogo } from '@/components/LiftOperatorLogo/LiftOperatorLogo';
import { FlexContainer } from '@/components/Layout/Container';

type ProductsPageLiftOperatorInfoProps = {
    liftOperator: LiftOperator;
};

export const ProductsPageLiftOperatorInfo: FunctionComponent<
    ProductsPageLiftOperatorInfoProps
> = ({ liftOperator }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                p: 3,
                mb: 3,
            }}
        >
            <FlexContainer
                sx={{
                    width: '120px',
                    alignItems: 'center',
                    JustifyContent: 'center',
                }}
            >
                <LiftOperatorLogo
                    logoType={'100w'}
                    liftOperator={liftOperator}
                />
            </FlexContainer>
            <Typography
                sx={{
                    ml: 3,
                }}
                variant='h5'
            >
                {liftOperator.name.de}
            </Typography>
        </Box>
    );
};
