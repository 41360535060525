import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Chip } from '@mui/material';

type ProductFilterTagsProps = {
    filterTags: string[];
    selectedTag: string;
    onTagsSelectChange: (currentSelected: string) => void;
};

export const ProductFilterTags: FunctionComponent<
    React.PropsWithChildren<ProductFilterTagsProps>
> = ({ filterTags, onTagsSelectChange, selectedTag }) => {
    const onAddSelectedTag = (tag: string) => {
        onTagsSelectChange(tag === selectedTag ? '' : tag);
    };

    return (
        <FlexContainer center fullWidth flexWrap='wrap'>
            {filterTags
                .filter((f) => !f.startsWith('*'))
                .map((tag, index) => (
                    <Chip
                        key={`${tag}_${index}`}
                        sx={(theme) => ({
                            fontSize: '1.1rem',
                            margin: theme.spacing(1, 2),

                            ...(selectedTag === tag && {
                                backgroundColor: `${theme.palette.primary.main}!important`,
                                color: theme.palette.white,
                            }),
                        })}
                        variant='outlined'
                        size='medium'
                        label={tag}
                        color='primary'
                        onClick={() => onAddSelectedTag(tag)}
                        clickable
                    />
                ))}
        </FlexContainer>
    );
};
