import React, {
    CSSProperties,
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { Coordinates } from '@/models/general';
import StarjackMarker from '@/assets/icons/starjack-marker.png';
import {
    getLiftOperatorProductsPageLink,
    LiftOperator,
} from '@/models/liftOperators';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { LiftOperatorCard } from '@/components/LiftOperatorSidebar/LiftOperatorCard';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';

type GoogleMapsProps = {
    containerStyles: CSSProperties;
    defaultCenter?: Coordinates;

    liftOperators: LiftOperator[];
    liftOperatorHovered?: LiftOperator;
    zoom: number;
};

export const GoogleMaps: FunctionComponent<
    React.PropsWithChildren<GoogleMapsProps>
> = ({ containerStyles, defaultCenter, zoom, liftOperators }) => {
    const { googleMapsLoaded } = useConfigSlice();
    const [map, setMap] = useState(null);
    const ref = React.createRef<GoogleMap>();
    const markerCoordinates = useMemo(
        () => liftOperators.map((lO) => lO.address.coordinates),
        [liftOperators]
    );

    const [showMarker, setShowMarker] = useState<LiftOperator>(null);
    const navigate = useNavigate();
    const { routerConfig, getPagePath } = useCustomRouter();
    const { locale } = useTranslation();

    const latLngLiterals = useMemo(() => {
        if (window.google) {
            return markerCoordinates.map(
                (c) => new window.google.maps.LatLng(c.lat, c.lng)
            );
        }
        return [];
    }, [markerCoordinates, window.google?.maps]);

    const setMapToPosition = useCallback(
        (loadedMap) => {
            if (window.google) {
                const bounds = new window.google.maps.LatLngBounds();

                if (latLngLiterals.length) {
                    latLngLiterals.forEach((latLng) => bounds.extend(latLng));

                    loadedMap.fitBounds(bounds);
                    loadedMap.setCenter(bounds.getCenter());
                }

                setMap(loadedMap);
            }
        },
        [markerCoordinates, window.google?.maps]
    );

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    useEffect(() => {
        if (window.google && map) {
            setMapToPosition(map);
        }
    }, [window.google, map]);

    if (!window.google) return null;

    return (
        googleMapsLoaded && (
            <GoogleMap
                ref={ref}
                mapContainerStyle={containerStyles}
                center={defaultCenter}
                zoom={zoom}
                onLoad={setMapToPosition}
                onUnmount={onUnmount}
                options={{
                    maxZoom: 12,
                }}
            >
                {latLngLiterals.map((latLng, i) => (
                    <Marker
                        icon={{
                            url: StarjackMarker,
                        }}
                        onClick={() => {
                            setShowMarker(liftOperators[i]);
                        }}
                        key={i}
                        position={latLng}
                    />
                ))}

                {!!showMarker && (
                    <InfoWindow
                        onCloseClick={() => setShowMarker(null)}
                        position={
                            new google.maps.LatLng(
                                showMarker.address.coordinates.lat,
                                showMarker.address.coordinates.lng
                            )
                        }
                    >
                        <LiftOperatorCard
                            liftOperator={showMarker}
                            onLiftOperatorSelect={(liftOperator) =>
                                navigate(
                                    getPagePath(routerConfig.Products, [
                                        {
                                            param: 'id',
                                            value: getLiftOperatorProductsPageLink(
                                                liftOperator,
                                                locale
                                            ),
                                        },
                                    ])
                                )
                            }
                            noBorders
                        />
                    </InfoWindow>
                )}
            </GoogleMap>
        )
    );
};
