import { FunctionComponent } from 'react';
import { Field, FieldConfig } from 'formik';
import { TextField, TextFieldProps } from '@mui/material';

type TextFieldFormProps = FieldConfig & {
    textFieldProps?: TextFieldProps;
};

export const TextFieldForm: FunctionComponent<TextFieldFormProps> = ({
    textFieldProps,
    name,
}) => (
    <Field name={name}>
        {({ field, form: { errors } }) => (
            <TextField
                {...field}
                error={!!errors[name]}
                helperText={!!errors[name] ? errors[name] : ''}
                {...textFieldProps}
            />
        )}
    </Field>
);
