import { FunctionComponent, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Fab,
    Typography,
} from '@mui/material';
import { ProsConsList } from '@/components/PageContent/CartPageContent/TicketIdentVariant/ProsConsList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IdentificationType } from '@/models/cart';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { FlexContainer } from '@/components/Layout/Container';
import { AppButtonAndroid } from '@/components/Layout/Button/AppButtonAndroid';
import { AppButtonAppStore } from '@/components/Layout/Button/AppButtonAppStore';
import { LoadingButton } from '@mui/lab';
import { useGetCurrentCheckoutSession } from '@/api/checkout';

type TicketIdentVariantTile = {
    variant: IdentificationType;
    onClick: (variant: IdentificationType) => void;
    avatarTitle: string | number;
    isLoading: boolean;
};

export const TicketIdentVariantTile: FunctionComponent<
    React.PropsWithChildren<TicketIdentVariantTile>
> = ({ variant, onClick, avatarTitle, isLoading }) => {
    const { getTranslated } = useTranslation();
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const { isLoading: checkoutLoading } = useGetCurrentCheckoutSession();

    return (
        <Card
            sx={(theme) => ({
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '680px',
                margin: theme.spacing(2),
            })}
            elevation={1}
        >
            <CardHeader
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    alignSelf: 'center',
                }}
                avatar={
                    <Avatar
                        sx={(theme) => ({
                            bgcolor: theme.palette.primary.main,
                        })}
                        aria-label='recipe'
                    >
                        {avatarTitle}
                    </Avatar>
                }
                action={
                    <LoadingButton
                        variant='outlined'
                        onClick={() => onClick(variant)}
                        loading={isLoading}
                        disabled={checkoutLoading}
                    >
                        {getTranslated('select')}
                    </LoadingButton>
                }
                title={
                    <Typography
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        {getTranslated(variant)}
                    </Typography>
                }
            />

            <CardContent
                sx={{
                    width: '100%',
                }}
            >
                <Accordion
                    sx={{
                        width: '80%',
                    }}
                    elevation={0}
                    expanded={accordionExpanded}
                >
                    <AccordionDetails
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        {getTranslated(
                            `productionType_${variant}` as unknown as TranslationKey
                        )}

                        {variant === IdentificationType.E_TICKET && (
                            <FlexContainer
                                sx={{
                                    m: 4,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <AppButtonAndroid
                                    sx={{
                                        width: '120px',
                                        height: 'auto',
                                        mx: 2,
                                    }}
                                />
                                <AppButtonAppStore
                                    sx={{
                                        width: '120px',
                                        height: 'auto',
                                        mx: 2,
                                    }}
                                />
                            </FlexContainer>
                        )}
                    </AccordionDetails>
                    <AccordionSummary>
                        <ProsConsList variant={variant} />
                    </AccordionSummary>
                </Accordion>
            </CardContent>

            <Fab
                onClick={() => setAccordionExpanded((prevState) => !prevState)}
                sx={{
                    boxShadow: 'none',
                    position: 'absolute',
                    bottom: '12px',
                    right: '12px',
                    width: '36px',
                    height: '36px',
                    color: '#fff',
                    transition: 'all 300ms ease-in-out',
                    transform: 'rotateZ(180deg)',

                    ...(accordionExpanded && {
                        transform: 'rotateZ(0)',
                    }),
                }}
                size={'small'}
                color={'secondary'}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Card>
    );
};
