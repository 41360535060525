import { FunctionComponent, useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FlexContainer } from '@/components/Layout/Container';
import { Button } from '@/components/Layout/Button/Button';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useTranslation } from '@/hooks/useTranslation';
import { useNotification } from '@/hooks/useNotification';
import { useGetUserDataQuery, usePutEmailMutation } from '@/api/user';
import { LoadingButton } from '@mui/lab';

type EditEmailDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const EditEmailDialog: FunctionComponent<
    React.PropsWithChildren<EditEmailDialogProps>
> = ({ isOpen, onClose }) => {
    const { getTranslated } = useTranslation();
    const { email } = useAuthentication();
    const { data: currentUser, isLoading } = useGetUserDataQuery();
    const [putEmail] = usePutEmailMutation();
    const { addNotification } = useNotification();
    const [nextEmail, setNextEmail] = useState(email);
    const [password, setPassword] = useState('');

    const handleClose = () => {
        onClose();
    };

    const onUpdateEmailClick = () => {
        if (!currentUser) {
            addNotification({
                message: getTranslated('Profile.errorChangeEmailFetchUser'),
                type: 'error',
            });

            return;
        }

        putEmail({
            id: currentUser.id,
            email: nextEmail,
            password,
        })
            .unwrap()
            .then(() => {
                addNotification({
                    message: getTranslated('Profile.changeEmailActivation'),
                    type: 'success',
                });

                handleClose();
            })
            .catch(() => {
                addNotification({
                    message: getTranslated('Profile.changeEmailError'),
                    type: 'error',
                });
            });
    };

    return (
        <FlexContainer>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                maxWidth='lg'
            >
                <DialogTitle id='form-dialog-title'>
                    {getTranslated('common.changeEmail')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{
                            mb: 3,
                        }}
                    >
                        {getTranslated('Profile.enterYourEmailAndPassword')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin='dense'
                        value={nextEmail}
                        onChange={(e) => {
                            const { value } = e.target;
                            setNextEmail(value);
                        }}
                        id='name'
                        label='Email Address'
                        type='email'
                        fullWidth
                    />

                    <TextField
                        autoFocus
                        margin='dense'
                        value={password}
                        onChange={(e) => {
                            const { value } = e.target;
                            setPassword(value);
                        }}
                        id='name'
                        label={getTranslated('Address.password')}
                        type='password'
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        isLoading={false}
                        onClick={handleClose}
                        color='primary'
                    >
                        {getTranslated('Core.cancel')}
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        variant='contained'
                        onClick={() => {
                            if (password && nextEmail) {
                                onUpdateEmailClick();
                            }
                        }}
                        disabled={!password || !nextEmail}
                        color='primary'
                    >
                        {getTranslated('Core.change')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </FlexContainer>
    );
};
