import { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { FlatCardWithTitle } from '@/components/Card/FlatCardWithTitle';
import {
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { PaymentMethod } from '@/models/order';
import { FlexContainer } from '@/components/Layout/Container';
import Visa from '@/assets/icons/paymentIcons/visa.png';
import Mastercard from '@/assets/icons/paymentIcons/mastercard.png';
import Eps from '@/assets/icons/paymentIcons/eps.png';
import Ideal from '@/assets/icons/paymentIcons/ideal.png';
import ApplePay from '@/assets/icons/paymentIcons/apple-pay.png';
import GooglePay from '@/assets/icons/paymentIcons/google-pay.png';
import Sofort from '@/assets/icons/paymentIcons/sofort.png';
import { useTranslation } from '@/hooks/useTranslation';
import { Select } from '@/components/Layout/Select';
import { styled } from '@mui/material/styles';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { useGetProfilesQuery } from '@/api/order';
import { RedeemIncertVoucher } from '@/components/PageContent/CartPageContent/Payment/PaymentOptions/RedeemIncertVoucher';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { ordersSlice } from '@/slices/ordersSlice';

const Icon = styled('img')(({ theme }) => ({
    width: 48,
    margin: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
        width: 48,
    },
}));

const RadioLabel: FunctionComponent<PropsWithChildren<{ title: string }>> = ({
    title,
    children,
}) => (
    <FlexContainer alignItems='center'>
        <Typography>{title}</Typography>
        {children}
    </FlexContainer>
);

export const PaymentOptionsBlock: FunctionComponent = ({}) => {
    const { getTranslated } = useTranslation();
    const {
        state: { selectedProfile, savePaymentData, paymentMethod },
        actions: { setPaymentMethod, setSavePaymentData, setProfile },
    } = useSlice(ordersSlice, 'orders');

    const { data: profiles = [] } = useGetProfilesQuery();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { IS_PORTAL } = useConfigSlice();

    const profileOptions = useMemo<[string, string][]>(
        () => [
            ['-', getTranslated('select')],
            ...profiles.map<[string, string]>((profile) => {
                return [
                    profile.id,
                    `${profile.identifier.slice(7)} - ${getTranslated(
                        'profileExpirationDate'
                    )}: ${profile.cardExpirationDate.format('MM/YY')}`,
                ];
            }),
        ],
        [profiles]
    );

    const hasIncertInput = !IS_PORTAL && selectedLiftOperator?.incertActive;

    return (
        <FlatCardWithTitle title={getTranslated('selectPaymentOption')}>
            {hasIncertInput && <RedeemIncertVoucher />}
            <FlexContainer>
                <RadioGroup row value={paymentMethod}>
                    <FormControlLabel
                        onClick={() => {
                            setPaymentMethod(PaymentMethod.TOKEN);
                        }}
                        value={PaymentMethod.TOKEN}
                        control={<Radio color='primary' />}
                        label={
                            <RadioLabel title='Visa / Mastercard'>
                                <Icon src={Visa} />
                                <Icon src={Mastercard} />
                            </RadioLabel>
                        }
                        labelPlacement='end'
                    />
                    <FormControlLabel
                        onClick={() => {
                            setPaymentMethod(PaymentMethod.EPS);
                        }}
                        value={PaymentMethod.EPS}
                        control={<Radio color='primary' />}
                        label={
                            <RadioLabel
                                title={getTranslated('CheckoutPage.eps')}
                            >
                                <Icon src={Eps} />
                            </RadioLabel>
                        }
                        labelPlacement='end'
                    />
                    <FormControlLabel
                        onClick={() => {
                            setPaymentMethod(PaymentMethod.IDEAL);
                        }}
                        value={PaymentMethod.IDEAL}
                        control={<Radio color='primary' />}
                        label={
                            <RadioLabel
                                title={getTranslated('CheckoutPage.ideal')}
                            >
                                <Icon src={Ideal} />
                            </RadioLabel>
                        }
                        labelPlacement='end'
                    />
                    {window.ApplePaySession &&
                        window.ApplePaySession.canMakePayments() === true && (
                            <FormControlLabel
                                onClick={() => {
                                    setPaymentMethod(PaymentMethod.APPLE_PAY);
                                }}
                                value={PaymentMethod.APPLE_PAY}
                                control={<Radio color='primary' />}
                                label={
                                    <RadioLabel
                                        title={getTranslated(
                                            'CheckoutPage.apple'
                                        )}
                                    >
                                        <Icon src={ApplePay} />
                                    </RadioLabel>
                                }
                                labelPlacement='end'
                            />
                        )}
                    <FormControlLabel
                        onClick={() => {
                            setPaymentMethod(PaymentMethod.GOOGLE_PAY);
                        }}
                        value={PaymentMethod.GOOGLE_PAY}
                        control={<Radio color='primary' />}
                        label={
                            <RadioLabel
                                title={getTranslated('CheckoutPage.google')}
                            >
                                <Icon src={GooglePay} />
                            </RadioLabel>
                        }
                        labelPlacement='end'
                    />
                    <FormControlLabel
                        onClick={() => {
                            setPaymentMethod(PaymentMethod.SOFORT);
                        }}
                        value={PaymentMethod.SOFORT}
                        control={<Radio color='primary' />}
                        label={
                            <RadioLabel
                                title={getTranslated('CheckoutPage.sofort')}
                            >
                                <Icon src={Sofort} />
                            </RadioLabel>
                        }
                        labelPlacement='end'
                    />
                </RadioGroup>
            </FlexContainer>

            {paymentMethod === PaymentMethod.TOKEN && (
                <>
                    {!!profiles.length && (
                        <Select
                            onChange={(e) => {
                                const { value } = e.target;

                                if (value === '-') {
                                    setProfile(null);
                                } else {
                                    setProfile(
                                        profiles.find((p) => p.id === value)
                                    );
                                }
                            }}
                            value={selectedProfile?.id || '-'}
                            sx={{
                                width: '240px',
                            }}
                            inputLabel=''
                            variant='outlined'
                            options={profileOptions}
                        />
                    )}
                    <FlexContainer>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={savePaymentData}
                                    color='primary'
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        setSavePaymentData(checked);
                                    }}
                                />
                            }
                            label={getTranslated('CheckoutPage.saveProfile')}
                        />
                    </FlexContainer>
                </>
            )}
        </FlatCardWithTitle>
    );
};
