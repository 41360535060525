import { ThemeOptions } from '@mui/material/styles';
import { starjackBaseTheme } from '@/theme/starjackBaseTheme';

export const oeamtcTheme: ThemeOptions = {
    ...starjackBaseTheme,
    palette: {
        ...starjackBaseTheme.palette,
        primary: {
            light: 'rgb(255, 231, 0)',
            main: 'rgb(255, 231, 0)',
            dark: 'rgb(255, 231, 0)',
        },

        secondary: {
            light: 'rgb(255, 231, 0)',
            main: '#000',
            dark: 'rgb(255, 231, 0)',
        },
    },

    components: {
        ...starjackBaseTheme.components,
        MuiTextField: {
            styleOverrides: {
                root: () => ({
                    '& label.Mui-focused': {
                        color: '#000',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#000',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#000',
                        },
                        '&:hover fieldset': {
                            borderColor: '#000',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#000',
                        },
                    },
                }),
            },
        },
        MuiLink: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.color === 'primary' && {
                        color: '#000',
                        textDecorationColor: '#000',
                    }),
                }),
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'text' && {
                        color: '#000',
                    }),
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'primary' && {
                            borderColor: '#000',
                            color: '#000',

                            '&:hover': {
                                borderColor: '#000',
                                color: '#000',
                            },
                        }),

                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                            color: '#000',
                        }),

                    ...(ownerState.color === 'secondary' && {
                        backgroundColor: '#000',

                        '&:hover': {
                            backgroundColor: '#000',
                        },
                    }),
                }),
            },
        },
    },
};
