import { FunctionComponent, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import { useTranslation } from '@/hooks/useTranslation';
import { useNotification } from '@/hooks/useNotification';
import { FlexContainer } from '@/components/Layout/Container';
import { usePostCardCodeUsageMutation } from '@/api/order';

type PromotionCodeDialogProps = {
    onCancel: () => void;
    keycardId: string;
};

export const PromotionCodeDialog: FunctionComponent<
    React.PropsWithChildren<PromotionCodeDialogProps>
> = ({ onCancel, keycardId }) => {
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const [code, setCode] = useState('');
    const [postCardCodeUsage] = usePostCardCodeUsageMutation();

    const onEnterPromotionCode = () => {
        if (code) {
            postCardCodeUsage({ code, keycardId })
                .unwrap()
                .then((response) => {
                    if (response.ok) {
                        addNotification({
                            message: getTranslated('Promotion.codeAdd'),
                            type: 'success',
                        });
                    } else {
                        addNotification({
                            message: getTranslated(
                                'validation.error.verificationFailed'
                            ),
                            type: 'error',
                        });
                    }
                })
                .catch(() => {
                    addNotification({
                        message: getTranslated('Request.error.internal'),
                        type: 'error',
                    });
                })
                .finally(() => {
                    onCancel();
                });
        }
    };

    return (
        <>
            <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
                {getTranslated('MyCardsPage.verify')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {getTranslated('Verify.message')}
                </DialogContentText>

                <FlexContainer>
                    <TextField
                        variant='outlined'
                        fullWidth
                        onChange={(e) => {
                            const { value } = e.target;

                            setCode(value);
                        }}
                    />
                </FlexContainer>
            </DialogContent>
            <DialogActions>
                <Button isLoading={false} onClick={onCancel} color='primary'>
                    {getTranslated('Core.cancel')}
                </Button>
                <Button
                    isLoading={false}
                    variant='contained'
                    onClick={onEnterPromotionCode}
                    color='primary'
                >
                    {getTranslated('VerifyForm.verify')}
                </Button>
            </DialogActions>
        </>
    );
};
