import { SnackbarNotification } from '@/models/notification';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NotificationState = {
    currentNotification: SnackbarNotification;
    next: SnackbarNotification[];
};

const initialState = {
    currentNotification: null,
    next: [],
};

export const notificationSlice = createSlice({
    name: 'notificationSlice',

    initialState,

    reducers: {
        addNotification(
            state: NotificationState,
            action: PayloadAction<SnackbarNotification>
        ) {
            const notification = action.payload;

            if (!state.currentNotification) {
                state.currentNotification = notification;
            } else {
                state.next.push(notification);
            }

            return state;
        },

        consumeNext(state: NotificationState) {
            if (state.next.length) {
                state.currentNotification = state.next.shift();
            } else {
                state.currentNotification = null;
            }

            return state;
        },

        clearNotifications() {
            return initialState;
        },
    },
});
