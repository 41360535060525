import { useState } from 'react';
import { LiftOperator } from '@/models/liftOperators';
import { FlexContainer } from '@/components/Layout/Container';
import { LiftOperatorSidebarContainer } from '@/components/LiftOperatorSidebar/LiftOperatorSidebarContainer';
import { GoogleMaps } from '@/components/GoogleMaps/GoogleMaps';
import { useLocation } from 'react-router-dom';
import { useGetLiftOperatorsQuery } from '@/api/liftOperator';
import {
    OeamtcBanner,
    oeamtcBannerHeight,
} from '@/components/OeamtcBanner/OeamtcBanner';
import { useConfigSlice } from '@/hooks/useConfigSlice';

export type LiftOperatorLocationState = {
    placeId: string;
    description: string;
};

export const LiftOperatorsPageContainer = () => {
    const { data: liftOperators } = useGetLiftOperatorsQuery();
    const { IS_OEAMTC } = useConfigSlice();

    const [filteredLiftOperators, setFilteredLiftOperators] = useState<
        LiftOperator[]
    >([]);
    const [selectedLiftOperator, setSelectedLiftOperator] =
        useState<LiftOperator>(null);
    const location = useLocation();

    return (
        <FlexContainer
            sx={(theme) => ({
                position: 'absolute',
                top: theme.appBar.height,
                left: 0,
                right: 0,
                bottom: 0,
            })}
        >
            <FlexContainer
                sx={(theme) => ({
                    position: 'fixed',
                    top: theme.appBar.height,
                    left: 0,
                    bottom: 0,
                    width: '540px',
                    zIndex: 100,
                    ml: 1,
                    py: IS_OEAMTC ? 0 : 1,
                })}
                column
            >
                {IS_OEAMTC && <OeamtcBanner />}

                <FlexContainer
                    sx={{
                        height: IS_OEAMTC
                            ? `calc(100% - ${oeamtcBannerHeight}px)`
                            : '100%',
                        width: '100%',
                    }}
                >
                    <LiftOperatorSidebarContainer
                        selectedLiftOperator={selectedLiftOperator}
                        onLiftOperatorListUpdate={(filteredLiftOperators) => {
                            setSelectedLiftOperator(null);
                            setFilteredLiftOperators(filteredLiftOperators);
                        }}
                        defaultLocation={
                            (location.state as LiftOperatorLocationState) ||
                            null
                        }
                    />
                </FlexContainer>
            </FlexContainer>

            <GoogleMaps
                defaultCenter={{
                    lat: 47.26751358029149,
                    lng: 11.3965715802915,
                }}
                containerStyles={{
                    width: '100%',
                    height: '100%',
                }}
                zoom={4}
                liftOperators={
                    filteredLiftOperators.length
                        ? filteredLiftOperators
                        : liftOperators
                }
            />
        </FlexContainer>
    );
};
