import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';
import { formatDate } from '@/utils/time';
import { Button } from '@/components/Layout/Button/Button';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { CheckoutIdentification } from '@/models/checkout/checkoutIdentification';
import { IdentificationType } from '@/models/cart';
import { useTranslation } from '@/hooks/useTranslation';
import { PromotionButtons } from './PromotionButtons';
import { CheckoutTicket } from '@/models/checkout/checkoutTicket';

type PersonAssignedRowProps = {
    assignedPerson: CheckoutIdentification;
    checkoutTicket: CheckoutTicket;
    onChangeClick: () => void;
};

export const PersonAssignedRow: FunctionComponent<PersonAssignedRowProps> = ({
    checkoutTicket,
    assignedPerson,
    onChangeClick,
}) => {
    const { getTranslated } = useTranslation();

    const getProductionTypeString = () => {
        const { type, serialNumber } = assignedPerson;
        if (
            (type === IdentificationType.KEYCARD ||
                type === IdentificationType.INTERNAL_PRODUCTION) &&
            !!serialNumber
        ) {
            return `${getTranslated(type)}, SN: ${serialNumber.slice(
                0,
                5
            )} ... ${serialNumber.slice(-5)}`;
        }

        return getTranslated(type);
    };

    return (
        <FlexContainer
            sx={{
                alignItems: 'center',
                width: '100%',
                px: 3,
                py: 4,
            }}
        >
            <TaskAltIcon
                color='success'
                sx={{
                    width: '24px',
                    height: '24px',
                    mr: 2,
                }}
            />

            <FlexContainer
                sx={{
                    ml: 2,
                }}
                column
            >
                <Typography>
                    {assignedPerson.firstname}, {assignedPerson.lastname} (
                    {formatDate(assignedPerson.birthday)})
                </Typography>
                <Typography
                    sx={{
                        fontSize: '0.8rem',
                    }}
                >
                    {getProductionTypeString()}
                </Typography>
            </FlexContainer>

            <FlexContainer
                sx={{
                    ml: 'auto',
                    alignItems: 'center',
                }}
            >
                {checkoutTicket.persons.length === 1 && (
                    <PromotionButtons checkoutTicket={checkoutTicket} />
                )}

                <Button
                    onClick={() => {
                        onChangeClick();
                    }}
                    variant='outlined'
                >
                    {getTranslated('Core.change')}
                </Button>
            </FlexContainer>
        </FlexContainer>
    );
};
