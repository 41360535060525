import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { styled } from '@mui/material/styles';
import { Link, LinkProps } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';

const socialIcons = [
    {
        IconComponent: FacebookIcon,
        href: 'https://www.facebook.com/MyStarjack/',
    },
    {
        IconComponent: InstagramIcon,
        href: 'https://www.instagram.com/starjack_onlinetickets/',
    },
    {
        IconComponent: YouTubeIcon,
        href: 'https://www.youtube.com/channel/UCGkqlvPeidwV830vMeKpf6w/featured',
    },
    {
        IconComponent: LinkedIn,
        href: 'https://www.linkedin.com/company/sjack-gmbh-starjack',
    },
];

const IconWrapper = styled(FlexContainer)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
}));

const Icon: FunctionComponent<LinkProps> = styled(Link)(({}) => ({
    cursor: 'pointer',
    textAlign: 'center',
    width: '36px',
    height: '36px',
    color: '#fff',
    transition: 'transform 150ms',

    '&:hover': {
        transform: 'scale(1.2)',
    },
}));

export const SocialIcons = () => {
    return (
        <FlexContainer sx={{ ml: '-12px' }}>
            {socialIcons.map(({ IconComponent, href }, index) => (
                <IconWrapper key={index}>
                    <Icon href={href} target='_blank'>
                        <IconComponent
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </Icon>
                </IconWrapper>
            ))}
        </FlexContainer>
    );
};
