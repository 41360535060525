import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { authSlice, AuthState } from '@/slices/authSlice';
import { ordersSlice, OrderState } from '@/slices/ordersSlice';
import {
    liftOperatorSlice,
    LiftOperatorState,
} from '@/slices/liftOperatorSlice';
import { cartSlice, CartState } from '@/slices/cartSlice';
import { configSlice, ConfigState } from '@/slices/configSlice';
import {
    notificationSlice,
    NotificationState,
} from '@/slices/notificationSlice';
import { infoSlice, InfoState } from '@/slices/infoSlice';
import { checkoutSlice, CheckoutState } from '@/slices/checkoutSlice';
import { NODE_ENV } from '@/constants';
import { baseApi, baseApiAuthenticated } from '@/api';

const reducer = {
    auth: authSlice.reducer,
    orders: ordersSlice.reducer,
    liftOperator: liftOperatorSlice.reducer,
    cart: cartSlice.reducer,
    config: configSlice.reducer,
    notification: notificationSlice.reducer,
    info: infoSlice.reducer,
    checkout: checkoutSlice.reducer,
    [baseApiAuthenticated.reducerPath]: baseApiAuthenticated.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
};

export type Store = {
    auth: AuthState;
    orders: OrderState;
    liftOperator: LiftOperatorState;
    cart: CartState;
    config: ConfigState;
    notification: NotificationState;
    info: InfoState;
    checkout: CheckoutState;
};

export const store: EnhancedStore<Store> = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            thunk: {
                extraArgument: '',
            },
        }).concat(baseApiAuthenticated.middleware, baseApi.middleware),
    devTools: NODE_ENV === 'development',
});
