import { LiftOperator } from '@/models/liftOperators';
import { Product } from '@/models/product';

export type ProductCollection = {
    productCollectionId: string;

    products: Product[];

    // data that is same for all products and can be leveled up to collection
    liftOperator: LiftOperator;
    duration: Product['duration'];
    type: Product['type'];
    name: Product['name'];
    startDay: Product['startDay'];
    validity: Product['validity'];
    filterTags: Product['filterTags'];
    infoText: Product['infoText'];
    photoRequired: Product['photoRequired'];
    sortKey: Product['sortKey'];
    currency: string;

    isPackage?: boolean;
    hasDiscount: boolean; // true if one of the products has discount price
};

export const buildProductCollectionsFromProducts = (
    products: Product[],
    liftOperator: LiftOperator
): ProductCollection[] => {
    const collection: Record<string, ProductCollection> = {};

    products.reduce((collection, product) => {
        const { shortProductId, persons, cancellationBlocked } = product;

        const productKey = `${shortProductId}_cB=${cancellationBlocked}`;

        if (collection[productKey]) {
            collection[productKey].products.push(product);

            const { hasDiscount } = collection[productKey];

            // only check for discount if so fare no product contains a discount
            // if there has been a product with discount before it is already set to true
            if (!hasDiscount) {
                collection[productKey].hasDiscount =
                    product.price.amount !== product.originalPrice.amount;
            }
        } else {
            collection[productKey] = {
                productCollectionId: productKey,
                products: [product],
                liftOperator,
                duration: product.duration,
                type: product.type,
                name: product.name,
                startDay: product.startDay,
                validity: product.validity,
                filterTags: product.filterTags,
                infoText: product.infoText,
                photoRequired: product.photoRequired,
                sortKey: product.sortKey,
                currency: product.price.currency,

                isPackage: persons.length > 1,
                hasDiscount:
                    product.price.amount !== product.originalPrice.amount,
            };
        }

        return collection;
    }, collection);

    return Object.values(collection);
};

export const getPackageProductCollectionPrice = (
    productCollection: ProductCollection
) => {
    if (!productCollection.isPackage) return null;

    return productCollection.products[0].price;
};
