import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { SubPageTemplate } from '@/pages/SubPageTemplate';

import { MergeAccount } from '@/components/PageContent/OAuthMergeCreatePageContent/MergeAccount';
import { CreateAccountForm } from '@/components/PageContent/OAuthMergeCreatePageContent/CreateAccountForm';
import { OAuthCreateUserData } from '@/models/user';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { Typography } from '@mui/material';
import { FlexContainer, LoadingContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import { getFromLocalStorage } from '@/utils/storage';
import { OAUTH_REDIRECT_FROM_KEY } from '@/constants/auth';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../theme/theme';
import {
    PostOAuthAuthResponse,
    usePostOauthAuthenticationMutation,
    usePostOAuthCreateAccountMutation,
    usePostOAuthLinkAccountMutation,
    usePostOauthMergeAccountMutation,
} from '@/api/authentication';
import { useSlice } from '@/hooks/useSlice';
import { authSlice } from '@/slices/authSlice';
import { useAuthentication } from '@/hooks/useAuthentication';

type ResponseType = 'merge' | 'create' | 'login' | 'link';

const useStyles = makeStyles((theme: Theme) => ({
    redirectionLabel: {
        marginBottom: theme.spacing(4),
    },
    loadingContainer: {
        marginTop: theme.spacing(4),
    },
}));

export const OAuthMergeCreatePage: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => {
    const {
        actions: { setAuthData },
    } = useSlice(authSlice, 'auth');
    const { isLoggedIn } = useAuthentication();
    const [postMergeOAuthAccount] = usePostOauthMergeAccountMutation();
    const [postCreateOAuthAccont] = usePostOAuthCreateAccountMutation();
    const [postOauthAuthentication] = usePostOauthAuthenticationMutation();
    const [postOauthLinkAccount] = usePostOAuthLinkAccountMutation();
    const { getTranslated } = useTranslation();
    const [authResponse, setAuthResponse] =
        useState<PostOAuthAuthResponse>(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const responseType: ResponseType = useMemo(() => {
        if (!!authResponse?.mergeAccount) return 'merge';
        if (!!authResponse?.createAccount) return 'create';
        if (!!authResponse?.login) return 'login';
        if (!!authResponse?.linkAccount) return 'link';
        return null;
    }, [authResponse]);
    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();
    const classes = useStyles();

    const authenticate = async () => {
        const urlParams = new URLSearchParams(window.location.search);

        await postOauthAuthentication({
            code: urlParams.get('code'),
            state: urlParams.get('state'),
        })
            .unwrap()
            .then((response) => {
                setAuthResponse(response);
            });
    };

    const onFormSubmit = async (form?: OAuthCreateUserData) => {
        setFormSubmitted(true);
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get('state');
        switch (responseType) {
            case 'merge': {
                const { accessToken } = authResponse.mergeAccount;
                postMergeOAuthAccount({ state, accessToken });
                break;
            }
            case 'create': {
                const { accessToken } = authResponse.createAccount;
                postCreateOAuthAccont({ ...form, state, accessToken });
                break;
            }

            case 'login': {
                const { jwt, refreshCode, validFor } = authResponse.login;
                setAuthData({
                    token: jwt,
                    longLiveToken: '',
                    refreshToken: refreshCode,
                    validFor,
                    uuid: '',
                    email: '',
                });

                break;
            }

            case 'link': {
                const { accessToken } = authResponse.linkAccount;
                postOauthLinkAccount({ state, accessToken })
                    .then(() => {
                        navigate(getPagePath(routerConfig.MyData));
                    })
                    .catch(() => {
                        console.error('Error linking account');
                    });
            }
        }
    };

    const renderAuthComponent = () => {
        switch (responseType) {
            case 'merge':
                return <MergeAccount onSubmit={onFormSubmit} />;
            case 'create':
                return (
                    <CreateAccountForm
                        initialValues={authResponse.createAccount.userData}
                        onSubmit={onFormSubmit}
                    />
                );

            default:
                return (
                    <FlexContainer column center>
                        <Typography className={classes.redirectionLabel}>
                            {getTranslated('Oauth.redirect')}
                        </Typography>
                        <LoadingContainer
                            className={classes.loadingContainer}
                        />
                    </FlexContainer>
                );
        }
    };

    useEffect(() => {
        authenticate();
    }, []);

    useEffect(() => {
        if (
            (responseType === 'login' || responseType === 'link') &&
            !formSubmitted
        ) {
            onFormSubmit();
        }
    }, [authResponse]);

    useEffect(() => {
        if (isLoggedIn && responseType && responseType !== 'link') {
            const fromLocation = getFromLocalStorage(OAUTH_REDIRECT_FROM_KEY);

            navigate(fromLocation ?? getPagePath(routerConfig.Home));
        }
    }, [isLoggedIn, responseType]);

    return (
        <SubPageTemplate title={getTranslated('Oauth.registerLoginTitle')}>
            {renderAuthComponent()}
        </SubPageTemplate>
    );
};
