import { LiftOperatorInfoMobile } from '@/components/PageContent/ProductsPageContent/ProductsOverviewMobile/LiftOperatorInfoMobile';
import { FlexContainer } from '@/components/Layout/Container';
import { ProductCatalogContainer } from '@/components/PageContent/ProductsPageContent/ProductCatalogContainer';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';

export const ProductFilterStepperWithCatalogContainer = () => {
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { IS_PORTAL } = useConfigSlice();

    return (
        <>
            {IS_PORTAL && (
                <LiftOperatorInfoMobile liftOperator={selectedLiftOperator} />
            )}

            <FlexContainer
                sx={(theme) => ({
                    flexDirection: 'column',
                    mx: 2,
                    pb: `${theme.appBar.height * 1.5}px`,
                })}
            >
                <ProductCatalogContainer />
            </FlexContainer>
        </>
    );
};
