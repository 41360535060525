import { FunctionComponent, useEffect, useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { Theme } from '@/theme/theme';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '@/constants';
import { useTranslation } from '@/hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) => ({
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    gridItem: {
        // padding: theme.spacing(4),
    },

    titleText: {
        paddingBottom: theme.spacing(),
    },

    textError: {
        color: theme.palette.error.main,
    },

    captionText: {
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
    },
}));

type InputState = { day: string; month: string; year: string };

const validateInputNumbers = (
    val: number,
    entity: keyof InputState
): number => {
    switch (entity) {
        case 'day':
            if (val < 0) return 0;
            else if (val > 31) return 31;
            else return val;
        case 'month':
            if (val < 0) return 1;
            else if (val > 12) return 12;
            else return val;

        case 'year':
            if (val < 1900) return 1900;
            else if (val > 2100) return 2100;
            else return val;
    }
};

type InputBirthdayProps = {
    className?: string;
    error?: string;
    value?: string;
    onChange?: (dateOfBirth: string) => void;
    onBlur?: (dateOfBirth: string) => void;
    onChangeParsed?: (dateOfBirth: Moment) => void;
    displayOnly?: boolean;
};

export const InputBirthday: FunctionComponent<
    React.PropsWithChildren<InputBirthdayProps>
> = ({
    className,
    error,
    onChange,
    onChangeParsed,
    onBlur,
    value,
    displayOnly = false,
}) => {
    const classes = useStyles();
    const { getTranslated } = useTranslation();

    const [internalError, setInternalError] = useState<string>(error);
    const [birthdayInput, setBirthDayInput] = useState<InputState>({
        day: null,
        month: null,
        year: null,
    });
    const hasError = !!internalError || !!error;

    const onBirthDateChange = () => {
        const hasAllVals = Object.values(birthdayInput).every(
            (value) => !!value
        );

        if (hasAllVals) {
            const { day, month, year } = birthdayInput;
            const date = moment(`${day}.${month}.${year}`, 'DD.MM.YYYY');

            if (date.isValid()) {
                setInternalError('');

                if (onChangeParsed) {
                    onChangeParsed(date);
                }

                if (onChange) {
                    onChange(date.format(DATE_FORMAT));
                }

                if (onBlur) {
                    onBlur(date.format(DATE_FORMAT));
                }
            } else {
                setInternalError(
                    getTranslated('Core.birthday.invalidBirthday')
                );
            }
        }
    };

    const handleInputChange = (key: keyof InputState) => (e) => {
        const val = e.target.value;

        setBirthDayInput((prevState) => ({
            ...prevState,
            [key]: val,
        }));
    };

    const handleInputBlur = (key: keyof InputState) => (e) => {
        const val = e.target.value;

        if (val) {
            setBirthDayInput((prevState) => ({
                ...prevState,
                [key]: validateInputNumbers(parseInt(val), key),
            }));
        }

        onBirthDateChange();
    };

    useEffect(() => {
        if (value) {
            const parsed = moment(value, DATE_FORMAT);
            if (parsed.isValid()) {
                // problems with moment function. Split the string and parse it separatyl
                const [day, month, year] = value.split('.');

                setBirthDayInput({
                    day,
                    month,
                    year,
                });
            }
        }
    }, [value]);

    return (
        <FlexContainer
            className={classNames(className)}
            flexDirection='column'
            justifyContent='center'
        >
            <Typography
                className={classNames(classes.titleText, {
                    [classes.textError]: hasError,
                })}
                variant='caption'
            >
                {getTranslated('Core.birthday')}
            </Typography>

            <Grid className={classes.gridContainer} spacing={3} container>
                <Grid className={classes.gridItem} xs={4} item>
                    <TextField
                        tabIndex={0}
                        onChange={handleInputChange('day')}
                        onBlur={handleInputBlur('day')}
                        variant='outlined'
                        type='number'
                        error={hasError}
                        value={birthdayInput.day ?? ''}
                        label={getTranslated('Core.birthday.dayFormat')}
                        disabled={displayOnly}
                    />
                </Grid>
                <Grid className={classes.gridItem} xs={4} item>
                    <TextField
                        tabIndex={1}
                        onChange={handleInputChange('month')}
                        onBlur={handleInputBlur('month')}
                        variant='outlined'
                        type='number'
                        value={birthdayInput.month ?? ''}
                        error={hasError}
                        label={getTranslated('Core.birthday.monthFormat')}
                        disabled={displayOnly}
                    />
                </Grid>
                <Grid xs={4} item>
                    <TextField
                        tabIndex={2}
                        onChange={handleInputChange('year')}
                        onBlur={handleInputBlur('year')}
                        variant='outlined'
                        type='number'
                        value={birthdayInput.year ?? ''}
                        error={hasError}
                        label={getTranslated('Core.birthday.yearFormat')}
                        disabled={displayOnly}
                    />
                </Grid>
            </Grid>

            {error && (
                <Typography
                    className={classes.captionText}
                    variant='caption'
                    color='error'
                >
                    {error}
                </Typography>
            )}

            {internalError && (
                <Typography
                    className={classes.captionText}
                    variant='caption'
                    color='error'
                >
                    {internalError}
                </Typography>
            )}
        </FlexContainer>
    );
};
