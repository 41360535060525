import { FunctionComponent, useState } from 'react';

import {
    Button,
    DialogActions,
    DialogContent,
    Stack,
    TextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTranslation } from '@/hooks/useTranslation';
import {
    useGetCurrentCheckoutSession,
    usePostCheckoutCustomerMutation,
} from '@/api/checkout';
import { LoadingButton } from '@mui/lab';
import { useNotification } from '@/hooks/useNotification';
import Dialog from '@mui/material/Dialog';
import { DialogTitle } from '@/components/Dialog/DialogTite';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment/moment';

type CheckoutInsuranceContactDialogProps = {
    open: boolean;
    onClose: (reason: 'submit' | 'abort') => void;
};

export const CheckoutInsuranceContactDialog: FunctionComponent<
    CheckoutInsuranceContactDialogProps
> = ({ open, onClose }) => {
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const [phoneInput, setPhoneInput] = useState<string | null>(
        checkoutSession?.phone || ''
    );
    const [birthdate, setBirthdate] = useState<Moment | null>(
        checkoutSession?.birthdate || null
    );
    const [inputError, setInputError] = useState<('phone' | 'birthdate')[]>([]);

    const { getTranslated } = useTranslation();
    const [postContact, { isLoading }] = usePostCheckoutCustomerMutation();
    const { addNotification } = useNotification();

    const savePhoneNumber = () => {
        if (!phoneInput) {
            setInputError((prev) => [...prev, 'phone']);
            return;
        }

        if (!birthdate) {
            setInputError((prev) => [...prev, 'birthdate']);
            return;
        }
        postContact({
            phone: phoneInput,
            birthdate,
            checkoutId: checkoutSession.id,
        })
            .unwrap()
            .then(() => {
                onClose('submit');
            })
            .catch(() => {
                addNotification({
                    type: 'error',
                    message: 'Fehler beim speichern',
                });
            });
    };

    return (
        <Dialog maxWidth='md' open={open} onClose={onClose}>
            <DialogTitle
                title={getTranslated(
                    'CheckoutPage.insurance.contactData.dialog.title'
                )}
                onClose={() => onClose('abort')}
            />
            <DialogContent>
                <Stack spacing={4}>
                    <Alert severity='warning'>
                        {getTranslated(
                            'CheckoutPage.insurance.contatctData.warning'
                        )}
                    </Alert>

                    <TextField
                        label={getTranslated(
                            'CheckoutPage.insurance.input.phone.label'
                        )}
                        value={phoneInput}
                        sx={{
                            width: '100%',
                        }}
                        onChange={(e) => {
                            const { value } = e.target;

                            if (value && inputError.includes('phone')) {
                                setInputError((prev) =>
                                    prev.filter((e) => e !== 'phone')
                                );
                            }

                            setPhoneInput(value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                savePhoneNumber();
                            }
                        }}
                        variant='outlined'
                        error={inputError.includes('phone')}
                        helperText={
                            inputError.includes('phone')
                                ? getTranslated(
                                      'CheckoutPage.insurance.input.phone.error'
                                  )
                                : ''
                        }
                    />

                    <DatePicker
                        sx={{
                            width: '100%',
                        }}
                        value={birthdate}
                        onChange={(value) => {
                            if (value && inputError.includes('birthdate')) {
                                setInputError((prev) =>
                                    prev.filter((e) => e !== 'birthdate')
                                );
                            }

                            setBirthdate(value);
                        }}
                        label={getTranslated('Core.birthday')}
                        name='birthdate-insurance-dialog'
                        slotProps={{
                            textField: {
                                error: inputError.includes('birthdate'),
                                helperText: inputError.includes('birthdate')
                                    ? getTranslated(
                                          'CheckoutPage.insurance.input.birthdate.error'
                                      )
                                    : '',
                            },
                        }}
                        disableFuture
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='outlined'
                    onClick={() => {
                        onClose('abort');
                    }}
                >
                    {getTranslated('Core.cancel')}
                </Button>

                <LoadingButton
                    onClick={() => {
                        savePhoneNumber();
                    }}
                    loading={isLoading}
                    variant='contained'
                >
                    {getTranslated('common.save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
