import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PercentIcon from '@mui/icons-material/Percent';
import { FlexContainer } from '@/components/Layout/Container';
import blueGrey from '@mui/material/colors/blueGrey';
import { useTranslation } from '@/hooks/useTranslation';
import { ProductCollection } from '@/models/collection/productCollection';
import { CheckoutTicketCollection } from '@/models/collection/checkoutTicketCollection';

type TicketExtraDataProps = {
    collection: ProductCollection | CheckoutTicketCollection;
};

export const TicketExtraData: FunctionComponent<TicketExtraDataProps> = ({
    collection,
}) => {
    const { getTranslated } = useTranslation();
    const hasDiscount = collection.hasDiscount;

    if (!collection.photoRequired && !hasDiscount) return null;

    return (
        <FlexContainer
            sx={() => ({
                position: 'relative',
                width: '100%',
                px: 3,
                py: 2,
            })}
        >
            <FlexContainer
                sx={{
                    alignItems: 'center',
                    width: '80%',
                    flexWrap: 'wrap',
                }}
            >
                {collection.photoRequired && (
                    <FlexContainer
                        sx={{
                            mr: 3,
                        }}
                    >
                        <PhotoCameraIcon
                            sx={() => ({
                                color: blueGrey['800'],
                                mr: 1,
                            })}
                        />
                        <Typography>
                            {getTranslated('Core.photoRequired')}
                        </Typography>
                    </FlexContainer>
                )}
            </FlexContainer>

            {hasDiscount && (
                <FlexContainer
                    sx={(theme) => ({
                        borderTop: `52px solid ${theme.palette.secondary.main}`,
                        borderLeft: `52px solid transparent`,
                        width: 0,
                        height: 0,
                        top: 0,
                        right: 0,
                        position: 'absolute',
                    })}
                    center
                >
                    <PercentIcon
                        sx={{
                            position: 'absolute',
                            top: '-48px',
                            right: '4px',
                            color: '#fff',
                        }}
                    />
                </FlexContainer>
            )}
        </FlexContainer>
    );
};
