import { Discount } from '@/models/discount';
import { baseApiAuthenticated } from '@/api/index';

const AHA_REST_API = 'https://rueckerstattung.3taeler.at';

const promotionUrls = () => ({
    taeler: `${AHA_REST_API}/rest/ticket/discount`,
    oeamtc: 'https://oeamtc.partner.starjack.com/rest/ticket/discount',
});

type BaseTicketDiscountBody = {
    liftoperatorId: string;
    productId: string;
    startDate: string;
};

type AhaTicketDiscountBody = BaseTicketDiscountBody & {
    ahaCard?: string;
    familyPass?: string;
};

type AhaTaelerDiscountResponse = {
    liftoperatorId: string;
    productId: string;
    startDate: string;
    price: number;
    priceCode: string;
    discounts: Discount[];
    priceReason?: string;
    ahaCard?: string;
    familyPass?: string;
    discardPriceConditions?: boolean;
    discardContingentConditions?: boolean;
};

type OeamtcTicketDiscountBody = BaseTicketDiscountBody & {
    oeamtcMemberCode: string;
};

export type PostDiscountErrorResponse = {
    code: number;
    message: string;
};

type OeamtcDiscountResponse = {
    price: number;
    priceCode: string;
    perOrderLimit: number;
    discounts: Discount[];
    priceReason: string;
    discardPriceConditions: boolean;
    discardContingentConditions: boolean;
};

const promotionApi = baseApiAuthenticated.injectEndpoints({
    endpoints: (builder) => ({
        postDiscountAha: builder.mutation<
            AhaTaelerDiscountResponse,
            AhaTicketDiscountBody
        >({
            query: (body) => {
                return {
                    mode: 'cors',
                    url: promotionUrls().taeler,
                    method: 'POST',
                    body,
                };
            },
        }),

        postDiscountOeamtc: builder.mutation<
            OeamtcDiscountResponse,
            OeamtcTicketDiscountBody
        >({
            query: (body) => {
                return {
                    mode: 'cors',
                    url: promotionUrls().oeamtc,
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const { usePostDiscountAhaMutation, usePostDiscountOeamtcMutation } =
    promotionApi;
