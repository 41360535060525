import { ChangeEvent, FunctionComponent, PropsWithChildren } from 'react';
import { Box, Fade, FormControlLabel, Typography } from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import { Address, AddressType } from '@/models/user';

import { FlatCardWithTitle } from '@/components/Card/FlatCardWithTitle';
import { getCountryNameByCode } from '@/constants/countries';
import { useTranslation } from '@/hooks/useTranslation';
import { FlexContainer } from '@/components/Layout/Container';
import { LoadingCheckbox } from '@/components/LoadingCheckbox/LoadingCheckbox';
import { CheckoutSession } from '@/models/checkout/checkoutSession';
import { useNotification } from '@/hooks/useNotification';
import { AddressPreviewLoading } from '@/components/PageContent/CartPageContent/Payment/AddressBlock/AddressPreviewLoading';
import { useDeleteInvoiceAddressMutation } from '@/api/checkout';

type AddressOverviewProps = {
    onChange: (type: AddressType) => void;
    checkoutSession: CheckoutSession;
    shippingSameAsInvoice: boolean;
    onShippingInvoiceChange: (value: boolean) => void;
};

export const AddressPreview: FunctionComponent<
    PropsWithChildren<AddressOverviewProps>
> = ({
    onChange,
    checkoutSession,
    shippingSameAsInvoice,
    onShippingInvoiceChange,
}) => {
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const [deleteAddress, { isLoading: addressUpdateLoading }] =
        useDeleteInvoiceAddressMutation();

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        if (checked) {
            deleteAddress({
                checkoutId: checkoutSession.id,
            })
                .unwrap()
                .then(() => {
                    onShippingInvoiceChange(checked);
                    addNotification({
                        message: getTranslated('invoiceAddressSameAsShipping'),
                        type: 'success',
                    });
                });
        } else {
            onShippingInvoiceChange(checked);
        }
    };

    const extraHeight = !!checkoutSession?.shippingAddress?.company;
    return (
        <FlatCardWithTitle title={getTranslated('deliveryAndBillingAddress')}>
            {!checkoutSession ? (
                <AddressPreviewLoading />
            ) : (
                <>
                    <FlexContainer
                        sx={(theme) => ({
                            height: extraHeight ? '240px' : '220px',
                            transition: `height 150ms ${theme.transitions.easing.bounce}`,

                            ...(!shippingSameAsInvoice && {
                                height:
                                    extraHeight && extraHeight
                                        ? '460px'
                                        : '440px',
                            }),
                        })}
                        column
                        fullWidth
                    >
                        <Typography gutterBottom>
                            {getTranslated('deliveryAddress')}:
                        </Typography>
                        <AddressDisplay
                            address={checkoutSession?.shippingAddress}
                        />

                        <Button
                            sx={{
                                mt: 3,
                            }}
                            variant='outlined'
                            onClick={() => {
                                onChange('shipping');
                            }}
                        >
                            {getTranslated('changeAddress')}
                        </Button>

                        <FlexContainer
                            sx={{
                                mt: 3,
                            }}
                            column
                            fullWidth
                        >
                            <FormControlLabel
                                control={
                                    <LoadingCheckbox
                                        onChange={handleCheckboxChange}
                                        checked={shippingSameAsInvoice}
                                        name='Rechnungsadresse wie Lieferadresse klonk'
                                        loading={addressUpdateLoading}
                                    />
                                }
                                label={getTranslated('billingSameAsDelivery')}
                            />
                        </FlexContainer>

                        <Fade in={!shippingSameAsInvoice}>
                            <FlexContainer column fullWidth>
                                <Typography gutterBottom>
                                    {getTranslated('billingAddress')}:
                                </Typography>

                                <AddressDisplay
                                    address={checkoutSession?.invoiceAddress}
                                />

                                <Button
                                    sx={{
                                        mt: 3,
                                    }}
                                    variant='outlined'
                                    onClick={() => {
                                        onChange('invoice');
                                    }}
                                >
                                    {getTranslated('changeAddress')}
                                </Button>
                            </FlexContainer>
                        </Fade>
                    </FlexContainer>
                </>
            )}
        </FlatCardWithTitle>
    );
};

const AddressDisplay: FunctionComponent<{ address: Address }> = ({
    address,
}) => {
    const { locale } = useTranslation();

    return address === null ? (
        <Box
            sx={{
                py: 2,
                textAlign: 'center',
            }}
        >
            <Typography
                sx={{
                    ml: 2,
                    fontWeight: 600,
                }}
            >
                Noch keine Adresse hinterlegt
            </Typography>
        </Box>
    ) : (
        <FlexContainer
            sx={{
                ml: 2,
            }}
            column
        >
            {!!address.company && (
                <Typography gutterBottom>{address.company}</Typography>
            )}
            <Typography>
                {address.firstname} {address.lastname}
            </Typography>

            {!!address?.street && <Typography>{address.street}</Typography>}

            {!!address?.zipcode && address?.city && (
                <Typography>
                    {address.zipcode} {address.city}
                </Typography>
            )}

            <Typography>
                {getCountryNameByCode(locale, address.country)}
            </Typography>
        </FlexContainer>
    );
};
