import { CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CollectionCardContent = styled(CardContent)(() => ({
    display: 'flex',
    flexDirection: 'column',

    // [theme.breakpoints.down('lg')]: {
    //     padding: `${theme.spacing(2)} !important`,
    // },
}));
