import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { PopoverContent } from '@/components/ProductSelectionFilter/PopoverContent/PopoverContent';
import { LiftOperator } from '@/models/liftOperators';
import { useGetButtonLabel } from '@/components/ProductSelectionFilter/useGetButtonLabel';
import { ProductType } from '@/models/product';

type TicketTypeSelectionContentProps = {
    onSelect: (type: ProductType) => void;
    liftOperator: LiftOperator;
};

export const TicketTypeSelectionContent: FunctionComponent<
    TicketTypeSelectionContentProps
> = ({ onSelect, liftOperator }) => {
    const { dayTicketButtonText, seasonTicketButtonText } = useGetButtonLabel({
        liftOperator,
    });

    return (
        <PopoverContent
            sx={{
                width: '400px',
            }}
        >
            <SelectionContainer
                onClick={() => onSelect(ProductType.DAY)}
                Icon={TodayIcon}
                label={dayTicketButtonText}
            />

            <SelectionContainer
                onClick={() => onSelect(ProductType.SEASON)}
                Icon={DateRangeIcon}
                label={seasonTicketButtonText}
            />
        </PopoverContent>
    );
};

type SelectionContainerProps = {
    onClick: () => void;
    Icon: OverridableComponent<SvgIconTypeMap>;
    label: string;
};

const SelectionContainer: FunctionComponent<SelectionContainerProps> = ({
    label,
    Icon,
    onClick,
}) => (
    <Box
        onClick={onClick}
        sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            height: '80px',
            padding: 4,
            transition: 'background-color 100ms linear',
            borderRadius: '4px',

            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        }}
    >
        <Icon
            sx={{
                fontSize: '2rem',
                mr: 3,
            }}
        />

        <Typography variant='h6'>{label}</Typography>
    </Box>
);
