import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Link, LinkProps, Typography } from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { styled } from '@mui/material/styles';

const FooterLightContainer = styled(FlexContainer)(({ theme }) => ({
    alignItems: 'center',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(4, 10),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap-reverse',
    },
}));

const LegalLinkContainer = styled(FlexContainer)(({ theme }) => ({
    alignItems: 'center',
    marginLeft: theme.spacing(3),
}));

const LegalLink: FunctionComponent<LinkProps> = styled(Link)(({ theme }) => ({
    color: theme.palette.black,
    textDecoration: 'none',
    margin: theme.spacing(0, 2),
}));

const Dot = styled(Typography)(() => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translateY(-20%)',
}));

export const FooterLight = () => {
    const { getTranslated } = useTranslation();
    const { getInPageLinks } = useCustomRouter();
    return (
        <FooterLightContainer>
            <Typography>© SJACK GMBH</Typography>

            <LegalLinkContainer>
                <LegalLink target='_blank' href={getInPageLinks('imprint')}>
                    {getTranslated('footer.imprint')}
                </LegalLink>
                <Dot>.</Dot>
                <LegalLink target='_blank' href={getInPageLinks('gtc')}>
                    {getTranslated('footer.gtc')}
                </LegalLink>
                <Dot>.</Dot>
                <LegalLink target='_blank' href={getInPageLinks('dataPrivacy')}>
                    {getTranslated('footer.dataPrivacy')}
                </LegalLink>
            </LegalLinkContainer>
        </FooterLightContainer>
    );
};
