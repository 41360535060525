import { FunctionComponent, useState } from 'react';
import { LoginType } from '@/models/user';
import { FlexContainer } from '@/components/Layout/Container';
import { MoreLoginOptions } from '@/components/PageContent/MyDataPageContent/MoreLoginOptions';
import { difference } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@/theme/theme';
import { DeleteAccountContainer } from '@/components/PageContent/MyDataPageContent/DeleteAccountContainer';
import { LoginVariantContainer } from '@/components/PageContent/MyDataPageContent/LoginVariantContainer';

import { useGetLoginsQuery } from '@/api/authentication';

const AVAILABLE_ADDITIONAL_LOGINS = [LoginType.FACEBOOK, LoginType.GOOGLE];

const useStyles = makeStyles((theme: Theme) => ({
    moreLoginsContainer: {
        marginTop: theme.spacing(4),
    },
}));

export const LoginsListContainer: FunctionComponent = () => {
    const [showModal, setShowModal] = useState<{
        showWithConfirmation: boolean;
    }>(null);
    const { data: loginInformation = [] } = useGetLoginsQuery();

    const currentLoginTypes = loginInformation.map((lI) => lI.type);
    const leftLoginOptions = difference(
        AVAILABLE_ADDITIONAL_LOGINS,
        currentLoginTypes
    );

    const classes = useStyles();

    return (
        <FlexContainer column fullWidth>
            <FlexContainer column fullWidth>
                {loginInformation.map((lI, index) => (
                    <LoginVariantContainer
                        key={index}
                        loginInformationItem={lI}
                        onRemoveEmailAccount={() => {
                            setShowModal({
                                showWithConfirmation: true,
                            });
                        }}
                    />
                ))}
            </FlexContainer>

            {!!leftLoginOptions.length && (
                <FlexContainer className={classes.moreLoginsContainer}>
                    <MoreLoginOptions moreLoginOptions={leftLoginOptions} />
                </FlexContainer>
            )}

            <DeleteAccountContainer
                isOpen={!!showModal}
                onClose={() => setShowModal(null)}
                confirmWithPassword={showModal?.showWithConfirmation}
            />
        </FlexContainer>
    );
};
