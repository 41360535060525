import { FunctionComponent, useMemo, useRef } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { QuantityCounter } from '@/components/Quantity/QuantityCounter';
import { CollectionCardPriceLine } from '@/components/CollectionCard/common/CollectionCardPriceLine';
import { getPropByCurrentLocale } from '@/utils/locale';
import { Typography } from '@mui/material';
import { DateRange } from '@/models/general';
import { ProductCollection } from '@/models/collection/productCollection';
import moment from 'moment/moment';
import { DATE_FORMAT, DATE_FORMAT_API } from '@/constants';
import {
    usePostCheckoutTicketMutation,
    useDeleteCheckoutTicketMutation,
    useGetCurrentCheckoutSession,
    CheckoutMuationCacheKeys,
} from '@/api/checkout';
import { useSlice } from '@/hooks/useSlice';
import { checkoutSlice } from '@/slices/checkoutSlice';
import { Moment } from 'moment';
import { TicketNotRefundableLine } from '@/components/Ticket/TicketNotRefundableLine';
import { useTranslation } from '@/hooks/useTranslation';

const getUniqueProductIdentifier = (productId: string, date: Moment) =>
    `${productId}_${date.format(DATE_FORMAT_API)}`;

const getBirthdayAsString = (dateRange: DateRange) => {
    if (!!dateRange?.from && !!dateRange?.to) {
        return `(${moment(dateRange?.from).format(DATE_FORMAT)} - ${moment(
            dateRange.to
        ).format(DATE_FORMAT)})`;
    } else if (!!dateRange?.from && !dateRange?.to) {
        return `(${moment(dateRange.from).format(DATE_FORMAT)})`;
    } else return '';
};

type ProductQuantityControlProps = {
    productCollection: ProductCollection;
};

export const ProductQuantityControl: FunctionComponent<
    ProductQuantityControlProps
> = ({ productCollection }) => {
    const { products, liftOperator } = productCollection;
    const {
        state: { checkoutId },
    } = useSlice(checkoutSlice, 'checkout');
    const { data: checkoutResponse } = useGetCurrentCheckoutSession();
    const { getTranslated } = useTranslation();
    const [deleteTicket, { isLoading: deleteLoading }] =
        useDeleteCheckoutTicketMutation({
            fixedCacheKey:
                CheckoutMuationCacheKeys.DELETE_FROM_CHECKOUT_SESSION,
        });
    const [postTicket, { isLoading: postLoading }] =
        usePostCheckoutTicketMutation({
            fixedCacheKey: CheckoutMuationCacheKeys.PUT_CHECKOUT_SESSION,
        });

    const currentMutationId = useRef<string | null>(null);

    if (currentMutationId.current && !deleteLoading && !postLoading) {
        currentMutationId.current = null;
    }

    const productsInCheckoutMap = useMemo(() => {
        const productsInCheckoutMap: Record<string, number> = {};

        if (!checkoutResponse) return productsInCheckoutMap;

        return checkoutResponse.tickets.reduce<Record<string, number>>(
            (map, nextTicket) => {
                const productIdentifier = getUniqueProductIdentifier(
                    nextTicket.productId,
                    nextTicket.startDate
                );
                const quantity = map[productIdentifier] || 0;

                map[productIdentifier] = quantity + 1;

                return map;
            },
            {}
        );
    }, [checkoutResponse]);

    const productsSortedByPerson = products.sort((productA, productB) => {
        const { birthday: birthdayA } = productA.persons[0];
        const { birthday: birthdayB } = productB.persons[0];

        if (birthdayA?.from && birthdayB?.from) {
            return birthdayA.from.unix() - birthdayB.from.unix();
        }

        return 0;
    });

    const getAvailableContingentString = (availableContingent = 0) => {
        if (availableContingent === null || availableContingent === undefined) {
            return '';
        }

        if (availableContingent === 0)
            return getTranslated('Core.noContingentLeft');

        return getTranslated('Core.contingent', {
            tickets: availableContingent,
        });
    };

    return (
        <FlexContainer column>
            {productsSortedByPerson.map(
                ({
                    productId,
                    startDay,
                    persons,
                    availableContingent,
                    externalConditionNames,
                    cancellationBlocked,
                    price,
                    originalPrice,
                }) => (
                    <FlexContainer
                        key={productId}
                        sx={(theme) => ({
                            alignItems: 'center',

                            [theme.breakpoints.down('lg')]: {
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                            },
                        })}
                    >
                        <QuantityCounter
                            value={
                                productsInCheckoutMap[
                                    getUniqueProductIdentifier(
                                        productId,
                                        startDay
                                    )
                                ] || 0
                            }
                            allowNegative={false}
                            disabled={
                                availableContingent === 0 ||
                                currentMutationId.current ===
                                    getUniqueProductIdentifier(
                                        productId,
                                        startDay
                                    )
                            }
                            min={0}
                            max={availableContingent}
                            onCounterChange={(_, action) => {
                                if (checkoutResponse) {
                                    currentMutationId.current =
                                        getUniqueProductIdentifier(
                                            productId,
                                            startDay
                                        );
                                    if (action === 'subtract') {
                                        // first ticket with same product id shall be deleted
                                        const ticket =
                                            checkoutResponse.tickets.find(
                                                (ticket) =>
                                                    ticket.productId ===
                                                    productId
                                            );

                                        if (ticket) {
                                            deleteTicket({
                                                checkoutId: checkoutId,
                                                ticketId: ticket.id,
                                            });
                                        }
                                    } else {
                                        postTicket({
                                            checkoutId: checkoutId,
                                            liftOperator: liftOperator.id,
                                            productId: productId,
                                            startDate:
                                                startDay.format(
                                                    DATE_FORMAT_API
                                                ),
                                        });
                                    }
                                }
                            }}
                        />

                        <CollectionCardPriceLine
                            title={getPropByCurrentLocale(persons[0].name)}
                            subtitle={getBirthdayAsString(persons[0].birthday)}
                            subtitle2={externalConditionNames}
                            subtitle3={
                                <FlexContainer
                                    sx={(theme) => ({
                                        ml: 1,
                                        [theme.breakpoints.down('lg')]: {
                                            ml: 3,
                                        },
                                    })}
                                    column
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '0.85rem',
                                        }}
                                        color='error'
                                    >
                                        {getAvailableContingentString(
                                            availableContingent
                                        )}
                                    </Typography>

                                    {cancellationBlocked && (
                                        <TicketNotRefundableLine />
                                    )}
                                </FlexContainer>
                            }
                            price={price}
                            originalPrice={originalPrice}
                        />
                    </FlexContainer>
                )
            )}
        </FlexContainer>
    );
};
