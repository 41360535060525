import {
    FunctionComponent,
    PropsWithChildren,
    useEffect,
    useState,
} from 'react';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { useLocalStorageCopy } from '@/hooks/useLocalStorageCopy';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { buildTheme } from './theme/theme';
import { configSlice, PortalConfig } from '@/slices/configSlice';

import { GOOGLE_MAP_API_KEY, OEAMTC_ID } from '@/constants';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { useSlice } from '@/hooks/useSlice';
import { setPortalConfig } from '@/pageConfiguration';

import moment from 'moment';

import 'moment/dist/locale/de.js';
import { useInitializeCheckoutSession } from '@/api/checkout';
import { ProductEnvironment } from '@/models/order';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { CssBaseline, GlobalStyles } from '@mui/material';

// use this container to do any initializing

const loadScript = (url: string, onLoad: () => void) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    script.src = url;

    script.onload = () => {
        onLoad();
    };

    document.getElementsByTagName('head')[0].appendChild(script);
};

const getOnePageOperator = () => {
    const hostnameSplit = window.location.hostname.split('.');

    if (hostnameSplit.length > 3 && hostnameSplit[1] === 'shop') {
        return hostnameSplit[0];
    }

    return null;
};

export const Initialize: FunctionComponent<PropsWithChildren> = ({
    children,
}) => {
    const {
        pageConfig,
        setPageConfig,
        pageConfigFactory,
        setConfiguration,
        locale,
        setGoogleMapsLoaded,
    } = useConfigSlice();
    const {
        actions: { setIsOeamtc, setProductEnvironment },
    } = useSlice(configSlice, 'config');

    const {
        actions: { setSelectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const [, setMomentLocale] = useState(locale);
    const [pageConfigSet, setPageConfigSet] = useState(false);

    const operator =
        import.meta.env.VITE_LIFT_OPEREATOR || getOnePageOperator();

    if (!operator && !pageConfigSet) {
        setPageConfigSet(true);
    }

    useEffect(() => {
        moment.locale(locale);
        setMomentLocale(locale);
    }, [locale]);

    useEffect(() => {
        // this should happen before first render
        // if pageConfig is empty but operator is defined means we are on first render of a onepage
        if (!pageConfig && operator) {
            // check if operator is a partner

            // operator is a webshop integration
            setIsOeamtc(operator === OEAMTC_ID);
            const urlParams = new URLSearchParams(window.location.search);
            pageConfigFactory(operator, urlParams.get('tags')?.split(',')).then(
                ({ pageConfig, liftOperator }) => {
                    setPageConfig(pageConfig);

                    if (operator !== OEAMTC_ID) {
                        setSelectedLiftOperator(liftOperator);
                    }

                    setConfiguration(
                        pageConfig && operator !== OEAMTC_ID
                            ? PortalConfig.INTEGRATION_CONFIGURATION
                            : PortalConfig.PORTAL_CONFIGURATION
                    );

                    if (pageConfig && operator !== OEAMTC_ID) {
                        setProductEnvironment(ProductEnvironment.INTEGRATION);
                    }

                    setPortalConfig(
                        pageConfig && operator !== OEAMTC_ID
                            ? PortalConfig.INTEGRATION_CONFIGURATION
                            : PortalConfig.PORTAL_CONFIGURATION
                    );

                    setPageConfigSet(true);
                }
            );
        } else {
            setProductEnvironment(ProductEnvironment.PORTAL);
        }
    }, [operator]);

    useEffect(() => {
        // implement google maps api on start

        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`,
            () => {
                setGoogleMapsLoaded(true);
            }
        );
    }, []);

    // initialize a checkout session
    useInitializeCheckoutSession(!pageConfigSet);
    useLocalStorageCopy('cart', ['cartErrors']);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider
                theme={buildTheme(pageConfig, operator === OEAMTC_ID, locale)}
            >
                <CssBaseline />

                <GlobalStyles
                    styles={{
                        body: {
                            '--insurance-component-font-family':
                                'Roboto, sans-serif',
                        },
                    }}
                />

                <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale='de'
                >
                    <>{pageConfigSet ? children : null}</>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
