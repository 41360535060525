import {
    ConfirmationDialog,
    ConfirmationDialogProps,
} from '@/components/Dialogs/v2/ConfirmationDialog';
import {
    InformationDialog,
    InformationDialogProps,
} from '@/components/Dialogs/v2/InformationDialog';
import { FunctionComponent } from 'react';
import {
    DeleteConfirmationDialog,
    DeleteConfirmationDialogProps,
} from '@/components/Dialogs/v2/DeleteConfirmationDialog';

type ModalType = 'confirmation' | 'information' | 'delete';

const modalFactory = (type: ModalType) => {
    switch (type) {
        case 'confirmation':
            return ConfirmationDialog as FunctionComponent<
                React.PropsWithChildren<ConfirmationDialogProps>
            >;
        case 'information':
            return InformationDialog as FunctionComponent<
                React.PropsWithChildren<InformationDialogProps>
            >;

        case 'delete':
            return DeleteConfirmationDialog as FunctionComponent<
                React.PropsWithChildren<DeleteConfirmationDialogProps>
            >;
        default:
            return null;
    }
};

type UseModalReturn<T extends ModalType> = T extends 'confirmation'
    ? FunctionComponent<React.PropsWithChildren<ConfirmationDialogProps>>
    : T extends 'information'
    ? FunctionComponent<React.PropsWithChildren<InformationDialogProps>>
    : FunctionComponent<React.PropsWithChildren<DeleteConfirmationDialogProps>>;

export function useModal<T extends ModalType>(type: T): UseModalReturn<T> {
    return modalFactory(type) as UseModalReturn<T>;
}
