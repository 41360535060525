import { FunctionComponent } from 'react';
import { Money } from '@/models/general';
import { FlexContainer } from '@/components/Layout/Container';
import {
    Box,
    Divider,
    Skeleton,
    SkeletonProps,
    Typography,
} from '@mui/material';
import { getMoneyAsString } from '@/utils/general';
import { useCartCosts } from '@/hooks/useCartCosts';
import { useTranslation } from '@/hooks/useTranslation';
import { KEYCARD_COST } from '@/constants';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { styled } from '@mui/material/styles';
import { FlatCardWithTitle } from '@/components/Card/FlatCardWithTitle';
import { RedeemCodeInputContainer } from '@/components/RedeemCodeInput/RedeemCodeInputContainer';
import { useGetCurrentCheckoutSession } from '@/api/checkout';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';

export const CostSummaryBlock = () => {
    const cartCosts = useCartCosts();
    const { isLoading } = useGetCurrentCheckoutSession();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { IS_PORTAL, isOeamtc } = useConfigSlice();

    const { getTranslated } = useTranslation();

    const hasIncertInput = !IS_PORTAL && selectedLiftOperator?.incertActive;
    const hasSomeDiscounts =
        cartCosts.totalDiscounts.amount > 0 ||
        cartCosts.totalCreditBalance.amount > 0;

    return (
        <FlatCardWithTitle title={getTranslated('yourOrderSummary')}>
            <FlexContainer
                sx={{
                    mt: 4,
                    mb: 2,
                    width: {
                        mobile: '100%',
                        laptop: 'auto',
                    },
                }}
            >
                {!hasIncertInput && !isOeamtc && <RedeemCodeInputContainer />}
            </FlexContainer>

            {isLoading ? (
                <Box>
                    <LoaderLines numberOfRows={3} />
                </Box>
            ) : (
                <>
                    <CostLine
                        label={getTranslated('Cart.tickets')}
                        money={cartCosts.subtotalTickets}
                    />

                    {cartCosts.totalDiscounts.amount ? (
                        <CostLine
                            label={getTranslated('Cart.discounts')}
                            money={cartCosts.totalDiscounts}
                        />
                    ) : null}

                    {cartCosts.subtotalVouchers.amount ? (
                        <CostLine
                            label={getTranslated('Cart.vouchers')}
                            money={cartCosts.subtotalVouchers}
                        />
                    ) : null}
                    {cartCosts.subtotalKeycards.amount ? (
                        <CostLine
                            label={getTranslated('keycardCosts')}
                            money={{
                                amount: cartCosts.subtotalKeycards.amount,
                                currency: KEYCARD_COST.currency,
                            }}
                        />
                    ) : null}

                    {cartCosts.subtotalInsurances.amount ? (
                        <CostLine
                            label={getTranslated('insuranceCosts')}
                            money={{
                                amount: cartCosts.subtotalInsurances.amount,
                                currency: KEYCARD_COST.currency,
                            }}
                        />
                    ) : null}

                    {cartCosts.depositAmounts.amount ? (
                        <CostLine
                            label={getTranslated('depositCosts')}
                            money={{
                                amount: cartCosts.depositAmounts.amount,
                                currency: KEYCARD_COST.currency,
                            }}
                        />
                    ) : null}

                    {cartCosts.shippingCosts.amount ? (
                        <CostLine
                            label={getTranslated('deliveryCost')}
                            money={cartCosts.shippingCosts}
                        />
                    ) : null}
                </>
            )}

            <Divider />

            {isLoading ? (
                <LoaderLines numberOfRows={1} />
            ) : (
                <LineContainer>
                    <CostTotalTitle sx={{ fontWeight: 'bold' }}>
                        {getTranslated(
                            hasSomeDiscounts ? 'Core.totalCost' : 'toPay'
                        )}
                    </CostTotalTitle>
                    <CostTotalTitle sx={{ fontWeight: 'bold' }}>
                        {getMoneyAsString(
                            hasSomeDiscounts
                                ? cartCosts.totalBeforeDiscounts
                                : cartCosts.totalAfterCredits
                        )}
                    </CostTotalTitle>
                </LineContainer>
            )}

            {hasSomeDiscounts && !isLoading && (
                <>
                    {cartCosts.incertVoucherDiscounts.amount ? (
                        <CostLine
                            label={getTranslated('MyAccountNavigation.credit')}
                            money={cartCosts.incertVoucherDiscounts}
                            showNegative
                        />
                    ) : null}

                    {cartCosts.usedCreditBalance.amount ? (
                        <CostLine
                            label={getTranslated('MyAccountNavigation.credit')}
                            money={cartCosts.usedCreditBalance}
                            showNegative
                        />
                    ) : null}

                    <Divider />

                    <LineContainer>
                        <CostTotalTitle sx={{ fontWeight: 'bold' }}>
                            {getTranslated('toPay')}
                        </CostTotalTitle>
                        <CostTotalTitle sx={{ fontWeight: 'bold' }}>
                            {getMoneyAsString(cartCosts.totalAfterCredits)}
                        </CostTotalTitle>
                    </LineContainer>

                    <Box
                        sx={{
                            mt: 2,
                        }}
                    >
                        <CostLine
                            label={getTranslated('remainingCredit')}
                            money={{
                                amount:
                                    cartCosts.totalCreditBalance.amount -
                                    cartCosts.usedCreditBalance.amount,
                                currency: cartCosts.usedCreditBalance.currency,
                            }}
                        />
                    </Box>
                </>
            )}
        </FlatCardWithTitle>
    );
};

const LoaderLines: FunctionComponent<{ numberOfRows: number }> = ({
    numberOfRows,
}) => {
    return new Array(numberOfRows)
        .fill(Skeleton)
        .map((Component: FunctionComponent<SkeletonProps>, index) => (
            <Component
                sx={{
                    my: 1,
                }}
                key={index}
                variant='text'
                animation='wave'
            />
        ));
};

const LineContainer = styled(FlexContainer)(({ theme }) => ({
    margin: theme.spacing(2),
    justifyContent: 'space-between',
}));

const CostLine: FunctionComponent<
    React.PropsWithChildren<{
        label: string;
        money: Money;
        showNegative?: boolean;
    }>
> = ({ label, money, showNegative }) => (
    <LineContainer>
        <Typography>{label}</Typography>
        <Typography>
            {showNegative && '-'} {getMoneyAsString(money)}
        </Typography>
    </LineContainer>
);

const CostTotalTitle = styled(Typography)(({ theme }) => ({
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
}));
