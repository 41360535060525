import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { FlexContainer } from '@/components/Layout/Container';
import { Dialog } from '@/components/Dialog/Dialog';
import { GetTranslated, useTranslation } from '@/hooks/useTranslation';
import { IdentificationType } from '@/models/cart';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { useSlice } from '@/hooks/useSlice';
import { cartSlice } from '@/slices/cartSlice';
import { SingleTicketIdentSelectionContainer } from '@/components/PageContent/CartPageContent/IdentificationSelection/SingleTicketIdentSelectionContainer';
import { useRef } from 'react';

const CloseButton = styled(IconButton)(() => ({
    color: '#fff',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 24,
}));

const getDialogTitle = (
    productionType: IdentificationType,
    getTranslated: GetTranslated
) => {
    if (!productionType)
        return getTranslated('identificationDialogNoOptionTitle');
    else if (productionType === IdentificationType.KEYCARD)
        return getTranslated('personalizeYourKeycard');
    else
        return getTranslated('personalizeYourTicket', {
            productionType: getTranslated(productionType),
        });
};

export const IdentificationSelectionDialogContainer = () => {
    const {
        state: { personAssignmentDialogControls },
        actions: { setPersonAssignmentDialogControls },
    } = useSlice(cartSlice, 'cart');
    const internalControls = useRef(personAssignmentDialogControls);

    const { IS_OEAMTC } = useConfigSlice();
    const { getTranslated } = useTranslation();

    if (!!personAssignmentDialogControls && !internalControls.current) {
        internalControls.current = personAssignmentDialogControls;
    }

    // for now
    const productionType = null;

    const color = IS_OEAMTC ? '#000' : '#fff';
    return (
        <Dialog
            open={!!personAssignmentDialogControls}
            maxWidth={'xl'}
            TransitionProps={{
                onExited: () => {
                    internalControls.current = null;
                },
            }}
            onClose={() => {
                setPersonAssignmentDialogControls(null);
            }}
        >
            <FlexContainer
                id='identification_dialog_title'
                sx={(theme) => ({
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary.main,
                    position: 'relative',
                    width: '100%',
                    color,
                })}
            >
                <DialogTitle
                    sx={(theme) => ({
                        width: '100%',

                        [theme.breakpoints.down('lg')]: {
                            width: '80%',
                        },
                    })}
                >
                    {getDialogTitle(productionType, getTranslated)}
                </DialogTitle>
                <CloseButton
                    onClick={() => {
                        setPersonAssignmentDialogControls(null);
                    }}
                >
                    <CloseIcon
                        sx={{
                            color,
                        }}
                    />
                </CloseButton>
            </FlexContainer>

            <DialogContent
                id='identification_dialog_content'
                sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    backgroundColor: theme.palette.sjLight,

                    [theme.breakpoints.down('lg')]: {
                        p: 1,
                    },
                })}
            >
                {internalControls.current && (
                    <SingleTicketIdentSelectionContainer
                        ticketId={internalControls.current.ticket.id}
                        personId={internalControls.current.personId}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
