import { FunctionComponent } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Faq } from '@/models/faq';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type FaqsListProps = {
    faqs: Faq[];
    accordionColor: 'primary' | 'grey';
};

export const FaqsList: FunctionComponent<FaqsListProps> = ({
    faqs,
    accordionColor,
}) => {
    return (
        <>
            {faqs.map((faq, index) => (
                <Accordion
                    sx={{
                        my: 2,
                    }}
                    elevation={1}
                    key={index}
                >
                    <AccordionSummary
                        sx={(theme) => ({
                            py: 4,
                            px: 6,

                            bgcolor:
                                accordionColor === 'primary'
                                    ? theme.palette.primary.main
                                    : theme.palette.grey[200],
                            color: theme.palette.getContrastText(
                                accordionColor === 'primary'
                                    ? theme.palette.primary.main
                                    : theme.palette.grey[200]
                            ),
                        })}
                        expandIcon={
                            <ExpandMoreIcon
                                sx={(theme) => ({
                                    color: theme.palette.getContrastText(
                                        accordionColor === 'primary'
                                            ? theme.palette.primary.main
                                            : theme.palette.grey[200]
                                    ),
                                })}
                            />
                        }
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                {faq.title}
                            </ReactMarkdown>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            px: 6,
                            py: 3,
                        }}
                    >
                        <Typography
                            sx={(theme) => ({
                                fontSize: '1.1rem',
                                lineHeight: '2',
                                color: theme.palette.grey['800'],
                                img: {
                                    width: '100%',
                                    objectFit: 'contain',
                                },
                                strong: {
                                    display: 'inline',
                                },
                            })}
                            component='div'
                        >
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                {faq.text}
                            </ReactMarkdown>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};
