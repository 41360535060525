import { RouteProps } from 'react-router-dom';
import { toJSX } from '@/utils/react';
import * as pages from '@/pages/index';
import { LiftOperator } from '@/models/liftOperators';
import { Locale } from '@/models/general';
import { SeoHtmlDoc } from '@/models/seo';
import { MyVouchersPage } from '@/pages/MyVouchersPage';
import { ReportClaimPage } from '@/pages/ReportClaimPage';
import { MyInsurancesPage } from '@/pages/MyInsurancesPage';
import { InsuranceProductsPage } from '@/pages/InsuranceProductsPage';

type SeoInfoBuilderParams = {
    liftOperator: LiftOperator;
    locale: Locale;
};

const buildPath = (pathString: string) => [pathString, `/en${pathString}`];

export type RouteConfigEntry = {
    protectedRoute: boolean;

    // define path which should be pushed for this entry
    // useful when path is an array
    // this field overwrites the path field when it comes to route navigation
    isDefault?: boolean;

    pushTo?: string;
    inOnePage?: boolean; // if false this page cannot be accessed in integrationMode
    inPartner?: boolean; // if true this page is only accessible for OAEMTC (or in future other partners)
    hideAppBar?: boolean;
    excludeFromMainTemplate?: boolean; // don't use standard page template
    footer?: 'main' | 'light';
    seo?: SeoHtmlDoc | ((params: SeoInfoBuilderParams) => SeoHtmlDoc);
} & RouteProps;

export const getRouterConfig = (portal = true) => ({
    Home: {
        path: buildPath('/'),
        exact: true,
        children: toJSX(pages['HomePage']),
        protectedRoute: false,
        hideAppBar: false,
        isDefault: true,
        footer: portal ? 'main' : 'light',
    },

    // HomeSummer: {
    //     path: buildPath('/home-summer'),
    //     exact: true,
    //     children: toJSX(pages['HomePageSummer']),
    //     protectedRoute: false,
    //     hideAppBar: false,
    //     isDefault: true,
    //     footer: portal ? 'main' : 'light',
    // },

    // HomeWinter: {
    //     path: buildPath('/home-winter'),
    //     exact: true,
    //     children: toJSX(pages['HomePage']),
    //     protectedRoute: false,
    //     hideAppBar: false,
    //     isDefault: true,
    //     footer: portal ? 'main' : 'light',
    // },

    Login: {
        path: buildPath('/login'),
        exact: true,
        children: toJSX(pages['LoginPage']),
        hideAppBar: false,
        protectedRoute: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    LiftOperators: {
        path: buildPath('/lift-operators'),
        exact: true,
        children: toJSX(pages['LiftOperatorsPage']),
        protectedRoute: false,
        inPartner: true,
    },

    Products: {
        path: buildPath('/products/:id'),
        children: toJSX(pages['ProductsPage']),
        protectedRoute: false,
        exact: true,
        isDefault: true,
        inOnePage: true,
        inPartner: true,
        seo: ({ liftOperator, locale }: SeoInfoBuilderParams) => {
            if (!liftOperator) return null;

            const metaData = liftOperator.metaData.find(
                (md) => md.language.toLocaleLowerCase() === locale
            );

            const defaultTitleGerman = `Skipass für ${liftOperator.name.de} online kaufen`;
            const defaultTitleEnglish = `Ski ticket | ${
                liftOperator.name[locale] || liftOperator.name.de
            }`;

            return {
                title:
                    metaData?.title ||
                    (locale === 'de'
                        ? defaultTitleGerman
                        : defaultTitleEnglish),
                description:
                    metaData?.title ||
                    (locale === 'de'
                        ? defaultTitleGerman
                        : defaultTitleEnglish),
            };
        },
    },

    Cart: {
        path: buildPath('/cart'),
        exact: true,
        children: toJSX(pages['CartsPage']),
        protectedRoute: true,
        inOnePage: true,
        inPartner: true,
    },

    Vouchers: {
        path: buildPath('/vouchers'),
        exact: true,
        children: toJSX(pages['VouchersPage']),
        protectedRoute: false,
        inOnePage: true,
    },

    FAQ: {
        path: buildPath('/faq'),
        exact: true,
        children: toJSX(pages['FaqPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    MyData: {
        path: buildPath('/my-data'),
        exact: true,
        children: toJSX(pages['MyDataPage']),
        protectedRoute: true,
        hideAppBar: false,
        footer: 'light',
        inOnePage: true,
        inPartner: true,
    },

    MyKeycards: {
        path: buildPath('/my-keycards'),
        exact: true,
        children: toJSX(pages['MyKeycardsPage']),
        protectedRoute: true,
        hideAppBar: false,
        footer: 'light',
        inOnePage: true,
        inPartner: true,
    },

    MyOrders: {
        path: buildPath('/my-orders'),
        exact: true,
        children: toJSX(pages['MyOrdersPage']),
        protectedRoute: true,
        hideAppBar: false,
        footer: 'light',
        inOnePage: true,
        inPartner: true,
    },

    MyTickets: {
        path: buildPath('/my-tickets'),
        exact: true,
        children: toJSX(pages['MyTicketsPage']),
        protectedRoute: true,
        hideAppBar: false,
        footer: 'light',
        inOnePage: true,
        inPartner: true,
    },

    RedeemVouchers: {
        path: buildPath('/redeem-coupons'),
        exact: true,
        children: toJSX(pages['RedeemCouponsPage']),
        protectedRoute: true,
        hideAppBar: false,
        footer: 'light',
        inOnePage: true,
        inPartner: true,
    },

    ForgotPassword: {
        path: buildPath('/forgot-password'),
        exact: true,
        children: toJSX(pages['ForgotPasswordPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: 'light',
        inOnePage: true,
        inPartner: true,
    },

    PasswordForgotton: {
        path: buildPath('/password-forgotten/:id'),
        exact: true,
        children: toJSX(pages['PasswordForgottenPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: 'light',
        inOnePage: true,
        inPartner: true,
    },

    Register: {
        path: buildPath('/register'),
        exact: true,
        children: toJSX(pages['RegisterPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    Imprint: {
        path: buildPath('/imprint'),
        exact: true,
        children: toJSX(pages['ImprintPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    DataPrivacy: {
        path: buildPath('/data-privacy'),
        exact: true,
        children: toJSX(pages['DataPrivacy']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    GTCPage: {
        path: buildPath('/gtc'),
        exact: true,
        children: toJSX(pages['GTCPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    NewKeycardPage: {
        path: buildPath('/new-keycard'),
        exact: true,
        children: toJSX(pages['NewKeycardPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },
    ActivatePage: {
        path: buildPath('/activate/:id'),
        exact: true,
        children: toJSX(pages['ActivatePage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    SuccessPage: {
        path: buildPath('/checkout-success'),
        exact: true,
        children: toJSX(pages['SuccessPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },
    ErrorPage: {
        path: buildPath('/checkout-error'),
        exact: true,
        children: toJSX(pages['ErrorPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    VouchersDownloadPage: {
        path: buildPath('/voucher-download/:id'),
        exact: true,
        children: toJSX(pages['VouchersDownloadPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: false,
    },
    EmailConfirmationPage: {
        path: buildPath('/confirm-email/:code'),
        exact: true,
        children: toJSX(pages['EmailConfirmationPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    KeycardTransferPage: {
        path: buildPath('/keycard-transfer/:id'),
        exact: true,
        children: toJSX(pages['KeycardTransferPage']),
        protectedRoute: true,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    OAuthLogin: {
        path: buildPath('/oauth/login'),
        exact: true,
        children: toJSX(pages['OAuthMergeCreatePage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: portal ? 'main' : 'light',
        inOnePage: true,
        inPartner: true,
    },

    CartTransfer: {
        path: buildPath('/cart-transfer/:id'),
        exact: true,
        children: toJSX(pages['CartTransferPage']),
        protectedRoute: false,
        hideAppBar: false,
        footer: 'light',
        inOnePage: true,
        inPartner: false,
    },

    MyVouchersPage: {
        path: buildPath(MyVouchersPage.path),
        exact: true,
        children: <MyVouchersPage />,
        protectedRoute: true,
        hideAppBar: false,
        footer: 'light',
        inOnePage: true,
        inPartner: false,
    },

    ReportClaimPage: {
        path: buildPath(ReportClaimPage.path),
        exact: true,
        children: <ReportClaimPage />,
        protectedRoute: false,
        hideAppBar: false,
        footer: 'main',
        inOnePage: false,
        inPartner: false,
    },

    MyInsurancesPage: {
        path: buildPath(MyInsurancesPage.path),
        exact: true,
        children: <MyInsurancesPage />,
        protectedRoute: true,
        hideAppBar: false,
        footer: 'main',
        inOnePage: false,
        inPartner: false,
    },

    InsuranceProductPage: {
        path: buildPath(InsuranceProductsPage.path),
        exact: true,
        children: <InsuranceProductsPage />,
        protectedRoute: true,
        hideAppBar: false,
        footer: 'main',
        inOnePage: false,
        inPartner: false,
    },
});

export const defaultRouterConfig = getRouterConfig();

export type RouterConfig = {
    [key in keyof typeof defaultRouterConfig]?: RouteConfigEntry;
};
