import { FunctionComponent } from 'react';
import { ProductCatalogStepper } from '@/components/PageContent/ProductsPageContent/ProductCatalogStepper/ProductCatalogStepper';
import { ProductType } from '@/models/product';
import { TicketVariant } from '@/models/ticket';
import {
    getQueryUrlFromProductFilter,
    isProductFilterComplete,
    ProductFilter,
    updateFilter,
} from '@/models/productFilter';
import { FlexContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import { Typography } from '@mui/material';
import { usePromotion } from '@/hooks/usePromotion';
import { getTagsFromUrl } from '@/utils/url';
import {
    useGetProductDetailsQuery,
    useGetProductOverviewQuery,
} from '@/api/liftOperator';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { useProductFilter } from '@/hooks/useProductFilter';
import { useSearchParams } from 'react-router-dom';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { ProductsOverview } from '@/models/product';

export type ProductFilterUpdateHandler = <T extends keyof ProductFilter>(
    key: T
) => (value: ProductFilter[T]) => void;

export type CatalogStepToShow = {
    typeSelection: boolean;
    variantSelection: boolean;
    dateSelection: boolean;
    personCount: boolean;
    productCollection: boolean;
};

const showCatalogStep = (
    productFilter: ProductFilter,
    productsOverview: ProductsOverview
): CatalogStepToShow => {
    if (!productFilter || !productsOverview) return null;

    const key = productFilter.type === ProductType.DAY ? 'day' : 'season';

    return {
        typeSelection:
            !!productsOverview?.season?.singleProduct ||
            !!productsOverview?.season?.packageProduct ||
            !!productsOverview?.day?.singleProduct ||
            !!productsOverview?.day?.packageProduct,

        variantSelection:
            !!productFilter.type &&
            !!productsOverview[key]?.packageProduct &&
            !!productsOverview[key]?.singleProduct,

        dateSelection:
            !!productFilter.variant &&
            !!productFilter.type &&
            productFilter.type !== ProductType.SEASON,
        personCount:
            productFilter.variant === TicketVariant.PACKAGE &&
            (productFilter.type === ProductType.SEASON ||
                (productFilter.type === ProductType.DAY &&
                    !!productFilter.date)),
        productCollection:
            productFilter.type === ProductType.SEASON ||
            !!productFilter.date ||
            !!productFilter.persons?.length,
    };
};

const hasNoTickets = (productsOverview: ProductsOverview) =>
    !productsOverview.season.singleProduct &&
    !productsOverview.season.packageProduct &&
    !productsOverview.day.singleProduct &&
    !productsOverview.day.packageProduct;

const getDisabledTypeButton = (productsOverview: ProductsOverview) => {
    if (
        productsOverview?.day?.packageProduct === null &&
        productsOverview?.day?.singleProduct === null
    ) {
        return ProductType.DAY;
    } else if (
        productsOverview?.season?.packageProduct === null &&
        productsOverview?.season?.singleProduct === null
    ) {
        return ProductType.SEASON;
    } else return null;
};

export const ProductCatalogContainer: FunctionComponent = () => {
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { configuration } = useConfigSlice();
    const { getTranslated } = useTranslation();
    const promotion = usePromotion();
    const { data: productsOverview } = useGetProductOverviewQuery({
        liftOperatorId: selectedLiftOperator.id,
        configuration,
        tags: getTagsFromUrl(),
    });

    const [{ productFilter }] = useProductFilter();
    const [searchParams, setSearchParams] = useSearchParams();

    useGetProductDetailsQuery(
        {
            liftOperatorId: selectedLiftOperator.id,
            productFilter: productFilter,
            configuration,
            tags: searchParams.getAll('tags'),
        },
        {
            skip: !promotion && !isProductFilterComplete(productFilter),
        }
    );

    const onFilterUpdate: ProductFilterUpdateHandler = (key) => (value) => {
        const nextFilter = updateFilter({
            filter: productFilter,
            productsOverview,
            key,
            value,
        });

        const queryUrl = getQueryUrlFromProductFilter(nextFilter);

        const tags = getTagsFromUrl();

        if (tags.length) {
            queryUrl.append('tags', tags.join(','));
        } else {
            queryUrl.delete('tags');
        }

        setSearchParams(queryUrl);
    };

    if (!productsOverview) return null;

    const noTickets =
        hasNoTickets(productsOverview) || !!selectedLiftOperator.noOffersText;
    const noTicketsText =
        selectedLiftOperator?.noOffersText ||
        getTranslated('ProductsPage.noTicketsFoundForLiftOperator');

    return noTickets ? (
        <FlexContainer
            sx={{
                py: 6,
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: '1.2rem',
                }}
            >
                {noTicketsText}
            </Typography>
        </FlexContainer>
    ) : (
        <>
            {showCatalogStep(productFilter, productsOverview) && (
                <ProductCatalogStepper
                    productFilter={productFilter}
                    productsOverview={productsOverview}
                    onFilterUpdate={onFilterUpdate}
                    showCatalogStep={showCatalogStep(
                        productFilter,
                        productsOverview
                    )}
                    typeButtonDisabled={getDisabledTypeButton(productsOverview)}
                />
            )}
        </>
    );
};
