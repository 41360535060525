import { FunctionComponent } from 'react';
import { Button } from '@/components/Layout/Button/Button';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@/theme/theme';
import FacebookIcon from '@mui/icons-material/Facebook';

const useStyles = makeStyles((theme: Theme) => ({
    facebookButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.facebookBlue,
        color: theme.palette.white,
        margin: theme.spacing(0, 2),
        width: 272,
        height: 58,

        '&:hover': {
            backgroundColor: theme.palette.facebookBlue,
        },

        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0),
            width: '100%',
        },
    },

    facebookIcon: {
        marginRight: theme.spacing(2),
    },
}));

export const FacebookButton: FunctionComponent<
    React.PropsWithChildren<{
        onClick: () => void;
    }>
> = ({ onClick, children }) => {
    const classes = useStyles();

    return (
        <Button
            isLoading={false}
            className={classes.facebookButton}
            variant='contained'
            onClick={onClick}
        >
            <FacebookIcon className={classes.facebookIcon} /> {children}
        </Button>
    );
};
