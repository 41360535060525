import { useDispatch, useSelector } from 'react-redux';
import { Store } from '@/store';
import {
    configSliceActions,
    PageConfig,
    PortalConfig,
} from '@/slices/configSlice';
import { LiftOperator } from '@/models/liftOperators';
import { CARDTYPE_IDS } from '@/constants/index';
import { CardType } from '@/models/order';
import { useGetOrderInfoQuery } from '@/api/order';
import {
    useLazyGetConfigurationQuery,
    useLazyGetLiftOperatorByIdQuery,
} from '@/api/liftOperator';

/**
 * @deprecated This hook is deprecated and will be removed in future versions.
 * Please use (and adjust) usePageConfig
 */
export const useConfigSlice = () => {
    const configState = useSelector((store: Store) => store.config);
    const { data: orderInfo } = useGetOrderInfoQuery();
    const dispatch = useDispatch();

    const [getLiftOperatorById] = useLazyGetLiftOperatorByIdQuery();
    const [getConfiguration] = useLazyGetConfigurationQuery();

    const pageConfigFactory = (operatorName: string, tags: string[] = []) => {
        if (!operatorName) return null;
        let pageConfig = null;

        return new Promise<{
            pageConfig: PageConfig;
            liftOperator: LiftOperator;
        }>((resolve) => {
            getConfiguration({ vanityIdentifier: operatorName })
                .unwrap()
                .then((configuration) => {
                    pageConfig = {
                        url: window.location.origin,
                        configuration,
                        tags,
                    };

                    return getLiftOperatorById({
                        liftOperatorId: configuration.liftOperatorId,
                    }).unwrap();
                })
                .then((liftOperator) => {
                    resolve({
                        pageConfig,
                        liftOperator,
                    });
                })
                .catch(() => {
                    resolve({ pageConfig: null, liftOperator: null });
                });
        });
    };

    const IS_INTEGRATION =
        configState.configuration === PortalConfig.INTEGRATION_CONFIGURATION;

    const IS_PORTAL =
        configState.configuration === PortalConfig.PORTAL_CONFIGURATION;

    const IS_OEAMTC = !!configState.isOeamtc;

    const setLocale = (locale: 'de' | 'en') => {
        dispatch(configSliceActions.setLocale(locale));
    };

    const setPageConfig = (config: PageConfig) => {
        dispatch(configSliceActions.setPageConfig(config));
    };

    const setConfiguration = (config: PortalConfig) => {
        dispatch(configSliceActions.setConfiguration(config));
    };

    const setGoogleMapsLoaded = (loaded: boolean) => {
        dispatch(configSliceActions.setGoogleMapsLoaded(loaded));
    };

    const isWebshop = (vanityIdentifier: string) => {
        if (IS_PORTAL) return false;

        return (
            configState.pageConfig?.configuration?.vanityIdentifier ===
            vanityIdentifier
        );
    };

    // 2b23b57b-04c2-4b1b-9386-22793b2ad057 - hardcoded standardCardType id
    const getCardType = (): CardType | null => {
        if (!orderInfo) return null;

        if (
            configState.configuration === PortalConfig.INTEGRATION_CONFIGURATION
        ) {
            // check for alternative id
            const identifier =
                configState?.pageConfig?.configuration?.vanityIdentifier;

            if (identifier && !!CARDTYPE_IDS[identifier]) {
                const id = CARDTYPE_IDS[identifier];
                const cardType = orderInfo.cardTypes.find(
                    (cardType) => cardType.id === id
                );

                if (cardType) {
                    return cardType;
                }
            }
        }

        // return standard type
        return orderInfo.cardTypes.find(
            (cardType) => cardType.id === '2b23b57b-04c2-4b1b-9386-22793b2ad057'
        );
    };

    return {
        ...configState,
        IS_PORTAL,
        IS_INTEGRATION,
        IS_OEAMTC,
        isWebshop,
        setLocale,
        setPageConfig,
        pageConfigFactory,
        setConfiguration,
        setGoogleMapsLoaded,
        getCardType,
    };
};
