import { FunctionComponent } from 'react';
import {
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
} from '@mui/material';

type OptionProps = React.OptionHTMLAttributes<unknown>;

export type SelectProps = {
    inputLabel: string;
    options: [OptionProps['value'], OptionProps['label']][];
    formControlProps?: FormControlProps;
} & MuiSelectProps;

export const Select: FunctionComponent<
    React.PropsWithChildren<SelectProps>
> = ({ options, inputLabel, formControlProps, ...selectProps }) => {
    return (
        <FormControl variant='outlined' {...formControlProps}>
            <InputLabel htmlFor={selectProps.name}>{inputLabel}</InputLabel>
            <MuiSelect {...selectProps} value={selectProps.value || ''}>
                {options.map(([value, label]) => (
                    <MenuItem key={label} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
};
