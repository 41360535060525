import { Address } from '@/models/user';
import { Ticket } from '@/models/ticket';
import {
    BackendType,
    convertDateToFrontend,
    CustomDate,
    FrontendType,
    Gender,
    Money,
    MultiLangString,
    ObjectType,
} from '@/models/general';
import { IdentificationType } from '@/models/cart';
import { Logo } from '@/models/liftOperators';
import { mapOrderedVoucherToFrontend, OrderedVoucher } from '@/models/voucher';
import { IDENTIFICATION_REF_START } from '@/constants/order';

export enum ProductEnvironment {
    PORTAL = 'PORTAL',
    INTEGRATION = 'INTEGRATION',
    MOBILE = 'MOBILE',
    B2B = 'B2B',
}

enum EntranceProvider {
    'SKIDATA' = 'SKIDATA',
    'AXESS' = 'AXESS',
}

enum KeycardStatus {
    ORDERED = 'ORDERED',
    LOCKED = 'LOCKED',
    NEW = 'NEW',
    ORDERED_INTERNAL = 'ORDERED_INTERNAL',
    PRODUCED = 'PRODUCED',
}

enum OrderStatus {
    PAID = 'PAID',
}

export enum KeycardTransferStatus {
    REJECTED = 'REJECTED',
    READY = 'READY',
    ACCEPTED = 'ACCEPTED',
}

export type KeycardTransfer<T extends ObjectType = FrontendType> = {
    birthday: CustomDate<T>;
    createdAt: CustomDate<T>;
    email: string;
    firstname: string;
    id: string;
    lastname: string;
    keycardId: string;
    status: KeycardTransferStatus;
};

export const convertTransferKeycardToFrontend = (
    transfer: KeycardTransfer<BackendType>
): KeycardTransfer<FrontendType> => ({
    ...transfer,
    birthday: convertDateToFrontend(transfer.birthday),
    createdAt: convertDateToFrontend(transfer.createdAt),
});

export type Keycard<T extends ObjectType = FrontendType> = {
    birthday: CustomDate<T>;
    deletionDate: CustomDate<T>;
    expirationDate: CustomDate<T>;
    external: boolean;
    firstname: string;
    lastname: string;
    title: string;
    gender: Gender;
    id: string;
    image: string;
    logo: Logo;
    serialNumber: string;
    singleCardCodes: { code: string; externalCode: string }[];
    status: KeycardStatus;
    tickets?: KeycardTicket<T>[];
};

type KeycardTicket<T extends ObjectType = FrontendType> = {
    cancelable: boolean;
    depositAmount: Money;
    duration: number;
    id: string;
    identificationType: IdentificationType;
    liftOperator: MultiLangString;
    liftOperatorId: string;
    name: MultiLangString;
    orderDate: CustomDate<T>;
    orderDisplayId: string;
    orderId: string;
    permissionNumber: null;
    personName: MultiLangString;
    price: Money;
    startDate: CustomDate<T>;
    status: string;
    type: string;
    keycardFirstname: string;
    keycardLastname: string;
};

export const convertKeycardToFrontend = (
    keycard: Keycard<BackendType>
): Keycard => ({
    ...keycard,
    birthday: convertDateToFrontend(keycard.birthday),
    deletionDate: convertDateToFrontend(keycard.deletionDate),
    expirationDate: convertDateToFrontend(keycard.expirationDate),
    tickets: keycard.tickets
        ? keycard.tickets.map((ticket) => ({
              ...ticket,
              orderDate: convertDateToFrontend(ticket.orderDate),
              startDate: convertDateToFrontend(ticket.startDate),
          }))
        : [],
});

export type IdentificationSuggestion<T extends ObjectType = FrontendType> = {
    ref: string;
    title: string;
    firstname: string;
    lastname: string;
    image: string;
    gender: Gender;
    birthday: CustomDate<T>;
    external?: true;
};

export const convertIdentificationSuggestionToFrontend = (
    iS: IdentificationSuggestion<BackendType>,
    index: number
): IdentificationSuggestion => {
    const { firstname, lastname } = iS;

    const feBirthDay = convertDateToFrontend(iS.birthday);
    return {
        ...iS,
        // this ref cannot be random otherwise suggestions that are stored in localstorage at an earlier time
        // would not match with this object anymore (in order to load checkout from local storage)
        // for now we generate an id like this and mark it with 'toUUID'. This way we know we need to gen an uuid before the orders call
        ref: `${IDENTIFICATION_REF_START}.${firstname}.${lastname}.${index}`,
        birthday: feBirthDay,
    };
};

export type KeycardSuggestion<T extends ObjectType = FrontendType> =
    IdentificationSuggestion<T> & {
        expirationDate: CustomDate<T>;
        compatibility: EntranceProvider[];
        serialNumber?: string;
        external: boolean;
    } & (T extends BackendType ? { id: string } : Record<string, unknown>);

export const convertKeycardSuggestionToFrontend = (
    suggestion: KeycardSuggestion<BackendType>
): KeycardSuggestion => {
    const { id, ...kS } = suggestion;

    return {
        ...kS,
        ref: id,
        birthday: convertDateToFrontend(kS.birthday),
        expirationDate: convertDateToFrontend(kS.expirationDate),
    };
};

export type Identification<T extends ObjectType = FrontendType> = {
    ref: string;

    birthday: CustomDate<T>;
    cardType: string;
    firstname: string;
    lastname: string;

    image: string;
    price: Money;
    depositAmount?: Money;
    gender: Gender;

    title: string;
    type: IdentificationType;

    isExisting: boolean;
    isSingleIdentification: boolean;

    // if an existing keycard is selected, pass keycard serial number
    serialNumber?: string;
};

export type PostOrdersResponseBackend = {
    ticket: Ticket[];
    orderId: string;
    status: string;
    identifications: Identification[];
    shopProducts: [];
    vouchers: [];
    incertVoucherCode: null;
    location: null;
    token: null;
    total: Money;
    payAmount: Money;
    incertAmount: Money;
};

export type CardType = {
    id: string;
    name: MultiLangString;
    price: Money;
};

type ShippingCost = {
    country: string;
    price: Money;
};

export type OrderInfo = {
    cardTypes: CardType[];
    shippingCosts: ShippingCost[];
};

export enum PaymentMethod {
    TOKEN = 'CREDIT_CARD',
    SOFORT = 'SOFORT',
    IDEAL = 'IDEAL',
    EPS = 'EPS',
    APPLE_PAY = 'APPLE_PAY',
    GOOGLE_PAY = 'GOOGLE_PAY',
}

export type Order<T extends ObjectType = FrontendType> = {
    address: Address;
    creationDate: CustomDate<T>;
    displayId: string;
    free: boolean;
    id: string;
    keycards: Keycard<T>[];
    payAmount: Money;
    shopProducts: [];
    status: OrderStatus;
    tickets: unknown[];
    total: Money;
    vouchers: OrderedVoucher<T>[];
};

export const mapOrderToFrontend = (order: Order<BackendType>): Order => ({
    ...order,
    creationDate: convertDateToFrontend(order.creationDate),
    keycards: order.keycards.map(convertKeycardToFrontend),
    vouchers: order.vouchers?.map(mapOrderedVoucherToFrontend) || [],
});
