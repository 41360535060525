import { Order, Row } from './tableConfig';
import { DATE_FORMAT } from '@/constants/index';
import moment from 'moment';

type ColumnType = 'string' | 'date' | 'number';

function descendingComparator(
    a: Row,
    b: Row,
    orderBy: number,
    columnType: ColumnType
) {
    const aValue =
        columnType === 'date'
            ? moment(a.data[orderBy] as string, DATE_FORMAT).unix()
            : a.data[orderBy];
    const bValue =
        columnType === 'date'
            ? moment(b.data[orderBy] as string, DATE_FORMAT).unix()
            : b.data[orderBy];

    if (aValue < bValue) {
        return -1;
    }
    if (aValue > bValue) {
        return 1;
    }
    return 0;
}

export function getComparator(
    order: Order,
    orderBy: number,
    columnType: ColumnType
): (a: Row, b: Row) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, columnType)
        : (a, b) => -descendingComparator(a, b, orderBy, columnType);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
