import { FunctionComponent } from 'react';
import { FooterLight } from '@/components/Footer/FooterLight';
import { Footer } from '@/components/Footer/Footer';

type FooterContainerProps = {
    type: 'light' | 'main';
};

export const FooterContainer: FunctionComponent<FooterContainerProps> = ({
    type,
}) => {
    switch (type) {
        case 'light':
            return <FooterLight />;
        case 'main':
            return <Footer />;
        default:
            return null;
    }
};
