import { Theme as MuiTheme, createTheme } from '@mui/material/styles';
import { PageConfig } from '@/slices/configSlice';
import { starjackBaseTheme } from '@/theme/starjackBaseTheme';
import { oeamtcTheme } from '@/theme/extensions/oeamtcTheme';
import { createIntegrationTheme } from '@/theme/extensions/integrationTheme';
import { deDE } from '@mui/x-date-pickers/locales';
import { enUS } from '@mui/x-date-pickers/locales';
import { Locale } from '@/models/general';
import { createShadows } from '@/theme/create-shadows';

const appBarHeight = 64;
export const fullPageMinHeight = `calc(100vh - ${appBarHeight}px)`;

export type Theme = MuiTheme;

export const buildTheme = (
    pageConfig: PageConfig,
    isOeamtc = false,
    locale: Locale
) => {
    const currentLocale = locale === 'de' ? deDE : enUS;
    const shadows = createShadows();

    if (isOeamtc) {
        return createTheme({ ...oeamtcTheme, shadows }, currentLocale);
    } else if (pageConfig?.configuration) {
        return createTheme(
            { ...createIntegrationTheme(pageConfig.configuration), shadows },
            currentLocale
        );
    } else {
        return createTheme({ ...starjackBaseTheme, shadows }, currentLocale);
    }
};
