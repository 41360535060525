import {
    BackendType,
    convertDateToFrontend,
    CustomDate,
    FrontendType,
    Gender,
    ObjectType,
} from '@/models/general';

export enum PossibleIdentificationAssignmentType {
    KEYCARD = 'KEYCARD',
    INTERNAL_PRODUCTION = 'INTERNAL_PRODUCTION',
    SUGGESTION = 'SUGGESTION',
}

export type PossibleIdentificationAssignment<
    T extends ObjectType = FrontendType
> = {
    id: string;
    type: PossibleIdentificationAssignmentType;
    possibility: AssignmentPossibility;
    severity: Severity;
    firstname: string;
    lastname: string;
    email: string;
    gender: Gender;
    birthday: CustomDate<T>;
    serialNumber: string;
    imageUrl: string;
};

export const convertPossibleIdentificationAssignmentToFrontend = (
    backend: PossibleIdentificationAssignment<BackendType>
) => ({
    ...backend,
    birthday: convertDateToFrontend(backend.birthday),
});

enum Severity {
    OKAY = 'OKAY',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export enum AssignmentPossibility {
    POSSIBLE = 'POSSIBLE',
    START_DATE_MAYBE_TOO_EARLY = 'START_DATE_MAYBE_TOO_EARLY',
    START_DATE_TOO_EARLY = 'START_DATE_TOO_EARLY',
    AGE_OUTSIDE_RANGE = 'AGE_OUTSIDE_RANGE',
    IDENTIFICATION_ALREADY_USED = 'IDENTIFICATION_ALREADY_USED',
    INCOMPATIBLE_TICKET_PRODUCER = 'INCOMPATIBLE_TICKET_PRODUCER',
    INCOMPATIBLE_IDENTIFICATION_TYPE = 'INCOMPATIBLE_IDENTIFICATION_TYPE',
    IDENTIFICATION_PHOTO_REQUIRED = 'IDENTIFICATION_PHOTO_REQUIRED',
    EXTERNAL_CODE_NOT_PRESENT = 'EXTERNAL_CODE_NOT_PRESENT',
}
