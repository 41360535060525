import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';
import { LoginType } from '@/models/user';
import { FacebookButton } from '@/components/Layout/Button/FacebookButton';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useTranslation } from '@/hooks/useTranslation';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme/theme';
import { Button } from '@/components/Layout/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { GoogleButton } from '@/components/Layout/Button/GoogleButton';

const useStyles = makeStyles((theme: Theme) => ({
    buttonContainer: {
        justifyContent: 'space-around',
        marginTop: theme.spacing(4),
    },

    title: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const LoginOptionButton: FunctionComponent<
    React.PropsWithChildren<{ type: LoginType }>
> = ({ type }) => {
    const { oAuthAuthentication } = useAuthentication();
    const { getTranslated } = useTranslation();
    const { routerConfig, getPagePath } = useCustomRouter();
    const navigate = useNavigate();
    switch (type) {
        case LoginType.FACEBOOK:
            return (
                <FacebookButton
                    onClick={() =>
                        oAuthAuthentication({
                            providerUrl: LoginType.FACEBOOK,
                            from: window.location.pathname,
                            linkAccount: true,
                        })
                    }
                >
                    {getTranslated('Login.loginWithFacebook')}
                </FacebookButton>
            );
        case LoginType.GOOGLE:
            return (
                <GoogleButton
                    onClick={() =>
                        oAuthAuthentication({
                            providerUrl: LoginType.GOOGLE,
                            from: window.location.pathname,
                            linkAccount: true,
                        })
                    }
                >
                    {getTranslated('Login.loginWithGoogle')}
                </GoogleButton>
            );
        case LoginType.EMAIL_PASSWORD:
            return (
                <Button
                    isLoading={false}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        navigate(getPagePath(routerConfig.Register));
                    }}
                >
                    {getTranslated('Registration.EMAIL_PASSWORD')}
                </Button>
            );
        default:
            return null;
    }
};

type MoreLoginsOptionProps = {
    moreLoginOptions: LoginType[];
};

export const MoreLoginOptions: FunctionComponent<
    React.PropsWithChildren<MoreLoginsOptionProps>
> = ({ moreLoginOptions }) => {
    const classes = useStyles();
    const { getTranslated } = useTranslation();
    return (
        <FlexContainer column fullWidth>
            <Typography className={classes.title}>
                {getTranslated('Profile.addMoreLogins')}:
            </Typography>

            <FlexContainer className={classes.buttonContainer} fullWidth>
                {moreLoginOptions.map((type, index) => (
                    <LoginOptionButton key={index} type={type} />
                ))}
            </FlexContainer>
        </FlexContainer>
    );
};
