import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../../theme/theme';

const closeIconWidth = 32;

export const useItemStyles = makeStyles((theme: Theme) => ({
    cartContainerItem: {
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2, 0, 2, 2),
        },
    },

    cartItemTitle: {
        [theme.breakpoints.down('md')]: {
            fontSize: '.8rem',
        },
    },

    cartItemSubTitle: {
        fontWeight: 600,
    },

    cartContainerItemLeftIco: {
        width: '80px',
        height: '60px',
        color: theme.palette.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
            width: '60px',
            height: '40px',
        },
    },

    cartContainerItemRightIco: {
        marginLeft: 'auto',
        alignItems: 'center',
        // marginRight: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            width: '65px',
        },
    },

    cartContainerItemPrice: {
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: '.8rem',
        },
    },

    keycardNotificationContainer: {
        marginRight: closeIconWidth,
        minHeight: theme.spacing(30),
        flexGrow: 1,
        padding: theme.spacing(2, 4),
        backgroundColor: theme.palette.sjLight,

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            padding: theme.spacing(2, 2),
        },
    },

    identificationAddonContainer: {
        display: 'flex',
        marginRight: closeIconWidth,
        height: theme.spacing(16),
        flexGrow: 1,
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.sjLight,

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: 'auto',
            padding: theme.spacing(4, 1),
        },
    },

    closeButtonContainer: {
        alignItems: 'center',
        marginTop: 45,
        width: closeIconWidth,
        minWidth: closeIconWidth,
    },

    keyCardAddText: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
            fontSize: '.8rem',
            marginBottom: theme.spacing(2),
        },
    },

    keyCardInfoButton: {
        [theme.breakpoints.down('md')]: {
            width: '95%',
            margin: theme.spacing(4),
            borderRadius: '0',
        },
    },

    cartTicketContainer: {
        width: '100%',
        padding: theme.spacing(4),
        marginBottom: 16,
    },
}));
