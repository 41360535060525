import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { SxProps } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
    sx?: SxProps;
}

export const TabPanel: FunctionComponent<
    React.PropsWithChildren<TabPanelProps>
> = (props) => {
    const { children, value, index, sx, ...other } = props;

    return (
        <FlexContainer sx={sx} hidden={value !== index} {...other}>
            {value === index && (
                <FlexContainer column fullWidth>
                    {children}
                </FlexContainer>
            )}
        </FlexContainer>
    );
};
