import {
    red as Red,
    blue as Blue,
    blueGrey as BlueGrey,
} from '@mui/material/colors';
import { PaletteOptions } from '@mui/material/styles';

export const themePalette: PaletteOptions = {
    red: Red,
    blue: Blue,
    blueGrey: BlueGrey,

    black: '#000',
    black20: 'rgba(0,0,0,.2)',

    white: '#fff',
    white65: 'rgba(255,255,255,.65)',

    sjMain: '#e5e9ea',
    sjMain20: 'rgb(229, 233, 234, .2)',
    sjLight: '#ecf0f1',

    sjGreyMain: '#737373',

    sjTurquoiseMain: '#84BEBF',

    errorRed: '#CF000F',

    infoBlue: '#2196f3',
    facebookBlue: '#3b5998',
    googleRed: '#DB4437',
};
