import { FunctionComponent } from 'react';
import GooglePlay from '@/styles/images/googleDark.svg?react';
import { AppButtonContainer } from '@/components/Layout/Button/AppButtonContainer';
import { SxProps } from '@mui/material';

export const AppButtonAndroid: FunctionComponent<{
    sx?: SxProps;
}> = ({ sx }) => {
    return (
        <AppButtonContainer
            sx={sx}
            href='https://starjack.com/apps/android'
            target='_blank'
            rel='noreferrer'
        >
            <GooglePlay
                style={{
                    width: '100%',
                    height: 'auto',
                }}
            />
        </AppButtonContainer>
    );
};
