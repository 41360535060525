import { matchPath } from 'react-router-dom';

export const matchPathArray = (
    path: string[] | string,
    pathname
): ReturnType<typeof matchPath> => {
    const match = (path: string) => matchPath(path, pathname);

    if (Array.isArray(path)) {
        return path.map(match).find((m) => !!m);
    } else {
        return match(path);
    }
};
