import { FunctionComponent } from 'react';
import { ProductTypeButton } from '@/components/PageContent/ProductsPageContent/ProductFilter/ProductTypeButton';
import { FlexContainer } from '@/components/Layout/Container';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TodayIcon from '@mui/icons-material/Today';
import { ProductType } from '@/models/product';

type ProductTypeSelectionContainerProps = {
    value: ProductType;
    onTypeSelect: (type: ProductType) => void;
    disabled: ProductType;
};

export const ProductTypeSelectionContainer: FunctionComponent<
    React.PropsWithChildren<ProductTypeSelectionContainerProps>
> = ({ value, onTypeSelect, disabled }) => {
    return (
        <FlexContainer
            sx={(theme) => ({
                [theme.breakpoints.down('lg')]: {
                    flexDirection: 'column',
                },
            })}
            fullWidth
        >
            <ProductTypeButton
                type={ProductType.DAY}
                onClick={onTypeSelect}
                isSelected={value === ProductType.DAY}
                disabled={disabled === ProductType.DAY}
                Icon={TodayIcon}
            />
            <ProductTypeButton
                type={ProductType.SEASON}
                onClick={onTypeSelect}
                isSelected={value === ProductType.SEASON}
                disabled={disabled === ProductType.SEASON}
                Icon={DateRangeIcon}
            />
        </FlexContainer>
    );
};
