import Paper from '@mui/material/Paper';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, Divider, Fab, Popover, Typography } from '@mui/material';
import { TicketTypeSelectionContent } from '@/components/ProductSelectionFilter/PopoverContent/TicketTypeSelectionContent';
import { TicketVariant } from '@/models/ticket';
import { SelectionButton } from '@/components/ProductSelectionFilter/SelectionButton';
import { DateSelectionContent } from '@/components/ProductSelectionFilter/PopoverContent/DateSelectionContent';
import { VariantSelectionContent } from '@/components/ProductSelectionFilter/PopoverContent/VariantSelectionContent';
import { useProductFilter } from '@/hooks/useProductFilter';
import { PersonCounterContent } from '@/components/ProductSelectionFilter/PopoverContent/PersonCounterContent';
import { isProductFilterComplete, ProductFilter } from '@/models/productFilter';
import { LiftOperator } from '@/models/liftOperators';
import { useGetProductOverviewQuery } from '@/api/liftOperator';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { getTagsFromUrl } from '@/utils/url';
import { formatDate } from '@/utils/time';
import SearchIcon from '@mui/icons-material/Search';
import { withAlphas } from '@/theme/themeHelpers';
import { useTranslation } from '@/hooks/useTranslation';
import TodayIcon from '@mui/icons-material/Today';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import { getPropByCurrentLocale } from '@/utils/locale';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment, { Moment } from 'moment';

import { useGetButtonLabel } from '@/components/ProductSelectionFilter/useGetButtonLabel';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { PersonCount, ProductsOverview, ProductType } from '@/models/product';
import { useIsOnPlatform } from '@/hooks/useIsOnPlatform';

type SelectionType = 'ticketType' | 'variant' | 'date' | 'person';

type CatalogStepToShow = Partial<Record<keyof ProductFilter, boolean>>;

const availableSelections = (
    productFilter: ProductFilter,
    productsOverview: ProductsOverview
): CatalogStepToShow => {
    if (!productFilter || !productsOverview) return null;

    const key = productFilter.type === ProductType.DAY ? 'day' : 'season';

    return {
        type:
            !!productsOverview?.season?.singleProduct ||
            !!productsOverview?.season?.packageProduct ||
            !!productsOverview?.day?.singleProduct ||
            !!productsOverview?.day?.packageProduct,

        variant:
            !!productFilter.type &&
            !!productsOverview[key]?.packageProduct &&
            !!productsOverview[key]?.singleProduct,

        date:
            !!productFilter.variant &&
            !!productFilter.type &&
            productFilter.type !== ProductType.SEASON,
        persons:
            productFilter.variant === TicketVariant.PACKAGE &&
            (productFilter.type === ProductType.SEASON ||
                (productFilter.type === ProductType.DAY &&
                    !!productFilter.date)),
    };
};

export const ProductSelectionFilter: FunctionComponent = () => {
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { configuration } = useConfigSlice();
    const [{ productFilter, presetFields }, updateProductFilter] =
        useProductFilter();
    const { getTranslated } = useTranslation();
    const { data: productsOverview = null } = useGetProductOverviewQuery({
        liftOperatorId: selectedLiftOperator.id,
        configuration,
        tags: getTagsFromUrl(),
    });

    const [currentAnchorEl, setCurrentAnchorEl] =
        useState<HTMLDivElement>(null);
    const [selectedType, setSelectedType] = useState<SelectionType | null>(
        null
    );

    const typeSelection = useRef<HTMLDivElement>(null);
    const variantSelection = useRef<HTMLDivElement>(null);
    const dateSelection = useRef<HTMLDivElement>(null);
    const personSelection = useRef<HTMLDivElement>(null);

    // ui states
    const [hideDividers, setHideDividers] = useState<number[]>([]);
    const userClosedPopover = useRef(false); // if true no automatic popver open will be triggered

    // this filter is to track ui changes without lags
    const [internalProductFilter, setInternalProductFilter] =
        useState<ProductFilter>(productFilter);

    // this is to keep track of productFilter changes
    const [prevFilter, setPrevFilter] = useState<ProductFilter>(productFilter);

    const customXL = useMediaQuery('(max-width:1445px)');
    const onPortalOrStubai = useIsOnPlatform(['portal', 'stubai']);

    // if the internalFilter changes before productFilter - do not sync
    // if the productFilter changes -> sync with the internalFilter
    if (productFilter !== prevFilter) {
        setPrevFilter(productFilter);
        setInternalProductFilter(productFilter);
    }

    useEffect(() => {
        // check if we have to open automatically the selection
        if (
            selectedType === null &&
            currentAnchorEl === null &&
            internalProductFilter &&
            productsOverview &&
            !isProductFilterComplete(internalProductFilter) &&
            !userClosedPopover.current
        ) {
            // need to use productFilter instead of internalProductFilter
            const selections = availableSelections(
                productFilter,
                productsOverview
            );

            const productKeyFilter: keyof ProductFilter = Object.keys(
                selections
            ).find((key) => {
                return selections[key] && internalProductFilter[key] === null;
            }) as keyof ProductFilter;

            if (productKeyFilter) {
                let nextSelectedType: SelectionType;
                let nextCurrentAnchorEl: HTMLDivElement;
                switch (productKeyFilter) {
                    case 'type':
                        nextSelectedType = 'ticketType';
                        nextCurrentAnchorEl = typeSelection.current;
                        break;
                    case 'variant':
                        nextSelectedType = 'variant';
                        nextCurrentAnchorEl = variantSelection.current;
                        break;
                    case 'date':
                        nextSelectedType = 'date';
                        nextCurrentAnchorEl = dateSelection.current;

                        break;
                    case 'persons':
                        nextSelectedType = 'person';
                        nextCurrentAnchorEl = personSelection.current;
                        break;
                    default:
                        return;
                }

                // timeout for ui updated quirks
                setTimeout(() => {
                    setSelectedType(nextSelectedType);
                    setCurrentAnchorEl(nextCurrentAnchorEl);
                }, 100);
            }
        }
    }, [
        selectedType,
        currentAnchorEl,
        internalProductFilter,
        presetFields,
        productsOverview,
    ]);

    const updateInternalProductFilter = <T extends keyof ProductFilter>(
        key: T,
        value: ProductFilter[T]
    ) => {
        if (key === 'variant' && value === TicketVariant.SINGLE) {
            setInternalProductFilter((prev) => ({
                ...prev,
                [key]: value,
                persons: null,
            }));
        } else {
            setInternalProductFilter((prev) => ({
                ...prev,
                [key]: value,
            }));
        }

        if (key !== 'persons') {
            setSelectedType(null);
            setCurrentAnchorEl(null);
        }

        userClosedPopover.current = false;

        // update the product filter with a delay in order to prevent UI lags
        setTimeout(() => {
            if (key !== 'persons') {
                updateProductFilter(key, value);
            }
        }, 250);
    };

    const handleClick =
        (selectionType: SelectionType) => (ref: HTMLDivElement) => {
            setSelectedType(selectionType);
            setCurrentAnchorEl(ref);
        };

    const addToHideDivider = (indices: number[]) => () => {
        setHideDividers((prev) => [...prev, ...indices]);
    };

    const removeFromHideDivder = (indices: number[]) => () => {
        setHideDividers((prev) => prev.filter((i) => !indices.includes(i)));
    };

    const getDateString = (date: Moment) => {
        if (date.isSame(moment().add(1, 'days'), 'day')) {
            return getTranslated('tomorrow');
        } else if (date.isSame(moment(), 'day')) {
            return getTranslated('today');
        }

        return formatDate(date);
    };

    const productFilerTypeKey =
        internalProductFilter.type === null
            ? null
            : internalProductFilter.type === ProductType.DAY
            ? 'day'
            : 'season';

    return (
        <Paper
            sx={{
                borderRadius: '44px',
                height: '80px',
                display: 'flex',
                width: () => {
                    if (customXL) {
                        return '100%';
                    } else {
                        return internalProductFilter.variant === 'PACKAGE'
                            ? '90%'
                            : '80%';
                    }
                },

                transition: 'all 100ms ease-in-out',
                mx: 'auto',
            }}
            elevation={5}
        >
            <SelectionButton
                ref={typeSelection}
                title={getTranslated(
                    onPortalOrStubai
                        ? 'productFilterProductCategory'
                        : 'productFilterChooseTicketType'
                )}
                label={
                    <TicketTypeLabel
                        type={internalProductFilter.type}
                        liftOperator={selectedLiftOperator}
                    />
                }
                onClick={handleClick('ticketType')}
                position='start'
                disabled={!!presetFields.type}
                onMouseEnter={addToHideDivider([0])}
                onMouseLeave={removeFromHideDivder([0])}
                selected={currentAnchorEl === typeSelection.current}
                containerFocused={!!currentAnchorEl}
                grow={0.5}
            />

            <VerticalDivider
                hide={
                    hideDividers.includes(0) ||
                    currentAnchorEl === typeSelection.current ||
                    currentAnchorEl === variantSelection.current
                }
                containedFocused={!!currentAnchorEl}
            />

            <SelectionButton
                ref={variantSelection}
                title={getTranslated('productFilterChooseTicketVariant')}
                label={
                    <TicketVariantLabel
                        variant={internalProductFilter.variant}
                    />
                }
                onClick={handleClick('variant')}
                onMouseEnter={addToHideDivider([0, 1])}
                onMouseLeave={removeFromHideDivder([0, 1])}
                selected={currentAnchorEl === variantSelection.current}
                containerFocused={!!currentAnchorEl}
                disabled={!!presetFields.variant}
                grow={0.5}
                position={
                    internalProductFilter.variant !== 'PACKAGE' &&
                    internalProductFilter.type === ProductType.SEASON
                        ? 'end'
                        : 'center'
                }
            />

            {internalProductFilter.type !== ProductType.SEASON && (
                <>
                    <VerticalDivider
                        hide={
                            hideDividers.includes(1) ||
                            currentAnchorEl === variantSelection.current ||
                            currentAnchorEl === dateSelection.current
                        }
                        containedFocused={!!currentAnchorEl}
                    />

                    <SelectionButton
                        ref={dateSelection}
                        title={getTranslated('Product.startDay.from')}
                        label={
                            <Typography
                                sx={{
                                    '&:first-letter': {
                                        textTransform: 'capitalize',
                                    },
                                }}
                            >
                                {internalProductFilter.date
                                    ? getDateString(internalProductFilter.date)
                                    : getTranslated('Core.chooseDate')}
                            </Typography>
                        }
                        onClick={handleClick('date')}
                        position={
                            internalProductFilter.variant === 'PACKAGE'
                                ? 'center'
                                : 'end'
                        }
                        adornment={
                            internalProductFilter.variant === 'PACKAGE' ? (
                                <SearchBox
                                    onClick={() => {
                                        setSelectedType(null);
                                        setCurrentAnchorEl(null);
                                    }}
                                />
                            ) : null
                        }
                        onMouseEnter={addToHideDivider([1])}
                        onMouseLeave={removeFromHideDivder([1])}
                        disabled={!!presetFields.date}
                        selected={currentAnchorEl === dateSelection.current}
                        containerFocused={!!currentAnchorEl}
                        grow={0.5}
                    />
                </>
            )}

            {internalProductFilter.variant === 'PACKAGE' && (
                <>
                    <VerticalDivider
                        hide={
                            hideDividers.includes(2) ||
                            currentAnchorEl === personSelection.current
                        }
                        containedFocused={!!currentAnchorEl}
                    />
                    <SelectionButton
                        ref={personSelection}
                        title={getTranslated('productFilterChoosePersons')}
                        label={
                            <PersonsLabel
                                persons={internalProductFilter.persons}
                            />
                        }
                        onClick={handleClick('person')}
                        position='end'
                        onMouseEnter={addToHideDivider([2])}
                        onMouseLeave={removeFromHideDivder([2])}
                        adornment={
                            <SearchBox
                                onClick={() => {
                                    updateProductFilter(
                                        'persons',
                                        internalProductFilter.persons
                                    );
                                    setSelectedType(null);
                                    setCurrentAnchorEl(null);
                                }}
                            />
                        }
                        selected={currentAnchorEl === personSelection.current}
                        containerFocused={!!currentAnchorEl}
                        grow={0.75}
                    />
                </>
            )}

            <Popover
                sx={{
                    m: 2,
                }}
                open={!!currentAnchorEl}
                anchorEl={currentAnchorEl}
                onClose={() => {
                    userClosedPopover.current = true;
                    setCurrentAnchorEl(null);
                    setSelectedType(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    id: 'product-selection-popover-paper',
                    sx: {
                        zIndex: 5,
                        margin: 2,
                    },
                }}
            >
                {selectedType == 'ticketType' && (
                    <TicketTypeSelectionContent
                        liftOperator={selectedLiftOperator}
                        onSelect={(producType) => {
                            updateInternalProductFilter('type', producType);
                        }}
                    />
                )}

                {selectedType == 'date' && (
                    <DateSelectionContent
                        dateDefaultValue={internalProductFilter.date}
                        onDateSelect={(date) => {
                            updateInternalProductFilter('date', date);
                        }}
                    />
                )}

                {selectedType === 'variant' && (
                    <VariantSelectionContent
                        onSelect={(variant) => {
                            updateInternalProductFilter('variant', variant);
                        }}
                    />
                )}

                {productFilerTypeKey !== null && selectedType === 'person' && (
                    <PersonCounterContent
                        personsToSelect={
                            productsOverview[productFilerTypeKey].packageProduct
                                .persons || []
                        }
                        selectedPerson={internalProductFilter.persons || []}
                        onPersonChange={(personCounts) => {
                            updateInternalProductFilter(
                                'persons',
                                personCounts
                            );
                        }}
                    />
                )}
            </Popover>
        </Paper>
    );
};

type SearchBoxProps = {
    onClick: () => void;
};

const SearchBox: FunctionComponent<SearchBoxProps> = ({ onClick }) => {
    return (
        <Box
            sx={{
                ml: 'auto',
            }}
        >
            <Fab onClick={onClick} color='primary' aria-label='add'>
                <SearchIcon />
            </Fab>
        </Box>
    );
};

const VerticalDivider: FunctionComponent<{
    hide: boolean;
    containedFocused: boolean;
}> = ({ hide, containedFocused }) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '1px',
            backgroundColor: containedFocused ? withAlphas().main : '#fff',

            transition: 'all 100ms linear',
        }}
    >
        <Divider
            sx={{
                height: '75%',
                ...(hide && {
                    visibility: 'hidden',
                }),
            }}
            orientation='vertical'
        />
    </Box>
);

const TicketTypeLabel: FunctionComponent<{
    type: ProductType;
    liftOperator: LiftOperator;
}> = ({ type, liftOperator }) => {
    const { getTranslated } = useTranslation();
    const { dayTicketButtonText, seasonTicketButtonText } = useGetButtonLabel({
        liftOperator,
    });

    return type ? (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {type === ProductType.DAY ? <TodayIcon /> : <DateRangeIcon />}

            <Typography
                sx={{
                    ml: 1,
                }}
            >
                {type === ProductType.DAY
                    ? dayTicketButtonText
                    : seasonTicketButtonText}
            </Typography>
        </Box>
    ) : (
        <Typography>
            {getTranslated('productFilterChooseTicketType')}
        </Typography>
    );
};

const TicketVariantLabel: FunctionComponent<{ variant: TicketVariant }> = ({
    variant,
}) => {
    const { getTranslated } = useTranslation();

    return variant ? (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {variant === TicketVariant.SINGLE ? <PersonIcon /> : <GroupIcon />}

            <Typography
                sx={{
                    ml: 1,
                }}
            >
                {variant === TicketVariant.SINGLE
                    ? getTranslated('Core.singleTicket')
                    : getTranslated('Core.packageTicket')}
            </Typography>
        </Box>
    ) : (
        <Typography>
            {getTranslated('productFilterChooseTicketVariant')}
        </Typography>
    );
};

const PersonsLabel: FunctionComponent<{ persons: PersonCount[] }> = ({
    persons,
}) => {
    const { getTranslated } = useTranslation();

    const personsString = persons
        ?.filter((p) => p.quantity > 0)
        .reduce((buildString, person, currentIndex) => {
            if (person.quantity === 0) return buildString;

            const isLast = currentIndex === persons.length - 1;

            return `${buildString}${person.quantity}x${getPropByCurrentLocale(
                person.name
            ).substring(0, 3)}.${isLast ? '' : ','} `;
        }, '');

    return persons?.length ? (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Typography
                sx={{
                    ml: 1,
                    width: '150px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {personsString}
            </Typography>
        </Box>
    ) : (
        <Typography>{getTranslated('productFilterChoosePersons')}</Typography>
    );
};
