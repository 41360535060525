import { useConfigSlice } from '@/hooks/useConfigSlice';
import { promotions } from '@/constants/promotions';
import { Promotion } from '@/models/promotion';

export const usePromotion = (): Promotion => {
    const { pageConfig } = useConfigSlice();

    const vanityIdentifier = pageConfig?.configuration?.vanityIdentifier;

    if (!vanityIdentifier) {
        return null;
    }

    return promotions.find((p) => p.name === vanityIdentifier) || null;
};
