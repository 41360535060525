import { FunctionComponent } from 'react';
import { Button } from '@/components/Layout/Button/Button';
import { Theme } from '../../../theme/theme';
import GoogleIcon from '@/assets/icons/googleLight.svg?react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    googleButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(0, 2),
        color: '#535353',
        backgroundColor: '#fff',
        width: 272,
        height: 58,

        '&:hover': {
            backgroundColor: '#fff',
        },

        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0),
            width: '100%',
        },
    },

    googleIcon: {
        marginLeft: theme.spacing(-5),
    },
}));

export const GoogleButton: FunctionComponent<{
    onClick: () => void;
    children: React.ReactNode;
}> = ({ onClick, children }) => {
    const classes = useStyles();

    return (
        <Button
            isLoading={false}
            className={classes.googleButton}
            variant='contained'
            onClick={onClick}
        >
            <GoogleIcon className={classes.googleIcon} />
            {children}
        </Button>
    );
};
