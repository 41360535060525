import { Button } from '@/components/Layout/Button/Button';
import { FlexContainer } from '@/components/Layout/Container';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const ProductsPageNavigation = () => {
    const navigate = useNavigate();
    const { routerConfig, getPagePath } = useCustomRouter();
    return (
        <FlexContainer
            sx={{
                p: 3,
                alignItems: 'center',
            }}
        >
            <Button
                sx={{
                    mr: 'auto',
                }}
                onClick={() => {
                    navigate(getPagePath(routerConfig.LiftOperators));
                }}
                variant='outlined'
            >
                <KeyboardArrowLeftIcon
                // sx={(theme) => ({
                //     color: theme.palette.primary.main,
                // })}
                />
                Zu den Bergbahnen
            </Button>
        </FlexContainer>
    );
};
