import { FunctionComponent, PropsWithChildren } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import Dialog from '@mui/material/Dialog';

import Button from '@mui/material/Button';
import { UserTicket } from '@/models/ticket';
import { useNotification } from '@/hooks/useNotification';
import {
    usePostTicketCancelInsuranceMutation,
    usePostTicketRevokeInsuranceMutation,
} from '@/api/order';

type DeleteInsuranceDialogProps = {
    open: boolean;
    onClose: () => void;
    ticket: UserTicket;
    type: 'cancel' | 'refund';
    onDialogClosed?: () => void;
};

export const DeleteInsuranceDialog: FunctionComponent<
    PropsWithChildren<DeleteInsuranceDialogProps>
> = ({ open, onClose, type, onDialogClosed, ticket }) => {
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const [postCancelInsurance, { isLoading: cancelLoading }] =
        usePostTicketCancelInsuranceMutation();
    const [postRevokeInsurance, { isLoading: revokeLoading }] =
        usePostTicketRevokeInsuranceMutation();

    const textBody =
        type === 'cancel'
            ? getTranslated('myInsurancePage.deleteDialog.body.cancel')
            : getTranslated('myInsurancePage.deleteDialog.body.refund');

    const onDelete = () => {
        if (ticket.insurance) {
            const mutation =
                type === 'cancel' ? postCancelInsurance : postRevokeInsurance;
            mutation({
                ticketId: ticket.id,
            })
                .unwrap()
                .then(() => {
                    addNotification({
                        type: 'success',
                        message: getTranslated(
                            type === 'refund'
                                ? 'myInsurancePage.deleteDialog.refund.response.success'
                                : 'myInsurancePage.deleteDialog.cancel.response.success'
                        ),
                    });
                    onClose();
                })
                .catch(() => {
                    addNotification({
                        type: 'error',
                        message:
                            type === 'refund'
                                ? getTranslated(
                                      'myInsurancePage.deleteDialog.refund.response.error'
                                  )
                                : getTranslated(
                                      'myInsurancePage.deleteDialog.cancel.response.error'
                                  ),
                    });
                });
        }
    };

    return (
        <Dialog
            maxWidth='md'
            open={open}
            onClose={onClose}
            slotProps={{
                transition: {
                    onExited: () => {
                        onDialogClosed?.();
                    },
                },
            }}
        >
            <DialogTitle>
                {getTranslated(
                    type === 'refund'
                        ? 'myInsurancePage.deleteDialog.title.refund'
                        : 'myInsurancePage.deleteDialog.title.cancel'
                )}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{textBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary'>
                    {getTranslated('Core.cancel')}
                </Button>
                <Button
                    loading={cancelLoading || revokeLoading}
                    onClick={onDelete}
                    color='error'
                    variant='contained'
                >
                    {getTranslated(
                        type === 'cancel'
                            ? 'Core.ticket.cancel.button.label'
                            : 'Core.ticket.refund.button.label'
                    )}
                </Button>
            </DialogActions>
            <DialogContentText
                sx={{
                    padding: '16px',
                    color: 'text.secondary',
                    fontSize: '0.85rem',
                }}
                dangerouslySetInnerHTML={{
                    __html: getTranslated(
                        type === 'cancel'
                            ? 'myInsurancePage.deleteDialog.caption.cancel'
                            : 'myInsurancePage.deleteDialog.caption.refund'
                    ),
                }}
            ></DialogContentText>
        </Dialog>
    );
};
