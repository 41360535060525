import { FunctionComponent, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FlexContainer } from '@/components/Layout/Container';
import { Button } from '@/components/Layout/Button/Button';
import { FormikTextField } from '@/components/Layout/Form/Field';
import { Form, Formik } from 'formik';
import { translateFunction, useTranslation } from '@/hooks/useTranslation';
import { getCurrentLocale } from '@/utils/locale';
import { useGetUserDataQuery, usePutPasswordMutation } from '@/api/user';
import { useNotification } from '@/hooks/useNotification';

const initialValues = {
    old: '',
    new: '',
    newRepeat: '',
};

const validate = (values) => {
    const errors: Partial<typeof initialValues> = {};
    const locale = getCurrentLocale();

    if (!values.old) {
        errors.old = translateFunction(
            'validation.error.password.current',
            locale
        );
    }

    if (!values.new) {
        errors.new = translateFunction(
            'validation.error.password.addNew',
            locale
        );
    }

    if (!values.newRepeat) {
        errors.newRepeat = translateFunction(
            'validation.error.password.addNewAgain',
            locale
        );
    }

    if (values.new && values.newRepeat && values.new !== values.newRepeat) {
        errors.newRepeat = translateFunction(
            'validation.error.password.match',
            locale
        );
    }

    return errors;
};

type EditEmailDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const EditPasswordDialog: FunctionComponent<
    React.PropsWithChildren<EditEmailDialogProps>
> = ({ isOpen, onClose }) => {
    const { data: currentUser } = useGetUserDataQuery();
    const [putPassword, { isLoading: passwordLoading }] =
        usePutPasswordMutation();
    const { getTranslated } = useTranslation();
    const [open, setOpen] = useState(isOpen);

    const { addNotification } = useNotification();

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <FlexContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle id='form-dialog-title'>
                    {getTranslated('Header.changePassword')}{' '}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {getTranslated('Profile.enterYourPassword')}
                    </DialogContentText>

                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        validateOnChange={false}
                        onSubmit={(values) => {
                            // update password

                            putPassword({
                                id: currentUser.id,
                                current: values.old,
                                password: values.new,
                                password2: values.newRepeat,
                            })
                                .unwrap()
                                .then(() => {
                                    addNotification({
                                        message: getTranslated(
                                            'PasswordForgottenForm.success'
                                        ),
                                        type: 'success',
                                    });
                                    onClose();
                                })
                                .catch(() => {
                                    addNotification({
                                        message: getTranslated(
                                            'PasswordForgottenForm.error'
                                        ),
                                        type: 'error',
                                    });
                                });
                        }}
                    >
                        <Form
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                        >
                            <FlexContainer column>
                                <FormikTextField
                                    name={'old'}
                                    textFieldProps={{
                                        sx: {
                                            m: 1,
                                        },
                                        label: getTranslated(
                                            'Profile.oldPassword'
                                        ),
                                        variant: 'outlined',
                                        type: 'password',
                                    }}
                                />
                                <FormikTextField
                                    name={'new'}
                                    textFieldProps={{
                                        sx: {
                                            m: 1,
                                        },
                                        label: getTranslated(
                                            'Profile.newPassword'
                                        ),
                                        variant: 'outlined',
                                        type: 'password',
                                    }}
                                />
                                <FormikTextField
                                    name={'newRepeat'}
                                    textFieldProps={{
                                        sx: {
                                            m: 1,
                                        },
                                        label: getTranslated(
                                            'Profile.passwordRepeat'
                                        ),
                                        variant: 'outlined',
                                        type: 'password',
                                    }}
                                />
                            </FlexContainer>

                            <DialogActions>
                                <Button
                                    isLoading={false}
                                    onClick={handleClose}
                                    color='primary'
                                >
                                    {getTranslated('Core.cancel')}
                                </Button>
                                <Button
                                    isLoading={passwordLoading}
                                    type='submit'
                                    color='primary'
                                >
                                    {getTranslated('common.save')}
                                </Button>
                            </DialogActions>
                        </Form>
                    </Formik>
                </DialogContent>
            </Dialog>
        </FlexContainer>
    );
};
