import { CircularProgress, TextField, Typography } from '@mui/material';
import { Fragment, FunctionComponent, useCallback, useState } from 'react';
import { Address } from '@/models/user';
import { AddressAutoComplete } from '@/components/AutoComplete/AddressAutoComplete';
import {
    AddressValidationAddressComponent,
    AddressValidationComponentType,
    AddressValidationResponse,
    filterRelevantMissingComponents,
    findMissingComponentsFromAddressComponents,
} from '@/models/googleAddressValidation';
import { useTranslation } from '@/hooks/useTranslation';
import { FlexContainer } from '@/components/Layout/Container';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const getAddressValidation = async (
    addressLine: string
): Promise<AddressValidationResponse> => {
    const response = await fetch(
        `https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyAbIWnuh9ECNBFlMsvkkbgcN4jwI7DY_Q8`,
        {
            method: 'POST',
            body: JSON.stringify({
                address: {
                    addressLines: [addressLine],
                },
            }),
        }
    );

    if (response.status !== 200) {
        return null;
    } else {
        return (await response.json()) as AddressValidationResponse;
    }
};

const validateAddressForMissingComponents = (
    addressData: AddressValidationAddressComponent
) => {
    let error: AddressValidationComponentType = null;

    if (addressData.missingComponentTypes?.length) {
        const missingComponents = filterRelevantMissingComponents(
            addressData.missingComponentTypes
        );

        if (missingComponents.length) {
            error = missingComponents[0];
        }
    } else {
        error = findMissingComponentsFromAddressComponents(
            addressData.addressComponents
        );
    }

    return error;
};

type GoogleAddressAutoCompleteProps = {
    initialAddress: Address;
    onAddressChange: (address: Address) => void;
};

export const GoogleAddressAutoComplete: FunctionComponent<
    GoogleAddressAutoCompleteProps
> = ({ initialAddress, onAddressChange }) => {
    const [validationSucceeded, setValidationSucceeded] = useState(false);
    const [error, setError] = useState<AddressValidationComponentType>();
    const [loading, setLoading] = useState(false);
    const { getTranslated } = useTranslation();

    const getErrorMessage = useCallback(
        (error: AddressValidationComponentType) => {
            switch (error) {
                case 'street_number':
                    return getTranslated(
                        'error.addressValidation.missing_street_number'
                    );
                case 'postal_code':
                    return getTranslated(
                        'error.addressValidation.missing_postal_code'
                    );
                case 'locality':
                    return getTranslated(
                        'error.addressValidation.missing_locality'
                    );
                default:
                    return getTranslated(
                        'error.addressValidation.missing_general'
                    );
            }
        },
        []
    );

    const fetchAddress = async (addressLine: string) => {
        setLoading(true);
        setError(null);

        const addressValidation = await getAddressValidation(addressLine);

        setLoading(false);

        const error = validateAddressForMissingComponents(
            addressValidation.result.address
        );

        if (error) {
            setValidationSucceeded(false);
            setError(error);
        } else {
            setValidationSucceeded(true);
            const { locality, addressLines, postalCode, regionCode } =
                addressValidation.result.address.postalAddress;
            onAddressChange({
                ...initialAddress,
                street: addressLines[0],
                zipcode: postalCode,
                city: locality,
                country: regionCode,
            });
        }
    };

    return (
        <FlexContainer column>
            <AddressAutoComplete
                onSelect={(address) => {
                    setError(null);

                    if (address) {
                        fetchAddress(address.description);
                    }
                }}
                onInputChange={(_1, _2, reason) => {
                    if (reason === 'clear') {
                        setValidationSucceeded(false);
                        setError(null);
                    }
                }}
                renderInput={(props) => (
                    <TextField
                        label={getTranslated(
                            'googleAddressAutoComplete.enterAndSearch'
                        )}
                        sx={{
                            width: '100%',
                        }}
                        variant='outlined'
                        {...props}
                        InputProps={{
                            ...props.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color='inherit'
                                            size={20}
                                        />
                                    ) : validationSucceeded ? (
                                        <TaskAltIcon
                                            sx={{
                                                fontSize: 20,
                                            }}
                                            color='success'
                                        />
                                    ) : null}
                                    {props.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                    />
                )}
            />

            {!!error && (
                <Typography
                    color='error'
                    variant='subtitle2'
                    sx={{
                        mt: 2,
                        ml: 1,
                    }}
                >
                    {getErrorMessage(error)}
                </Typography>
            )}
        </FlexContainer>
    );
};
