import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Keycard,
    Order,
    OrderInfo,
    PaymentMethod,
    PostOrdersResponseBackend,
} from '@/models/order';
import { VoucherTemplate } from '@/models/voucher';
import { Profile } from '@/models/user';
import { getFromLocalStorage, saveToLocalStorage } from '@/utils/storage';

import { PostPaymentResponse } from '@/models/payment';

export type OrderState = {
    orders: Order[];
    orderResponse: PostOrdersResponseBackend;
    paymentResponse: PostPaymentResponse;
    orderInfo: OrderInfo;
    keycards: Keycard[];
    selectedVoucher: VoucherTemplate;
    selectedProfile: Profile;
    paymentMethod: PaymentMethod;
    savePaymentData: boolean;
};

const initialState: OrderState = {
    orders: [],
    orderResponse: null,
    paymentResponse: getFromLocalStorage('paymentResponse', '') || null,
    orderInfo: null,
    keycards: [],
    selectedVoucher: null,
    selectedProfile: null,
    paymentMethod: PaymentMethod.TOKEN,
    savePaymentData: false,
};

export const ordersSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        setOrders(state: OrderState, action: PayloadAction<Order[]>) {
            state.orders = action.payload;

            return state;
        },

        setPaymentResponse(
            state: OrderState,
            action: PayloadAction<PostPaymentResponse>
        ) {
            saveToLocalStorage('paymentResponse', action.payload);
            state.paymentResponse = action.payload;

            return state;
        },

        setProfile(state: OrderState, action: PayloadAction<Profile>) {
            state.selectedProfile = action.payload;

            return state;
        },

        setPaymentMethod(
            state: OrderState,
            action: PayloadAction<PaymentMethod>
        ) {
            state.paymentMethod = action.payload;

            return state;
        },

        setSavePaymentData(state: OrderState, action: PayloadAction<boolean>) {
            state.savePaymentData = action.payload;

            return state;
        },
    },
});
