import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { Typography } from '@mui/material';
import { getMoneyAsString } from '@/utils/general';

import { BackendVoucher } from '@/models/voucher';
import { useTranslation } from '@/hooks/useTranslation';
import { useItemStyles } from '@/components/PageContent/CartPageContent/CheckoutItemList/itemStyles';

type VoucherEntryProps = {
    voucher: BackendVoucher;
};

export const VoucherEntry: FunctionComponent<
    React.PropsWithChildren<VoucherEntryProps>
> = ({ voucher }) => {
    const classes = useItemStyles();
    const { getTranslated } = useTranslation();

    return (
        <FlexContainer flexGrow={1} className={classes.cartContainerItem}>
            <FlexContainer className={classes.cartContainerItemLeftIco}>
                <CardGiftcardIcon />
            </FlexContainer>
            <FlexContainer column>
                <Typography gutterBottom>
                    {getTranslated('VoucherPage.headline')}: {voucher.name}
                </Typography>

                <Typography>
                    {getTranslated('VoucherPage.sender')}: {voucher.sender}
                </Typography>
                <Typography>
                    {getTranslated('VoucherPage.receiver')}: {voucher.receiver}
                </Typography>
            </FlexContainer>
            <FlexContainer className={classes.cartContainerItemRightIco}>
                <Typography className={classes.cartContainerItemPrice}>
                    {getMoneyAsString(voucher.amount)}
                </Typography>
            </FlexContainer>
        </FlexContainer>
    );
};
