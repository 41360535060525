import { FunctionComponent, useState } from 'react';
import { useTranslation } from '@/hooks/useTranslation';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useModal } from '@/hooks/useModal';
import { DialogContentText, TextField } from '@mui/material';
import { useNotification } from '@/hooks/useNotification';
import { useGetUserDataQuery } from '@/api/user';
import { usePostDeleteAccountMutation } from '@/api/authentication';
import { isOfTypeRTKAPIError } from '@/models/request';

type DeleteAccountDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    confirmWithPassword: boolean;
};

export const DeleteAccountContainer: FunctionComponent<
    React.PropsWithChildren<DeleteAccountDialogProps>
> = ({ isOpen, onClose, confirmWithPassword }) => {
    const DeleteDialog = useModal('delete');
    const { data: currentUser } = useGetUserDataQuery();
    const [postDeleteAccount, { isLoading }] = usePostDeleteAccountMutation();
    const { logout } = useAuthentication();
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const getErrorMessage = (error: string): string => {
        switch (error) {
            case 'incorrect_password':
                return getTranslated(
                    'validation.error.changePassword.incorrectPassword'
                );
            default:
                return getTranslated('validation.error.payment.unknownError');
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;

        if (error) {
            setError(false);
        }

        setPassword(value);
    };

    const onDelete = async () => {
        if (!password && confirmWithPassword) {
            setError(true);
            return;
        }

        postDeleteAccount({
            accountId: currentUser.id,
            password,
        })
            .unwrap()
            .then(() => {
                logout();
                onClose();
            })
            .catch((e) => {
                if (isOfTypeRTKAPIError(e)) {
                    addNotification({
                        type: 'error',
                        message: getErrorMessage(e.data.errors[0]?.error),
                    });
                }
            });
    };

    return (
        <DeleteDialog
            title={getTranslated('Profile.deleteAccount')}
            onDelete={onDelete}
            onClose={onClose}
            deleteButtonLabel={getTranslated('Profile.deleteAccount')}
            deleteButtonLoading={isLoading}
            open={isOpen}
        >
            <DialogContentText
                sx={{
                    mb: 2,
                }}
            >
                {getTranslated('Profile.confirmAccountDeletion')}
            </DialogContentText>

            {confirmWithPassword && (
                <TextField
                    label={getTranslated('Core.password')}
                    type='password'
                    value={password}
                    onChange={handleChange}
                    variant='outlined'
                    helperText={
                        error &&
                        getTranslated('validation.error.password.requiredShort')
                    }
                    error={error}
                    fullWidth
                />
            )}
        </DeleteDialog>
    );
};
