export const NODE_ENV = process.env.NODE_ENV;

if (NODE_ENV !== 'production') {
    console.info(
        `%cNODE_ENV ${process.env.NODE_ENV}`,
        'font-size: 24px; padding: 12px; color: #03a9f4'
    );
}

export const ORIGIN = window.origin;

const mode = import.meta.env.VITE_OVERRIDE_MODE || import.meta.env.MODE;

export const IS_DEVELOPMENT = mode !== 'production';

export const IS_MOBILE_APP =
    window.navigator.userAgent.includes('starjack-mobile');

export const CURRENT_LOCALE: 'de' | 'en' = 'de';

export const API = IS_DEVELOPMENT
    ? 'https://api.staging.starjack.com/'
    : 'https://api.starjack.com/';

export const DEFAULT_API_VERSION = 'v1/';
export const LOCAL_STORAGE_KEY = `${
    IS_DEVELOPMENT ? '__dev__' : '__prod__'
}starjack-portal`;

export const LOCAL_STORAGE_KEY_IMAGE = `${LOCAL_STORAGE_KEY}_image_`;

// localstorage nodes
export const LOCAL_STORAGE_KEY_AUTH = 'auth';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_API = 'YYYY-MM-DD';

export const GOOGLE_MAP_API_KEY = 'AIzaSyB8iVXko6_Io_CydY8SI_PLQG32T1qOT34';

export const ACCEPTED_FILES_IMAGE = ['.png', '.jpg', '.jpeg'];

// TODO make keycard costs dynamic
export const KEYCARD_COST = {
    amount: 4.9,
    currency: 'EUR',
};

export const TAELER_ID = '913ea511-a676-4294-95aa-649878de7115';

export const NEWSLETTER_EN = '9e11704beb';
export const NEWSLETTER_DE = '99a55e74fc';

export const CARDTYPE_IDS = {
    feldberg: 'cdd0345d-3d9b-428a-8af6-ba151668eab7',
    skipluscity: '54adb295-7290-4828-876e-7e2ed6a69648',
    stubai: '9675a007-9753-413e-b011-337bf6259fe2',
    brauhaus: 'da720dcd-cd86-4792-9aed-4fc300bfaf54',
    skijuwel: 'be51a8b2-88ab-44ea-82da-fe6a61576aa6',
    willingen: '580ad0f6-7582-4e06-a594-f1c6a5c278c0',
    skiliftkarussell: '131f5e35-b51e-4b01-b68e-82e74cd128b5',
};

export const OEAMTC_ID = 'oeamtc';
