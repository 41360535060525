import { FunctionComponent, useMemo } from 'react';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';

import { useConfigSlice } from '@/hooks/useConfigSlice';
import {
    CheckoutTicketCollection,
    ticketCollectionReadyForPayment,
} from '@/models/collection/checkoutTicketCollection';
import { useSlice } from '@/hooks/useSlice';
import { checkoutSlice } from '@/slices/checkoutSlice';
import { useGetCurrentCheckoutSession } from '@/api/checkout';
import {
    CheckoutValidationErrorCode,
    directTicketNotifications,
    notificationsWarningTypes,
    ticketNotificationsShownGlobally,
} from '@/models/checkout/checkoutValidation';
import Button from '@mui/material/Button';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useNotification } from '@/hooks/useNotification';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';

type AlertType =
    | {
          type: 'info' | 'success';
      }
    | {
          type: 'error' | 'warning';
          error: CheckoutValidationErrorCode;
          errorVariant: 'direct' | 'global';
      };

// lower is more important
const checkoutValidationErrorSeverityMap = {
    [CheckoutValidationErrorCode.TICKET_EXPIRED]: 0,
    [CheckoutValidationErrorCode.TICKET_IDENTIFICATION_TYPE_NOT_SET]: 1,

    [CheckoutValidationErrorCode.NO_IDENTIFICATION_ASSIGNED]: 1,
    [CheckoutValidationErrorCode.PHOTO_REQUIRED]: 2,
    [CheckoutValidationErrorCode.DUPLICATE_TICKET]: 2,
    [CheckoutValidationErrorCode.START_DATE_IN_PAST]: 3,
    [CheckoutValidationErrorCode.START_DATE_TOO_EARLY_HARD]: 3,
    [CheckoutValidationErrorCode.START_DATE_TOO_EARLY_SOFT]: 3,
    [CheckoutValidationErrorCode.INVALID_REF]: 3,
};

export const CheckoutTicketCollectionBottomInfo: FunctionComponent<{
    ticketCollection: CheckoutTicketCollection;
}> = ({ ticketCollection }) => {
    const { getTranslated, locale } = useTranslation();
    const {
        state: { paymentSubmitTries },
    } = useSlice(checkoutSlice, 'checkout');
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const { addNotification } = useNotification();
    const matchesMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const alert: AlertType = useMemo(() => {
        // if ready for payment always show success
        if (ticketCollectionReadyForPayment(ticketCollection)) {
            return { type: 'success' };
        }

        const ticketIds = ticketCollection.checkoutTickets.map(
            (ticket) => ticket.id
        );

        // if no tries always show info
        if (paymentSubmitTries === 0) {
            return { type: 'info' };
        }

        // check if a validation error contains an error for a ticket of this ticketCollection
        const validationErrors = checkoutSession?.validation.errors.filter(
            (error) => ticketIds.includes(error.ticketId)
        );

        if (!validationErrors.length) {
            return { type: 'success' };
        }

        // filter errors that should be shown directly at the ticket
        const directErrorsInCollection = validationErrors.filter(
            (error) =>
                directTicketNotifications.includes(error.error) ||
                ticketNotificationsShownGlobally.includes(error.error)
        );

        const errorsSorted = directErrorsInCollection.sort((a, b) => {
            const sortErrorA =
                checkoutValidationErrorSeverityMap[a.error] || -1;
            const sortErrorB =
                checkoutValidationErrorSeverityMap[b.error] || -1;
            return sortErrorA - sortErrorB;
        });

        if (errorsSorted.length === 0) {
            return {
                type: 'success',
            };
        }

        const [firstError] = errorsSorted;

        return {
            type: notificationsWarningTypes.includes(firstError.error)
                ? 'warning'
                : 'error',
            error: firstError.error,
            errorVariant: ticketNotificationsShownGlobally.includes(
                firstError.error
            )
                ? 'global'
                : 'direct',
        };
    }, [paymentSubmitTries, checkoutSession]);

    const Icon = useMemo(() => {
        switch (alert.type) {
            case 'info':
                return InfoIcon;
            case 'success':
                return CheckCircleOutlineIcon;
            case 'warning':
            case 'error':
                return ErrorIcon;
        }
    }, [alert]);

    const errorMessage = useMemo(() => {
        if (alert.type === 'error' || alert.type === 'warning') {
            if (alert.errorVariant === 'direct') {
                return getTranslated(
                    `checkoutValidation_${alert.error.toLocaleLowerCase()}` as TranslationKey
                );
            } else {
                return getTranslated(
                    alert.type === 'error'
                        ? 'checkoutValidationTicketGeneralError'
                        : 'checkoutValidationTicketGeneralWarning'
                );
            }
        }
        return '';
    }, [alert, locale]);

    const onExtraErrorInfoClick = () => {
        if (alert.type === 'error' || alert.type === 'warning') {
            addNotification({
                type: alert.type,
                message: getTranslated(
                    `checkoutValidation_${alert.error.toLocaleLowerCase()}` as TranslationKey
                ),
            });
        }
    };

    const { IS_OEAMTC } = useConfigSlice();
    const theme = useTheme();
    const color = IS_OEAMTC ? '#000' : '#fff';
    const bgColor = useMemo(() => {
        switch (alert.type) {
            case 'info':
                return theme.palette.primary.main;
            case 'success':
                return theme.palette.success.main;
            case 'error':
                return theme.palette.error.main;
            case 'warning':
                return theme.palette.warning.main;
        }
    }, [alert?.type, theme]);

    return (
        <FlexContainer
            sx={{
                alignItems: 'center',
                width: '100%',
                height: '80px',
                p: 3,
                bgcolor: bgColor,
            }}
        >
            {!!Icon && (
                <Icon
                    sx={{
                        color: alert.type === 'info' ? color : '#fff',
                        fontSize: '1.6rem',
                        mr: 2,
                    }}
                />
            )}

            {alert.type === 'info' && (
                <Typography
                    sx={{
                        color,
                    }}
                >
                    {getTranslated('personIdentSelectInfo')}
                </Typography>
            )}

            {alert.type === 'success' && (
                <Typography
                    sx={{
                        color: '#fff',
                    }}
                >
                    {getTranslated('personIdentSelectSuccess')}
                </Typography>
            )}

            {alert.type === 'error' || alert.type === 'warning' ? (
                <FlexContainer
                    sx={{
                        alignItems: 'center',
                        clor: '#fff',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#fff',
                        }}
                    >
                        {errorMessage}
                    </Typography>

                    {alert.errorVariant === 'global' && (
                        <Button
                            sx={{
                                color: '#fff',
                                ml: 'auto',
                            }}
                            variant='outlined'
                            color='inherit'
                            onClick={onExtraErrorInfoClick}
                        >
                            {!matchesMobile && getTranslated('readMore')}

                            <KeyboardArrowRight
                                sx={{
                                    ml: 1,
                                }}
                            />
                        </Button>
                    )}
                </FlexContainer>
            ) : null}
        </FlexContainer>
    );
};
