import { FunctionComponent } from 'react';
import { FlexContainer, FlexContainerProps } from '../Container';
import { useTheme } from '@mui/material';

type FieldWrapperProps = {
    children: React.ReactNode;
} & FlexContainerProps;

export const FieldWrapper: FunctionComponent<
    React.PropsWithChildren<FieldWrapperProps>
> = ({ children, ...rest }) => (
    <FlexContainer margin={useTheme().spacing(2)} {...rest}>
        {children}
    </FlexContainer>
);
