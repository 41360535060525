import { FunctionComponent } from 'react';

import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Typography,
} from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { OrderedVoucher } from '@/models/voucher';
import IconButton from '@mui/material/IconButton';
import { urlBuilder } from '@/utils/api';
import { DATE_FORMAT } from '@/constants';
import { getMoneyAsString } from '@/utils/general';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';

export const OrderedVoucherPreviewCard: FunctionComponent<{
    orderedVoucher: OrderedVoucher;
}> = ({ orderedVoucher }) => {
    const { getTranslated } = useTranslation();

    return (
        <Card
            elevation={0}
            sx={{
                border: '1px solid #E0E0E0',
                width: '100%',
            }}
        >
            <CardHeader
                sx={{
                    borderBottom: '1px solid #E0E0E0',
                    bgcolor: 'primary.main',
                }}
                title={
                    <Typography
                        sx={{
                            fontWeight: 600,
                            color: '#fff',
                        }}
                        variant='h6'
                    >
                        {orderedVoucher.title}
                    </Typography>
                }
            />
            <CardContent
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        p: 2,
                    }}
                >
                    <Typography
                        sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 2,
                        }}
                        variant='body1'
                    >
                        {orderedVoucher.text}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            mt: 3,
                            alignItems: 'center',
                        }}
                    >
                        <VoucherLabel
                            label={`${getTranslated('price')}: `}
                            value={getMoneyAsString(orderedVoucher.price)}
                        />

                        <VeritcalDivider />

                        <VoucherLabel
                            label={`${getTranslated('common.status')}: `}
                            value={getTranslated(
                                `orderedVouchers.status.${orderedVoucher.status}` as TranslationKey
                            )}
                        />
                        {orderedVoucher?.redemptionDate && (
                            <>
                                <VeritcalDivider />
                                <VoucherLabel
                                    label={`${getTranslated(
                                        'orderedVouchers.redemptionDate'
                                    )}: `}
                                    value={orderedVoucher.redemptionDate.format(
                                        DATE_FORMAT
                                    )}
                                />
                            </>
                        )}
                    </Box>
                </Box>
            </CardContent>

            <CardActions>
                <IconButton
                    color='inherit'
                    sx={{
                        ml: 'auto',
                    }}
                >
                    <a
                        href={`${urlBuilder('order', 'vouchers')}/${
                            orderedVoucher.id
                        }.pdf`}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <DownloadForOfflineIcon
                            sx={{
                                color: '#84BEBF',
                                width: '2.5rem',
                                height: '2.5rem',
                            }}
                        />
                    </a>
                </IconButton>
            </CardActions>
        </Card>
    );
};

const VoucherLabel = ({ label, value }) => (
    <Typography
        sx={{
            display: 'flex',
            alignItems: 'center',
        }}
    >
        <Typography
            sx={{
                fontWeight: 600,
            }}
            component='span'
            variant='subtitle2'
        >
            {label}
        </Typography>{' '}
        <Typography
            sx={{
                ml: 1,
            }}
            component='span'
            variant='subtitle2'
        >
            {value}
        </Typography>
    </Typography>
);

const VeritcalDivider = () => (
    <Divider
        sx={{
            width: '1px',

            mx: 2,
            backgroundColor: 'black20',
            height: '16px',
        }}
        orientation='vertical'
    />
);
