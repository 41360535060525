import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { deleteFromLocalStorage, getFromLocalStorage } from '@/utils/storage';
import moment from 'moment';
import { merge } from 'lodash';
import { StoreLocalStorageEntity } from '@/hooks/useLocalStorageCopy';
import { PromotionDialogInput } from '@/models/promotionDialog';
import { CheckoutTicket } from '@/models/checkout/checkoutTicket';

type PersonAssignmentDialogControls = {
    ticket: CheckoutTicket;
    personId: string | null;
};

export type CartState = {
    oeamtcPerOrderLimit: number;

    // ticketId -> oeamtcCode
    ticktsAppliedOeamtcCode: {
        [ticketId: string]: string;
    };

    promotionCodeInput: PromotionDialogInput;
    personAssignmentDialogControls: PersonAssignmentDialogControls | null;

    cartContentComplete: boolean;
};

const initialStateTemplate: CartState = {
    oeamtcPerOrderLimit: null,
    ticktsAppliedOeamtcCode: {},
    personAssignmentDialogControls: null,
    promotionCodeInput: null,
    cartContentComplete: false,
};

const getStateFromLocalStorage = () => {
    const storageEntity: StoreLocalStorageEntity<'cart'> = getFromLocalStorage(
        'cart',
        null
    );

    if (storageEntity) {
        const { timestamp, storeNode } = storageEntity;
        const todayString = moment().format('DD.MM.YYYY');

        // storage data is older than today before 3:00am delete don't use this data
        if (
            timestamp <
            moment(`${todayString} 03:00`, 'DD.MM.YYYY HH:mm').unix()
        ) {
            return initialStateTemplate;
        } else {
            return merge(initialStateTemplate, storeNode);
        }
    }

    return initialStateTemplate;
};

const initialState = getStateFromLocalStorage();

export const cartSlice = createSlice({
    name: 'cartSlice',
    initialState,
    reducers: {
        clearCart() {
            deleteFromLocalStorage('cart');

            return {
                ...initialStateTemplate,
                ...initialStateTemplate,
                ticketCollection: {},
            };
        },

        cartContentComplete(state: CartState, action: PayloadAction<boolean>) {
            state.cartContentComplete = action.payload;

            return state;
        },

        setPromotionDialogInput(
            state: CartState,
            action: PayloadAction<PromotionDialogInput>
        ) {
            state.promotionCodeInput = action.payload;

            return state;
        },

        setPerOrderLimit(state: CartState, action: PayloadAction<number>) {
            state.oeamtcPerOrderLimit = action.payload;

            return state;
        },

        addUsedOeamtcCode(
            state: CartState,
            action: PayloadAction<{ oeamtcCode: string; ticketId: string }>
        ) {
            const { oeamtcCode, ticketId } = action.payload;

            state.ticktsAppliedOeamtcCode[ticketId] = oeamtcCode;

            return state;
        },

        removeOeamtcCode(
            state: CartState,
            action: PayloadAction<{ ticketId: string }>
        ) {
            delete state.ticktsAppliedOeamtcCode[action.payload.ticketId];

            return state;
        },

        setPersonAssignmentDialogControls(
            state: CartState,
            action: PayloadAction<PersonAssignmentDialogControls>
        ) {
            const { payload } = action;

            state.personAssignmentDialogControls = payload;

            return state;
        },
    },
});
