import { FunctionComponent } from 'react';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { FlexContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';

import { ReactComponent as ImprintGerman } from '@/components/PageContent/ImprintPageContent/imprint_de.md';
import { ReactComponent as ImprintEnglish } from '@/components/PageContent/ImprintPageContent/imprint_en.md';

export const ImprintPage: FunctionComponent = () => {
    const { locale, getTranslated } = useTranslation();

    const ImprintContent = locale === 'de' ? ImprintGerman : ImprintEnglish;

    return (
        <SubPageTemplate title={getTranslated('footer.imprint')}>
            <FlexContainer
                sx={{
                    flexDirection: 'column',
                    width: '80%',
                    mx: 'auto',
                }}
            >
                <ImprintContent />
            </FlexContainer>
        </SubPageTemplate>
    );
};
