import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { IdentificationBadge } from '@/components/PageContent/CartPageContent/CheckoutItemList/Ticket/IdentificationBadge';
import { IdentificationType } from '@/models/cart';

import { IdentificationSelectionCard } from '@/components/PageContent/CartPageContent/IdentificationSelection/IdentificationSelectionCard';
import { CheckoutTicket } from '@/models/checkout/checkoutTicket';
import { PossibleIdentificationAssignmentType } from '@/models/checkout/possibleIdentificationAssignment';
import { SelectionControlContainer } from '@/components/PageContent/CartPageContent/CheckoutItemList/Ticket/SelectionControlContainer';
import {
    useGetCurrentCheckoutSession,
    usePostAssignPersonToTicketMutation,
    usePutCheckoutTicketMutation,
} from '@/api/checkout';
import { useNotification } from '@/hooks/useNotification';
import { useSlice } from '@/hooks/useSlice';
import { cartSlice } from '@/slices/cartSlice';
import { AddIdentificationFormContainer } from '@/components/AddIdentificationForm/AddIdentificationFormContainer';
import { RegisterKeycardFormContainer } from '@/components/RegisterKeycardForm/RegisterKeycardFormContainer';
import { useTranslation } from '@/hooks/useTranslation';
import { useConfigSlice } from '@/hooks/useConfigSlice';

type IdentificationSelectionContainerProps = {
    ticket: CheckoutTicket;
    personId: string;
    selectedIdentificationType: IdentificationType;
};

export const IdentificationSelectionContainer: FunctionComponent<
    PropsWithChildren<IdentificationSelectionContainerProps>
> = ({ personId, ticket, selectedIdentificationType }) => {
    const [postAssignment] = usePostAssignPersonToTicketMutation();
    const { isWebshop } = useConfigSlice();

    const onlyAddAvailable =
        ticket.supportedIdentificationTypes.length === 0 ||
        isWebshop('skipluscity');

    const isInternal =
        selectedIdentificationType === IdentificationType.INTERNAL_PRODUCTION ||
        isWebshop('skipluscity');
    const [view, setView] = useState<'list' | 'add' | 'register'>(
        isInternal ? 'add' : 'list'
    );
    const { getTranslated } = useTranslation();

    const person = ticket.persons.find((p) => p.id === personId);
    const possibleSuggestions = person.possibleAssignments.filter((pA) => {
        if (selectedIdentificationType === IdentificationType.KEYCARD) {
            return pA.type === PossibleIdentificationAssignmentType.KEYCARD;
        } else if (
            selectedIdentificationType ===
            IdentificationType.INTERNAL_PRODUCTION
        ) {
            return (
                pA.type ===
                PossibleIdentificationAssignmentType.INTERNAL_PRODUCTION
            );
        } else {
            return pA.type === PossibleIdentificationAssignmentType.SUGGESTION;
        }
    });
    const ticketIsPackage = ticket.persons.length > 1;
    const { data: checkout } = useGetCurrentCheckoutSession();
    const [putCheckoutTicket] = usePutCheckoutTicketMutation();
    const {
        actions: { setPersonAssignmentDialogControls },
    } = useSlice(cartSlice, 'cart');
    const { addNotification } = useNotification();

    const onResetIdentificationType = () => {
        putCheckoutTicket({
            checkoutId: checkout.id,
            id: ticket.id,
            identificationType: null,
        });
    };

    const showBackButton =
        !onlyAddAvailable &&
        (ticketIsPackage ||
            ticket.supportedIdentificationTypes.length > 1 ||
            view !== 'list');

    return (
        <>
            <IdentificationSelectionCard
                title={selectedIdentificationType}
                productionType={selectedIdentificationType}
                viewType={view}
                onBack={
                    showBackButton
                        ? () => {
                              if (
                                  view === 'list' ||
                                  ticketIsPackage ||
                                  isInternal
                              ) {
                                  onResetIdentificationType();
                              } else {
                                  setView('list');
                              }
                          }
                        : null
                }
                backButtonLabel={ticketIsPackage ? 'changeBookingOption' : null}
            >
                <FlexContainer
                    sx={{
                        flexWrap: 'wrap',
                        width: '100%',
                        alignItems: 'flex-start',
                    }}
                >
                    {view === 'list' &&
                        possibleSuggestions.map((possibleAssignment, index) => (
                            <IdentificationBadge
                                onSelect={() => {
                                    postAssignment({
                                        checkoutId: checkout.id,
                                        possibleAssignmentId:
                                            possibleAssignment.id,
                                        ticketId: ticket.id,
                                        personId: personId,
                                        cardType: null,
                                    })
                                        .unwrap()
                                        .then(() => {
                                            addNotification({
                                                type: 'success',
                                                message:
                                                    'Person wurde erfolgreich hinzugefügt',
                                            });

                                            setPersonAssignmentDialogControls(
                                                null
                                            );
                                        });
                                }}
                                key={index}
                                color={null}
                                isAssigned={
                                    possibleAssignment?.id ===
                                    person?.assignedIdentification?.id
                                }
                                assignmentSuggestion={possibleAssignment}
                            />
                        ))}

                    {view === 'register' && (
                        <RegisterKeycardFormContainer
                            onKeycardRegistered={() => {
                                setView('list');
                            }}
                        />
                    )}

                    {view === 'add' && (
                        <AddIdentificationFormContainer
                            checkoutId={checkout.id}
                            identificationType={selectedIdentificationType}
                            person={person}
                            ticket={ticket}
                            onSuccess={() => {
                                addNotification({
                                    type: 'success',
                                    message: getTranslated(
                                        'personIdentificationAdd'
                                    ),
                                });

                                setPersonAssignmentDialogControls(null);
                            }}
                            onError={() => {
                                addNotification({
                                    type: 'error',
                                    message: getTranslated(
                                        'personIdentificationAddError'
                                    ),
                                });
                            }}
                        />
                    )}
                </FlexContainer>

                <SelectionControlContainer
                    type={selectedIdentificationType}
                    onFabButtonClick={(type) => {
                        setView(type);
                    }}
                    showButtons={view === 'list'}
                />
            </IdentificationSelectionCard>
        </>
    );
};
