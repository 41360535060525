import { FunctionComponent } from 'react';
import { Button } from '@/components/Layout/Button/Button';
import { FlexContainer } from '@/components/Layout/Container';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const NavigationContainer = styled(FlexContainer)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderTop: `1px solid ${grey[200]}`,
    boxShadow: theme.design.general.boxShadow,
    bottom: 0,
    height: theme.appBar.height,
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),
    position: 'fixed',
    top: 'auto',
    width: '100%',
    zIndex: '10',
}));

const NavigationButton = styled(Button)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

type BottomNavigationMobileProps = {
    labelBack: string;
    labelNext: string;
    onBack: () => void;
    onNext: () => void;
    nextIsLoading?: boolean;
    backIsLoading?: boolean;
};

export const BottomNavigationMobile: FunctionComponent<
    BottomNavigationMobileProps
> = ({
    labelBack,
    labelNext,
    onBack,
    onNext,
    nextIsLoading = false,
    backIsLoading = false,
}) => {
    return (
        <NavigationContainer>
            <NavigationButton
                sx={{
                    fontSize: '0.8rem',
                    px: 2,
                }}
                isLoading={backIsLoading}
                variant='outlined'
                onClick={onBack}
            >
                <KeyboardArrowLeft />
                {labelBack}
            </NavigationButton>
            <NavigationButton
                sx={{
                    fontSize: '0.8rem',
                    px: 2,
                }}
                isLoading={nextIsLoading}
                variant='contained'
                onClick={onNext}
            >
                {labelNext}
                <KeyboardArrowRight />
            </NavigationButton>
        </NavigationContainer>
    );
};
