import { FunctionComponent } from 'react';
import { Fab, FabProps, CircularProgress } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';

export const IdentificationFormFabButton: FunctionComponent<
    {
        loading?: boolean;
    } & FabProps
> = ({ loading = false, children, ...rest }) => (
    <Fab
        sx={(theme) => ({
            color: '#fff',
            mx: 2,
            minWidth: '240px',
            position: 'relative',
            zIndex: 100,

            [theme.breakpoints.down('lg')]: {
                my: 1,
                mx: 'auto',
                width: '100%',
                maxWidth: '520px',
            },
        })}
        {...rest}
        color='secondary'
        variant='extended'
        disabled={loading}
    >
        {loading ? (
            <FlexContainer
                sx={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress
                    sx={{
                        mr: 3,
                    }}
                    color={'inherit'}
                    size={24}
                />
                {children}
            </FlexContainer>
        ) : (
            children
        )}
    </Fab>
);
