import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Link, Typography } from '@mui/material';
import { LiftOperator } from '@/models/liftOperators';
import { LiftOperatorLogo } from '@/components/LiftOperatorLogo/LiftOperatorLogo';
import { getPropByCurrentLocale } from '@/utils/locale';
import { useTranslation } from '@/hooks/useTranslation';
import { useConfigSlice } from '@/hooks/useConfigSlice';

type LiftOperatorInfoMobileProps = {
    liftOperator: LiftOperator;
};

export const LiftOperatorInfoMobile: FunctionComponent<
    LiftOperatorInfoMobileProps
> = ({ liftOperator }) => {
    const { getTranslated } = useTranslation();
    const { isOeamtc } = useConfigSlice();

    const color = isOeamtc ? '#000' : '#fff';
    return (
        <FlexContainer
            sx={(theme) => ({
                px: 4,
                py: 6,
                bgcolor: theme.palette.primary.main,
                color,
            })}
            column
        >
            <FlexContainer
                sx={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <FlexContainer
                    sx={{
                        width: '120px',
                        alignItems: 'center',
                        JustifyContent: 'center',
                    }}
                >
                    <LiftOperatorLogo
                        logoType={'100w'}
                        liftOperator={liftOperator}
                    />
                </FlexContainer>

                <Typography
                    sx={{
                        mt: 2,
                        color,
                    }}
                    variant='h6'
                >
                    {getPropByCurrentLocale(liftOperator.name)}
                </Typography>
            </FlexContainer>

            <FlexContainer
                sx={{
                    alignItems: 'center',
                    ml: 2,
                    color,
                }}
            >
                <Typography>
                    {getTranslated('ProductsPage.toWebsite')}
                </Typography>
                <Link
                    sx={{
                        ml: 2,
                        color,
                        textDecoration: 'none',
                    }}
                >
                    {liftOperator.homepage}
                </Link>
            </FlexContainer>
        </FlexContainer>
    );
};
