import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme/theme';
import { useNavigate } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) => ({
    successIcon: {
        color: theme.palette.success.main,
        fontSize: 64,
        marginBottom: theme.spacing(2),
    },
    button: {
        maxWidth: '250px',
    },
    registrationSuccessTypo: {
        textAlign: 'center',
        marginBottom: theme.spacing(8),
    },
}));

type RegistrationSuccessProps = {
    email: string;
};

export const RegistrationSuccess: FunctionComponent<
    React.PropsWithChildren<RegistrationSuccessProps>
> = ({}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { routerConfig, getPagePath } = useCustomRouter();
    const { getTranslated } = useTranslation();

    return (
        <FlexContainer column center>
            <CheckCircleOutlineIcon className={classes.successIcon} />
            <Typography
                variant={'h6'}
                gutterBottom
                className={classes.registrationSuccessTypo}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: getTranslated('RegisterPage.text'),
                    }}
                />
            </Typography>

            <Button
                isLoading={false}
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={() => navigate(getPagePath(routerConfig.Login))}
            >
                {getTranslated('Registration.navigation.toLogin')}
            </Button>
        </FlexContainer>
    );
};
