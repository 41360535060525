import { FunctionComponent } from 'react';
import { Person } from '@/models/general';
import { PackagePersonCountSelection } from '@/components/PageContent/ProductsPageContent/ProductFilter/PackagePersonCountSelection';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import { ProductFilter } from '@/models/productFilter';
import { PersonCount } from '@/models/product';

type PersonCountSelectionContainerProps = {
    persons: Person[];
    onPersonSelect: (persons: PersonCount[]) => void;
    productFilter: ProductFilter;
};

export const PersonCountSelectionContainer: FunctionComponent<
    React.PropsWithChildren<PersonCountSelectionContainerProps>
> = ({ persons, onPersonSelect, productFilter }) => {
    const { getTranslated } = useTranslation();

    const onPersonCountSelect = (person: Person, count: number) => {
        const { persons } = productFilter;
        const personNameIndex = productFilter.persons?.findIndex(
            (personCount) => personCount.name.de === person.name.de
        );

        const nextPersons = [...(persons || [])];
        if (personNameIndex > -1) {
            if (count === 0) {
                nextPersons.splice(personNameIndex, 1);
            } else {
                nextPersons[personNameIndex] = {
                    ...person,
                    quantity: count,
                };
            }
        } else {
            if (count > 0) {
                nextPersons.push({
                    ...person,
                    quantity: count,
                });
            }
        }

        onPersonSelect(nextPersons);
    };

    return (
        <FlexContainer
            sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                    marginTop: theme.spacing(3),
                },
            })}
            column
            fullWidth
        >
            <Typography
                sx={{
                    mt: 3,
                    mb: 2,
                    pl: 4,
                }}
                variant='h6'
            >
                {getTranslated('Core.selectQuantity')}
            </Typography>

            {persons.map((person) => {
                const selectedPerson =
                    productFilter.persons?.find(
                        (personCount) => personCount.name.de === person.name.de
                    ) || null;

                return (
                    <PackagePersonCountSelection
                        key={`${person?.name.de}`}
                        person={person}
                        onSelect={(count) => {
                            onPersonCountSelect(person, count);
                        }}
                        quantity={selectedPerson?.quantity || 0}
                    />
                );
            })}
        </FlexContainer>
    );
};
