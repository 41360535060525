import { FunctionComponent, useEffect } from 'react';
import { useAuthentication } from '@/hooks/useAuthentication';
import { getTimeInSeconds } from '@/utils/time';
import { usePostRefreshMutation } from '@/api/authentication';
import { useSlice } from '@/hooks/useSlice';
import { authSlice } from '@/slices/authSlice';

export const AuthContainer: FunctionComponent<
    React.PropsWithChildren<unknown>
> = ({ children }) => {
    const { expiresIn, token, logout } = useAuthentication();
    const {
        state: { refreshToken },
    } = useSlice(authSlice, 'auth');
    const [postRefresh] = usePostRefreshMutation();
    useEffect(() => {
        if (token) {
            if (expiresIn < getTimeInSeconds()) {
                logout();
            } else {
                const rest = expiresIn - getTimeInSeconds();

                // subtract 5 seconds as a buffer for refresh
                window.setTimeout(() => {
                    postRefresh({ refreshToken });
                }, rest * 1000 - 5000);
            }
        }
    }, [expiresIn]);

    return <>{children}</>;
};
