import { FunctionComponent, PropsWithChildren } from 'react';
import { TicketIdentTypeSelectionContainer } from '@/components/PageContent/CartPageContent/CheckoutItemList/Ticket/TicketIdentTypeSelectionContainer';
import { IdentificationSelectionContainer } from '@/components/PageContent/CartPageContent/IdentificationSelection/IdentificationSelectionContainer';
import { useGetCurrentCheckoutSession } from '@/api/checkout';

type SingleTicketIdentSelectionContainerProps = {
    personId: string;
    ticketId: string;
};

export const SingleTicketIdentSelectionContainer: FunctionComponent<
    PropsWithChildren<SingleTicketIdentSelectionContainerProps>
> = ({ personId, ticketId }) => {
    const { data: checkout } = useGetCurrentCheckoutSession();

    const updatedTicket = checkout?.tickets.find((t) => t.id === ticketId);

    if (!updatedTicket) return null;

    return updatedTicket.identificationType === null ? (
        <TicketIdentTypeSelectionContainer ticket={updatedTicket} />
    ) : (
        <IdentificationSelectionContainer
            ticket={updatedTicket}
            personId={personId}
            selectedIdentificationType={updatedTicket.identificationType}
        />
    );
};
