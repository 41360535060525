import { getEmptyMoneyValue, Money } from '@/models/general';
import { useMemo } from 'react';
import { useGetCurrentCheckoutSession } from '@/api/checkout';
import { CheckoutSession } from '@/models/checkout/checkoutSession';

type CheckoutTotalsExtended = CheckoutSession['totals'] & {
    totalDiscounts: Money;
};

export const useCartCosts = () => {
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    const totalDiscounts: Money = useMemo(() => {
        if (checkoutSession) {
            return {
                amount:
                    checkoutSession.totals.totalBeforeDiscounts.amount -
                    checkoutSession.totals.totalAfterDiscounts.amount,
                currency: 'EUR',
            };
        }

        return getEmptyMoneyValue();
    }, [checkoutSession]);

    const totalsProxy: ProxyHandler<CheckoutTotalsExtended> = {
        get: (
            target: CheckoutTotalsExtended | Record<string, never>,
            prop: keyof CheckoutTotalsExtended
        ) => {
            if (prop === 'totalDiscounts') {
                return totalDiscounts;
            }

            return target[prop] || getEmptyMoneyValue();
        },
    };

    // the proxy catches access to empty fields.
    // So as a default we can pass an empty object and cast it to the type we need
    const totalsCasted = (checkoutSession?.totals ||
        {}) as CheckoutTotalsExtended;

    return new Proxy<CheckoutTotalsExtended>(totalsCasted, totalsProxy);
};
