import { FunctionComponent } from 'react';
import { IdentificationType } from '@/models/cart';
import KeycardIcon from '@/assets/icons/keycard.svg?react';
import KeycardIconWhite from '@/assets/icons/keycard_white.svg?react';
import PickupIcon from '@/assets/icons/pickup.svg?react';
import PickupIconWhite from '@/assets/icons/pickup_white.svg?react';
import ETicketIcon from '@/assets/icons/eTicket.svg?react';
import ETicketIconWhite from '@/assets/icons/eTicket_white.svg?react';
import MobileFlowIcon from '@/assets/icons/mobileFlow.png';

export const KeycardProductionTypeIcon: FunctionComponent<
    React.PropsWithChildren<{
        variant: IdentificationType;
        color?: 'black' | 'white';
        className?: string;
    }>
> = ({ variant, className, color = 'black' }) => {
    switch (variant) {
        case IdentificationType.KEYCARD:
        case IdentificationType.INTERNAL_PRODUCTION:
            return color === 'black' ? (
                <KeycardIcon className={className} />
            ) : (
                <KeycardIconWhite className={className} />
            );
        case IdentificationType.PICKUP:
            return color === 'black' ? (
                <PickupIcon className={className} />
            ) : (
                <PickupIconWhite className={className} />
            );
        case IdentificationType.BARCODE:
            return color === 'black' ? (
                <ETicketIcon className={className} />
            ) : (
                <ETicketIconWhite className={className} />
            );
        case IdentificationType.E_TICKET:
            return <img src={MobileFlowIcon} className={className} />;
        default:
            return null;
    }
};
