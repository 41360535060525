import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';

import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { CostSummaryBlock } from '@/components/PageContent/CartPageContent/CostSummaryBlock';
import { useTranslation } from '@/hooks/useTranslation';
import { GTCNotice } from '@/components/PageContent/CartPageContent/GTCNotice';
import { AddressBlockContainer } from '@/components/PageContent/CartPageContent/Payment/AddressBlock/AddressBlockContainer';
import { PaymentOptionsBlock } from '@/components/PageContent/CartPageContent/Payment/PaymentOptions/PaymentOptionsBlock';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { LoadingButton } from '@mui/lab';
import {
    useGetCurrentCheckoutSession,
    usePostOrderMutation,
} from '@/api/checkout';
import { postPaymentOrderKey } from '@/hooks/useSubmitOrder';
import { useCheckoutHelpers } from '@/hooks/useCheckoutHelpers';
import { InsuranceConsentContainer } from '@/components/PageContent/CartPageContent/InsuranceConsentContainer';
import Alert from '@mui/material/Alert';
import { CheckoutInsuranceContactDialog } from '@/components/PageContent/CartPageContent/Payment/CheckoutInsuranceContactDialog';
import { CheckoutInsuranceContactCard } from '@/components/PageContent/CartPageContent/Payment/CheckoutInsuranceContactCard';
import { InsuranceInfoCard } from '@/components/PageContent/CartPageContent/InsuranceInfoCard';
import { InsuranceComponentSjReact } from '@crossnative/insurance-component/react';
import { IS_DEVELOPMENT } from '@/constants';

export const PaymentColumn: FunctionComponent<
    PropsWithChildren<{
        onOrderSubmit: () => void;
    }>
> = ({ onOrderSubmit }) => {
    const [insuranceContactDialogOpen, setinsuranceContactDialogOpen] =
        useState(false);
    const [, { isLoading }] = usePostOrderMutation({
        fixedCacheKey: postPaymentOrderKey,
    });
    const { data: checkout } = useGetCurrentCheckoutSession();
    const {
        isCheckoutEmpty,
        hasInsuranceSelected,
        insuranceConsentSelected,
        isInsuranceEnabled,
    } = useCheckoutHelpers();
    const { IS_OEAMTC } = useConfigSlice();
    const { locale, getTranslated } = useTranslation();

    const color = IS_OEAMTC ? '#000' : '#fff';

    const showAlert = hasInsuranceSelected && !insuranceConsentSelected;

    return (
        <FlexContainer
            sx={() => ({
                position: 'relative',
                height: '100%',
                mt: '64px',
                mx: 'auto',
                px: 2,
                pb: 16,
            })}
            column
            fullWidth
        >
            <FlexContainer
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: 1,
                    py: 2,
                    width: '100%',
                    height: '64px',
                    mb: 6,
                    mx: -2,
                }}
            >
                <Avatar
                    sx={{
                        width: '48px',
                        height: '48px',
                        bgcolor: 'primary.main',
                        mr: 3,
                        color,
                    }}
                >
                    2
                </Avatar>

                <Typography
                    sx={{
                        textTransform: 'capitalize',
                    }}
                    variant='h4'
                >
                    {getTranslated('checkout')}
                </Typography>
            </FlexContainer>

            {isInsuranceEnabled && !hasInsuranceSelected && (
                <Box
                    sx={{
                        display: 'flex',
                        mx: 'auto',
                        my: 3,
                        p: 3,
                        maxWidth: 600,
                    }}
                >
                    <InsuranceComponentSjReact
                        language={locale.toLocaleUpperCase()}
                        stage={IS_DEVELOPMENT ? 'test' : undefined}
                    />
                </Box>
            )}

            <Card
                sx={(theme) => ({
                    width: '100%',
                    maxWidth: 600,
                    mx: 'auto',
                    borderRadius: '0',

                    [theme.breakpoints.down('md')]: {
                        m: 'spacing(2, 0)',
                    },
                })}
                elevation={1}
            >
                <CardContent>
                    <AddressBlockContainer />

                    {hasInsuranceSelected && <CheckoutInsuranceContactCard />}

                    <CostSummaryBlock />

                    <PaymentOptionsBlock />

                    {hasInsuranceSelected && <InsuranceInfoCard />}

                    {hasInsuranceSelected && <InsuranceConsentContainer />}

                    <FlexContainer
                        sx={{
                            mt: 4,
                        }}
                        column
                        center
                    >
                        {
                            // If there are insurances selected, the user has to accept the terms and conditions
                            showAlert && (
                                <Alert
                                    sx={{
                                        mb: 2,
                                        width: '100%',
                                    }}
                                    severity='warning'
                                >
                                    {getTranslated(
                                        'checkoutPage.insuranceAlert.label'
                                    )}
                                </Alert>
                            )
                        }

                        <LoadingButton
                            sx={{
                                mb: 2,
                            }}
                            disabled={!checkout || isCheckoutEmpty}
                            loading={isLoading}
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                if (
                                    hasInsuranceSelected &&
                                    (!checkout.phone || !checkout.birthdate)
                                ) {
                                    setinsuranceContactDialogOpen(true);
                                    return;
                                }

                                onOrderSubmit();
                            }}
                        >
                            {getTranslated('Cart.pay')}
                        </LoadingButton>

                        <GTCNotice />
                    </FlexContainer>
                </CardContent>
            </Card>

            <CheckoutInsuranceContactDialog
                open={insuranceContactDialogOpen}
                onClose={(reason) => {
                    setinsuranceContactDialogOpen(false);

                    if (reason === 'submit') {
                        onOrderSubmit();
                    }
                }}
            />
        </FlexContainer>
    );
};
