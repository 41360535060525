import { FunctionComponent, RefObject, useMemo, useRef } from 'react';
import { Cropper as ReactCropper } from 'react-cropper';

import { debounce } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';

import 'cropperjs/dist/cropper.css';

type CropperProps = {
    src: string;
    aspect?: number;
    onCrop: (crop: string, imgRef: RefObject<HTMLImageElement>) => void;
};

export const Cropper: FunctionComponent<
    React.PropsWithChildren<CropperProps>
> = ({ src, aspect, onCrop }) => {
    const cropperRef = useRef<HTMLImageElement>(null);
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    const crop = useMemo(
        () =>
            debounce(() => {
                const imageElement = cropperRef?.current;
                const cropper = imageElement?.['cropper'];

                if (cropper) {
                    const data = cropper.getCroppedCanvas().toDataURL();

                    onCrop(data, cropperRef);
                }
            }, 400),
        []
    );

    return (
        <ReactCropper
            src={src}
            style={{ height: isMobile ? 320 : 400, width: '100%' }}
            // Cropper.js options
            initialAspectRatio={aspect}
            aspectRatio={aspect}
            guides={false}
            crop={crop}
            ref={cropperRef}
        />
    );
};
