import { GetTranslated } from '@/hooks/useTranslation';

export const tableConfig = (getTranslated: GetTranslated) => ({
    columns: [
        {
            label: getTranslated('Order.orderedAt'),
        },
        {
            label: getTranslated('orderPosition'),
        },
        {
            label: getTranslated('orderNumber'),
        },
        {
            label: getTranslated('Core.total'),
            isPrice: true,
        },
        {
            label: getTranslated('MyOrdersPage.invoice'),
        },
    ],
    rowsSelectable: false,
    sortable: false,
});
