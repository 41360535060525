import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Form, Formik } from 'formik';
import { Typography, Box } from '@mui/material';
import { FormikTextField } from '@/components/Layout/Form/Field';
import { FieldWrapper } from '@/components/Layout/Form/FieldWrapper';

import { useNotification } from '@/hooks/useNotification';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { PageTemplate } from '@/pages/PageTemplate';
import { usePostRecoveryMutation } from '@/api/authentication';
import { LoadingButton } from '@mui/lab';

const getErrorMessageCode = (error: string): TranslationKey => {
    switch (error) {
        case 'customer_has_no_password': {
            return 'resetPasswordCustomerHasNoPassword';
        }
        default:
            return 'PasswordForgottenForm.error';
    }
};

export const ForgotPasswordPage: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => {
    const [postRecovery, { isLoading: buttonLoading }] =
        usePostRecoveryMutation();

    const { addNotification } = useNotification();
    const { getTranslated } = useTranslation();

    return (
        <PageTemplate>
            <FlexContainer
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    flexGrow: 1,
                }}
            >
                <Formik
                    initialValues={{ email: '' }}
                    validate={(values) => {
                        const errors: { email?: string } = {};

                        if (!values.email) {
                            errors.email = getTranslated(
                                'validation.error.email.required'
                            );
                        }

                        return errors;
                    }}
                    validateOnChange={false}
                    onSubmit={(values) => {
                        postRecovery({ email: values.email })
                            .then(() => {
                                addNotification({
                                    message: getTranslated(
                                        'PasswordForgottenForm.info'
                                    ),
                                });
                            })
                            .catch((e) => {
                                addNotification({
                                    message: getTranslated(
                                        getErrorMessageCode(e?.errors[0]?.error)
                                    ),
                                    type: 'error',
                                });
                            });
                    }}
                >
                    <Form
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                minHeight: '200px',
                            }}
                        >
                            <Typography variant='h4' gutterBottom>
                                {getTranslated('LoginForm.passwordForgotten')}
                            </Typography>
                            <FormikTextField
                                name={'email'}
                                textFieldProps={{
                                    id: 'login-email',
                                    label: 'Email',
                                    sx: {
                                        margin: (theme) => theme.spacing(4, 0),
                                        width: '320px',
                                    },
                                    type: 'text',
                                    autoComplete: 'current-email',
                                    variant: 'outlined',
                                }}
                            />

                            <FieldWrapper
                                position='relative'
                                justifyContent='center'
                            >
                                <LoadingButton
                                    loading={buttonLoading}
                                    sx={{ width: '256px' }}
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                >
                                    {getTranslated(
                                        'PasswordForgottenForm.reset'
                                    )}
                                </LoadingButton>
                            </FieldWrapper>
                        </Box>
                    </Form>
                </Formik>
            </FlexContainer>
        </PageTemplate>
    );
};
