import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { KeycardCard } from '@/components/Keycard/KeycardCard';
import { FlexContainer, LoadingContainer } from '@/components/Layout/Container';
import { AddNewKeycardDialog } from '@/components/PageContent/MyKeycardsPage/AddNewKeycardDialog';
import { RegisterKeycardDialog } from '@/components/PageContent/MyKeycardsPage/RegisterKeycardDialog';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import Dialog from '@mui/material/Dialog';
import { Keycard } from '@/models/order';
import { ConfirmationDialog } from '@/components/Dialogs/ConfirmationDialog';
import { useTranslation } from '@/hooks/useTranslation';
import { PromotionCodeDialog } from '@/components/Dialogs/PromotionCodeDialog';
import { TransferKeycardDialog } from '@/components/Dialogs/TransferKeycardDialog';
import { ChangeKeycardImageDialog } from '@/components/PageContent/MyKeycardsPage/ChangeKeycardImageDialog';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import Button from '@mui/material/Button';
import {
    useGetKeycardsQuery,
    useDeleteKeycardMutation,
    useGetKeycardTransfersQuery,
    useDeleteTransferKeycardMutation,
} from '@/api/order';
import { KeycardTransferCollection } from '@/models/keycard';

const getKeycardTransferSent = (
    keycard: Keycard,
    collection: KeycardTransferCollection
) => {
    return collection.sent.find((kT) => kT.keycardId === keycard.id);
};

export const MyKeycardsPage = () => {
    const {
        data: keycards = [],
        isLoading: fetchLoading,
        refetch,
    } = useGetKeycardsQuery();
    const { data: keycardsTransfer, refetch: refetchKeycardTransfers } =
        useGetKeycardTransfersQuery();
    const [deleteKeycard, { isLoading: deleteLoading }] =
        useDeleteKeycardMutation();
    const [deleteTransferKeycard] = useDeleteTransferKeycardMutation();
    const { isWebshop } = useConfigSlice();
    const { getTranslated } = useTranslation();

    const [newKeycardDialogOpen, setNewKeycardDialogOpen] =
        useState<boolean>(false);
    const [registerKeycardDialogOpen, setRegisterKeycardDialogOpen] =
        useState<boolean>(false);
    const [changeKeycardImageDialogOpen, setChangeKeycardImageDialogOpen] =
        useState<Keycard>(null);
    const [keycardDelete, setKeycardDelete] = useState<Keycard>(null);
    const [promotionCodeOpen, setPromotionCodeOpen] = useState<string>('');
    const [showDeleted, setShowDeleted] = useState(false);
    const [keycardToTransfer, setKeycardToTransfer] = useState<Keycard>(null);

    const keycardsFiltered = showDeleted
        ? keycards
        : keycards.filter((k) => !k.deletionDate);

    const stopKeycardTransfer = (keycard: Keycard) => {
        const keycardId = getKeycardTransferSent(keycard, keycardsTransfer).id;
        deleteTransferKeycard({ keycardId });
    };

    return (
        <SubPageTemplate title={getTranslated('Header.myKeycards')}>
            <FlexContainer>
                <Button
                    onClick={() =>
                        setNewKeycardDialogOpen((prevState) => !prevState)
                    }
                >
                    {getTranslated('My.keycards.order.new')}
                </Button>

                {!isWebshop('skipluscity') && (
                    <Button
                        onClick={() =>
                            setRegisterKeycardDialogOpen(
                                (prevState) => !prevState
                            )
                        }
                    >
                        {getTranslated('My.keycards.order.register')}
                    </Button>
                )}
            </FlexContainer>

            <FlexContainer>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showDeleted}
                            onChange={() =>
                                setShowDeleted((prevState) => !prevState)
                            }
                            name='deleteFilter'
                            color='primary'
                        />
                    }
                    label={getTranslated('MyCardsPage.showInactive')}
                />
            </FlexContainer>

            {fetchLoading ? (
                <LoadingContainer />
            ) : (
                <FlexContainer gap={2} column center>
                    {keycardsFiltered.map((keycard, i) => {
                        if (isWebshop('skipluscity') && keycard.external)
                            return null;

                        return (
                            <KeycardCard
                                key={i}
                                keycard={keycard}
                                keycardTransfer={getKeycardTransferSent(
                                    keycard,
                                    keycardsTransfer
                                )}
                                onKeycardDelete={setKeycardDelete}
                                onTransferKeycard={(keycard, stop = false) => {
                                    if (!stop) {
                                        setKeycardToTransfer(keycard);
                                        return;
                                    }

                                    stopKeycardTransfer(keycard);
                                }}
                                promotionCodeButtonClick={(keycardId: string) =>
                                    setPromotionCodeOpen(keycardId)
                                }
                                onEditImage={setChangeKeycardImageDialogOpen}
                            />
                        );
                    })}
                </FlexContainer>
            )}

            <AddNewKeycardDialog
                isOpen={newKeycardDialogOpen}
                onClose={() => setNewKeycardDialogOpen(false)}
            />

            <RegisterKeycardDialog
                isOpen={registerKeycardDialogOpen}
                onClose={() => setRegisterKeycardDialogOpen(false)}
            />

            {!!keycardToTransfer && (
                <TransferKeycardDialog
                    keycard={keycardToTransfer}
                    isOpen={!!keycardToTransfer}
                    onTransferConfirmed={() => {
                        setKeycardToTransfer(null);
                        refetchKeycardTransfers();
                    }}
                    onCancel={() => setKeycardToTransfer(null)}
                />
            )}

            <ChangeKeycardImageDialog
                isOpen={!!changeKeycardImageDialogOpen}
                keycard={changeKeycardImageDialogOpen}
                onClose={(withReload) => {
                    setChangeKeycardImageDialogOpen(null);

                    if (withReload) {
                        refetch();
                    }
                }}
            />

            <Dialog
                open={!!keycardDelete}
                onClose={() => setKeycardDelete(null)}
                maxWidth='md'
            >
                <ConfirmationDialog
                    confirmationButtonLoading={deleteLoading}
                    onConfirmation={() => {
                        deleteKeycard(keycardDelete)
                            .unwrap()
                            .then(() => {
                                setKeycardDelete(null);
                            });
                    }}
                    onCancel={() => setKeycardDelete(null)}
                />
            </Dialog>

            <Dialog
                open={!!promotionCodeOpen}
                onClose={() => setPromotionCodeOpen('')}
                maxWidth='md'
            >
                <PromotionCodeDialog
                    keycardId={promotionCodeOpen}
                    onCancel={() => setPromotionCodeOpen('')}
                />
            </Dialog>
        </SubPageTemplate>
    );
};
