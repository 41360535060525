import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { useSlice } from '@/hooks/useSlice';
import { ordersSlice } from '@/slices/ordersSlice';
import {
    useGetCurrentCheckoutSession,
    usePostOrderMutation,
} from '@/api/checkout';
import { checkoutSlice } from '@/slices/checkoutSlice';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useNotification } from '@/hooks/useNotification';
import {
    isnGeneralCheckoutError,
    notificationsWarningTypes,
} from '@/models/checkout/checkoutValidation';
import { getCheckoutTicketCollectionId } from '@/models/collection/checkoutTicketCollection';

export const postPaymentOrderKey = 'PAYMENT_ORDER_KEY';

export const useSubmitOrder = () => {
    const [postOrder] = usePostOrderMutation({
        fixedCacheKey: postPaymentOrderKey,
    });
    const { locale } = useTranslation();
    const navigate = useNavigate();
    const { routerConfig, getPagePath } = useCustomRouter();
    const {
        state: { savePaymentData, selectedProfile, paymentMethod },
        actions: { setPaymentResponse },
    } = useSlice(ordersSlice, 'orders');
    const {
        actions: { addPaymentSubmitTries },
    } = useSlice(checkoutSlice, 'checkout');
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const { addNotification } = useNotification();
    const { getTranslated } = useTranslation();

    const checkAndHandleTicketErrors = () => {
        const errorTicket =
            checkoutSession?.validation.errors.find(
                (ticketError) => ticketError.ticketId
            )?.ticketId || null;

        if (!errorTicket) {
            return false;
        }

        const ticket = checkoutSession?.tickets.find(
            (ticket) => ticket.id === errorTicket
        );

        if (ticket) {
            const ticketCollectionId = getCheckoutTicketCollectionId(ticket);

            // scroll to dom element with data id ticketCollectionId
            const element = document.querySelector(
                `[data-id="ticketCollection-${ticketCollectionId}"]`
            );
            if (element) {
                const marginTop = 100; // Adjust this value to set the margin

                window.scrollTo({
                    top:
                        element.getBoundingClientRect().top +
                        window.scrollY -
                        marginTop,

                    behavior: 'smooth',
                });
            }
        }
        return true;
    };

    const submitOrder = async () => {
        addPaymentSubmitTries();

        if (!checkoutSession) {
            console.error(
                'No checkout session. Cannot submit order - reset everything in cart.'
            );

            return;
        }

        const hasCheckoutTicketError = checkAndHandleTicketErrors();

        if (hasCheckoutTicketError) {
            return;
        }

        if (checkoutSession.validation.errors.length) {
            const allGlobalErrors = checkoutSession.validation.errors.filter(
                isnGeneralCheckoutError
            );

            // only show global error if no errors belonging to a ticket are left
            if (allGlobalErrors.length) {
                const [first] = allGlobalErrors;
                const isWarning = notificationsWarningTypes.includes(
                    first.error
                );
                addNotification({
                    message: getTranslated(
                        `checkoutValidation_${first.error.toLocaleLowerCase()}` as TranslationKey
                    ),
                    type: isWarning ? 'warning' : 'error',
                });
            }

            return;
        }

        if (
            !!checkoutSession.insurance &&
            !checkoutSession.insurance.mandatoryDocumentsDownloaded
        ) {
            addNotification({
                message: getTranslated('CheckoutPage.consent.missing.error'),
                type: 'error',
            });
            return;
        }

        postOrder({
            checkoutId: checkoutSession.id,
            email: null,
            saveProfile: savePaymentData,
            paymentMethod: paymentMethod,
            profile: selectedProfile?.id || null,
            successUrl:
                locale === 'en'
                    ? `${location.origin}/${locale}/checkout-success`
                    : `${location.origin}/checkout-success`,
            errorUrl:
                locale === 'en'
                    ? `${location.origin}/${locale}/checkout-error`
                    : `${location.origin}/checkout-error`,

            totalAfterCredits: checkoutSession?.totals.totalAfterCredits,
        })
            .unwrap()
            .then((paymentResult) => {
                setPaymentResponse(paymentResult);

                if (paymentResult.order.location) {
                    window.location.href = paymentResult.order.location;
                    return;
                }

                const { status } = paymentResult;

                if (status === 'ORDERED') {
                    navigate(getPagePath(routerConfig.SuccessPage));
                } else {
                    addNotification({
                        message: getTranslated('paymentFailed'),
                        type: 'error',
                    });
                }
            })
            .catch(() => {
                addNotification({
                    message: getTranslated('paymentFailed'),
                    type: 'error',
                });
            });
    };

    return {
        submitOrder,
    };
};
