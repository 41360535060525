import React, { FunctionComponent, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { default as MuiTable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Column, Order, Row } from './tableConfig';
import { TableHeader } from './TableHeader';
import { FlexContainer } from '../Layout/Container';
import { Checkbox, useTheme } from '@mui/material';
import { getComparator, stableSort } from './sort';
import { TablePaginationActions } from './TablePaginationActions';
import { Theme } from '../../theme/theme';
import { NoEntries, NoEntriesProgress } from './NoEntriesComponent';
import { useTranslation } from '@/hooks/useTranslation';

// TODO: update action controls when there are less items than defaultRowsPerPage

export type TableProps = {
    columns: Column[];
    rowsPage?: number;
    rows?: Row[];
    count?: number;
    rowsSelectable?: boolean;
    sortable?: boolean;
    NoEntriesComponent?: React.JSXElementConstructor<unknown>;
    LoadingComponent?: React.JSXElementConstructor<unknown>;
    onPageChange?: (pageNumber: number) => void;
    onRowsPerPageChange?: (rowsPerPage: number) => void;
    isLoading?: boolean;
    isAsyncPaginated?: boolean;
};

const Table: FunctionComponent<React.PropsWithChildren<TableProps>> = ({
    columns,
    rows = [],
    rowsSelectable = true,
    sortable = false,
    NoEntriesComponent = NoEntries,
    LoadingComponent = NoEntriesProgress,
    count,
    onPageChange,
    onRowsPerPageChange,
    isAsyncPaginated = false,
    isLoading = false,
}) => {
    const { getTranslated } = useTranslation();

    const theme: Theme = useTheme();

    const [order, setOrder] = React.useState<Order>('asc');

    // order by column index - default first column -> 0
    const [orderBy, setOrderBy] = React.useState<number>(0);
    const [selected, setSelected] = React.useState<Row['id'][]>([]);
    const [page, setPage] = React.useState(0);

    const { defaultRowsPerPage } = theme.table.regular;

    // const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(
        isAsyncPaginated
            ? theme.table.async.defaultRowsPerPage
            : rows.length < defaultRowsPerPage
            ? rows.length
            : defaultRowsPerPage
    );

    useEffect(() => {
        setRowsPerPage(
            isAsyncPaginated
                ? theme.table.async.defaultRowsPerPage
                : rows.length < defaultRowsPerPage
                ? rows.length
                : defaultRowsPerPage
        );
    }, [rows]);

    const handleRequestSort = (property: Column['label']) => {
        const isAsc = columns[orderBy].label === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');

        setOrderBy(columns.findIndex((column) => column.label === property));
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            setSelected(rows.map((row) => row.id));
            return;
        }
        setSelected([]);
    };

    const handleClick = (rowId: Row['id']) => {
        if (!rowsSelectable) {
            return;
        }

        const rowIndex = selected.findIndex((id) => id === rowId);

        let newSelected: Row['id'][] = [];

        if (rowIndex === -1) {
            newSelected = newSelected.concat(selected, rowId);
        } else if (rowIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (rowIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (rowIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, rowIndex),
                selected.slice(rowIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (_, newPage: number) => {
        setPage(newPage);

        if (onPageChange) {
            onPageChange(newPage);
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(rowsPerPage);
        setPage(0);

        if (onPageChange) {
            onPageChange(0);
        }

        if (onRowsPerPageChange) {
            onRowsPerPageChange(rowsPerPage);
        }
    };

    const isSelected = (id: Row['id']) =>
        selected.findIndex((rowId) => rowId === id) !== -1;

    const sortedRows = sortable
        ? stableSort(
              rows,
              getComparator(
                  order,
                  orderBy,
                  columns[orderBy].isDate ? 'date' : 'string'
              )
          )
        : rows;

    // if table is not paginated async return all rows
    // else just render given rows
    const rowsToRender: Row[] = isAsyncPaginated
        ? rows
        : sortedRows.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
          );

    // let emptyRows = isAsyncPaginated
    //     ? rowsPerPage - rows.length
    //     : rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const rowsPerPageOptions = isAsyncPaginated
        ? []
        : theme.table.regular.rowsPerPageOptions;

    const renderTableBody = () => {
        if (isLoading) {
            return (
                <TableRow>
                    <TableCell colSpan={columns.length}>
                        <LoadingComponent />
                    </TableCell>
                </TableRow>
            );
        } else if (!rowsToRender.length) {
            return (
                <TableRow>
                    <TableCell colSpan={columns.length}>
                        <NoEntriesComponent />
                    </TableCell>
                </TableRow>
            );
        } else {
            return rowsToRender.map((row, i) => (
                <TableRow
                    key={i}
                    onClick={() => handleClick(row.id)}
                    selected={isSelected(row.id)}
                >
                    {rowsSelectable && (
                        <TableCell padding='checkbox'>
                            <Checkbox checked={isSelected(row.id)} />
                        </TableCell>
                    )}

                    {row.data.map((cell, i) => (
                        <TableCell
                            key={i}
                            scope='row'
                            align={
                                columns[i].isNumeric ||
                                columns[i].isDate ||
                                columns[i].isPrice
                                    ? 'right'
                                    : 'left'
                            }
                        >
                            {cell}
                        </TableCell>
                    ))}
                </TableRow>
            ));
        }
    };

    return (
        <FlexContainer column fullWidth>
            <Paper>
                <TableContainer>
                    <MuiTable>
                        <TableHeader
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                            headCells={columns}
                            rowCount={rows.length}
                            rowsSelectable={rowsSelectable}
                            sortable={sortable}
                        />

                        <TableBody>{renderTableBody()}</TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    labelRowsPerPage={getTranslated(
                                        'Core.table.rowsPerPage'
                                    )}
                                    labelDisplayedRows={({ from, to, count }) =>
                                        `${from}-${to} ${getTranslated(
                                            'Core.from'
                                        )} ${count}`
                                    }
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    colSpan={columns.length}
                                    count={count || rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                    ActionsComponent={(props) => (
                                        <TablePaginationActions
                                            {...props}
                                            isAsyncPaginated
                                        />
                                    )}
                                />
                            </TableRow>
                        </TableFooter>
                    </MuiTable>
                </TableContainer>
            </Paper>
        </FlexContainer>
    );
};

export default Table;
