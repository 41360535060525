import Table, { TableProps } from './Table';
import { convertItemsToRows, RowConfig } from './convertItemsToRows';

// Table that uses convertItemsToRows function to convert items and passes rows to table

type TransformTableProps<T extends object = Record<string, unknown>> = {
    items: T[];
    rowConfig: RowConfig<T>;
} & Exclude<TableProps, 'rows'>;

export const TransformTable = <T extends object = Record<string, unknown>>({
    items,
    rowConfig,
    ...tableProps
}: TransformTableProps<T>) => {
    const rows = convertItemsToRows(items, rowConfig);
    return <Table rows={rows} {...tableProps} />;
};
