import { getPropByCurrentLocale } from '@/utils/locale';
import { CollectionCardPriceLine } from '@/components/CollectionCard/common/CollectionCardPriceLine';
import { DateRange, Money, multiplyMoneyByFactor } from '@/models/general';
import { FunctionComponent } from 'react';
import { CheckoutTicketCollection } from '@/models/collection/checkoutTicketCollection';
import moment from 'moment/moment';
import { DATE_FORMAT } from '@/constants';
import { TicketNotRefundableLine } from '@/components/Ticket/TicketNotRefundableLine';
import { useTranslation } from '@/hooks/useTranslation';
import { Box, Stack, Typography } from '@mui/material';
import {
    useGetCurrentCheckoutSession,
    usePutCheckoutTicketMutation,
} from '@/api/checkout';
import { CheckoutTicket } from '@/models/checkout/checkoutTicket';
import { LoadingChip } from '@/components/Chip/LoadingChip';
import SecurityIcon from '@mui/icons-material/Security';
import { getMoneyAsString } from '@/utils/general';
import { ADD_INSURANCE_CACHE_KEY } from '@/components/CollectionCard/CheckoutTicketCollectionCard/InsuranceAddContainer';

type PersonInsuranceListProps = {
    ticketCollection: CheckoutTicketCollection;
};

export const PersonInsuranceList: FunctionComponent<
    PersonInsuranceListProps
> = ({ ticketCollection }) => {
    const personNameTicketMap: Record<string, CheckoutTicket[]> =
        ticketCollection.checkoutTickets.reduce((personsMap, ticket) => {
            ticket.persons.forEach((person) => {
                if (!personsMap[person.name.de]) {
                    personsMap[person.name.de] = [];
                }

                personsMap[person.name.de].push(ticket);
            });
            return personsMap;
        }, {});

    const personIdsSortedByAge = Object.keys(personNameTicketMap).sort(
        (personNameA, personNameB) => {
            const ticketA = personNameTicketMap[personNameA][0];
            const ticketB = personNameTicketMap[personNameB][0];

            const { birthdate: birthdayA } = ticketA.persons[0];
            const { birthdate: birthdayB } = ticketB.persons[0];

            if (birthdayA?.from && birthdayB?.from) {
                return birthdayA.from.unix() - birthdayB.from.unix();
            }

            return 0;
        }
    );

    if (ticketCollection.isPackage) {
        console.error(
            'PersonInsuranceList must not be used for package CheckoutTicketCollections'
        );
        return null;
    }

    if (!ticketCollection.aonInsuranceEnabled) {
        console.error(
            'PersonInsuranceList can only be used if aonInsuranceEnabled is true'
        );
        return null;
    }

    return (
        <Stack spacing={1}>
            {personIdsSortedByAge.map((personName) => {
                return personNameTicketMap[personName].map(
                    (ticket, ticketIndex) => {
                        return (
                            <InusrancePriceLine
                                key={`${personName}_${ticketIndex}`}
                                ticket={ticket}
                            />
                        );
                    }
                );
            })}
        </Stack>
    );
};

const InusrancePriceLine: FunctionComponent<{ ticket: CheckoutTicket }> = ({
    ticket,
}) => {
    const { getTranslated } = useTranslation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const [putCheckoutTicket, { isLoading: isDeleting }] =
        usePutCheckoutTicketMutation();
    const [, { isLoading: addInsuranceLoading }] = usePutCheckoutTicketMutation(
        { fixedCacheKey: ADD_INSURANCE_CACHE_KEY }
    );
    const {
        price,
        cancellationBlocked,
        originalPrice,
        availableContingent,
        persons,
        aonInsurances,
    } = ticket;
    const [person] = persons;
    const inusrance = aonInsurances[0] || null;

    const onDeleteInsurance = (ticket: CheckoutTicket) => {
        putCheckoutTicket({
            checkoutId: checkoutSession.id,
            id: ticket.id,
            identificationType: ticket.identificationType,
            tariffIdentifier: null,
        });
    };

    const getAvailableContingentString = (availableContingent = 0) => {
        if (availableContingent === null || availableContingent === undefined) {
            return '';
        }

        if (availableContingent === 0)
            return getTranslated('Core.noContingentLeft');

        return getTranslated('Core.contingent', {
            tickets: availableContingent,
        });
    };

    const getPrice = (price: Money) => {
        const insurancePrice = inusrance?.selected ? inusrance.price.amount : 0;

        return {
            ...price,
            amount: price.amount + insurancePrice,
        };
    };

    const priceSubtitle = inusrance?.selected ? (
        <Typography
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
            }}
            component='span'
            variant='subtitle2'
        >
            (inkl. {getMoneyAsString(inusrance.price)} Vers.)
        </Typography>
    ) : null;

    return (
        <Box
            sx={{
                borderRadius: '4px',
                border: '1px solid #eee',
                p: 2,
            }}
        >
            <CollectionCardPriceLine
                title={`${getPropByCurrentLocale(person.name)}`}
                subtitle={getBirthdayAsString(person.birthdate)}
                subtitle3={
                    <Box>
                        {cancellationBlocked && <TicketNotRefundableLine />}
                        <Typography
                            sx={{
                                mt: 1,
                                fontSize: '0.85rem',
                            }}
                            color='error'
                        >
                            {getAvailableContingentString(availableContingent)}
                        </Typography>
                    </Box>
                }
                price={multiplyMoneyByFactor(getPrice(price), 1)}
                originalPrice={multiplyMoneyByFactor(
                    getPrice(originalPrice),
                    1
                )}
                priceSubtitle={priceSubtitle}
            />

            {inusrance?.selected && (
                <LoadingChip
                    sx={{
                        color: '#fff',

                        '& .MuiChip-deleteIcon': {
                            color: '#fff',

                            '&:hover': {
                                color: '#fff',
                            },
                        },
                    }}
                    icon={<SecurityIcon />}
                    label={getTranslated(
                        'insurance.productAddContainer.chip.label',
                        {
                            tariffName: getPropByCurrentLocale(
                                inusrance.tariffName
                            ),
                        }
                    )}
                    color='success'
                    onDelete={() => onDeleteInsurance(ticket)}
                    loading={isDeleting}
                    disabled={addInsuranceLoading}
                />
            )}
        </Box>
    );
};

const getBirthdayAsString = (dateRange: DateRange) => {
    if (!!dateRange?.from && !!dateRange?.to) {
        return `(${moment(dateRange?.from).format(DATE_FORMAT)} - ${moment(
            dateRange.to
        ).format(DATE_FORMAT)})`;
    } else if (!!dateRange?.from && !dateRange?.to) {
        return `(${moment(dateRange.from).format(DATE_FORMAT)})`;
    } else return '';
};
