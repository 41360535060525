import { ORIGIN } from '@/constants';
import { saveToLocalStorage } from '@/utils/storage';
import { OAUTH_REDIRECT_FROM_KEY } from '@/constants/auth';
import { cartSlice } from '@/slices/cartSlice';
import { useSlice } from '@/hooks/useSlice';
import { authSlice } from '@/slices/authSlice';
import {
    usePostOAuthLoginWithoutTokenMutation,
    usePostOAuthLoginWithTokenMutation,
} from '@/api/authentication';
import { userApi } from '@/api/user';
import { useDispatch } from 'react-redux';

export const useAuthentication = () => {
    const {
        actions: { clearCart },
    } = useSlice(cartSlice, 'cart');
    const {
        state: authState,
        actions: { clearAuthData },
    } = useSlice(authSlice, 'auth');
    const [postOauthWithToken] = usePostOAuthLoginWithTokenMutation();
    const [postOauthWithoutToken] = usePostOAuthLoginWithoutTokenMutation();
    const dispatch = useDispatch();

    const logout = () => {
        clearCart();
        clearAuthData();
    };

    const postOAuthAuthentication = ({
        providerUrl,
        from,
        linkAccount,
    }: {
        providerUrl: string;
        from?: string;
        linkAccount?: boolean;
    }) => {
        const action = linkAccount ? postOauthWithToken : postOauthWithoutToken;

        return action({
            providerType: providerUrl,
            redirectUrl: `${ORIGIN}/oauth/login`,
        })
            .unwrap()
            .then(({ url }) => {
                dispatch(userApi.util.invalidateTags(['USER']));
                if (url) {
                    if (from) {
                        saveToLocalStorage(OAUTH_REDIRECT_FROM_KEY, from);
                    }

                    window.location.href = url;
                }
            });
    };

    return {
        isLoggedIn: !!authState.token,
        ...authState,
        logout,
        oAuthAuthentication: postOAuthAuthentication,
    };
};
