import { FunctionComponent } from 'react';
import { Typography, TypographyProps } from '@mui/material';

export const CapFirstWord: FunctionComponent<
    TypographyProps & { children: string }
> = ({ children, ...typProps }) => {
    const [first, ...rest] = children.split(' ');
    return (
        <Typography {...typProps}>
            <span
                style={{
                    textTransform: 'capitalize',
                }}
            >
                {first}
            </span>
            &nbsp;
            {rest.join(' ')}
        </Typography>
    );
};
