import { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import {
    getLiftOperatorProductsPageLink,
    LiftOperator,
} from '@/models/liftOperators';
import { IS_DEVELOPMENT } from '@/constants';
import { FlexContainer } from '@/components/Layout/Container';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';

const LogoImage = styled('img')(() => ({
    maxWidth: '100%',
    borderRadius: '4px',
    objectFit: 'contain',
    cursor: 'pointer',
}));

type LiftOperatorLogoProps = {
    logoType: string;
    liftOperator: LiftOperator;
};

export const LiftOperatorLogo: FunctionComponent<LiftOperatorLogoProps> = ({
    logoType,
    liftOperator,
}) => {
    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();
    const { locale } = useTranslation();

    const navigateToProducts = () => {
        navigate(
            getPagePath(routerConfig.Products, [
                {
                    param: 'id',
                    value: getLiftOperatorProductsPageLink(
                        liftOperator,
                        locale
                    ),
                },
            ])
        );
    };

    let logo = liftOperator.logos.find((l) => l.type === logoType);
    if (!logo && !IS_DEVELOPMENT) {
        if (liftOperator.logos.length > 0) {
            logo = liftOperator.logos[0];
        } else {
            return null;
        }
    }

    if (!liftOperator) return null;

    if (IS_DEVELOPMENT) {
        return (
            <FlexContainer
                onClick={() => {
                    navigateToProducts();
                }}
                sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#2196f3',
                    color: '#fff',
                    fontWeight: 500,
                    p: 3,
                    borderRadius: '4px',
                    cursor: 'pointer',
                }}
            >
                <BrandingWatermarkIcon
                    sx={{
                        color: '#fff',
                        width: '32px',
                        height: '32px',
                    }}
                />
            </FlexContainer>
        );
    }

    return (
        <LogoImage
            onClick={() => {
                navigateToProducts();
            }}
            src={logo.href}
        />
    );
};
