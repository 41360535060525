import { FunctionComponent } from 'react';
import {
    Accordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProsConsList } from '@/components/PageContent/CartPageContent/TicketIdentVariant/ProsConsList';
import { IdentificationType } from '@/models/cart';
import { styled } from '@mui/material/styles';

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingTop: theme.spacing(3),
}));

type TicketIdentVariantInfoAccordionProps = {
    variant: IdentificationType;
};

export const TicketIdentVariantInfoAccordion: FunctionComponent<
    TicketIdentVariantInfoAccordionProps
> = ({ variant }) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                aria-controls='panel1a-content'
                id='panel1a-header'
            >
                <Typography>Mehr Erfahren</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ProsConsList variant={variant} />
            </AccordionDetails>
        </Accordion>
    );
};
