import { baseApiAuthenticated } from '.';
import { urlBuilder } from '@/utils/api';
import { convertUserToFrontend, Newsletter, User } from '@/models/user';
import { BackendType } from '@/models/general';
import { NEWSLETTER_DE, NEWSLETTER_EN } from '@/constants';

type PutPasswordParams = {
    id: string;
    current: string;
    password: string;
    password2: string;
};

type GetNewsLettersResponse = {
    newsletters: Newsletter[];
};

const userEndpoint = (endpoint?: string) => `customers/me${endpoint}`;

const userUrls = () => ({
    userData: urlBuilder('auth', userEndpoint('')),
    password: urlBuilder('auth', userEndpoint('/password')),
    email: urlBuilder('auth', userEndpoint('/email')),
    emailConfirmation: urlBuilder('auth', userEndpoint('/email')),
    newsletter: urlBuilder('auth', userEndpoint('/newsletters')),
    favorites: urlBuilder('auth', userEndpoint('/favorites')),
});

export const userApi = baseApiAuthenticated
    .enhanceEndpoints({ addTagTypes: ['USER', 'NEWSLETTERS'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUserData: builder.query<User, void>({
                query: () => userUrls().userData,
                providesTags: ['USER'],
            }),

            putUserData: builder.mutation<User, User>({
                query: (userData) => ({
                    url: userUrls().userData,
                    method: 'PUT',
                    body: userData,
                }),

                transformResponse: (response: User<BackendType>) =>
                    convertUserToFrontend(response),

                invalidatesTags: ['USER'],
            }),

            putPassword: builder.mutation<void, PutPasswordParams>({
                query: (passwordParams) => ({
                    url: userUrls().password,
                    method: 'PUT',
                    body: passwordParams,
                }),
            }),
            putEmail: builder.mutation<
                void,
                { id: string; password: string; email: string }
            >({
                query: (body) => ({
                    url: userUrls().email,
                    method: 'PUT',
                    body,
                }),
            }),
            postEmailConfirmation: builder.mutation<void, string>({
                query: (code) => ({
                    url: `userUrls().emailConfirmation/${code}`,
                    method: 'POST',
                }),
            }),
            getNewsletters: builder.query<
                { de: Newsletter; en: Newsletter },
                void
            >({
                query: () => ({
                    url: userUrls().newsletter,
                    method: 'GET',
                }),
                transformResponse: (response: GetNewsLettersResponse) => {
                    const { newsletters } = response;
                    const enIndex = newsletters.findIndex(
                        (nl) => nl.id === NEWSLETTER_EN
                    );
                    const deIndex = newsletters.findIndex(
                        (nl) => nl.id === NEWSLETTER_DE
                    );

                    if (deIndex > -1 && enIndex > -1) {
                        return {
                            en: newsletters[enIndex],
                            de: newsletters[deIndex],
                        };
                    }

                    return { en: null, de: null };
                },
                providesTags: ['NEWSLETTERS'],
            }),
            postNewsletter: builder.mutation<void, string>({
                query: (id) => ({
                    url: `${userUrls().newsletter}/${id}`,
                    method: 'POST',
                }),
                invalidatesTags: ['NEWSLETTERS'],
            }),
            deleteNewsletter: builder.mutation<void, string>({
                query: (id) => ({
                    url: `${userUrls().newsletter}/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['NEWSLETTERS'],
            }),
        }),
    });

export const {
    useGetUserDataQuery,
    usePutUserDataMutation,
    usePutPasswordMutation,
    usePutEmailMutation,
    usePostEmailConfirmationMutation,
    useGetNewslettersQuery,
    usePostNewsletterMutation,
    useDeleteNewsletterMutation,
} = userApi;
