import {
    BackendType,
    convertDateToFrontend,
    CustomDate,
    FrontendType,
    Money,
    ObjectType,
} from '@/models/general';

type CreditUsageStatus = 'USED' | 'LOCKED';

type CreditUsage<T extends ObjectType = FrontendType> = {
    amount: Money;
    orderId: string;
    status: CreditUsageStatus;
    timestamp: CustomDate<T>;
};

export type Credit<T extends ObjectType = FrontendType> = {
    balance: Money;
    usages: CreditUsage<T>[];
};

export const convertCreditToFrontend = (credit: Credit<BackendType>) => ({
    ...credit,
    usages: credit.usages.map((usage) => ({
        ...usage,
        timestamp: convertDateToFrontend(usage.timestamp),
    })),
});

export type AddressType = 'shipping' | 'invoice';

export type Address = {
    company: string;
    firstname: string;
    lastname: string;
    street: string;
    zipcode: string;
    city: string;
    country: string;
};

export const createEmptyAddress = (): Address => ({
    company: '',
    firstname: '',
    lastname: '',
    street: '',
    zipcode: '',
    city: '',
    country: '',
});

export type User<T extends ObjectType = FrontendType> = {
    id: string;

    email: string;
    salutation: string;
    title: string;
    phone: string;
    marketingAcceptance: boolean;
    marketingAcceptanceDate: CustomDate<T>;
    groups: [];
} & Address;

export const convertUserToFrontend = (user: User<BackendType>): User => ({
    ...user,
    marketingAcceptanceDate: convertDateToFrontend(
        user.marketingAcceptanceDate
    ),
});

export type Profile<T extends ObjectType = FrontendType> = {
    id: string;
    identifier: string;
    cardExpirationDate: CustomDate<T>;
};

export const convertProfileToFrontend = (
    profile: Profile<BackendType>
): Profile => ({
    ...profile,
    cardExpirationDate: convertDateToFrontend(profile.cardExpirationDate),
});

export type RegistrationData = {
    userData?: boolean;
    agb: boolean;
    salutation: string;
    title: string;
    phone: string;
    firstname: string;
    lastname: string;
    email: string;
    newsletters: string[];
};

export type OAuthCreateUserData = {
    email: string;
    salutation: string;
    firstname: string;
    lastname: string;
    phone: string;
    street: string;
    zipcode: string;
    city: string;
    country: string;
    newsletters: string[];
    marketingAcceptance: boolean;
};

export type ActivationData = {
    activationCode: string;
    password: string;
    rememberMe: boolean;
};

export type Newsletter = {
    id: string;
    status: 'SUBSCRIBED' | 'UNSUBSCRIBED' | 'PENDING';
};

export enum LoginType {
    EMAIL_PASSWORD = 'EMAIL_PASSWORD',
    FACEBOOK = 'FACEBOOK',
    GOOGLE = 'GOOGLE',
}

export type LoginInformation<T extends ObjectType = FrontendType> = {
    id: string;
    createdAt: CustomDate<T>;
    lastUsedAt: CustomDate<T>;
    name: string;
    type: LoginType;
};

export const convertLoginInformationToFrontend = (
    lI: LoginInformation<BackendType>
): LoginInformation => ({
    ...lI,
    createdAt: convertDateToFrontend(lI.createdAt),
    lastUsedAt: convertDateToFrontend(lI.lastUsedAt),
});

export type Favorite = {
    liftOperatorId: string;
};
