import { FunctionComponent } from 'react';
import { Link, Typography } from '@mui/material';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme/theme';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { getPropByCurrentLocale } from '@/utils/locale';

const useStyles = makeStyles((theme: Theme) => ({
    gtcNotice: {
        fontSize: theme.spacing(3),
    },
}));

export const GTCNotice: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => {
    const { routerConfig, getPagePath } = useCustomRouter();
    const { IS_PORTAL, pageConfig } = useConfigSlice();
    const { getTranslated } = useTranslation();
    const classes = useStyles();

    const gtcLink = IS_PORTAL
        ? getPagePath(routerConfig.GTCPage)
        : getPropByCurrentLocale(
              pageConfig?.configuration?.termsAndConditionsUrl,
              ''
          );

    const dataPrivacyLink = IS_PORTAL
        ? getPagePath(routerConfig.DataPrivacy)
        : getPropByCurrentLocale(pageConfig?.configuration?.dataPrivacyUrl, '');
    return (
        <Typography className={classes.gtcNotice}>
            {getTranslated('gtcNoticePart1')}&nbsp;
            <Link
                className={classes.gtcNotice}
                href={dataPrivacyLink}
                target='_blank'
            >
                {getTranslated('dataPrivacyPolicies')}
            </Link>{' '}
            {getTranslated('and')}{' '}
            <Link className={classes.gtcNotice} href={gtcLink} target='_blank'>
                {getTranslated('gtc')}
            </Link>
            &nbsp;{getTranslated('gtcNoticePart2')}
        </Typography>
    );
};
