import { configSlice, PortalConfig } from '@/slices/configSlice';
import { useSlice } from '@/hooks/useSlice';

export const usePageConfig = () => {
    const { state: configState } = useSlice(configSlice, 'config');

    const isPortal =
        configState.configuration === PortalConfig.PORTAL_CONFIGURATION &&
        !configState.isOeamtc;
    const isOeamtc =
        configState.configuration === PortalConfig.PORTAL_CONFIGURATION &&
        configState.isOeamtc;
    const isWebshop =
        configState.configuration === PortalConfig.INTEGRATION_CONFIGURATION;
    const currentWebshop =
        configState.pageConfig?.configuration?.vanityIdentifier || null;

    const isCurrentlyInWebshop = (vanityIdentifier: string) => {
        if (!isWebshop) return false;

        return (
            configState.pageConfig?.configuration?.vanityIdentifier ===
            vanityIdentifier
        );
    };

    return {
        isPortal,
        isOeamtc,
        isWebshop,
        isCurrentlyInWebshop,
        currentWebshop,
    };
};
