import { CheckoutViewMobile } from '@/components/PageContent/CartPageContent/mobile/CheckoutView/CheckoutViewMobile';
import { AddressBlockContainer } from '@/components/PageContent/CartPageContent/Payment/AddressBlock/AddressBlockContainer';
import { CostSummaryBlock } from '@/components/PageContent/CartPageContent/CostSummaryBlock';
import { PaymentOptionsBlock } from '@/components/PageContent/CartPageContent/Payment/PaymentOptions/PaymentOptionsBlock';
import { useCheckoutHelpers } from '@/hooks/useCheckoutHelpers';
import { InsuranceConsentContainer } from '@/components/PageContent/CartPageContent/InsuranceConsentContainer';
import { CheckoutInsuranceContactCard } from '@/components/PageContent/CartPageContent/Payment/CheckoutInsuranceContactCard';
import { InsuranceInfoCard } from '@/components/PageContent/CartPageContent/InsuranceInfoCard';

export const PaymentViewContainerMobile = () => {
    const { hasInsuranceSelected, insuranceSelectionPossible } =
        useCheckoutHelpers();
    return (
        <CheckoutViewMobile title={'Bezahlung'}>
            <AddressBlockContainer />
            {hasInsuranceSelected && <CheckoutInsuranceContactCard />}
            <CostSummaryBlock />
            <PaymentOptionsBlock />

            {insuranceSelectionPossible && <InsuranceInfoCard />}
            {hasInsuranceSelected && <InsuranceConsentContainer />}
        </CheckoutViewMobile>
    );
};
