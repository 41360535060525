import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { useTheme } from '@mui/material/styles';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { Theme } from '@/theme/theme';
import { ProductsPageNavigation } from '@/components/PageContent/ProductsPageContent/ProductsPageNavigation';
import { OnePageHeader } from '@/components/WebShopHeaderImage/OnePageHeader';
import Button from '@mui/material/Button';
import { useTranslation } from '@/hooks/useTranslation';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography } from '@mui/material';
import { getMoneyAsString } from '@/utils/general';
import { useCartCosts } from '@/hooks/useCartCosts';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import Paper from '@mui/material/Paper';
import { FooterContainer } from '@/components/Footer/FooterContainer';
import { CartOverviewContainer } from '@/components/PageContent/ProductsPageContent/CartTicketList/CartOverviewContainer';

import { ProductsPageLiftOperatorInfo } from '@/components/PageContent/ProductsPageContent/ProductsPageLiftOperatorInfo';

import { OeamtcBannerContainer } from '@/components/OeamtcBanner/OeamtcBanner';
import { ProductSelectionFilter } from '@/components/ProductSelectionFilter/ProductSelectionFilter';
import { ProductSelection } from '@/components/PageContent/ProductsPageContent/ProductSelection';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { useCheckoutHelpers } from '@/hooks/useCheckoutHelpers';

export const ProductsPageContentContainer: FunctionComponent = () => {
    const { IS_PORTAL } = useConfigSlice();
    const theme: Theme = useTheme();
    const cartCosts = useCartCosts();
    const { getTranslated } = useTranslation();
    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();
    const { numberOfItemsInCart } = useCheckoutHelpers();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');

    return (
        <FlexContainer
            sx={() => ({
                mt: `${theme.appBar.height}px`,
                minHeight: `calc(100vh - ${theme.appBar.height}px)`,
            })}
            fullWidth
        >
            <FlexContainer
                sx={{
                    flexDirection: 'column',
                    flexGrow: 1,
                    mr: '560px ',
                    minHeight: '100%',
                }}
            >
                <OeamtcBannerContainer />
                {!IS_PORTAL && <OnePageHeader />}

                {IS_PORTAL && (
                    <>
                        <ProductsPageNavigation />
                        <ProductsPageLiftOperatorInfo
                            liftOperator={selectedLiftOperator}
                        />
                    </>
                )}

                <FlexContainer
                    sx={{
                        mx: 3,
                        height: '100%',
                    }}
                    column
                >
                    {selectedLiftOperator.noOffersText ? (
                        <Box
                            sx={{
                                p: 3,
                            }}
                        >
                            <Typography variant='h6'>
                                {selectedLiftOperator.noOffersText}
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <ProductSelectionFilter />
                            <ProductSelection />
                        </>
                    )}
                </FlexContainer>

                <FooterContainer type='light' />
            </FlexContainer>

            <FlexContainer
                sx={(theme) => ({
                    position: 'fixed',
                    right: 0,
                    top: `${theme.appBar.height}px`,
                    bottom: 0,
                    width: '560px',
                    backgroundColor: theme.palette.sjLight,
                    p: 3,
                    paddingBottom: '64px',
                })}
            >
                <CartOverviewContainer />

                <Paper
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        right: 0,
                        left: 0,
                        bottom: 0,
                        height: '64px',
                        alignItems: 'center',
                        px: 3,
                        backgroundColor: theme.palette.sjLight,
                        borderRadius: 0,
                        borderTop: `1px solid #d9d5d5`,
                    }}
                    square={false}
                    elevation={0}
                >
                    <Typography
                        sx={{
                            fontSize: '1.2rem',
                            fontWeight: 600,
                        }}
                    >
                        {getTranslated('Cart.total')}:{' '}
                        {getMoneyAsString(cartCosts.subtotalSum)}
                    </Typography>
                    <Button
                        sx={{
                            ml: 'auto',
                        }}
                        onClick={() => {
                            navigate(getPagePath(routerConfig.Cart));
                        }}
                        variant='contained'
                        color='secondary'
                        disabled={numberOfItemsInCart === 0}
                    >
                        {getTranslated('Cart.toCheckout')}

                        <KeyboardArrowRightIcon
                            sx={{
                                ml: 1,
                            }}
                        />
                    </Button>
                </Paper>
            </FlexContainer>
        </FlexContainer>
    );
};
