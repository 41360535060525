import { CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TicketCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(4)} !important`,

    [theme.breakpoints.down('lg')]: {
        padding: `${theme.spacing(2)} !important`,
    },
}));
