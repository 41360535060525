import { FunctionComponent, PropsWithChildren, useEffect } from 'react';

import { CartPageContent } from '@/components/PageContent/CartPageContent/CartPageContent';

import useMediaQuery from '@mui/material/useMediaQuery';
import { CartPageContainerMobile } from '@/components/PageContent/CartPageContent/mobile/CartPageContainerMobile';
import { useTheme } from '@mui/material/styles';
import { useNotification } from '@/hooks/useNotification';
import { useTranslation } from '@/hooks/useTranslation';
import { useSlice } from '@/hooks/useSlice';

import { useCartCosts } from '@/hooks/useCartCosts';
import { checkoutSlice } from '@/slices/checkoutSlice';
import { useNavigate } from 'react-router';
import { useGetCreditQuery } from '@/api/order';
import { useCheckoutHelpers } from '@/hooks/useCheckoutHelpers';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { getLiftOperatorProductsPageLink } from '@/models/liftOperators';
import TagManager from 'react-gtm-module';
import { mapBackendVoucherToGAItem } from '@/models/voucher';
import { mapIdentificationToGAItem } from '@/models/checkout/checkoutIdentification';
import { useGetCurrentCheckoutSession } from '@/api/checkout';
import { mapCheckoutTicketToGAItem } from '@/models/checkout/checkoutTicket';

export const CartsPage: FunctionComponent<PropsWithChildren> = () => {
    const { addNotification } = useNotification();
    const { getTranslated } = useTranslation();
    const { isCheckoutEmpty } = useCheckoutHelpers();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const { totalAfterCredits } = useCartCosts();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xl'));
    const {
        state: { checkoutId },
    } = useSlice(checkoutSlice, 'checkout');
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { getPagePath, routerConfig } = useCustomRouter();

    const navigate = useNavigate();
    const { data: credit } = useGetCreditQuery();
    const { locale } = useTranslation();

    useEffect(() => {
        if (checkoutSession) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'begin_checkout',
                    ecommerce: {
                        value: totalAfterCredits.amount,
                        currency: 'EUR',
                        items: [
                            ...checkoutSession.tickets.map(
                                mapCheckoutTicketToGAItem
                            ),

                            ...checkoutSession.vouchers.map(
                                mapBackendVoucherToGAItem
                            ),
                            ...checkoutSession.identifications.map(
                                mapIdentificationToGAItem
                            ),
                        ],
                    },
                },
            });
        }
    }, [checkoutSession]);

    useEffect(() => {
        if (credit) {
            const lockedUsages = credit.usages.filter(
                (usage) => usage.status === 'LOCKED'
            );

            if (lockedUsages.length) {
                const sum = lockedUsages.reduce(
                    (sum, nextUsage) => sum + nextUsage.amount.amount,
                    0
                );

                addNotification({
                    message: getTranslated('lockedBalance', {
                        value: sum,
                    }),
                    type: 'warning',
                });
            }
        }
    }, [credit]);

    if (!checkoutId || isCheckoutEmpty) {
        if (!checkoutId) {
            addNotification({
                type: 'error',
                message: getTranslated('checkoutIdMissing'),
            });
        }

        const fallbackPage = selectedLiftOperator
            ? getPagePath(routerConfig.Products, [
                  {
                      param: 'id',
                      value: getLiftOperatorProductsPageLink(
                          selectedLiftOperator,
                          locale
                      ),
                  },
              ])
            : getPagePath(routerConfig.LiftOperators);
        navigate(fallbackPage);

        return;
    }

    return mobileView ? <CartPageContainerMobile /> : <CartPageContent />;
};
