import { LiftOperator } from '@/models/liftOperators';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LiftOperatorState = {
    selectedLiftOperator: LiftOperator;
};

const initialState: LiftOperatorState = {
    selectedLiftOperator: null,
};

export const liftOperatorSlice = createSlice({
    name: 'liftOperatorSlice',
    initialState,
    reducers: {
        setSelectedLiftOperator(
            state: LiftOperatorState,
            action: PayloadAction<LiftOperator>
        ) {
            state.selectedLiftOperator = action.payload;

            return state;
        },
    },
});
