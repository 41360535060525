import { FunctionComponent, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import { useNotification } from '@/hooks/useNotification';
import { useTranslation } from '@/hooks/useTranslation';
import { useSlice } from '@/hooks/useSlice';
import { cartSlice } from '@/slices/cartSlice';
import { CheckoutTicket } from '@/models/checkout/checkoutTicket';
import {
    PostDiscountErrorResponse,
    usePostDiscountOeamtcMutation,
} from '@/api/promotion';
import {
    useGetCurrentCheckoutSession,
    usePutCheckoutTicketMutation,
} from '@/api/checkout';

type OEAMTCPromotionDialogProps = {
    onClose: () => void;
    checkoutTicket: CheckoutTicket;
};

export const OEAMTCPromotionDialogContent: FunctionComponent<
    React.PropsWithChildren<OEAMTCPromotionDialogProps>
> = ({ onClose, checkoutTicket }) => {
    const [oeamtcMemberCode, setOeamtcMemberCode] = useState('');
    const [postDiscount, { isLoading }] = usePostDiscountOeamtcMutation();
    const [putCheckoutTicket] = usePutCheckoutTicketMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    const {
        state: { oeamtcPerOrderLimit, ticktsAppliedOeamtcCode },
        actions: { setPerOrderLimit, addUsedOeamtcCode },
    } = useSlice(cartSlice, 'cart');
    const { addNotification } = useNotification();
    const { getTranslated } = useTranslation();

    const redeemCode = () => {
        if (!checkoutSession) return;

        const codesUsed = Object.values(ticktsAppliedOeamtcCode).filter(
            (c) => c === oeamtcMemberCode
        ).length;
        if (
            typeof oeamtcPerOrderLimit === 'number' &&
            typeof codesUsed === 'number' &&
            codesUsed >= oeamtcPerOrderLimit
        ) {
            addNotification({
                message: getTranslated(
                    'Promotion.oeamtc.enter.error.doubleUsage'
                ),
                type: 'error',
            });

            return;
        }

        postDiscount({
            liftoperatorId: checkoutTicket.liftOperatorId,
            productId: checkoutTicket.productId,
            startDate: checkoutTicket.startDate.format('YYYY-MM-DD'),
            oeamtcMemberCode: oeamtcMemberCode,
        })
            .unwrap()
            .then(({ price, perOrderLimit, ...rest }) => {
                addUsedOeamtcCode({
                    oeamtcCode: oeamtcMemberCode,
                    ticketId: checkoutTicket.id,
                });
                setPerOrderLimit(perOrderLimit);

                addNotification({
                    message: getTranslated('Promotion.oeamtc.enter.success'),
                    type: 'success',
                });

                putCheckoutTicket({
                    customization: {
                        ...rest,
                        price: {
                            amount: price,
                            currency: 'EUR',
                        },
                    },
                    id: checkoutTicket.id,
                    identificationType: checkoutTicket.identificationType,
                    checkoutId: checkoutSession.id,
                })
                    .unwrap()
                    .then(() => {
                        addNotification({
                            message: getTranslated(
                                'Promotion.oeamtc.enter.success'
                            ),
                            type: 'success',
                        });

                        onClose();
                    });
            })
            .catch((error) => {
                const errorData = error.data as PostDiscountErrorResponse;

                let errorMessage = '';
                switch (errorData.message) {
                    case 'Discount not available':
                        errorMessage = getTranslated(
                            'Promotion.oeamtc.enter.error.notavailable'
                        );
                        break;
                    case 'Invalid oeamtc member code':
                        errorMessage = getTranslated(
                            'Promotion.oeamtc.enter.invalidMemberCode'
                        );
                        break;
                    default:
                        errorMessage = getTranslated(
                            'Promotion.oeamtc.enter.error'
                        );
                }

                addNotification({
                    message: errorMessage,
                    type: 'error',
                });
            });
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <DialogTitle>
                {getTranslated('Promotion.oeamtc.dialog.title')}
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    sx={{
                        mb: 4,
                    }}
                    onChange={(e) => {
                        const { value } = e.target;
                        setOeamtcMemberCode(value.replaceAll(' ', ''));
                    }}
                    margin='dense'
                    variant='outlined'
                    label={getTranslated('Promotion.oeamtc.enter.label')}
                    type='text'
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button isLoading={false} onClick={handleClose} color='primary'>
                    Schließen
                </Button>
                <Button
                    isLoading={isLoading}
                    onClick={redeemCode}
                    variant={'contained'}
                    color='primary'
                >
                    {getTranslated('Promotion.oeamtc.enter.activate')}
                </Button>
            </DialogActions>
        </>
    );
};
