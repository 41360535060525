import { FunctionComponent, useRef, useState } from 'react';

import { Box, Stack, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTranslation } from '@/hooks/useTranslation';
import {
    useGetCurrentCheckoutSession,
    usePostCheckoutCustomerMutation,
} from '@/api/checkout';
import { LoadingButton } from '@mui/lab';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNotification } from '@/hooks/useNotification';
import { FlatCardWithTitle } from '@/components/Card/FlatCardWithTitle';
import { Moment } from 'moment';

export const CheckoutInsuranceContactCard: FunctionComponent = () => {
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const [phoneInput, setPhoneInput] = useState<string | null>(
        checkoutSession?.phone || ''
    );
    const [birthdate, setBirthdate] = useState<Moment | null>(
        checkoutSession?.birthdate || null
    );
    const [inputError, setInputError] = useState<('phone' | 'birthdate')[]>([]);
    const { getTranslated } = useTranslation();
    const [postCustomer, { isLoading }] = usePostCheckoutCustomerMutation();
    const { addNotification } = useNotification();
    const inputDirty = useRef(false);

    if (
        !inputDirty.current &&
        checkoutSession &&
        checkoutSession.phone !== phoneInput
    ) {
        setPhoneInput(checkoutSession.phone);
    }

    const savePhoneNumber = () => {
        if (!phoneInput) {
            setInputError((prev) => [...prev, 'phone']);
            return;
        }

        if (!birthdate) {
            setInputError((prev) => [...prev, 'birthdate']);
            return;
        }

        postCustomer({
            phone: phoneInput,
            birthdate: birthdate,
            checkoutId: checkoutSession.id,
        })
            .unwrap()
            .then(() => {
                addNotification({
                    type: 'success',
                    message: getTranslated(
                        'CheckoutPage.insurance.enter.success'
                    ),
                });
            })
            .catch(() => {
                addNotification({
                    type: 'error',
                    message: getTranslated(
                        'CheckoutPage.insurance.enter.error'
                    ),
                });
            });
    };

    return (
        <FlatCardWithTitle
            title={getTranslated('CheckoutPage.insuranceDataCard.title')}
        >
            <Stack spacing={4} alignItems='center'>
                <Alert severity='info'>
                    {getTranslated('CheckoutPage.insurance.phone.alert')}
                </Alert>

                <TextField
                    label={getTranslated(
                        'CheckoutPage.insurance.input.phone.label'
                    )}
                    value={phoneInput}
                    sx={{
                        width: '100%',
                    }}
                    onChange={(e) => {
                        const { value } = e.target;
                        inputDirty.current = true;

                        if (value && inputError.includes('phone')) {
                            setInputError((prev) =>
                                prev.filter((e) => e !== 'phone')
                            );
                        }

                        setPhoneInput(value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            savePhoneNumber();
                        }
                    }}
                    variant='outlined'
                    error={inputError.includes('phone')}
                    helperText={
                        inputError.includes('phone')
                            ? getTranslated(
                                  'CheckoutPage.insurance.input.phone.error'
                              )
                            : ''
                    }
                />

                <Alert severity='info'>
                    {getTranslated('CheckoutPage.insurance.birthdate.alert')}
                </Alert>

                <DatePicker
                    sx={{
                        width: '100%',
                    }}
                    value={birthdate}
                    onChange={(value) => {
                        if (value && inputError.includes('birthdate')) {
                            setInputError((prev) =>
                                prev.filter((e) => e !== 'birthdate')
                            );
                        }

                        setBirthdate(value);
                    }}
                    label={getTranslated('Core.birthday')}
                    name='birthdate-insurance-card'
                    slotProps={{
                        textField: {
                            error: inputError.includes('birthdate'),
                            helperText: inputError.includes('birthdate')
                                ? getTranslated(
                                      'CheckoutPage.insurance.input.birthdate.error'
                                  )
                                : '',
                        },
                    }}
                    disableFuture
                />

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingButton
                        variant='contained'
                        color='secondary'
                        onClick={savePhoneNumber}
                        loading={isLoading}
                        sx={{
                            textTransform: 'unset',
                            borderRadius: 5,
                            color: '#fff',
                            width: 160,
                        }}
                    >
                        {getTranslated('common.save')}
                    </LoadingButton>
                </Box>
            </Stack>
        </FlatCardWithTitle>
    );
};
