import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Box } from '@mui/material';

type VoucherPreviewProps = {
    imagePreview: string;
};

export const VoucherPreview: FunctionComponent<
    React.PropsWithChildren<VoucherPreviewProps>
> = ({ imagePreview }) => {
    return (
        <FlexContainer column>
            <Box
                sx={{
                    maxWidth: '100vw',
                    m: 3,
                    boxShadow: 3,
                }}
            >
                <img alt='voucher-preview' src={imagePreview} />
            </Box>
        </FlexContainer>
    );
};
