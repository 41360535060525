import { FunctionComponent } from 'react';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { useTranslation } from '@/hooks/useTranslation';
import { useSlice } from '@/hooks/useSlice';
import { cartSlice } from '@/slices/cartSlice';
import { PromotionType } from '@/models/promotionDialog';
import { Button } from '@/components/Layout/Button/Button';
import { TAELER_ID } from '@/constants';
import { TAELER_PROMOTION_TICKETS } from '@/constants/3taeler';
import {
    CheckoutTicket,
    ticketHasAHAPromotion,
} from '@/models/checkout/checkoutTicket';

export const PromotionButtons: FunctionComponent<{
    checkoutTicket: CheckoutTicket;
}> = ({ checkoutTicket }) => {
    const { IS_OEAMTC } = useConfigSlice();
    const { getTranslated } = useTranslation();
    const {
        state: { ticktsAppliedOeamtcCode },
        actions: { setPromotionDialogInput },
    } = useSlice(cartSlice, 'cart');

    const onPromotionClick = (promotionType: PromotionType) => {
        setPromotionDialogInput({
            checkoutTicket,
            promotionType,
        });
    };

    return (
        <>
            {IS_OEAMTC && (
                <>
                    {ticktsAppliedOeamtcCode[checkoutTicket.id] ? (
                        <Button
                            color='success'
                            variant='outlined'
                            sx={{
                                mx: 1,
                            }}
                        >
                            {getTranslated('Promotion.oeamtc.enter.activated')}
                        </Button>
                    ) : (
                        <Button
                            onClick={() =>
                                onPromotionClick(PromotionType.OEAMTC)
                            }
                            variant='outlined'
                            sx={{
                                mx: 1,
                            }}
                        >
                            {getTranslated('Promotion.oeamtc.enter.activate')}
                        </Button>
                    )}
                </>
            )}

            {checkoutTicket.liftOperatorId === TAELER_ID &&
                ticketHasAHAPromotion(checkoutTicket) && (
                    <Button
                        onClick={() => onPromotionClick(PromotionType.AHA)}
                        variant='outlined'
                        sx={{
                            mx: 1,
                        }}
                    >
                        {TAELER_PROMOTION_TICKETS.AHA} /{' '}
                        {TAELER_PROMOTION_TICKETS.LAST_SEASON}
                    </Button>
                )}
        </>
    );
};
