import React, { FunctionComponent } from 'react';
import {
    Dialog as MuiDialog,
    DialogProps,
    Fade,
    Slide,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

// Dialog that switches to fullscreen slide up dialog on mobile

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export const Dialog: FunctionComponent<
    Omit<DialogProps, 'fullWidth' | 'fullScreen'>
> = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <MuiDialog
            fullWidth={true}
            fullScreen={isMobile}
            TransitionComponent={isMobile ? Transition : Fade}
            {...props}
        />
    );
};
