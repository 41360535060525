import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FlexContainer, LoadingContainer } from '@/components/Layout/Container';
import { ProductType } from '@/models/product';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { TicketInfoDialog } from '@/components/PageContent/ProductsPageContent/TicketInfoDialog';
import { ProductFilterTags } from '@/components/PageContent/ProductsPageContent/ProductFilterTags';
import { useTranslation } from '@/hooks/useTranslation';
import { ProductsResult } from '@/components/PageContent/ProductsPageContent/ProductsResult';
import { ProductCollection } from '@/models/collection/productCollection';
import { useProductFilter } from '@/hooks/useProductFilter';
import {
    useGetProductCollectionsQuery,
    useGetProductDetailsQueryWrapper,
} from '@/api/liftOperator';
import { useSearchParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { isProductFilterComplete } from '@/models/productFilter';
import { Box, Typography } from '@mui/material';
import { mapProductToGAItem } from '@/models/product';

export type DurationProductMap = {
    [duration: number]: ProductCollection[];
};

export const ProductSelection: FunctionComponent = () => {
    const { locale } = useConfigSlice();
    const [{ productFilter }] = useProductFilter();
    const { data: productDetails, isFetching: productDetailsLoading } =
        useGetProductDetailsQueryWrapper();
    const { productCollections } = useGetProductCollectionsQuery();
    const { getTranslated } = useTranslation();
    const [infoText, setInfoText] = useState<string>(null);
    const [query, setQuery] = useSearchParams();
    const [selectedFilterTag, setSelectedFilterTag] = useState<string>(
        query.get('filterTag') || ''
    );

    const filteredProductCollections = useMemo(() => {
        if (selectedFilterTag) {
            return productCollections.filter((collection) => {
                return collection.filterTags[locale].includes(
                    selectedFilterTag
                );
            });
        }

        return productCollections;
    }, [productCollections, selectedFilterTag]);

    const productCollectionsByDuration: DurationProductMap =
        filteredProductCollections.reduce<DurationProductMap>(
            (durationMap, productCollection) => {
                const { duration } = productCollection;
                if (durationMap[duration]) {
                    durationMap[duration].push(productCollection);
                } else {
                    durationMap[duration] = [productCollection];
                }

                return durationMap;
            },
            {}
        );

    useEffect(() => {
        if (productCollections) {
            const productGAItems = productCollections
                .map((collection) =>
                    collection.products.map(mapProductToGAItem)
                )
                .flat();

            TagManager.dataLayer({
                dataLayer: {
                    event: 'view_item_list',
                    ecommerce: {
                        items: productGAItems,
                    },
                },
            });
        }
    }, [productCollections]);

    const filterTags = productDetails?.filterTags[locale];

    if (!isProductFilterComplete(productFilter)) {
        return (
            <InfoMessageContainer
                label={getTranslated('productSelectionCompleteFilter')}
            />
        );
    }

    if (
        isProductFilterComplete(productFilter) &&
        !productCollections.length &&
        !productDetailsLoading
    ) {
        return (
            <InfoMessageContainer
                label={getTranslated('ProductsPage.noTicketsFound')}
            />
        );
    }

    return productDetailsLoading ? (
        <LoadingContainer
            sx={{
                height: '320px',
            }}
        />
    ) : (
        <>
            <FlexContainer
                sx={{
                    px: 2,
                }}
                fullWidth
                column
            >
                {!!filterTags.length && (
                    <FlexContainer
                        sx={{
                            mx: 'auto',
                            px: 1,
                            alignItems: 'center',
                            py: 6,
                        }}
                    >
                        <ProductFilterTags
                            filterTags={filterTags}
                            selectedTag={selectedFilterTag}
                            onTagsSelectChange={(filterTag) => {
                                setSelectedFilterTag(filterTag);

                                setQuery((searchParams) => {
                                    if (filterTag) {
                                        searchParams.set(
                                            'filterTag',
                                            filterTag
                                        );
                                    } else {
                                        searchParams.delete('filterTag');
                                    }

                                    return searchParams;
                                });
                            }}
                        />
                    </FlexContainer>
                )}

                <FlexContainer
                    sx={{
                        width: '100%',
                        mt: 4,
                        alignItems: 'center',
                    }}
                    column
                >
                    <ProductsResult
                        durationProductsMap={productCollectionsByDuration}
                        onShowInfoText={setInfoText}
                        isSeason={productFilter.type === ProductType.SEASON}
                        key={query.toString()}
                    />
                </FlexContainer>
            </FlexContainer>

            <TicketInfoDialog
                title={getTranslated('productCard.info')}
                body={infoText}
                open={!!infoText}
                onClose={() => {
                    setInfoText(null);
                }}
            />
        </>
    );
};

const InfoMessageContainer: FunctionComponent<{ label: string }> = ({
    label,
}) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '320px',
            p: 4,
        }}
    >
        <Typography variant='h6'>{label}</Typography>
    </Box>
);
