import { useEffect } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { useSlice } from '@/hooks/useSlice';
import { ordersSlice } from '@/slices/ordersSlice';

export const ErrorPage = () => {
    const navigate = useNavigate();
    const { routerConfig, getPagePath } = useCustomRouter();
    const {
        actions: { setPaymentResponse },
    } = useSlice(ordersSlice, 'orders');
    const { getTranslated } = useTranslation();

    useEffect(() => {
        // to be sure delete previous payment data and make a fresh start
        setPaymentResponse(null);
    }, []);

    return (
        <SubPageTemplate>
            <FlexContainer column center>
                <ErrorOutlineIcon
                    sx={{
                        color: 'error.main',
                        fontSize: 64,
                        marginBottom: 2,
                    }}
                />
                <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ textAlign: 'center', marginBottom: 8 }}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getTranslated('CheckoutErrorPage.headline'),
                        }}
                    />
                </Typography>
                <Typography variant='body1' gutterBottom>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getTranslated('CheckoutErrorPage.text'),
                        }}
                    />
                </Typography>
                <Button
                    isLoading={false}
                    color='primary'
                    variant='contained'
                    sx={{ maxWidth: '250px', marginTop: 8 }}
                    onClick={() => navigate(getPagePath(routerConfig.Cart))}
                >
                    {getTranslated('Core.toCart')}
                </Button>
            </FlexContainer>
        </SubPageTemplate>
    );
};
