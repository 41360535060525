import { CheckoutTicketCollection } from '@/models/collection/checkoutTicketCollection';
import { Fragment, FunctionComponent } from 'react';
import { PersonAssignmentTitle } from '@/components/CollectionCard/CheckoutTicketCollectionCard/PersonAssignmentTitle';
import { Box, Divider } from '@mui/material';
import { useSlice } from '@/hooks/useSlice';
import { cartSlice } from '@/slices/cartSlice';
import { PersonAssignmentSingleTicket } from '@/components/CollectionCard/CheckoutTicketCollectionCard/PersonAssignmentSingleTicket';

type PersonAssignmentWithInsuranceContainerProps = {
    ticketCollection: CheckoutTicketCollection;
};

export const PersonAssignmentContainerSingle: FunctionComponent<
    PersonAssignmentWithInsuranceContainerProps
> = ({ ticketCollection }) => {
    const {
        actions: { setPersonAssignmentDialogControls },
    } = useSlice(cartSlice, 'cart');

    return (
        <Box>
            <PersonAssignmentTitle ticketCollection={ticketCollection} />

            {ticketCollection.checkoutTickets.map((ticket, index) => {
                const isLast =
                    index === ticketCollection.checkoutTickets.length - 1;
                return (
                    <Fragment key={`${ticket.id}_${index}`}>
                        <PersonAssignmentSingleTicket
                            key={`${ticket.id}_${index}`}
                            ticket={ticket}
                            rowIndex={index + 1}
                            onPersonAssignClick={(personId) => {
                                setPersonAssignmentDialogControls({
                                    personId,
                                    ticket: ticket,
                                });
                            }}
                        />

                        {!isLast && (
                            <Divider
                                sx={{
                                    width: '100%',
                                }}
                            />
                        )}
                    </Fragment>
                );
            })}
        </Box>
    );
};
