import { ThemeOptions } from '@mui/material/styles';
import { starjackBaseTheme } from '@/theme/starjackBaseTheme';
import { Configuration } from '@/models/configuration';
import { PaletteColor } from '@mui/material/styles/createPalette';

export const createIntegrationTheme = (
    configuration: Configuration
): ThemeOptions => ({
    ...starjackBaseTheme,
    palette: {
        ...starjackBaseTheme.palette,
        primary: {
            ...starjackBaseTheme.palette.primary,
            main:
                configuration.primaryColor ||
                (starjackBaseTheme.palette.primary as PaletteColor).main,
        },
    },

    button: {
        custom: {
            backgroundColor:
                configuration.filterBackgroundColor ||
                starjackBaseTheme.button.custom.backgroundColor,
            color:
                configuration.filterTextColor ||
                starjackBaseTheme.button.custom.color,
        },
    },
});
