import { FunctionComponent, useState } from 'react';
import { Form, Formik } from 'formik';
import { FlexContainer } from '@/components/Layout/Container';
import { Select } from '@/components/Layout/Select';
import { NEWSLETTER_DE, NEWSLETTER_EN } from '@/constants/index';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FormikTextField } from '@/components/Layout/Form/Field';
import { CheckboxForm } from '@/components/Layout/Form/CheckboxForm';
import { Button } from '@/components/Layout/Button/Button';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme/theme';
import * as Yup from 'yup';
import { getCurrentLocale } from '@/utils/locale';
import { translateFunction, useTranslation } from '@/hooks/useTranslation';
import { RegistrationData } from '@/models/user';
import { getTranslatedSalutationsOptions } from '@/utils/general';
import { usePageConfig } from '@/hooks/useConfig';

const validate = Yup.object().shape({
    firstname: Yup.string().required(
        translateFunction(
            'validation.error.firstname.required',
            getCurrentLocale()
        )
    ),
    lastname: Yup.string().required(
        translateFunction(
            'validation.error.lastname.required',
            getCurrentLocale()
        )
    ),
    email: Yup.string()
        .required(
            translateFunction(
                'validation.error.email.invalid',
                getCurrentLocale()
            )
        )
        .email(
            translateFunction(
                'validation.error.email.required',
                getCurrentLocale()
            )
        ),
    agb: Yup.string().required('Bitte bestätige die AGBs'),
});

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        marginBottom: theme.spacing(4),
        alignSelf: 'flex-start',
    },
    field: {
        width: '75%',
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },

    form: {
        width: '100%',
        flexFlow: 'wrap',
    },

    sectionBlock: {
        margin: theme.spacing(6, 0),
    },

    sectionBlockBtn: {
        margin: theme.spacing(6, 0),
        alignItems: 'center',
    },

    button: {
        width: '256px',
    },
    flexContainer: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'block',
        },
    },

    checkbox: {
        fontSize: theme.layout.textual.formControl.fontSize.small,
        marginTop: theme.spacing(4),
    },
}));

type RegistrationFormProps = {
    onFormSubmit: (values: RegistrationData) => void;
    isLoading: boolean;
};

export const RegistrationForm: FunctionComponent<
    React.PropsWithChildren<RegistrationFormProps>
> = ({ isLoading, onFormSubmit }) => {
    const classes = useStyles();
    const { getTranslated, locale } = useTranslation();
    const { isPortal } = usePageConfig();
    const [agbError, setAgbError] = useState(false);
    const [salutationError, setSalutationError] = useState(false);
    const [salutation, setSalutation] = useState('-');
    const [newslettersOption, setNewslettersOption] = useState<{
        en: string;
        de: string;
    }>({
        en: null,
        de: null,
    });

    const onNewslettersCheckboxChange = (language: 'en' | 'de') => () => {
        const id = language === 'de' ? NEWSLETTER_DE : NEWSLETTER_EN;

        setNewslettersOption((prevState) => ({
            ...prevState,
            [language]: prevState[language] ? null : id,
        }));
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                title: '',
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                agb: false,
                userData: false,
            }}
            validationSchema={validate}
            onSubmit={(values) => {
                if (!values.agb) {
                    setAgbError(true);
                    return;
                }

                if (!salutation) {
                    setSalutationError(true);
                    return;
                }

                const newsletters = [];
                for (const key in newslettersOption) {
                    if (newslettersOption[key]) {
                        newsletters.push(newslettersOption[key]);
                    }
                }

                onFormSubmit({
                    ...values,
                    salutation,
                    newsletters,
                });
            }}
            validateOnBlur={false}
        >
            <Form
                className={classes.form}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                <FlexContainer column fullWidth center>
                    <Select
                        name={'salutation'}
                        error={salutationError}
                        inputLabel={getTranslated('Address.salutation')}
                        onChange={(e) => {
                            setSalutation(e.target.value as string);
                            setSalutationError(false);
                        }}
                        value={salutation}
                        formControlProps={{
                            className: classes.field,
                        }}
                        options={getTranslatedSalutationsOptions(locale)}
                    />
                    {salutationError && (
                        <Typography variant='caption' color='error'>
                            {getTranslated(
                                'validation.error.salutation.required'
                            )}
                        </Typography>
                    )}

                    <FormikTextField
                        name={'title'}
                        textFieldProps={{
                            label: getTranslated('Address.title'),
                            variant: 'outlined',
                            className: classes.field,
                        }}
                    />
                    <FormikTextField
                        name={'firstname'}
                        textFieldProps={{
                            label: getTranslated('Address.firstname') + '*',
                            variant: 'outlined',
                            className: classes.field,
                        }}
                    />
                    <FormikTextField
                        name={'lastname'}
                        textFieldProps={{
                            label: getTranslated('Address.lastname') + '*',
                            variant: 'outlined',
                            className: classes.field,
                        }}
                    />

                    <FormikTextField
                        name={'phone'}
                        textFieldProps={{
                            label: getTranslated('Address.phone'),
                            variant: 'outlined',
                            className: classes.field,
                        }}
                    />

                    <FormikTextField
                        name={'email'}
                        textFieldProps={{
                            label: getTranslated('Address.email') + '*',
                            variant: 'outlined',
                            className: classes.field,
                        }}
                    />

                    <FlexContainer
                        className={classes.sectionBlock}
                        column
                        fullWidth
                    >
                        <CheckboxForm
                            name='agb'
                            color='primary'
                            onChange={() => {
                                setAgbError(false);
                            }}
                            label={
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: getTranslated(
                                            isPortal
                                                ? 'RegisterPage.gtcCheckbox.portal.label'
                                                : 'RegisterPage.gtcCheckbox.integration.label'
                                        ),
                                    }}
                                />
                            }
                        />
                        {agbError && (
                            <Typography variant='caption' color='error'>
                                {getTranslated('validation.error.gtc.required')}
                            </Typography>
                        )}
                        <CheckboxForm
                            name={'userData'}
                            color='primary'
                            label={getTranslated('Address.marketingAcceptance')}
                        />

                        {locale === 'de' && (
                            <FormControlLabel
                                className={classes.checkbox}
                                control={
                                    <Checkbox
                                        checked={!!newslettersOption['de']}
                                        onChange={onNewslettersCheckboxChange(
                                            'de'
                                        )}
                                        color='primary'
                                        name='newsLetterGerman'
                                    />
                                }
                                label={
                                    <Typography className={classes.checkbox}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: getTranslated(
                                                    'Newsletter.de'
                                                ),
                                            }}
                                        />
                                    </Typography>
                                }
                            />
                        )}

                        {locale === 'en' && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!newslettersOption['en']}
                                        onChange={onNewslettersCheckboxChange(
                                            'en'
                                        )}
                                        color='primary'
                                        name='newsLetterEnglish'
                                    />
                                }
                                label={
                                    <Typography className={classes.checkbox}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: getTranslated(
                                                    'Newsletter.en'
                                                ),
                                            }}
                                        />
                                    </Typography>
                                }
                            />
                        )}
                    </FlexContainer>

                    <FlexContainer
                        className={classes.sectionBlockBtn}
                        column
                        fullWidth
                    >
                        <Button
                            isLoading={isLoading}
                            type='submit'
                            color='primary'
                            variant='contained'
                            className={classes.button}
                            fullWidth
                        >
                            {getTranslated('MyAccountNavigation.register')}
                        </Button>
                    </FlexContainer>
                </FlexContainer>
            </Form>
        </Formik>
    );
};
