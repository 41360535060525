import { FunctionComponent, useMemo, useState } from 'react';
import { PageTemplate } from '@/pages/PageTemplate';
import { FlexContainer } from '@/components/Layout/Container';
import { useTheme } from '@mui/material/styles';
import { fullPageMinHeight, Theme } from '@/theme/theme';
import { VoucherTemplateGridList } from '@/components/PageContent/VouchersPageContent/VoucherTemplateGridList';
import { VoucherDataForm } from '@/components/PageContent/VouchersPageContent/VoucherDataForm';
import {
    convertVoucherToBackendVoucher,
    Voucher,
    VoucherTemplate,
} from '@/models/voucher';
import { VoucherPreview } from '@/components/PageContent/VouchersPageContent/VoucherPreview';
import { Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@/components/Layout/Button/Button';
import { useTranslation } from '@/hooks/useTranslation';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import {
    useGetCurrentCheckoutSession,
    usePostVoucherMutation,
} from '@/api/checkout';
import { useNotification } from '@/hooks/useNotification';
import {
    useGetVoucherTemplatesQuery,
    usePostVoucherPreviewMutation,
} from '@/api/order';

export type VoucherSelectState = 'select' | 'preview';

export const VouchersPage: FunctionComponent = () => {
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    const { data: voucherTemplates = [] } = useGetVoucherTemplatesQuery();
    const [selectedVoucherTemplate, setSelectedVoucherTemplate] =
        useState<VoucherTemplate>(null);
    const [voucherSelectState, setVoucherSelectState] =
        useState<VoucherSelectState>('select');
    const [finalVoucher, setFinalVoucher] = useState<Voucher>(null);
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [mobileFormExpanded, setMobileFormExpanded] = useState(false);
    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();
    const { getTranslated } = useTranslation();
    const theme: Theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [postVoucher, { isLoading: postVoucherLoading }] =
        usePostVoucherMutation();
    const [postVoucherPreview, { isLoading: previewLoading }] =
        usePostVoucherPreviewMutation();
    const { addNotification } = useNotification();
    const { locale } = useConfigSlice();

    const templates = useMemo(
        () =>
            voucherTemplates.filter(
                (v) => v.language.toLocaleLowerCase() === locale
            ),
        [voucherTemplates, locale]
    );

    const onFormSubmit = (voucher: Voucher) => {
        postVoucherPreview({
            voucherTemplateId: voucher.id,
            amount: voucher.amount,
            sender: voucher.from,
            receiver: voucher.to,
            text: voucher.text,
            language: voucher.language,
        })
            .unwrap()
            .then((response) => {
                const url = URL.createObjectURL(response);

                setPreviewUrl(url);
                setVoucherSelectState('preview');

                if (isMobile) {
                    setMobileFormExpanded(false);
                }
            });
    };

    if (!selectedVoucherTemplate) {
        if (previewUrl) setPreviewUrl('');
        if (finalVoucher) setFinalVoucher(null);
        if (voucherSelectState !== 'select') setVoucherSelectState('select');

        if (isMobile && mobileFormExpanded) {
            setMobileFormExpanded(false);
        }
    }

    return (
        <PageTemplate noPadding>
            <FlexContainer
                sx={{
                    minHeight: fullPageMinHeight,
                }}
                fullWidth
            >
                <FlexContainer
                    sx={{
                        width: theme.cartPage.splitWidthLeft,
                        marginBottom: 64,
                        alignItems:
                            voucherSelectState === 'preview'
                                ? 'center'
                                : 'flex-start',
                        [theme.breakpoints.down('lg')]: {
                            width: '100%',
                        },
                    }}
                    column
                >
                    <FlexContainer fullWidth>
                        <Typography
                            sx={{
                                padding: theme.spacing(5, 8),
                            }}
                            variant='h5'
                        >
                            {getTranslated(
                                voucherSelectState === 'select'
                                    ? 'VoucherPage.selectTemplateTitle'
                                    : 'VoucherPage.previewTile'
                            )}
                        </Typography>
                    </FlexContainer>
                    {voucherSelectState === 'select' ? (
                        <FlexContainer
                            sx={{
                                display: 'flex',
                                padding: theme.spacing(0, 8, 8),
                            }}
                        >
                            <VoucherTemplateGridList
                                selected={selectedVoucherTemplate}
                                vouchers={templates}
                                onVoucherSelect={setSelectedVoucherTemplate}
                            />
                        </FlexContainer>
                    ) : (
                        <VoucherPreview imagePreview={previewUrl} />
                    )}
                </FlexContainer>

                {isMobile && (
                    <Button
                        sx={{
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            height: 48,
                        }}
                        onClick={() => setMobileFormExpanded(true)}
                        isLoading={false}
                        color='primary'
                        variant='contained'
                        disabled={!selectedVoucherTemplate}
                    >
                        {getTranslated(
                            voucherSelectState === 'select'
                                ? 'VoucherPage.fillVoucher'
                                : 'VoucherPage.viewDetail'
                        )}
                    </Button>
                )}

                <FlexContainer
                    sx={{
                        width: theme.cartPage.splitWidthRight,
                        backgroundColor: theme.palette.sjLight,
                        padding: theme.spacing(4),
                        transition: 'all 500ms linear',
                        [theme.breakpoints.down('lg')]: {
                            position: 'fixed',
                            zIndex: 200,
                            height: 'calc(100vh - 64px)',
                            bottom: mobileFormExpanded ? 0 : '-200%',
                            left: 0,
                            right: 0,
                            width: '100%',
                            overflowY: 'scroll',
                            transition: 'all .3s',
                        },
                    }}
                >
                    <FlexContainer fullWidth column>
                        {isMobile && (
                            <FlexContainer
                                sx={{
                                    height: 64,
                                    padding: theme.spacing(4),
                                }}
                                fullWidth
                                justifyContent='flex-end'
                            >
                                <CloseIcon
                                    onClick={() =>
                                        setMobileFormExpanded(
                                            (prevState) => !prevState
                                        )
                                    }
                                    sx={{
                                        fontSize: 32,
                                    }}
                                />
                            </FlexContainer>
                        )}

                        <VoucherDataForm
                            selectedTemplate={selectedVoucherTemplate}
                            isFormSubmitting={
                                previewLoading || postVoucherLoading
                            }
                            onFormSubmit={(voucher) => {
                                setFinalVoucher(voucher);
                                onFormSubmit(voucher);
                            }}
                            voucherSelectState={voucherSelectState}
                            addToCart={() => {
                                postVoucher({
                                    ...convertVoucherToBackendVoucher(
                                        finalVoucher
                                    ),
                                    checkoutId: checkoutSession.id,
                                })
                                    .unwrap()
                                    .then(() => {
                                        navigate(
                                            getPagePath(routerConfig.Cart)
                                        );
                                    })
                                    .catch(() => {
                                        addNotification({
                                            type: 'error',
                                            message: getTranslated(
                                                'VoucherPage.errorAddingVoucher'
                                            ),
                                        });
                                    });
                            }}
                            cancel={() => {
                                setVoucherSelectState('select');
                            }}
                        />
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        </PageTemplate>
    );
};
