import { PaymentColumn } from '@/components/PageContent/CartPageContent/PaymentColumn';
import { useSubmitOrder } from '@/hooks/useSubmitOrder';

export const PaymentColumnContainer = () => {
    const { submitOrder } = useSubmitOrder();
    return (
        <PaymentColumn
            onOrderSubmit={() => {
                submitOrder();
            }}
        />
    );
};
