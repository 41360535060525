import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Avatar, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useTranslation } from '@/hooks/useTranslation';
import { CapFirstWord } from '@/components/Label/CapFirstWord';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { getLiftOperatorProductsPageLink } from '@/models/liftOperators';
import { useGetCurrentCheckoutSession } from '@/api/checkout';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';

export const CartPageTitleContainer: FunctionComponent = () => {
    const navigate = useNavigate();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { IS_PORTAL, IS_OEAMTC } = useConfigSlice();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const { getPagePath, routerConfig } = useCustomRouter();
    const { getTranslated, locale } = useTranslation();

    const color = IS_OEAMTC ? '#000' : '#fff';
    return (
        <FlexContainer
            sx={{
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                mb: 4,
            }}
        >
            <FlexContainer
                sx={() => ({
                    alignItems: 'center',
                    // borderBottom: `1px solid ${theme.palette.grey[200]}`,
                    height: '64px',
                    width: '100%',
                    px: 3,
                })}
            >
                <Button
                    sx={{
                        alignItems: 'center',
                    }}
                    onClick={() => {
                        if (selectedLiftOperator) {
                            navigate(
                                getPagePath(routerConfig.Products, [
                                    {
                                        param: 'id',
                                        value: getLiftOperatorProductsPageLink(
                                            selectedLiftOperator,
                                            locale
                                        ),
                                    },
                                ])
                            );
                        } else {
                            navigate(getPagePath(routerConfig.LiftOperators));
                        }
                    }}
                    variant='outlined'
                >
                    <KeyboardArrowLeftIcon />

                    {IS_PORTAL
                        ? getTranslated('backToProductsPage')
                        : getTranslated('back')}
                </Button>
            </FlexContainer>

            <FlexContainer
                sx={{
                    px: 3,
                    py: 2,
                    mb: 3,
                    width: '100%',
                    height: '64px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Avatar
                    sx={{
                        width: '48px',
                        height: '48px',
                        mr: 3,
                        bgcolor: 'primary.main',
                        color,
                    }}
                >
                    1
                </Avatar>

                <Typography
                    sx={{
                        textTransform: 'capitalize',
                    }}
                    variant='h4'
                >
                    {getTranslated('yourCart')}
                </Typography>
            </FlexContainer>

            {!!checkoutSession?.tickets.length && (
                <FlexContainer
                    sx={{
                        px: 6,
                        width: '100%',
                    }}
                    column
                >
                    <CapFirstWord
                        sx={{
                            mb: 2,
                        }}
                        variant='h6'
                    >
                        {getTranslated('personalizeTickets')}
                    </CapFirstWord>
                    <CapFirstWord>
                        {getTranslated('personalizeTicketsSubtitle')}
                    </CapFirstWord>
                </FlexContainer>
            )}
        </FlexContainer>
    );
};
