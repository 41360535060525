export type AddressValidationComponentType =
    | 'regional_code'
    | 'language_code'
    | 'postal_code'
    | 'administrative_Area'
    | 'street_number'
    | 'locality'
    | 'address_Lines';

type AddValidationConfirmationLevel =
    | 'CONFIRMED'
    | 'UNCONFIRMED_BUT_PLAUSIBLE'
    | 'UNCONFIRMED_AND_SUSPICIOUS';

type AddressValidationComponent = {
    componentName: {
        text: string;
        languageCode: string;
    };
    componentType: AddressValidationComponentType;
    confirmationLevel: AddValidationConfirmationLevel;
    inferred: boolean;
    spellCorrected: boolean;
    replaced: boolean;
    unexpected: boolean;
};

export type AddressValidationAddressComponent = {
    formattedAddress: string;
    postalAddress: {
        revision: number;
        regionCode: string;
        languageCode: string;
        postalCode: string;
        sortingCode: string;
        administrativeArea: string;
        locality: string;
        sublocality: string;
        addressLines: string[];
        recipients: string[];
        organization: string;
    };
    addressComponents: AddressValidationComponent[];
    missingComponentTypes: AddressValidationComponentType[];
};

export type AddressValidationResponse = {
    responseId: string;
    result: {
        address: AddressValidationAddressComponent;
    };
};

const relevantComponentTypes: AddressValidationComponentType[] = [
    'street_number',
    'postal_code',
    'locality',
];

const findAddressComponent = (
    addressComponents: AddressValidationComponent[],
    componentType: AddressValidationComponentType
) => {
    return addressComponents?.find((ac) => ac.componentType === componentType);
};

export const findMissingComponentsFromAddressComponents = (
    addressComponents: AddressValidationComponent[]
) => {
    return relevantComponentTypes.find((missingComponent) => {
        return !findAddressComponent(addressComponents, missingComponent);
    });
};

export const filterRelevantMissingComponents = (
    missingComponents: AddressValidationComponentType[]
) => {
    return missingComponents.filter((mC) =>
        relevantComponentTypes.includes(mC)
    );
};
