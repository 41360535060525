import {
    FunctionComponent,
    PropsWithChildren,
    useMemo,
    useRef,
    useState,
} from 'react';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import {
    FlexContainer,
    LoadingContainer,
    SectionContainer,
} from '@/components/Layout/Container';
import { Box, Chip, ChipProps, Tab, Tabs, Typography } from '@mui/material';
import { formatDate } from '@/utils/time';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { getPropByCurrentLocale } from '@/utils/locale';
import { TabPanel } from '@/components/Tabs/TabPanel';

import { styled } from '@mui/material/styles';
import moment from 'moment';
import Button from '@mui/material/Button';
import { DeleteInsuranceDialog } from '@/components/PageContent/MyInsurancePageContent/DeleteInsuranceDialog';
import { InsuranceStatus, TicketStatus, UserTicket } from '@/models/ticket';
import { useGetUserTicketsQuery } from '@/api/order';

export const MyInsurancePageContent: FunctionComponent = () => {
    const { data: ticketsData, isFetching: isLoading } = useGetUserTicketsQuery(
        { insuranceTickets: true }
    );
    const tickets = useMemo(() => {
        if (!ticketsData?.length)
            return {
                ticketsExpired: [],
                ticketsCurrent: [],
            };

        const newTickets = ticketsData.filter(
            (t) => t.status !== TicketStatus.NEW
        );

        newTickets.sort((a, b) => {
            if (a.startDate.isBefore(b.startDate)) {
                return 1;
            }

            if (a.startDate.isAfter(b.startDate)) {
                return -1;
            }

            return 0;
        });

        // separate by past and current
        return newTickets.reduce(
            (acc, curr) => {
                if (
                    curr.startDate.isSameOrAfter(moment().subtract(7, 'days'))
                ) {
                    acc.ticketsCurrent.push(curr);
                } else {
                    acc.ticketsExpired.push(curr);
                }

                return acc;
            },
            {
                ticketsExpired: [],
                ticketsCurrent: [],
            }
        );
    }, [ticketsData]);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const { getTranslated } = useTranslation();

    const insuranceToDelete = useRef<{
        ticket: UserTicket;
        type: 'cancel' | 'refund';
    } | null>(null);

    const handleTabValueChange = (_, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <SubPageTemplate
            title={getTranslated('myInsrancePage.title')}
            widthExtended
        >
            <SectionContainer column center fullWidth>
                {isLoading ? (
                    <LoadingContainer />
                ) : (
                    <>
                        <Tabs
                            value={tabValue}
                            sx={{
                                display: 'flex',
                                mb: 4,
                                justifyContent: 'center',
                            }}
                            onChange={handleTabValueChange}
                            indicatorColor='primary'
                        >
                            <Tab
                                label={getTranslated(
                                    'myInsurancePage.tabs.label.current'
                                )}
                            />
                            <Tab
                                label={getTranslated(
                                    'myInsurancePage.tabs.label.expired'
                                )}
                            />
                        </Tabs>
                        <TabPanel
                            sx={{
                                mt: 3,
                                width: '100%',
                            }}
                            value={tabValue}
                            index={0}
                        >
                            {tickets.ticketsCurrent.length ? (
                                tickets.ticketsCurrent.map((ticket, index) => (
                                    <InsuranceRow
                                        key={index}
                                        ticket={ticket}
                                        onCancel={(ticket, cancelVariant) => {
                                            insuranceToDelete.current = {
                                                ticket,
                                                type: cancelVariant,
                                            };
                                            setIsDialogOpen(true);
                                        }}
                                    />
                                ))
                            ) : (
                                <Box
                                    sx={{
                                        p: 3,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            mt: 4,
                                        }}
                                    >
                                        {getTranslated(
                                            'myInsurancePage.noInsurance.current'
                                        )}
                                    </Typography>
                                </Box>
                            )}
                        </TabPanel>
                        <TabPanel
                            sx={{
                                mt: 3,
                                width: '100%',
                            }}
                            value={tabValue}
                            index={1}
                        >
                            {tickets.ticketsExpired.length ? (
                                tickets.ticketsExpired.map((ticket, index) => (
                                    <InsuranceRow
                                        key={index}
                                        ticket={ticket}
                                        onCancel={(ticket, cancelVariant) => {
                                            insuranceToDelete.current = {
                                                ticket,
                                                type: cancelVariant,
                                            };
                                            setIsDialogOpen(true);
                                        }}
                                    />
                                ))
                            ) : (
                                <Box
                                    sx={{
                                        p: 3,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            mt: 4,
                                        }}
                                    >
                                        {getTranslated(
                                            'myInsurancePage.noInsurance.expired'
                                        )}
                                    </Typography>
                                </Box>
                            )}
                        </TabPanel>
                    </>
                )}
            </SectionContainer>

            {insuranceToDelete.current && (
                <DeleteInsuranceDialog
                    open={!!isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    ticket={insuranceToDelete.current.ticket}
                    type={insuranceToDelete.current.type}
                />
            )}
        </SubPageTemplate>
    );
};

const InsuranceRow: FunctionComponent<
    PropsWithChildren<{
        ticket: UserTicket;
        onCancel: (
            insurance: UserTicket,
            cancelVariant: 'cancel' | 'refund'
        ) => void;
    }>
> = ({ ticket, onCancel }) => {
    const { getTranslated, locale } = useTranslation();

    if (!ticket.insurance) {
        return null;
    }

    const insuranceExpired = ticket.startDate.isBefore(moment().startOf('day'));

    const ticketNames = ticket.identifications.map((identification, index) => {
        let owner = '';
        if (index > 0) {
            owner += ', ';
        }
        owner +=
            identification.firstname +
            ' ' +
            identification.lastname +
            ' (' +
            identification.birthday.format('DD.MM.YYYY') +
            ')';
        return owner;
    });

    return (
        <FlexContainer
            sx={(theme) => ({
                borderBottom: `2px solid ${theme.palette.black}`,
                p: 2,
                mb: 3,

                [theme.breakpoints.down('lg')]: {
                    px: 3,
                },
            })}
            fullWidth
            column
        >
            <Typography
                sx={{
                    mb: 2,
                }}
                variant='h6'
            >
                {formatDate(ticket.startDate)}
            </Typography>

            <FlexContainer
                sx={(theme) => ({
                    ml: 2,

                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column',
                        alignItems: 'center',
                    },
                })}
            >
                <FlexContainer
                    sx={(theme) => ({
                        justifyContent: 'center',
                        width: '50%',
                        px: 3,
                        py: 2,
                        borderRight: '1px solid',
                        borderColor: 'divider',

                        [theme.breakpoints.down('md')]: {
                            p: 2,
                            width: '100%',
                            borderRight: 'unset',
                        },
                    })}
                    column
                >
                    <RowLabel>
                        <Bold>
                            {getTranslated(
                                'myInsurancePage.insuranceName.label'
                            )}
                            :
                        </Bold>{' '}
                        {getPropByCurrentLocale(
                            ticket.insurance.tariffName,
                            locale
                        )}
                    </RowLabel>

                    <RowLabel>
                        <Bold>
                            {getTranslated(
                                'myInsurancePage.liftoperator.label'
                            )}
                            :
                        </Bold>{' '}
                        {getPropByCurrentLocale(ticket.liftOperator)}
                    </RowLabel>
                    <RowLabel>
                        <Bold>
                            {getTranslated('myInsurancePage.ticketName.label')}:
                        </Bold>{' '}
                        {getPropByCurrentLocale(ticket.name)}
                    </RowLabel>
                </FlexContainer>

                <FlexContainer
                    sx={(theme) => ({
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '50%',
                        px: 3,
                        py: 2,

                        [theme.breakpoints.down('md')]: {
                            p: 2,
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            width: '100%',
                        },
                    })}
                >
                    <FlexContainer column>
                        <RowLabel>
                            <Bold>
                                {getTranslated('Ticket.identificationNames')}:
                            </Bold>{' '}
                            {ticketNames}{' '}
                        </RowLabel>

                        <RowLabel>
                            <Bold>
                                {getTranslated('myInsurancePage.policyNumber')}:
                            </Bold>{' '}
                            {ticket.insurance.policyNumber || '-'}
                        </RowLabel>

                        <RowLabel>
                            <Bold>Status:</Bold>{' '}
                            <Chip
                                color={getInsuranceStatusChipColor(
                                    ticket.insurance.status
                                )}
                                label={getTranslated(
                                    `insurance.status.${ticket.insurance.status}` as TranslationKey
                                )}
                                size='small'
                            />
                        </RowLabel>
                    </FlexContainer>

                    {!insuranceExpired && (
                        <FlexContainer
                            sx={(theme) => ({
                                [theme.breakpoints.down('md')]: {
                                    mt: 3,
                                    p: 3,
                                    width: '100%',
                                    alignItems: 'center',
                                },
                            })}
                            column
                        >
                            {ticket.insurance.cancelable && (
                                <Button
                                    sx={(theme) => ({
                                        mb: 2,
                                        width: '142px',

                                        [theme.breakpoints.down('md')]: {
                                            width: '100%',
                                            maxWidth: '320px',
                                        },
                                    })}
                                    onClick={() => onCancel(ticket, 'cancel')}
                                    variant='outlined'
                                >
                                    {getTranslated(
                                        'Core.ticket.cancel.button.label'
                                    )}
                                </Button>
                            )}

                            {ticket.insurance.revocable && (
                                <Button
                                    sx={(theme) => ({
                                        width: '142px',

                                        [theme.breakpoints.down('md')]: {
                                            width: '100%',
                                            maxWidth: '320px',
                                        },
                                    })}
                                    onClick={() => onCancel(ticket, 'refund')}
                                    variant='outlined'
                                >
                                    {getTranslated(
                                        'Core.ticket.refund.button.label'
                                    )}
                                </Button>
                            )}
                        </FlexContainer>
                    )}
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    );
};

const RowLabel = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0, 2),
}));

const Bold = styled('span')({
    fontWeight: 600,
});

const getInsuranceStatusChipColor = (
    status: InsuranceStatus
): ChipProps['color'] => {
    switch (status) {
        case InsuranceStatus.BOOKED:
        case InsuranceStatus.PAID:
            return 'success';

        case InsuranceStatus.CREATED:
        case InsuranceStatus.REFUNDED:
            return 'primary';

        case InsuranceStatus.PERMISSION_BLOCKED:
        case InsuranceStatus.CANCELED:
            return 'error';
    }
};
