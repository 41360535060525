import { FunctionComponent } from 'react';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StagingBannerPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: '50%',
    color: theme.palette.white,
    width: 280,
    height: 48,
    position: 'fixed',
    backgroundColor: '#f6685e',
    transform: 'translate(-50%, 0)',
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
        left: 0,
        transform: 'translate(-50%, -20%)',
        width: 160,
        height: 32,
        fontSize: '1rem',
    },
}));

export const StagingBanner: FunctionComponent<
    React.PropsWithChildren<unknown>
> = () => {
    return (
        <StagingBannerPaper elevation={3}>
            <Typography variant='subtitle2'>!!! STAGING !!! </Typography>
        </StagingBannerPaper>
    );
};
