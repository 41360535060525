import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import throttle from 'lodash/throttle';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { LiftOperator } from '@/models/liftOperators';
import { AutocompleteProps, ListItem, ListSubheader } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { getAddressStreet } from '@/utils/string';
import { styled } from '@mui/material/styles';
import { Coordinates } from '@/models/general';
import { getPropByCurrentLocale } from '@/utils/locale';
import { Google } from '@/types/window';

// mostly taken from https://mui.com/material-ui/react-autocomplete/#google-maps-place
// removed a few quirks and added liftoperator search

const autocompleteService = { current: null };

const checkIfLiftOperatorFits = (
    liftOperator: LiftOperator,
    inputValue: string
) => {
    const inputSplit = inputValue.split(' ');

    return inputSplit.every((inputTerm) => {
        const inputTermLower = inputTerm.toLocaleLowerCase();
        const { name, alternativeNames } = liftOperator;
        const { de: deName } = name;
        let { en: enName } = name;

        if (!enName) {
            enName = deName;
        }

        const alternativeNamesLower = alternativeNames.toLocaleLowerCase();
        const deNameLower = deName.toLocaleLowerCase();
        const enNameLower = enName.toLocaleLowerCase();

        return (
            deNameLower.includes(inputTermLower) ||
            enNameLower.includes(inputTermLower) ||
            alternativeNamesLower.includes(inputTermLower)
        );
    });
};

type MainTextMatchedSubstrings = {
    offset: number;
    length: number;
};

type StructuredFormatting = {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
};

type LiftOperatorType = {
    type: 'liftOperatorType' | 'favorite';
    liftOperator: LiftOperator;
    // structured_formatting: StructuredFormatting;
};

export type PlaceType = {
    type: 'placeType';
    description: string;
    coordinates: Coordinates;
    structured_formatting: StructuredFormatting;
    place_id: string;
};

const LiftOperatorLogo = styled('img')`
    width: 56px;
    margin-right: ${(props) => props.theme.spacing(4)};
`;

type LocationAutoCompleteProps = {
    liftOperators: LiftOperator[];
    onSelect: (nextValue: PlaceType | LiftOperatorType) => void;
    defaultInput?: string;
    onFocus?: () => void;
    onBlur?: (currentSelected: LiftOperatorType | PlaceType) => void;
    sx?: AutocompleteProps<unknown, undefined, undefined, undefined>['sx'];
};

type AutoCompleteValue = PlaceType | LiftOperatorType;

export const LocationAutoComplete: FunctionComponent<
    LocationAutoCompleteProps
> = ({ liftOperators, onSelect, sx, onFocus, onBlur, defaultInput }) => {
    const { getTranslated } = useTranslation();

    const [value, setValue] = useState<AutoCompleteValue>(null);
    const [inputValue, setInputValue] = useState(defaultInput || '');

    const [options, setOptions] = useState<
        readonly (LiftOperatorType | PlaceType)[]
    >([]);

    const fetch = useMemo(
        () =>
            throttle(
                (
                    request: {
                        input: string;
                        bounds?: unknown;
                        location?: unknown;
                        radius?: number;
                    },
                    callback: (results?: readonly PlaceType[]) => void
                ) => {
                    (autocompleteService.current as Google).getPlacePredictions(
                        request,
                        callback
                    );
                },
                200
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions([]);
            setValue(null);
            return undefined;
        }

        if (inputValue.length < 3) {
            setOptions([]);
            return undefined;
        }

        fetch(
            {
                input: inputValue,
                location: new google.maps.LatLng(47.2854551, 11.3787899),
                radius: 5000,
            },
            (results?: readonly PlaceType[]) => {
                if (active) {
                    let newOptions: readonly PlaceType[] = [];

                    if (results) {
                        const resultsTyped: PlaceType[] = results.map(
                            (result) => ({
                                ...result,
                                type: 'placeType',
                            })
                        );
                        // add liftOperator Results
                        newOptions = [...resultsTyped];
                    }

                    const liftOperatorResult: LiftOperatorType[] = liftOperators
                        .map<LiftOperatorType>((liftOperator) => {
                            return {
                                type: 'liftOperatorType',
                                liftOperator: liftOperator,
                            };
                        })
                        .filter((liftOperatorType) => {
                            return checkIfLiftOperatorFits(
                                liftOperatorType.liftOperator,
                                inputValue
                            );
                        });

                    setOptions([...liftOperatorResult, ...newOptions]);
                }
            }
        );

        return () => {
            active = false;
        };
    }, [value, inputValue]);

    return (
        <Autocomplete
            sx={sx}
            inputValue={inputValue}
            getOptionLabel={(option) => {
                return option.type === 'placeType'
                    ? option.description
                    : getPropByCurrentLocale(option.liftOperator.name);
            }}
            filterOptions={(x) => x}
            onFocus={() => {
                if (onFocus) {
                    onFocus();
                }
            }}
            onBlur={() => {
                if (onBlur) {
                    onBlur(value);
                }
            }}
            noOptionsText={
                <FlexContainer
                    sx={{
                        height: '80px',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography>
                        {getTranslated('LiftoperatorSelector.placeholder')}
                    </Typography>
                </FlexContainer>
            }
            options={options}
            value={value}
            groupBy={(option) => option.type}
            isOptionEqualToValue={(option, value) => {
                if (value.type !== option.type) return false;
                else {
                    if (option.type === 'placeType') {
                        return (
                            option.description ===
                            (value as PlaceType).description
                        );
                    } else {
                        return (
                            option.liftOperator.id ===
                            (value as LiftOperatorType).liftOperator.id
                        );
                    }
                }
            }}
            onChange={(_, newValue) => {
                setValue(newValue as AutoCompleteValue);
                onSelect(newValue as AutoCompleteValue);
            }}
            onInputChange={(_, newInputValue, reason) => {
                if (reason === 'reset') {
                    if (newInputValue) {
                        setInputValue(newInputValue);
                    }
                } else {
                    setInputValue(newInputValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={getTranslated('LiftoperatorSelector.placeholder')}
                    fullWidth
                />
            )}
            renderGroup={(params) => [
                <ListSubheader
                    sx={(theme) => ({
                        backgroundColor: `${theme.palette.grey[50]}`,
                        borderBottom: `1px solid ${theme.palette.grey[200]}`,
                        borderTop: `1px solid ${theme.palette.grey[200]}`,
                    })}
                    key={`${params.key}_0`}
                    component='div'
                >
                    {params.group === 'placeType'
                        ? getTranslated('LocationSearch.location')
                        : params.group === 'favorite'
                        ? 'Favoriten'
                        : getTranslated('LocationSearch.liftoperator')}
                </ListSubheader>,
                <FlexContainer key={`${params.key}_1`} column>
                    {params.children}
                </FlexContainer>,
            ]}
            // TODO: implement highlighting like https://mui.com/material-ui/react-autocomplete/#google-maps-place
            renderOption={(props, option) => {
                let key = '';

                if (option.type === 'placeType') {
                    key = option.place_id;
                } else {
                    key =
                        option.liftOperator.facilityId ||
                        option.liftOperator.id;
                }

                return (
                    <ListItem {...props} key={key}>
                        {option.type === 'placeType' ? (
                            <Typography gutterBottom>
                                {option.description}
                            </Typography>
                        ) : (
                            <FlexContainer
                                alignItems='center'
                                justifyContent='space-between'
                                fullWidth
                            >
                                <FlexContainer column fullWidth>
                                    <Typography>
                                        {getPropByCurrentLocale(
                                            option.liftOperator.name
                                        )}
                                    </Typography>

                                    <Typography>
                                        {getAddressStreet(
                                            option.liftOperator.address
                                        )}
                                        <br />
                                        {getTranslated(
                                            `country.${option.liftOperator.address.country}` as TranslationKey
                                        )}
                                    </Typography>
                                </FlexContainer>

                                <LiftOperatorLogo
                                    alt='logo'
                                    src={option.liftOperator.logos[0]?.href}
                                />
                            </FlexContainer>
                        )}
                    </ListItem>
                );
            }}
            autoComplete
            includeInputInList
            fullWidth
        />
    );
};
