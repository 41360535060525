import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import { grey } from '@mui/material/colors';

export const FlatCard = styled(Card, {
    shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => ({
    border: `1px solid ${grey['200']}`,
    margin: theme.spacing(2, 0),
    boxShadow:
        '0px 0px 0px 0px rgb(0 0 0 / 20%), inset 0px 0px 1px 0px rgb(0 0 0 / 14%), inset 0px 0px 1px 0px rgb(0 0 0 / 12%)',
    ...(error && {
        borderColor: theme.palette.error.main,
    }),
}));
