import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';
import { useCartCosts } from '@/hooks/useCartCosts';
import { getMoneyAsString } from '@/utils/general';
import { useTranslation } from '@/hooks/useTranslation';
import { useGetCheckoutTicketCollections } from '@/api/checkout';
import { CheckoutTicketCollectionCard } from '@/components/CollectionCard/CheckoutTicketCollectionCard/CheckoutTicketCollectionCard';
import { InsuranceComponentSjReact } from '@crossnative/insurance-component/react';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { IS_DEVELOPMENT } from '@/constants';

export const CartsOverviewMobile = () => {
    const ticketCollections = useGetCheckoutTicketCollections();
    const { locale } = useTranslation();
    const cartCosts = useCartCosts();
    const { getTranslated } = useTranslation();
    const { IS_PORTAL, IS_OEAMTC } = useConfigSlice();
    const isInsuranceEnabled = !IS_OEAMTC && IS_PORTAL;
    return (
        <>
            <FlexContainer
                sx={(theme) => ({
                    flexDirection: 'column',
                    width: '100%',
                    borderBottom: `1px solid ${theme.palette.grey[200]}`,
                    p: 2,
                })}
            >
                <Typography
                    sx={{
                        my: 2,
                    }}
                    variant='h6'
                    gutterBottom
                >
                    {getTranslated('yourCart')}
                </Typography>
                <Typography
                    sx={{
                        ml: 2,
                    }}
                >
                    {getTranslated('Core.total')}{' '}
                    {getMoneyAsString(cartCosts.totalAfterCredits)}
                </Typography>
            </FlexContainer>
            <FlexContainer
                sx={(theme) => ({
                    flexDirection: 'column',
                    mx: 2,
                    pb: `${theme.appBar.height * 2.5}px`,
                    justifyContent: 'center',
                })}
            >
                {isInsuranceEnabled && (
                    <InsuranceComponentSjReact
                        language={locale.toLocaleUpperCase()}
                        stage={IS_DEVELOPMENT ? 'test' : undefined}
                    />
                )}

                {ticketCollections.map((ticketCollection, index) => (
                    <CheckoutTicketCollectionCard
                        key={index}
                        ticketCollection={ticketCollection}
                    />
                ))}
            </FlexContainer>
        </>
    );
};
