import { Typography } from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import Alert from '@mui/material/Alert';
import { FunctionComponent } from 'react';

export const InsuranceInfoCard: FunctionComponent = () => {
    const { getTranslated } = useTranslation();
    return (
        <Alert severity='info'>
            <Typography
                sx={{
                    li: {
                        mb: 1,
                    },
                }}
                dangerouslySetInnerHTML={{
                    __html: getTranslated('CheckoutPage.insurance.explanation'),
                }}
            />
        </Alert>
    );
};
