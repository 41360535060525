import { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Typography,
} from '@mui/material';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { getFromLocalStorage, saveToLocalStorage } from '@/utils/storage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FlexContainer } from '@/components/Layout/Container';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@/theme/theme';
import { useTranslation } from '@/hooks/useTranslation';
import { ReactComponent as CookieInfoEn } from '@/assets/cookies/cookies_consent_en.md';
import { ReactComponent as CookieInfoDe } from '@/assets/cookies/cookies_consent_de.md';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { Configuration } from '@/models/configuration';

const tagMangerArgsPortal: TagManagerArgs = {
    gtmId: 'GTM-599BCZZ',
    // dataLayerName: 'dataLayerPortal',
};

const tagMangerArgsWebShop: TagManagerArgs = {
    gtmId: 'GTM-M73SJL9',
    // dataLayerName: 'dataLayerIntegration',
};

const enableTM = (isPortal: boolean, config?: Configuration) => {
    TagManager.dataLayer({
        dataLayer: [
            'consent',
            'default',
            {
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                ad_storage: 'denied',
                analytics_storage: 'denied',
                wait_for_update: 500,
            },
        ],
    });

    TagManager.initialize(
        isPortal ? tagMangerArgsPortal : tagMangerArgsWebShop
    );

    if (config?.tagManagerCode) {
        TagManager.initialize({
            gtmId: config.tagManagerCode,
            // dataLayerName: config.vanityIdentifier,
        });
    }

    TagManager.dataLayer({
        dataLayer: {
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        },
    });

    TagManager.dataLayer({
        dataLayer: [
            'consent',
            'update',
            {
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                ad_storage: 'granted',
                analytics_storage: 'granted',
            },
        ],
    });
};

const useStyles = makeStyles((theme: Theme) => ({
    accordionContainer: {
        marginTop: theme.spacing(5),
    },
    accordionContent: {
        padding: theme.spacing(0, 5),
        display: 'flex',
        flexDirection: 'column',
    },

    explanationTitle: {
        fontWeight: 600,
    },
}));

export const CookiesConfirmationDialog = () => {
    const { IS_PORTAL, pageConfig } = useConfigSlice();
    const hasAlreadyConfirmed = getFromLocalStorage(
        'data.cookiesAccepted',
        false
    );

    const [isOpen, setIsOpen] = useState<boolean>(!hasAlreadyConfirmed);
    const [statisticsChecked, setStatisticsChecked] = useState<boolean>(false);
    const { getTranslated, locale } = useTranslation();

    const classes = useStyles();

    const onAcceptCookies = (variant: 'selection' | 'statistics') => () => {
        if (variant === 'selection' && !statisticsChecked) {
            saveToLocalStorage('data.acceptedCookies', 'selection');
        } else {
            enableTM(IS_PORTAL, pageConfig?.configuration);
            saveToLocalStorage('data.acceptedCookies', 'statistics');
        }

        saveToLocalStorage('data.cookiesAccepted', true);
        setIsOpen(false);
    };

    useEffect(() => {
        if (hasAlreadyConfirmed) {
            enableTM(IS_PORTAL, pageConfig?.configuration);
        }
    }, []);

    const Markdown = locale === 'en' ? CookieInfoEn : CookieInfoDe;

    return (
        <Dialog
            sx={{
                zIndex: 10000,
            }}
            open={isOpen}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            maxWidth='md'
        >
            <DialogTitle id='alert-dialog-title'>
                {getTranslated('Cookie.title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {getTranslated('Cookie.dataPrivacyMessage')}
                    <FlexContainer
                        alignItems='center'
                        justifyContent='space-between'
                        width='75%'
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={true}
                                    name='necessary'
                                    color='primary'
                                />
                            }
                            label={getTranslated('Cookie.necessaryCookies')}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statisticsChecked}
                                    onChange={() =>
                                        setStatisticsChecked(
                                            (prevState) => !prevState
                                        )
                                    }
                                    name='statistic'
                                    color='primary'
                                />
                            }
                            label={getTranslated('Cookie.statisticCookies')}
                        />
                    </FlexContainer>
                    <Accordion
                        className={classes.accordionContainer}
                        elevation={0}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>
                                {getTranslated('Cookie.dataPrivacyLabel')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionContent}>
                            <Markdown />
                        </AccordionDetails>
                    </Accordion>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onAcceptCookies('selection')}
                    color='primary'
                    variant='outlined'
                >
                    {getTranslated('Cookie.buttonLabelSelection')}
                </Button>
                <Button
                    onClick={onAcceptCookies('statistics')}
                    color='primary'
                    variant='outlined'
                >
                    {getTranslated('Cookie.buttonLabelAll')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
