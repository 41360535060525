import { FunctionComponent, useState } from 'react';
import { RedeemCodeInput } from '@/components/RedeemCodeInput/RedeemCodeInput';
import { useTranslation } from '@/hooks/useTranslation';
import { useNotification } from '@/hooks/useNotification';
import { useRedeemVoucherErrorCode } from '@/hooks/useRedeemVoucherErrorCode';
import {
    checkoutApi,
    useGetCurrentCheckoutSession,
    usePostRedeemCouponMutation,
} from '@/api/checkout';
import { isOfTypeRTKAPIError } from '@/models/request';
import { useDispatch } from 'react-redux';
import { orderApi } from '@/api/order';

export const RedeemCodeInputContainer: FunctionComponent = () => {
    const { getTranslated } = useTranslation();
    const { addNotification } = useNotification();
    const { getErrorMessage } = useRedeemVoucherErrorCode();
    const [value, setValue] = useState<string>(null);
    const [postRedeemVoucher, { isLoading, isError, error }] =
        usePostRedeemCouponMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const dispatch = useDispatch();

    const showRedeemSuccessMessage = () => {
        addNotification({
            type: 'success',
            message: getTranslated('CouponPage.success'),
        });
    };

    const showRedeemErrorMessage = (e: unknown) => {
        if (isOfTypeRTKAPIError(e)) {
            addNotification({
                type: 'error',
                message: getErrorMessage(e.data.errors[0].error),
            });
        }
    };

    const redeemCouponOrVoucher = async (code: string) => {
        try {
            await postRedeemVoucher({
                code,
                checkoutId: checkoutSession.id,
                type: 'coupons',
            }).unwrap();

            showRedeemSuccessMessage();
            dispatch(checkoutApi.util.invalidateTags(['CHECKOUTS']));
            dispatch(orderApi.util.invalidateTags(['CREDIT']));
        } catch (e) {
            if (isOfTypeRTKAPIError(e)) {
                if (e.data.errors[0].error === 'coupon_code_not_found') {
                    // coupon not found - maybe it's a voucher
                    try {
                        await postRedeemVoucher({
                            code,
                            checkoutId: checkoutSession.id,
                            type: 'vouchers',
                        }).unwrap();

                        showRedeemSuccessMessage();
                        dispatch(
                            checkoutApi.util.invalidateTags(['CHECKOUTS'])
                        );
                    } catch (e) {
                        showRedeemErrorMessage(e);
                    }
                } else {
                    // some other users error - flow stopped
                    showRedeemErrorMessage(e);
                }
            }
        }
    };

    let errorMessage = '';
    if (isError && isOfTypeRTKAPIError(error)) {
        errorMessage = getErrorMessage(error.data.errors[0].error);
    }

    return (
        <RedeemCodeInput
            inputLabel={getTranslated('enterRedeemCode')}
            isLoading={isLoading}
            value={value}
            onChange={(e) => {
                const { value } = e.target;
                setValue(value);
            }}
            handleIconButtonClick={redeemCouponOrVoucher}
            error={errorMessage || ''}
        />
    );
};
