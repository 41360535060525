import { AppBar, Link, Typography } from '@mui/material';
import { KeycardTransferStatus } from '@/models/order';
import { useNavigate } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { FlexContainer } from '@/components/Layout/Container';
import ArrowRight from '@mui/icons-material/ChevronRight';
import { useTranslation } from '@/hooks/useTranslation';
import { useGetKeycardTransfersQuery } from '@/api/order';
import { FunctionComponent } from 'react';

export const KeycardTransferBar: FunctionComponent<
    React.PropsWithChildren
> = () => {
    const { data: keycardsTransfer } = useGetKeycardTransfersQuery();
    const { routerConfig, getPagePath } = useCustomRouter();
    const navigate = useNavigate();
    const { getTranslated } = useTranslation();

    const pendingKeycardTransfer = keycardsTransfer?.received.find(
        (k) => k.status === KeycardTransferStatus.READY
    );

    return (
        !!pendingKeycardTransfer && (
            <AppBar
                position='fixed'
                sx={{
                    height: (theme) => theme.appBar.height,
                    marginTop: (theme) => theme.appBar.height,
                    backgroundColor: (theme) => theme.palette.infoBlue,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: (theme) => theme.palette.white,
                }}
                color='secondary'
            >
                <>
                    <FlexContainer center fullWidth>
                        <Typography variant='h6'>
                            {getTranslated('KecardTransfer.waitingTransfer', {
                                email: pendingKeycardTransfer.email,
                            })}
                        </Typography>
                        <Link
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: (theme) => theme.spacing(4),
                                color: (theme) =>
                                    `${theme.palette.white} !important`,
                            }}
                            onClick={() => {
                                navigate(
                                    getPagePath(
                                        routerConfig.KeycardTransferPage,
                                        [
                                            {
                                                value: pendingKeycardTransfer.id,
                                                param: 'id',
                                            },
                                        ]
                                    )
                                );
                            }}
                        >
                            {getTranslated('KeycardTransfer.linkToTransfers')}{' '}
                            <ArrowRight />
                        </Link>
                    </FlexContainer>
                </>
            </AppBar>
        )
    );
};
