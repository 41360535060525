import { FunctionComponent } from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingIcon = <CircularProgress size={24} />;

type LoadingCheckboxProps = {
    loading?: boolean;
} & CheckboxProps;

export const LoadingCheckbox: FunctionComponent<LoadingCheckboxProps> = ({
    loading = false,
    ...checkboxProps
}) => (
    <Checkbox
        {...checkboxProps}
        icon={loading ? LoadingIcon : checkboxProps.icon}
        checkedIcon={loading ? LoadingIcon : checkboxProps.checkedIcon}
        disabled={loading || checkboxProps.disabled}
    />
);
