import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { Person } from '@/models/general';
import { QuantityCounter } from '@/components/Quantity/QuantityCounter';
import { PopoverContent } from '@/components/ProductSelectionFilter/PopoverContent/PopoverContent';
import { formatDate } from '@/utils/time';
import { PersonCount } from '@/models/product';

type PersonCounterContentProps = {
    personsToSelect: Person[];
    selectedPerson: PersonCount[];
    onPersonChange: (personCounts: PersonCount[]) => void;
};

export const PersonCounterContent: FunctionComponent<
    PersonCounterContentProps
> = ({ personsToSelect, selectedPerson, onPersonChange }) => {
    // merge the selected persons with the persons to select
    const personCounts: PersonCount[] = personsToSelect.map((person) => {
        const emptyPersonCount: PersonCount = { ...person, quantity: 0 };
        const selected =
            selectedPerson.find(
                (selected) => selected.name.de === person.name.de
            ) || emptyPersonCount;

        return {
            ...selected,
        };
    });

    return (
        <PopoverContent
            sx={{
                width: '440px',
            }}
        >
            {personCounts.map((person) => (
                <PersonCounterContainer
                    key={person.name.de}
                    onPersonCountChange={(nextCount) => {
                        const nextPersonCounts = personCounts.map((p) => {
                            if (p.name.de === person.name.de) {
                                return {
                                    ...p,
                                    quantity: nextCount,
                                };
                            }

                            return p;
                        });

                        onPersonChange(nextPersonCounts);
                    }}
                    person={person}
                />
            ))}
        </PopoverContent>
    );
};

type PersonCounterContainerProps = {
    person: PersonCount;
    onPersonCountChange: (nextCount: number, type: 'subtract' | 'add') => void;
};

const PersonCounterContainer: FunctionComponent<
    PersonCounterContainerProps
> = ({ person, onPersonCountChange }) => (
    <Box
        sx={{
            display: 'flex',

            alignItems: 'center',
            cursor: 'pointer',
            height: '80px',
            padding: 4,
            transition: 'background-color 100ms linear',
            borderRadius: '4px',

            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        }}
    >
        <Box>
            <Typography
                sx={{
                    fontWeight: 'bold',
                }}
            >
                {person.name.de}
            </Typography>

            {person.birthday?.from && (
                <Typography
                    sx={{
                        ml: 2,
                    }}
                >
                    {formatDate(person.birthday.from)}{' '}
                    {person.birthday.to &&
                        ` - ${formatDate(person.birthday.to)}`}
                </Typography>
            )}
        </Box>

        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}
        >
            <QuantityCounter
                value={person.quantity}
                min={0}
                onCounterChange={onPersonCountChange}
            />
        </Box>
    </Box>
);
