import { useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { ProductsBottomNavigationContainerMobile } from '@/components/PageContent/ProductsPageContent/ProductsBottomNavigationContainerMobile';
import { ProductsOverviewContainerMobile } from '@/components/PageContent/ProductsPageContent/ProductsOverviewMobile/ProductsOverviewContainerMobile';
import { CartsOverviewMobile } from '@/components/PageContent/ProductsPageContent/CartsOverviewMobile/CartsOverviewMobile';
import { OeamtcBannerContainer } from '@/components/OeamtcBanner/OeamtcBanner';

export type ProductsPageMobileView = 'productsOverview' | 'cartOverview';

export const ProductsPageContainerMobile = () => {
    const [currentView, setCurrentView] =
        useState<ProductsPageMobileView>('productsOverview');

    return (
        <FlexContainer
            sx={(theme) => ({
                minHeight: `calc(100vh - ${theme.appBar.height}px)`,
                mt: `${theme.appBar.height}px`,
                overflowY: 'scroll',
                overflowX: 'hidden',
            })}
            column
            fullWidth
        >
            <OeamtcBannerContainer />
            {currentView === 'productsOverview' ? (
                <ProductsOverviewContainerMobile />
            ) : (
                <CartsOverviewMobile />
            )}

            <ProductsBottomNavigationContainerMobile
                currentNavigation={currentView}
                onNavigationClick={(nextView) => {
                    setCurrentView(nextView);
                }}
            />
        </FlexContainer>
    );
};
