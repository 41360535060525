import { store } from '@/store';
import { translateFunction } from '@/hooks/useTranslation';
import { MultiLangString } from '@/models/general';

export const getCurrentLocale = () => store.getState().config.locale;

export const getPropByCurrentLocale = <T extends string | string[]>(
    obj: MultiLangString<T>,
    defaultString?: T
): T | null => {
    if (!obj) return defaultString || null;

    // de prop should always exist
    return obj[getCurrentLocale()] || obj.de || defaultString;
};

export const translate = (toBeTranslated: string) => {
    const locale = getCurrentLocale();

    return translateFunction(toBeTranslated, locale);
};
