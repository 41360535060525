const countryOptions = [
    {
        cca2: 'AT',
        nameDE: 'Österreich',
        nameEN: 'Austria',
    },
    {
        cca2: 'DE',
        nameDE: 'Deutschland',
        nameEN: 'Germany',
    },
    {
        cca2: 'CH',
        nameDE: 'Schweiz',
        nameEN: 'Switzerland',
    },
    {
        cca2: 'AL',
        nameDE: 'Albanien',
        nameEN: 'Albania',
    },
    {
        cca2: 'AD',
        nameDE: 'Andorra',
        nameEN: 'Andorra',
    },
    {
        cca2: 'BE',
        nameDE: 'Belgien',
        nameEN: 'Belgium',
    },
    {
        cca2: 'BA',
        nameDE: 'Bosnien und Herzegowina',
        nameEN: 'Bosnia and Herzegovina',
    },
    {
        cca2: 'BG',
        nameDE: 'Bulgarien',
        nameEN: 'Bulgaria',
    },
    {
        cca2: 'DK',
        nameDE: 'Dänemark',
        nameEN: 'Denmark',
    },
    {
        cca2: 'EE',
        nameDE: 'Estland',
        nameEN: 'Estonia',
    },
    {
        cca2: 'FI',
        nameDE: 'Finnland',
        nameEN: 'Finland',
    },
    {
        cca2: 'FR',
        nameDE: 'Frankreich',
        nameEN: 'France',
    },
    {
        cca2: 'GR',
        nameDE: 'Griechenland',
        nameEN: 'Greece',
    },
    {
        cca2: 'IE',
        nameDE: 'Irland',
        nameEN: 'Ireland',
    },
    {
        cca2: 'IS',
        nameDE: 'Island',
        nameEN: 'Iceland',
    },
    {
        cca2: 'IT',
        nameDE: 'Italien',
        nameEN: 'Italy',
    },
    {
        cca2: 'XK',
        nameDE: 'Kosovo',
        nameEN: 'Kosovo',
    },
    {
        cca2: 'HR',
        nameDE: 'Kroatien',
        nameEN: 'Croatia',
    },
    {
        cca2: 'LV',
        nameDE: 'Lettland',
        nameEN: 'Latvia',
    },
    {
        cca2: 'LI',
        nameDE: 'Liechtenstein',
        nameEN: 'Liechtenstein',
    },
    {
        cca2: 'LT',
        nameDE: 'Litauen',
        nameEN: 'Lithuania',
    },
    {
        cca2: 'LU',
        nameDE: 'Luxemburg',
        nameEN: 'Luxembourg',
    },
    {
        cca2: 'MT',
        nameDE: 'Malta',
        nameEN: 'Malta',
    },
    {
        cca2: 'MD',
        nameDE: 'Moldawien',
        nameEN: 'Moldova',
    },
    {
        cca2: 'MC',
        nameDE: 'Monaco',
        nameEN: 'Monaco',
    },
    {
        cca2: 'ME',
        nameDE: 'Montenegro',
        nameEN: 'Montenegro',
    },
    {
        cca2: 'NL',
        nameDE: 'Niederlande',
        nameEN: 'Netherlands',
    },
    {
        cca2: 'MK',
        nameDE: 'Nordmazedonien',
        nameEN: 'North Macedonia',
    },
    {
        cca2: 'NO',
        nameDE: 'Norwegen',
        nameEN: 'Norway',
    },
    {
        cca2: 'PL',
        nameDE: 'Polen',
        nameEN: 'Poland',
    },
    {
        cca2: 'PT',
        nameDE: 'Portugal',
        nameEN: 'Portugal',
    },
    {
        cca2: 'RO',
        nameDE: 'Rumänien',
        nameEN: 'Romania',
    },
    {
        cca2: 'RU',
        nameDE: 'Russland',
        nameEN: 'Russia',
    },
    {
        cca2: 'SM',
        nameDE: 'San Marino',
        nameEN: 'San Marino',
    },
    {
        cca2: 'SE',
        nameDE: 'Schweden',
        nameEN: 'Sweden',
    },
    {
        cca2: 'RS',
        nameDE: 'Serbien',
        nameEN: 'Serbia',
    },
    {
        cca2: 'SK',
        nameDE: 'Slowakei',
        nameEN: 'Slovakia',
    },
    {
        cca2: 'SI',
        nameDE: 'Slowenien',
        nameEN: 'Slovenia',
    },
    {
        cca2: 'ES',
        nameDE: 'Spanien',
        nameEN: 'Spain',
    },
    {
        cca2: 'CZ',
        nameDE: 'Tschechien',
        nameEN: 'Czechia',
    },
    {
        cca2: 'UA',
        nameDE: 'Ukraine',
        nameEN: 'Ukraine',
    },
    {
        cca2: 'HU',
        nameDE: 'Ungarn',
        nameEN: 'Hungary',
    },
    {
        cca2: 'GB',
        nameDE: 'Vereinigtes Königreich',
        nameEN: 'United Kingdom',
    },
    {
        cca2: 'BY',
        nameDE: 'Weißrussland',
        nameEN: 'Belarus',
    },
    {
        cca2: 'CY',
        nameDE: 'Zypern',
        nameEN: 'Cyprus',
    },
];

export const countryOptionsByLocale = (locale: string): [string, string][] => {
    const nameVar = 'name' + locale.toUpperCase();
    return countryOptions.map((option) => [option.cca2, option[nameVar]]);
};

export const getCountryNameByCode = (
    locale: string,
    countryCode: string
): string => {
    const localeProp = locale === 'de' ? 'nameDE' : 'nameEN';
    return (
        countryOptions.find((cO) => cO.cca2 === countryCode)?.[localeProp] || ''
    );
};
