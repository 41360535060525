import { FunctionComponent } from 'react';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme/theme';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    progress: {
        position: 'absolute',
    },
    margin: {
        margin: theme.spacing(1),
    },

    textField: {
        width: '25ch',
    },
}));

type InputRedeemProps = {
    defaultValue: string;
    handleIconButtonClick: () => void;
    label: string;
    fullWidth?: boolean;
};

export const InputEdit: FunctionComponent<
    React.PropsWithChildren<InputRedeemProps>
> = ({ defaultValue, handleIconButtonClick, fullWidth, label }) => {
    const classes = useStyles();

    return (
        <FormControl
            className={`${classes.formControl} ${classes.margin} `}
            fullWidth={fullWidth}
            variant='outlined'
        >
            <InputLabel>{label}</InputLabel>

            <OutlinedInput
                type='text'
                defaultValue={defaultValue}
                fullWidth={fullWidth}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => handleIconButtonClick()}
                            size='large'
                        >
                            <EditIcon />
                        </IconButton>
                    </InputAdornment>
                }
                disabled
            />
        </FormControl>
    );
};
