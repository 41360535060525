import React, { FunctionComponent, useEffect, useState } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from '@/hooks/useTranslation';
import { Button } from '@/components/Layout/Button/Button';
import { DialogTitle } from '@/components/Dialog/DialogTite';
import { useNotification } from '@/hooks/useNotification';
import { RegisterKeycardFormContainer } from '@/components/RegisterKeycardForm/RegisterKeycardFormContainer';

type RegisterKeycardDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const RegisterKeycardDialog: FunctionComponent<
    React.PropsWithChildren<RegisterKeycardDialogProps>
> = ({ isOpen, onClose }) => {
    const [dialogOpen, setDialogOpen] = useState(isOpen);
    const [keycaredRegistered, setKeycardRegistered] = useState<boolean>(false);
    const { addNotification } = useNotification();
    const { getTranslated } = useTranslation();

    useEffect(() => {
        setDialogOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!dialogOpen) onClose();
    }, [dialogOpen]);

    return (
        <Dialog
            open={dialogOpen}
            onClose={() => {
                setDialogOpen(false);
            }}
            maxWidth='xl'
        >
            <DialogTitle
                title={getTranslated('common.linkExternalCard')}
                onClose={() => {
                    setDialogOpen(false);
                }}
            />
            <DialogContent
                sx={{
                    mb: 6,
                }}
            >
                {keycaredRegistered ? (
                    <FlexContainer
                        sx={{
                            height: '240px',
                        }}
                        alignItems='center'
                        justifyContent='space-between'
                        column
                    >
                        <CheckCircleOutlineIcon
                            color='success'
                            sx={{
                                width: '64px',
                                height: '64px',
                            }}
                        />
                        <Typography variant='h6' gutterBottom>
                            {getTranslated(
                                'myKeycardsPage.registration.success'
                            )}
                        </Typography>

                        <FlexContainer
                            sx={(theme) => ({
                                [theme.breakpoints.down('md')]: {
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                },
                            })}
                            fullWidth
                        >
                            <FlexContainer
                                sx={(theme) => ({
                                    minWidth: 440,
                                    marginRight: theme.spacing(3),
                                    justifyContent: 'space-evenly',

                                    [theme.breakpoints.down('md')]: {
                                        width: '100%',
                                        minWidth: 'auto',
                                        justifyContent: 'center',
                                        marginBottom: theme.spacing(2),
                                        '& button': {
                                            margin: theme.spacing(0, 2),
                                        },
                                    },
                                })}
                            >
                                <Button
                                    onClick={() => {
                                        setKeycardRegistered(false);
                                    }}
                                    isLoading={false}
                                    variant='contained'
                                    color='primary'
                                >
                                    {getTranslated('registerAnotherKeycard')}
                                </Button>
                            </FlexContainer>

                            <Button
                                onClick={() => {
                                    setDialogOpen(false);
                                    setKeycardRegistered(false);
                                }}
                                isLoading={false}
                                variant='outlined'
                            >
                                {getTranslated('Core.close')}
                            </Button>
                        </FlexContainer>
                    </FlexContainer>
                ) : (
                    <RegisterKeycardFormContainer
                        onKeycardRegistered={() => {
                            addNotification({
                                type: 'success',
                                message: getTranslated('Keycard.added'),
                            });
                            setKeycardRegistered(true);
                        }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
