import { FunctionComponent, PropsWithChildren } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Form, Formik } from 'formik';
import { Typography, Box, CircularProgress } from '@mui/material';
import { FormikTextField } from '@/components/Layout/Form/Field';
import { FieldWrapper } from '@/components/Layout/Form/FieldWrapper';
import { Button } from '@/components/Layout/Button/Button';
import { useNavigate, useParams } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useTranslation } from '@/hooks/useTranslation';
import { PageTemplate } from '@/pages/PageTemplate';
import { usePostRecoveryCodeMutation } from '@/api/authentication';

export const PasswordForgottenPage: FunctionComponent<
    PropsWithChildren
> = () => {
    const { isLoggedIn } = useAuthentication();
    const [postPasswordRecovery, { isLoading }] = usePostRecoveryCodeMutation();
    const { defaultRoutePathname } = useCustomRouter();
    const { id } = useParams();
    const navigate = useNavigate();
    const { getTranslated } = useTranslation();

    if (!id || isLoggedIn) {
        navigate(defaultRoutePathname);
    }

    return (
        <PageTemplate>
            <FlexContainer
                sx={{
                    flexGrow: 1,
                }}
                column
                fullWidth
                center
            >
                <Formik
                    initialValues={{ password: '', password2: '' }}
                    validate={(values) => {
                        const errors: {
                            password?: string;
                            password2?: string;
                        } = {};

                        if (!values.password) {
                            errors.password = getTranslated(
                                'validation.error.password.requiredShort'
                            );
                        }

                        if (values.password?.length < 6) {
                            errors.password = getTranslated(
                                'validation.error.password.required'
                            );
                        }

                        if (!values.password2) {
                            errors.password = getTranslated(
                                'validation.error.password.requiredShort'
                            );
                        }

                        return errors;
                    }}
                    validateOnChange={false}
                    onSubmit={(values) => {
                        postPasswordRecovery({
                            recoveryCode: id,
                            ...values,
                        });
                    }}
                >
                    <Form
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                minHeight: '200px',
                            }}
                        >
                            <Typography variant='h4' gutterBottom>
                                {getTranslated('LoginForm.passwordForgotten')}
                            </Typography>

                            <FormikTextField
                                name={'password'}
                                textFieldProps={{
                                    id: 'password',
                                    label: getTranslated('Address.newPassword'),
                                    sx: {
                                        margin: (theme) => theme.spacing(4, 0),
                                        width: '320px',
                                    },
                                    type: 'password',
                                    variant: 'outlined',
                                }}
                            />

                            <FormikTextField
                                name={'password2'}
                                textFieldProps={{
                                    id: 'password2',
                                    label: getTranslated('Address.password2'),
                                    sx: {
                                        margin: (theme) => theme.spacing(4, 0),
                                        width: '320px',
                                    },
                                    type: 'password',
                                    variant: 'outlined',
                                }}
                            />

                            <FieldWrapper
                                position='relative'
                                justifyContent='center'
                            >
                                <Button
                                    isLoading={isLoading}
                                    sx={{ width: '256px' }}
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                >
                                    {getTranslated('ChangePasswordForm.save')}
                                </Button>
                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: -12,
                                            marginLeft: -12,
                                        }}
                                    />
                                )}
                            </FieldWrapper>
                        </Box>
                    </Form>
                </Formik>
            </FlexContainer>
        </PageTemplate>
    );
};
