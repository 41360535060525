import { FunctionComponent } from 'react';
import Button from '@mui/material/Button';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { SvgIconProps, Typography } from '@mui/material';
import { getPropByCurrentLocale } from '@/utils/locale';
import { useGetLiftOperatorByIdQuery } from '@/api/liftOperator';
import { useParams } from 'react-router-dom';
import { ProductType } from '@/models/product';

const ButtonLabel: FunctionComponent<
    React.PropsWithChildren<{ type: ProductType }>
> = ({ type }) => {
    const { getTranslated } = useTranslation();
    const { IS_PORTAL, pageConfig } = useConfigSlice();
    const { id } = useParams<{ id: string }>();
    const { data: liftOperator } = useGetLiftOperatorByIdQuery({
        liftOperatorId: id,
    });

    const translationKey =
        type == ProductType.DAY ? 'dayTickets' : 'seasonTickets';
    const buttonKey =
        type == ProductType.DAY
            ? 'dayTicketsButtonText'
            : 'seasonTicketsButtonText';

    const buttonText = getPropByCurrentLocale(
        liftOperator?.[buttonKey],
        getTranslated(`Core.${translationKey}` as TranslationKey)
    );

    return (
        <Typography
            sx={{
                fontWeight: 'bold',
                fontSize: '.9rem',
            }}
            variant='overline'
        >
            {!IS_PORTAL && pageConfig?.configuration?.[buttonKey]
                ? getPropByCurrentLocale(
                      pageConfig.configuration?.[buttonKey],
                      getTranslated(`Core.${translationKey}` as TranslationKey)
                  )
                : buttonText}
        </Typography>
    );
};

type ProductTypeButtonProps = {
    type: ProductType;
    onClick: (type: ProductType) => void;
    isSelected: boolean;
    disabled: boolean;
    Icon: FunctionComponent<SvgIconProps>;
};

export const ProductTypeButton: FunctionComponent<ProductTypeButtonProps> = ({
    disabled,
    onClick,
    type,
    isSelected,
    Icon,
}) => {
    return (
        <Button
            sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                p: 6,
                mr: 4,
                minWidth: '320px',

                [theme.breakpoints.down('lg')]: {
                    width: '100%',
                    p: 2,
                    my: 1,
                    mx: 0,
                },
            })}
            onClick={() => {
                onClick(type);
            }}
            variant={isSelected ? 'contained' : 'outlined'}
            disabled={disabled}
            startIcon={
                <Icon
                    sx={{
                        fontSize: '32px !important',
                    }}
                />
            }
        >
            <ButtonLabel type={type} />
        </Button>
    );
};
