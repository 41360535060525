import {
    Fragment,
    FunctionComponent,
    PropsWithChildren,
    useState,
} from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import {
    Box,
    FormControl,
    List,
    ListSubheader,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { Capitalized } from '@/components/Layout/Textual';
import { DurationProductMap } from '@/components/PageContent/ProductsPageContent/ProductSelection';
import { useTranslation } from '@/hooks/useTranslation';
import { ProductCollection } from '@/models/collection/productCollection';
import { ProductCollectionCard } from '@/components/CollectionCard/ProductCollectionCard/ProductCollectionCard';

type ProductsResultProps = {
    durationProductsMap: DurationProductMap;
    onShowInfoText: (infoText: string) => void;
    isSeason: boolean;
};

export const ProductsResult: FunctionComponent<
    PropsWithChildren<ProductsResultProps>
> = ({ durationProductsMap, isSeason }) => {
    const { getTranslated } = useTranslation();
    const [selectValue, setSelectValue] = useState(-1);

    const onSelectChange = (e) => {
        const value = e.target.value;
        setSelectValue(value);
    };

    const durationsMapToRender =
        selectValue === -1
            ? durationProductsMap
            : { [selectValue]: durationProductsMap[selectValue] };

    return (
        <>
            {Object.keys(durationProductsMap).length > 3 && (
                <FlexContainer
                    sx={{
                        marginRight: 'auto',
                        width: 350,
                    }}
                    column
                >
                    <FormControl variant='outlined'>
                        <Select
                            variant='outlined'
                            onChange={onSelectChange}
                            value={selectValue}
                        >
                            <MenuItem value={-1}>
                                {getTranslated('ProductSelection.chooseDay')}
                            </MenuItem>
                            {Object.keys(durationProductsMap).map(
                                (duration) => (
                                    <MenuItem key={duration} value={duration}>
                                        <Typography component={'span'}>
                                            {getTranslated('coreTo')} {duration}{' '}
                                        </Typography>
                                        &nbsp;
                                        <Capitalized variant='body1'>
                                            {getTranslated(
                                                duration === '1'
                                                    ? 'core.day'
                                                    : 'core.days'
                                            )}
                                        </Capitalized>
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </FlexContainer>
            )}

            <List
                sx={(theme) => ({
                    width: '100%',
                    mt: 1,
                    [theme.breakpoints.up('lg')]: {
                        pt: 0,
                    },
                })}
            >
                {Object.keys(durationsMapToRender).map((duration) => (
                    <Fragment key={duration}>
                        {!isSeason && (
                            <ListSubheader
                                sx={{
                                    alignItems: 'center',
                                    py: 3,
                                    px: 2,
                                    my: 2,
                                    backgroundColor: '#e5e9ea',
                                    width: '100%',
                                    fontWeight: 600,
                                    borderRadius: 2,
                                    color: 'unset',
                                    zIndex: 0,
                                }}
                            >
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    variant='h5'
                                >
                                    {duration}{' '}
                                    {/* Once mui is upgraded change paragraph prop to component */}
                                    <Capitalized variant='h5'>
                                        {getTranslated(
                                            duration === '1'
                                                ? 'core.day'
                                                : 'core.days'
                                        )}
                                    </Capitalized>
                                </Typography>
                            </ListSubheader>
                        )}

                        <FlexContainer
                            sx={{
                                flexWrap: 'wrap',
                            }}
                        >
                            {durationProductsMap[duration].map(
                                (
                                    productCollection: ProductCollection,
                                    index: number
                                ) => (
                                    <Box
                                        key={index}
                                        sx={(theme) => ({
                                            p: 1,
                                            width: 520,

                                            [theme.breakpoints.down('xs')]: {
                                                width: '100%',
                                            },

                                            [theme.breakpoints.down('md')]: {
                                                width: 360,
                                            },
                                        })}
                                    >
                                        <ProductCollectionCard
                                            productCollection={
                                                productCollection
                                            }
                                        />
                                    </Box>
                                )
                            )}
                        </FlexContainer>
                    </Fragment>
                ))}
            </List>
        </>
    );
};
