import { FunctionComponent, PropsWithChildren } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import { LiftOperatorsPageContainer } from '@/components/PageContent/LiftOperatorsPageContent/LiftOperatorsPageContainer';
import { LiftOperatorsPageContainerMobile } from '@/components/PageContent/LiftOperatorsPageContent/LiftOperatorsPageContainerMobile';

export const LiftOperatorsPage: FunctionComponent<PropsWithChildren> = () => {
    const matchesMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    return matchesMobile ? (
        <LiftOperatorsPageContainerMobile />
    ) : (
        <LiftOperatorsPageContainer />
    );
};
