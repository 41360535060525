import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type InfoState = {
    message: string;
};

const initialState = {
    message: '',
};

export const infoSlice = createSlice({
    name: 'infoSlice',
    initialState,
    reducers: {
        addInfo: (
            state: InfoState,
            action: PayloadAction<{
                message: string;
            }>
        ) => {
            const { message } = action.payload;
            state.message = message;

            return state;
        },

        removeInfo: (state: InfoState) => {
            state.message = '';

            return state;
        },
    },
});
