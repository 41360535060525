import {
    Box,
    CardContent,
    CardHeader,
    Stack,
    SvgIcon,
    Typography,
} from '@mui/material';
import { FunctionComponent, useMemo, useState } from 'react';
import { FlatCard } from '@/components/Card/FlatCard';
import { CheckoutTicketCollection } from '@/models/collection/checkoutTicketCollection';
import {
    useGetCurrentCheckoutSession,
    usePutCheckoutTicketMutation,
} from '@/api/checkout';
import { useTranslation } from '@/hooks/useTranslation';
import { InsuranceComponentSjReact } from '@crossnative/insurance-component/react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { IS_DEVELOPMENT } from '@/constants';
import ShieldHeartIcon from '@/assets/icons/shield-heart-solid.svg?react';
import { FlexContainer } from '@/components/Layout/Container';
import { getPropByCurrentLocale } from '@/utils/locale';

export const ADD_INSURANCE_CACHE_KEY = 'addInsuranceContainer';

export const InsuranceAddContainer: FunctionComponent<{
    ticketCollection: CheckoutTicketCollection;
}> = ({ ticketCollection }) => {
    const [batchAddTicketsLoading, setBatchAddTicketsLoading] = useState(false);
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const [putCheckoutTicket] = usePutCheckoutTicketMutation({
        fixedCacheKey: ADD_INSURANCE_CACHE_KEY,
    });
    const { getTranslated, locale } = useTranslation();
    const insuranceAdded = useMemo(() => {
        // check if all tickets have the insurance added
        return ticketCollection.checkoutTickets.every((ticket) => {
            if (!ticket.aonInsurances[0]) return true;

            return ticket?.aonInsurances.some(
                (insurance) => insurance.selected
            );
        });
    }, [ticketCollection]);

    const insuranceData =
        ticketCollection.checkoutTickets.find((t) => !!t.aonInsurances[0])
            ?.aonInsurances[0] || null;

    const addTicketsToCart = async () => {
        setBatchAddTicketsLoading(true);

        try {
            for (const ticket of ticketCollection.checkoutTickets) {
                if (!ticket.aonInsurances[0]) continue;
                await putCheckoutTicket({
                    checkoutId: checkoutSession?.id,
                    identificationType: ticket.identificationType,
                    id: ticket.id,
                    tariffIdentifier: ticket.aonInsurances[0].tariffIdentifier,
                }).unwrap();
            }
        } catch (error) {}

        setBatchAddTicketsLoading(false);
    };

    if (!insuranceData) return null;

    return (
        <FlatCard>
            <CardHeader
                avatar={
                    <SvgIcon sx={{ fontSize: 32 }}>
                        <ShieldHeartIcon />
                    </SvgIcon>
                }
                title={
                    <Typography
                        sx={{
                            fontWeight: 600,
                        }}
                    >
                        {getPropByCurrentLocale(insuranceData.tariffName)}
                    </Typography>
                }
            />
            <CardContent>
                <Stack spacing={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <InsuranceComponentSjReact
                            language={locale.toLocaleUpperCase()}
                            tariffCode={insuranceData.tariffIdentifier}
                            popUpOnly
                            stage={IS_DEVELOPMENT ? 'test' : undefined}
                        />

                        <Typography>
                            {getPropByCurrentLocale(
                                insuranceData.tariffShortDescription
                            )}{' '}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: '0.95rem',
                        }}
                    >
                        {getTranslated(
                            insuranceData.tariffName.de === 'Refund+'
                                ? 'insurance.productAddContainer.body.refundPlus'
                                : 'insurance.productAddContainer.body.safteyPlus'
                        )}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Button
                            loading={batchAddTicketsLoading}
                            onClick={addTicketsToCart}
                            variant='contained'
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 240,
                                backgroundColor: 'success.light',
                                border: '1px solid #000',
                            }}
                            disabled={insuranceAdded}
                        >
                            <FlexContainer
                                sx={{
                                    alignItems: 'center',
                                }}
                            >
                                <AddIcon />
                                {getTranslated(
                                    insuranceAdded
                                        ? 'insurance.productAddContainer.button.labelAdded'
                                        : 'insurance.productAddContainer.button.label'
                                )}
                            </FlexContainer>
                        </Button>
                    </Box>
                </Stack>
            </CardContent>
        </FlatCard>
    );
};
