import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { Card, CardContent, Typography } from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import { LoginInformation, LoginType } from '@/models/user';
import { DATE_FORMAT } from '@/constants';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@/theme/theme';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@/assets/icons/googleLight.svg?react';
import { useTranslation } from '@/hooks/useTranslation';
import { StarjackIcon } from '@/components/Icon/StarjackIcon';

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        width: '100%',
        marginBottom: theme.spacing(4),
    },
    loginVariantContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    nameLabel: {
        marginLeft: theme.spacing(2),
    },

    variantTitleContainer: {
        marginBottom: theme.spacing(2),
    },

    loginVariantTitle: {
        fontWeight: 600,
        marginLeft: theme.spacing(),
    },

    facebookIcon: {
        color: theme.palette.facebookBlue,
    },

    googleIcon: {
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-2),
    },

    sjIcon: {
        width: 20,
        height: 20,
    },

    button: {
        height: 36,
    },
}));

const VariantTitle: FunctionComponent<
    React.PropsWithChildren<{ type: LoginType }>
> = ({ type }) => {
    const classes = useStyles();
    const { getTranslated } = useTranslation();
    let Icon = <div />;
    let label = '';

    switch (type) {
        case LoginType.FACEBOOK:
            Icon = <FacebookIcon className={classes.facebookIcon} />;
            label = 'Facebook';
            break;

        case LoginType.GOOGLE:
            Icon = <GoogleIcon className={classes.googleIcon} />;
            label = 'Google';
            break;
        case LoginType.EMAIL_PASSWORD:
            Icon = <StarjackIcon className={classes.sjIcon} />;
            label = getTranslated('Profile.starjackLogin');

            break;
    }

    return (
        <FlexContainer
            className={classes.variantTitleContainer}
            alignItems='center'
        >
            {Icon && Icon}
            <Typography className={classes.loginVariantTitle}>
                {label}
            </Typography>
        </FlexContainer>
    );
};

type LoginVariantProps = {
    loginInformation: LoginInformation;
    removable?: boolean;
    onRemove?: (lI: LoginInformation) => void;
    isLoading?: boolean;
};

export const LoginVariant: FunctionComponent<
    React.PropsWithChildren<LoginVariantProps>
> = ({ loginInformation, removable = false, onRemove, isLoading }) => {
    const classes = useStyles();
    const { getTranslated } = useTranslation();
    return (
        <Card className={classes.card}>
            <CardContent className={classes.loginVariantContainer}>
                <FlexContainer column>
                    <VariantTitle type={loginInformation.type} />

                    <Typography className={classes.nameLabel}>
                        {loginInformation.name}{' '}
                        {!!loginInformation.lastUsedAt && (
                            <Typography variant='caption'>
                                ({getTranslated('Profile.lastLogin')}{' '}
                                {loginInformation.lastUsedAt?.format(
                                    DATE_FORMAT
                                )}
                                )
                            </Typography>
                        )}
                    </Typography>
                </FlexContainer>

                <FlexContainer>
                    {removable && (
                        <Button
                            className={classes.button}
                            formType='error'
                            onClick={() => {
                                if (onRemove) {
                                    onRemove(loginInformation);
                                }
                            }}
                            isLoading={isLoading}
                        >
                            {getTranslated('Core.delete')}
                        </Button>
                    )}
                </FlexContainer>
            </CardContent>
        </Card>
    );
};
