import { FunctionComponent, PropsWithChildren } from 'react';
import { KeycardEntryContainer } from '@/components/PageContent/CartPageContent/CheckoutItemList/KeycardEntry/KeycardEntryContainer';
import { VoucherEntryContainer } from '@/components/PageContent/CartPageContent/CheckoutItemList/Voucher/VoucherEntryContainer';
import { Dialog, Typography } from '@mui/material';
import { PromotionDialogContent } from '@/components/PageContent/CartPageContent/3_TAELER/PromotionDialogContent';
import { IdentificationSelectionDialogContainer } from '@/components/PageContent/CartPageContent/IdentificationSelection/IdentificationSelectionDialogContainer';
import { FlexContainer } from '@/components/Layout/Container';
import { useSlice } from '@/hooks/useSlice';
import { cartSlice } from '@/slices/cartSlice';
import { useTranslation } from '@/hooks/useTranslation';
import { PromotionType } from '@/models/promotionDialog';
import { OEAMTCPromotionDialogContent } from '@/components/PageContent/CartPageContent/OEAMTCPromotionDialog';
import {
    useGetCheckoutTicketCollections,
    useGetCurrentCheckoutSession,
} from '@/api/checkout';
import { CheckoutTicketCollectionCard } from '@/components/CollectionCard/CheckoutTicketCollectionCard/CheckoutTicketCollectionCard';
import { CartPageEntryWrapper } from '@/components/PageContent/CartPageContent/CartPageEntryWrapper';

export const CheckoutListContainer: FunctionComponent = () => {
    const {
        state: { promotionCodeInput },
        actions: { setPromotionDialogInput },
    } = useSlice(cartSlice, 'cart');
    const ticketCollections = useGetCheckoutTicketCollections();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    const { getTranslated } = useTranslation();

    const renderSection = (arrayType: unknown[] = []) => arrayType.length > 0;

    return (
        <FlexContainer
            sx={{
                alignItems: 'center',
            }}
            column
            fullWidth
        >
            {renderSection(ticketCollections) && (
                <ContainerWithTitle
                    title={getTranslated('checkoutTicketsTitle')}
                >
                    {ticketCollections.map((ticketCollection, index) => (
                        <CartPageEntryWrapper
                            data-id={`ticketCollection-${ticketCollection.id}`}
                            key={index}
                        >
                            <CheckoutTicketCollectionCard
                                ticketCollection={ticketCollection}
                            />
                        </CartPageEntryWrapper>
                    ))}
                </ContainerWithTitle>
            )}

            {renderSection(checkoutSession?.identifications) && (
                <ContainerWithTitle
                    title={getTranslated('checkoutIdentificationsTitle')}
                >
                    {checkoutSession?.identifications.map(
                        (identification, index) => (
                            <CartPageEntryWrapper key={index}>
                                <KeycardEntryContainer
                                    identification={identification}
                                />
                            </CartPageEntryWrapper>
                        )
                    )}
                </ContainerWithTitle>
            )}

            {renderSection(checkoutSession?.vouchers) && (
                <ContainerWithTitle
                    title={getTranslated('checkoutVouchersTitle')}
                >
                    {checkoutSession?.vouchers.map((voucher, index) => (
                        <CartPageEntryWrapper key={index}>
                            <VoucherEntryContainer voucher={voucher} />
                        </CartPageEntryWrapper>
                    ))}
                </ContainerWithTitle>
            )}

            <IdentificationSelectionDialogContainer />

            <Dialog
                maxWidth='md'
                open={promotionCodeInput?.promotionType === PromotionType.AHA}
            >
                <PromotionDialogContent
                    onClose={() => setPromotionDialogInput(null)}
                    checkoutTicket={promotionCodeInput?.checkoutTicket}
                />
            </Dialog>

            <Dialog
                maxWidth='md'
                open={
                    promotionCodeInput?.promotionType === PromotionType.OEAMTC
                }
            >
                <OEAMTCPromotionDialogContent
                    onClose={() => {
                        setPromotionDialogInput(null);
                    }}
                    checkoutTicket={promotionCodeInput?.checkoutTicket}
                />
            </Dialog>
        </FlexContainer>
    );
};

const ContainerWithTitle: FunctionComponent<
    PropsWithChildren<{ title: string }>
> = ({ title, children }) => (
    <FlexContainer
        sx={{
            alignItems: 'center',
            mb: 4,
        }}
        column
    >
        <Typography
            sx={{
                mb: 2,
                mr: 'auto',
            }}
            variant='h4'
        >
            {title}
        </Typography>

        <FlexContainer
            sx={{
                gap: '12px',
                ml: 4,
            }}
            column
        >
            {children}
        </FlexContainer>
    </FlexContainer>
);
