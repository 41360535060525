import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    CircularProgress,
} from '@mui/material';
import { FunctionComponent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { green, red } from '@mui/material/colors';
import { Theme } from '@/theme/theme';

const useStyles = makeStyles((theme: Theme) => ({
    baseButton: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
    },
    buttonSuccess: {
        color: '#fff',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },

    buttonError: {
        color: '#fff',
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[600],
        },
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: theme.palette.white,
    },
}));

type ButtonProps = {
    isLoading?: boolean;
    formType?: 'regular' | 'success' | 'error';
} & MuiButtonProps;

export const Button: FunctionComponent<
    React.PropsWithChildren<ButtonProps>
> = ({ children, isLoading, formType = 'regular', ...buttonProps }) => {
    const classes = useStyles();

    if (formType !== 'regular') {
        const addClass =
            formType === 'success'
                ? classes.buttonSuccess
                : classes.buttonError;
        buttonProps.className = `${classes.baseButton} ${buttonProps.className} ${addClass}`;
    }

    return (
        <MuiButton
            {...buttonProps}
            disabled={isLoading || buttonProps.disabled}
        >
            {children}
            {isLoading && (
                <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                />
            )}
        </MuiButton>
    );
};
