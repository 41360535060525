import { FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { useTranslation } from '@/hooks/useTranslation';
import { ReactComponent as DataPrivacyGerman } from '@/components/PageContent/DataPrivacyPageContent/data_privacy_de.md';
import { ReactComponent as DataPrivacyEnglish } from '@/components/PageContent/DataPrivacyPageContent/data_privacy_en.md';

export const DataPrivacy: FunctionComponent = () => {
    const { locale, getTranslated } = useTranslation();

    const DataPrivacyContent =
        locale === 'de' ? DataPrivacyGerman : DataPrivacyEnglish;

    return (
        <SubPageTemplate title={getTranslated('dataPrivacyPolicies')}>
            <FlexContainer
                sx={{
                    flexDirection: 'column',
                    width: '80%',
                    mx: 'auto',
                }}
            >
                <DataPrivacyContent />
            </FlexContainer>
        </SubPageTemplate>
    );
};
