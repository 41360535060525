import { Locale, Money } from '@/models/general';
import { translateFunction } from '@/hooks/useTranslation';

export const getMoneyAsString = (money: Money) => {
    if (!money) return;
    switch (money.currency) {
        case 'EUR':
            return `EUR ${new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(money.amount)}`;
        default:
            return `${money.amount}`;
    }
};

export const getMoneyFormated = (money: Money) => {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(money.amount);
};

export const getTranslatedSalutationsOptions = (
    locale: Locale
): [string, string][] => {
    const values = ['-', 'female', 'male'];

    return values.map((value) => [
        value,
        translateFunction(`salutation.${value}`, locale),
    ]);
};
