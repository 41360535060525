import { useGetProductOverviewQuery } from '@/api/liftOperator';
import { getTagsFromUrl } from '@/utils/url';
import { TicketVariant } from '@/models/ticket';
import moment, { Moment } from 'moment';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import {
    getFilterDateOnTypeSeason,
    getQueryUrlFromProductFilter,
    initialFilter,
    isFilterInInitialPromotionState,
    isFilterInInitialState,
    ProductFilter,
    updateFilter,
} from '@/models/productFilter';
import { usePromotion } from '@/hooks/usePromotion';
import { DATE_FORMAT } from '@/constants';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import { PersonCount, ProductsOverview, ProductType } from '@/models/product';

type SetProductFilterHandler = <T extends keyof ProductFilter>(
    key: T,
    value: ProductFilter[T]
) => void;

type UseProductFilterData = {
    productFilter: ProductFilter;
    presetFields: Partial<Record<keyof ProductFilter, boolean>>;
};

export const useProductFilter = (): [
    UseProductFilterData,
    SetProductFilterHandler
] => {
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { configuration } = useConfigSlice();
    const promotion = usePromotion();
    const [query, setUrlSearchParams] = useSearchParams();
    const { data: productsOverview } = useGetProductOverviewQuery({
        liftOperatorId: selectedLiftOperator.id,
        configuration,
        tags: query.getAll('tags') || [],
    });
    let currentFilter = useMemo(
        () => buildProductFilter(query, productsOverview),
        [query, productsOverview]
    );

    if (
        !!promotion &&
        !isFilterInInitialPromotionState(currentFilter, promotion)
    ) {
        currentFilter = {
            ...initialFilter,
            type: ProductType.DAY,
            variant: TicketVariant.SINGLE,
            date: moment(promotion.date, DATE_FORMAT),
        };
    } else if (
        !!productsOverview &&
        productOverViewHasNoTickets(productsOverview) &&
        !isFilterInInitialState(currentFilter)
    ) {
        currentFilter = initialFilter;
    }

    const setProductFilter: SetProductFilterHandler = (key, value) => {
        const updatedFilter = updateFilter({
            filter: currentFilter,
            productsOverview,
            key,
            value,
        });

        const queryUrl = getQueryUrlFromProductFilter(updatedFilter);
        const tags = getTagsFromUrl();

        if (tags.length) {
            queryUrl.append('tags', tags.join(','));
        } else {
            queryUrl.delete('tags');
        }

        setUrlSearchParams(queryUrl);
    };

    const pOTypeKey =
        currentFilter && productsOverview
            ? currentFilter?.type === ProductType.DAY
                ? 'day'
                : 'season'
            : null;

    return [
        {
            productFilter: currentFilter,
            presetFields: {
                type: disableProductTypeSelection(productsOverview),
                variant:
                    pOTypeKey &&
                    (productsOverview?.[pOTypeKey]?.packageProduct === null ||
                        productsOverview?.[pOTypeKey]?.singleProduct === null),
                date: currentFilter?.type === ProductType.SEASON,
            },
        },

        setProductFilter,
    ];
};

const getNotAvailableProductType = (productOverview: ProductsOverview) => {
    if (
        productOverview?.day?.packageProduct === null &&
        productOverview?.day?.singleProduct === null
    ) {
        return ProductType.DAY;
    } else if (
        productOverview?.season?.packageProduct === null &&
        productOverview?.season?.singleProduct === null
    ) {
        return ProductType.SEASON;
    } else return null;
};

const getPersonsFromUrl = (
    type: ProductType,
    productOverview: ProductsOverview
): PersonCount[] => {
    const query = new URLSearchParams(window.location.search);
    const persons = query.getAll('person');

    if (!productOverview || !type || !persons?.length) return null;

    const personCountIndex = persons.reduce((index, person) => {
        index[person] = 1 + (index[person] || 0);

        return index;
    }, {});

    const typeKey = type.toLowerCase();

    const backendPersons: PersonCount[] =
        productOverview[typeKey]?.packageProduct?.persons || [];

    return backendPersons
        .map((backendPerson) => ({
            ...backendPerson,
            quantity: personCountIndex[backendPerson.name.de] || 0,
        }))
        .filter((backendPerson) => !!backendPerson.quantity);
};

const productOverViewHasNoTickets = (productsOverview: ProductsOverview) =>
    !productsOverview.season.singleProduct &&
    !productsOverview.season.packageProduct &&
    !productsOverview.day.singleProduct &&
    !productsOverview.day.packageProduct;

const buildProductFilter = (
    query: URLSearchParams,
    productsOverview: ProductsOverview
): ProductFilter => {
    let type = query.get('type') as ProductType | null;
    let variant = query.get('variant') as TicketVariant | null;
    const dateParsed = getDateFromQuery(query);
    const persons = getPersonsFromUrl(type, productsOverview);
    const duration = query.get('duration');

    const notAvailableProductType =
        getNotAvailableProductType(productsOverview);

    if (notAvailableProductType) {
        type =
            notAvailableProductType === ProductType.DAY
                ? ProductType.SEASON
                : ProductType.DAY;
    }

    if (type && !variant) {
        variant = getProductsOverviewVariant(type, productsOverview);
    }

    const presetDate =
        dateParsed !== null
            ? dateParsed
            : type === ProductType.SEASON
            ? getFilterDateOnTypeSeason({
                  productType: type,
                  productVariant: variant || null,
                  productsOverview,
              })
            : initialFilter.date;

    return {
        type: type || initialFilter.type,
        variant: variant || initialFilter.variant,
        date: presetDate,
        persons: persons,
        duration: isNaN(parseInt(duration))
            ? initialFilter.duration
            : parseInt(duration),
    };
};

const getProductsOverviewVariant = (
    type: ProductType | null,
    productsOverview: ProductsOverview
) => {
    if (!type || !productsOverview) return null;

    const typeKey = type === ProductType.DAY ? 'day' : 'season';

    const ticketType = productsOverview[typeKey];

    if (ticketType.packageProduct && !ticketType.singleProduct)
        return TicketVariant.PACKAGE;
    else if (!ticketType.packageProduct && ticketType.singleProduct)
        return TicketVariant.SINGLE;
    else return null;
};

// will be preset
const disableProductTypeSelection = (productsOverview: ProductsOverview) => {
    return (
        (productsOverview?.day?.packageProduct === null &&
            productsOverview?.day?.singleProduct === null) ||
        (productsOverview?.season?.packageProduct === null &&
            productsOverview?.season?.singleProduct === null)
    );
};

const getDateFromQuery = (query: URLSearchParams): Moment | null => {
    const dateParsed = query.get('date');

    if (!dateParsed) return null;
    else if (dateParsed === 'today') {
        return moment();
    } else if (dateParsed === 'tomorrow') {
        return moment().add(1, 'day');
    } else {
        const dateMomentParsed = moment(dateParsed, 'YYYY-MM-DD');

        return dateMomentParsed.isValid() ? dateMomentParsed : null;
    }
};
