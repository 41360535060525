import { Fragment, FunctionComponent, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { FlexContainer } from '@/components/Layout/Container';
import { IdentificationType } from '@/models/cart';
import { useMediaQuery } from '@mui/material';
import { TicketIdentVariantTileMobile } from '@/components/PageContent/CartPageContent/TicketIdentVariant/TicketIdentVariantTileMobile';
import { TicketIdentVariantTile } from '@/components/PageContent/CartPageContent/TicketIdentVariant/TicketIdentVariantTile';
import { CheckoutTicket } from '@/models/checkout/checkoutTicket';
import {
    useGetCurrentCheckoutSession,
    usePutCheckoutTicketMutation,
} from '@/api/checkout';
import { useSlice } from '@/hooks/useSlice';
import { cartSlice } from '@/slices/cartSlice';

const Container = styled(FlexContainer)(() => ({
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
}));

type TicketIdentTypeSelectionContainerProps = {
    ticket: CheckoutTicket;
};

export const TicketIdentTypeSelectionContainer: FunctionComponent<
    TicketIdentTypeSelectionContainerProps
> = ({ ticket }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { supportedIdentificationTypes } = ticket;
    const {
        actions: { setPersonAssignmentDialogControls },
    } = useSlice(cartSlice, 'cart');
    const [typeUpdating, setTypeUpdating] = useState<IdentificationType | null>(
        null
    );
    const { data: checkout } = useGetCurrentCheckoutSession();
    const [putTicket] = usePutCheckoutTicketMutation({
        fixedCacheKey: updateIdentificationTypeCacheKey,
    });

    const onVariantSelection = (variant: IdentificationType) => {
        setTypeUpdating(variant);
        putTicket({
            checkoutId: checkout.id,
            id: ticket.id,
            identificationType: variant,
        })
            .unwrap()
            .then(() => {
                if (ticket.persons.length > 1) {
                    setPersonAssignmentDialogControls(null);
                }
                setTypeUpdating(null);
            });
    };

    return (
        <Container>
            {supportedIdentificationTypes.map((type, index) => (
                <Fragment key={index}>
                    {isMobile ? (
                        <TicketIdentVariantTileMobile
                            variant={type}
                            onClick={onVariantSelection}
                            isLoading={type === typeUpdating}
                        />
                    ) : (
                        <TicketIdentVariantTile
                            variant={type}
                            onClick={onVariantSelection}
                            avatarTitle={index + 1}
                            isLoading={type === typeUpdating}
                        />
                    )}
                </Fragment>
            ))}
        </Container>
    );
};

export const updateIdentificationTypeCacheKey = 'updateIdentificationType';
