import { usePageConfig } from '@/hooks/useConfig';

export const useIsOnPlatform = (shops: string[]) => {
    const { isPortal, currentWebshop, isOeamtc } = usePageConfig();

    if (isPortal) {
        return shops.includes('portal');
    } else if (isOeamtc) {
        return shops.includes('oeamtc');
    } else {
        return shops.includes(currentWebshop);
    }
};
