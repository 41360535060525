import { Page } from '@/models/page';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { OrderedVoucherPreviewCard } from '@/components/PageContent/MyVouchersPageContent/OrderedVoucherPreviewCard';
import { FlexContainer, LoadingContainer } from '@/components/Layout/Container';
import { useGetOrdersQuery } from '@/api/order';
import { useTranslation } from '@/hooks/useTranslation';

export const MyVouchersPage: Page = () => {
    const { data: orders = [], isLoading } = useGetOrdersQuery();
    const { getTranslated } = useTranslation();

    const orderedVouchers = orders.flatMap((order) => order.vouchers);

    return (
        <SubPageTemplate title={getTranslated('myVouchers')}>
            <FlexContainer
                sx={{
                    gap: 2,
                }}
                column
                fullWidth
            >
                {isLoading ? (
                    <LoadingContainer center />
                ) : (
                    orderedVouchers.map((voucher) => {
                        return (
                            <OrderedVoucherPreviewCard
                                key={voucher.id}
                                orderedVoucher={voucher}
                            />
                        );
                    })
                )}
            </FlexContainer>
        </SubPageTemplate>
    );
};

MyVouchersPage.path = '/my-vouchers';
