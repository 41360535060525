export enum CheckoutValidationErrorCode {
    NO_IDENTIFICATION_ASSIGNED = 'NO_IDENTIFICATION_ASSIGNED',
    PHOTO_REQUIRED = 'PHOTO_REQUIRED',
    START_DATE_IN_PAST = 'START_DATE_IN_PAST',
    TICKET_IDENTIFICATION_TYPE_NOT_SET = 'TICKET_IDENTIFICATION_TYPE_NOT_SET',
    START_DATE_TOO_EARLY_HARD = 'START_DATE_TOO_EARLY_HARD',
    START_DATE_TOO_EARLY_SOFT = 'START_DATE_TOO_EARLY_SOFT',
    INVALID_REF = 'INVALID_REF',
    TOKEN_EXPIRED = 'TOKEN_EXPIRED',
    DUPLICATE_TICKET = 'DUPLICATE_TICKET',
    TICKET_EXPIRED = 'TICKET_EXPIRED',

    // actually not unused but just not touched from the frontend. They have to be in this enum
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    NO_PRODUCT_IN_CHECKOUT = 'NO_PRODUCT_IN_CHECKOUT',
    NO_SHIPPING_ADDRESS = 'NO_SHIPPING_ADDRESS',
}

// these errors will be shown directly at the ticket bottom
// if not in this error - a general error message will be shown with the possiblity to show the global snackbar
export const directTicketNotifications: CheckoutValidationErrorCode[] = [
    CheckoutValidationErrorCode.NO_IDENTIFICATION_ASSIGNED,
    CheckoutValidationErrorCode.TICKET_IDENTIFICATION_TYPE_NOT_SET,
    CheckoutValidationErrorCode.PHOTO_REQUIRED,
    CheckoutValidationErrorCode.DUPLICATE_TICKET,
    CheckoutValidationErrorCode.TOKEN_EXPIRED,
    CheckoutValidationErrorCode.TICKET_EXPIRED,
    CheckoutValidationErrorCode.START_DATE_TOO_EARLY_SOFT,
];

// these errors are error belonging to a ticket collection but shown as a general error - within the erro banner is a
// info button to show the global snackbar
export const ticketNotificationsShownGlobally: CheckoutValidationErrorCode[] = [
    CheckoutValidationErrorCode.START_DATE_IN_PAST,
    CheckoutValidationErrorCode.START_DATE_TOO_EARLY_HARD,
    CheckoutValidationErrorCode.INVALID_REF,
];

export const notificationsWarningTypes: CheckoutValidationErrorCode[] = [
    CheckoutValidationErrorCode.START_DATE_TOO_EARLY_SOFT,
];

export const isnGeneralCheckoutError = (
    checkoutError: CheckoutValidationError
) => !checkoutError.ticketId;

type CheckoutValidationError = {
    error: CheckoutValidationErrorCode;
    ticketId?: string;
};

export type CheckoutValidation = {
    errors: CheckoutValidationError[];
};
