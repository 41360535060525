import { notificationSlice } from '@/slices/notificationSlice';
import { useSlice } from '@/hooks/useSlice';

export const useNotification = () => {
    const {
        state,
        actions: { addNotification, consumeNext, clearNotifications },
    } = useSlice(notificationSlice, 'notification');

    return {
        ...state,
        addNotification,
        consumeNext,
        clearNotifications,
    };
};
