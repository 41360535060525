import { FunctionComponent, PropsWithChildren } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { ProductsPageContentContainer } from '@/components/PageContent/ProductsPageContent/ProductsPageContentContainer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ProductsPageContainerMobile } from '@/components/PageContent/ProductsPageContent/ProductsPageContainerMobile';
import { useGetCurrentCheckoutSession } from '@/api/checkout';
import { useGetLiftOperatryWithOeamtcDataById } from '@/api/liftOperator';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';

export const ProductsPage: FunctionComponent<PropsWithChildren> = () => {
    const {
        state: { selectedLiftOperator },
        actions: { setSelectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');

    const { isLoading: checkoutLoading } = useGetCurrentCheckoutSession();
    const { id } = useParams<{ id: string }>();
    const {
        data: liftOperator,
        isError,
        isLoading: liftOperatorLoading,
    } = useGetLiftOperatryWithOeamtcDataById({
        liftOperatorId: id,
    });

    const { IS_PORTAL, pageConfig } = useConfigSlice();

    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();
    const mobileView = useMediaQuery('(max-width:1330px)');

    const redirectOnError = () => {
        navigate(
            IS_PORTAL
                ? getPagePath(routerConfig.LiftOperators)
                : getPagePath(routerConfig.Products, [
                      {
                          param: 'id',
                          value: pageConfig.configuration.liftOperatorId,
                      },
                  ])
        );
    };

    if (liftOperator?.id !== selectedLiftOperator?.id) {
        setSelectedLiftOperator(liftOperator);
    }

    // error cases - redirect

    if (isError) {
        redirectOnError();
        return;
    }

    if (IS_PORTAL && liftOperator && !liftOperator.active) {
        setSelectedLiftOperator(null);
        navigate(getPagePath(routerConfig.Home));
        return;
    }

    if (
        !IS_PORTAL &&
        liftOperator &&
        pageConfig &&
        pageConfig.configuration.liftOperatorId !== id
    ) {
        navigate(
            getPagePath(routerConfig.Products, [
                {
                    param: 'id',
                    value: pageConfig.configuration.liftOperatorId,
                },
            ])
        );
        return;
    }

    if (checkoutLoading || liftOperatorLoading) return null;

    return mobileView ? (
        <ProductsPageContainerMobile />
    ) : (
        <ProductsPageContentContainer />
    );
};
