import { useMemo } from 'react';
import {
    defaultRouterConfig,
    getRouterConfig,
    RouteConfigEntry,
    RouterConfig,
} from '@/components/Router/routerConfig';
import { useLocation } from 'react-router-dom';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { getEntriesByAppBar } from '@/components/Router/routerFunctions';
import { matchPathArray } from '@/utils/router';
import { getPropByCurrentLocale } from '@/utils/locale';
import { configSlice, PortalConfig } from '@/slices/configSlice';
import { useSlice } from '@/hooks/useSlice';

type InPageLink = 'dataPrivacy' | 'imprint' | 'gtc';

const getValidPagePath = (routeConfigEntry: RouteConfigEntry): string[] => {
    const { path, pushTo } = routeConfigEntry;

    if (pushTo) return [pushTo];

    return Array.isArray(path) ? path : [path];
};

export const useCustomRouter = () => {
    const { IS_PORTAL, IS_INTEGRATION, IS_OEAMTC, pageConfig, configuration } =
        useConfigSlice();
    const {
        state: { isOeamtc },
    } = useSlice(configSlice, 'config');
    const { pathname } = useLocation();

    const routerConfig: RouterConfig = useMemo(() => {
        if (IS_PORTAL && !IS_OEAMTC) {
            return defaultRouterConfig;
        }

        const onePageRouterConfig = {};

        const webshopConfig = getRouterConfig(false);
        Object.keys(webshopConfig).forEach((entryKey) => {
            const entry: RouteConfigEntry = webshopConfig[entryKey];

            if (configuration === PortalConfig.INTEGRATION_CONFIGURATION) {
                if (entry.inOnePage) {
                    onePageRouterConfig[entryKey] = entry;
                }
            }

            if (isOeamtc) {
                if (entry.inPartner) {
                    onePageRouterConfig[entryKey] = entry;
                }
            }
        });

        return onePageRouterConfig;
    }, [IS_PORTAL]);

    const currentPageConfig = useMemo(() => {
        const allPaths = Object.values(routerConfig).flatMap(
            (entry) => entry.path
        );
        const currentConfig = matchPathArray(
            allPaths,
            window.location.pathname
        );

        return Object.values(routerConfig).find((entry) =>
            entry.path.includes(currentConfig?.pattern.path)
        );
    }, [pathname]);

    // TODO: replace with react router internal function
    const getPagePath = (
        page: RouteConfigEntry,
        params?: { param: string; value: string }[]
    ): string => {
        if (!page) return '/';

        const currentHasEnglishDeepLink = pathname.includes('/en');
        const pathArray = getValidPagePath(page);

        let path = currentHasEnglishDeepLink
            ? pathArray.find((p) => p.includes('/en'))
            : pathArray.find((p) => !p.includes('/en'));

        if (params) {
            params.forEach(({ param, value }) => {
                path = path.replace(`:${param}`, value);
            });
        }

        return path;
    };

    const isCurrentlyOnPage = (routeConfig: RouteConfigEntry) =>
        matchPathArray(routeConfig.path, pathname);

    const defaultRoutePathname: string = useMemo(() => {
        // let's keep it hardcoded for now
        if (IS_INTEGRATION) {
            return getPagePath(routerConfig.Products, [
                {
                    param: 'id',
                    value: pageConfig?.configuration.liftOperatorId,
                },
            ]);
        } else if (IS_OEAMTC) {
            return getPagePath(routerConfig.LiftOperators);
        } else {
            return getPagePath(
                Object.values(routerConfig).find((config) => config.isDefault)
            );
        }
    }, [routerConfig]);

    const [entriesWithAppBar, entriesWithoutAppBar] = useMemo(
        () => getEntriesByAppBar(routerConfig),
        [routerConfig]
    );

    const getInPageLinks = (link: InPageLink) => {
        const { origin } = window.location;

        switch (link) {
            case 'dataPrivacy':
                return IS_PORTAL
                    ? `${origin}${getPagePath(routerConfig.DataPrivacy)}`
                    : getPropByCurrentLocale(
                          pageConfig?.configuration?.dataPrivacyUrl
                      ) || `${origin}${getPagePath(routerConfig.DataPrivacy)}`;
            case 'imprint':
                return IS_PORTAL
                    ? `${origin}${getPagePath(routerConfig.Imprint)}`
                    : getPropByCurrentLocale(
                          pageConfig?.configuration?.imprintUrl
                      ) || `${origin}${getPagePath(routerConfig.Imprint)}`;
            case 'gtc':
                return IS_PORTAL
                    ? `${origin}${getPagePath(routerConfig.GTCPage)}`
                    : getPropByCurrentLocale(
                          pageConfig?.configuration?.termsAndConditionsUrl
                      ) || `${origin}${getPagePath(routerConfig.GTCPage)}`;
        }
    };

    return {
        currentPageConfig,
        getPagePath,
        isCurrentlyOnPage,
        routerConfig,
        defaultRoutePathname,
        entriesWithAppBar,
        entriesWithoutAppBar,
        getInPageLinks,
    };
};
