import { FunctionComponent, useMemo, useState } from 'react';
import { PageContentContainer } from '@/components/Layout/PageContentContainer';
import {
    LocationAutoComplete,
    PlaceType,
} from '@/components/AutoComplete/LocationAutoComplete';
import { Fade } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { LiftOperatorList } from '@/components/LiftOperatorSidebar/LiftOperatorList';
import { LiftOperator } from '@/models/liftOperators';
import { getDistance, isPointWithinRadius } from 'geolib';
import { LiftOperatorListType } from '@/components/LiftOperatorSidebar/LiftOperatorSidebarContainer';
import { useTranslation } from '@/hooks/useTranslation';
import { useAuthentication } from '@/hooks/useAuthentication';
import {
    useGetFavoritesQuery,
    useGetLiftOperatorsQuery,
} from '@/api/liftOperator';
import { OeamtcBannerContainer } from '@/components/OeamtcBanner/OeamtcBanner';

let closestLocationAddress = null;

export const LiftOperatorsPageContainerMobile: FunctionComponent = () => {
    const { isLoggedIn } = useAuthentication();
    const { getTranslated } = useTranslation();
    const { data: favoriteIds = [] } = useGetFavoritesQuery();
    const { data: allLiftOperators } = useGetLiftOperatorsQuery();
    const [showLiftOperators, setShowLiftOperators] =
        useState<LiftOperatorListType>('fullList');
    const [tempLiftOperators, setTempLiftOperators] = useState<LiftOperator[]>(
        []
    );

    const isLiftOperatorInFavorites = (liftOperatorId: string) =>
        favoriteIds.find((fLo) => fLo.liftOperatorId === liftOperatorId);

    // split liftOperators in favorites and rest
    const [favorites, liftOperators] = useMemo(
        () =>
            isLoggedIn
                ? allLiftOperators.reduce(
                      (splitted, currentLiftOperator) => {
                          if (
                              isLiftOperatorInFavorites(currentLiftOperator.id)
                          ) {
                              return [
                                  [...splitted[0], currentLiftOperator],
                                  [...splitted[1]],
                              ];
                          } else {
                              return [
                                  [...splitted[0]],
                                  [...splitted[1], currentLiftOperator],
                              ];
                          }
                      },
                      [[], []]
                  )
                : [[], allLiftOperators],
        [isLoggedIn, favoriteIds, allLiftOperators]
    );

    const filterByClosestLocation = (place: PlaceType) => {
        if (window.google) {
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ placeId: place.place_id }, (results) => {
                const { formatted_address } = results[0];
                const { lat, lng } = results[0].geometry.location;

                closestLocationAddress = formatted_address;

                const liftOperatorsCloseBy = liftOperators.filter(
                    (liftOperator) => {
                        return isPointWithinRadius(
                            liftOperator.address.coordinates,
                            { lat: lat(), lng: lng() },
                            25000
                        );
                    }
                );

                const sortedByDistance = liftOperatorsCloseBy.sort(
                    (la, lb) =>
                        getDistance(
                            { lat: lat(), lng: lng() },
                            la.address.coordinates
                        ) -
                        getDistance(
                            { lat: lat(), lng: lng() },
                            lb.address.coordinates
                        )
                );

                setTempLiftOperators(sortedByDistance);
            });
        }
    };

    const getLiftOperators = () => {
        switch (showLiftOperators) {
            case 'fullList':
                return liftOperators;
            default:
                return tempLiftOperators;
        }
    };

    return (
        <PageContentContainer column>
            <OeamtcBannerContainer />
            {!!liftOperators?.length && (
                <>
                    <FlexContainer
                        sx={{
                            p: 2,
                            width: '100%',
                        }}
                    >
                        <LocationAutoComplete
                            liftOperators={allLiftOperators}
                            onFocus={() => {
                                setShowLiftOperators(null);
                            }}
                            onBlur={(inputValue) => {
                                if (!inputValue) {
                                    setShowLiftOperators('fullList');
                                }
                            }}
                            onSelect={(nextValue) => {
                                if (!nextValue) {
                                    setShowLiftOperators('fullList');
                                    setTempLiftOperators([]);
                                } else if (nextValue.type === 'placeType') {
                                    setShowLiftOperators('closest');
                                    filterByClosestLocation(nextValue);
                                } else {
                                    setTempLiftOperators([
                                        nextValue.liftOperator,
                                    ]);
                                    setShowLiftOperators('selected');
                                }
                            }}
                        />
                    </FlexContainer>

                    <Fade in={!!showLiftOperators}>
                        <FlexContainer
                            sx={{
                                p: 2,
                            }}
                            column
                            fullWidth
                        >
                            <LiftOperatorList
                                favorites={favorites}
                                listTitle={
                                    showLiftOperators === 'closest'
                                        ? getTranslated('lifOperatorsNearby', {
                                              location: closestLocationAddress,
                                          })
                                        : getTranslated(
                                              getLiftOperators().length > 1
                                                  ? 'liftOperators'
                                                  : 'LocationSearch.liftoperator'
                                          )
                                }
                                resultType={
                                    showLiftOperators === 'fullList'
                                        ? 'fullList'
                                        : 'search'
                                }
                                liftOperators={getLiftOperators()}
                            />
                        </FlexContainer>
                    </Fade>
                </>
            )}
        </PageContentContainer>
    );
};
