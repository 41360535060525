import {
    FunctionComponent,
    PropsWithChildren,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Form, Formik } from 'formik';
import { FlexContainer } from '@/components/Layout/Container';
import { SelectForm } from '@/components/Layout/Form/SelectForm';
import { FormikTextField } from '@/components/Layout/Form/Field';
import { Identification } from '@/models/order';
import Alert from '@mui/material/Alert';
import { UploadAndCropper } from '@/components/Image/UploadAndCropper';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import { renderAsHtml } from '@/utils/react';
import { InputBirthday } from '@/components/Layout/Input/InputBirthday';
import { getTranslatedSalutationsOptions } from '@/utils/general';
import { IdentificationFormFabButton } from '@/components/PageContent/CartPageContent/IdentificationSelection/IdentificationFormFabButton';
import { DATE_FORMAT } from '@/constants';
import { Gender } from '@/models/general';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import { ErrorFormContainer } from '@/components/Formik/ErrorFormContainer';
import { CreateIdentificationFormData } from '@/models/checkout/checkoutIdentification';

// TODO: unifiy scroll logic with RegisterKeycardForm.tsx

const FormTextFieldWrapper: FunctionComponent<{
    label: string;
    name: string;
}> = ({ label, name }) => (
    <FormikTextField
        name={name}
        textFieldProps={{
            sx: {
                width: '100%',
            },
            variant: 'outlined',
            label: label,
        }}
    />
);

const initialValues = {
    title: '',
    firstname: '',
    lastname: '',
    gender: '',
    birthday: '',
    image: '',
};

const buildInitialValues = (identification: Identification) => {
    if (!identification) return initialValues;

    return {
        title: identification.title,
        firstname: identification.firstname,
        lastname: identification.lastname,
        gender: identification.gender
            ? identification.gender.toLocaleLowerCase()
            : '',
        image: '',
        serialNumber: '',
        birthday: identification.birthday.format(DATE_FORMAT),
    };
};

type AddIdentificationFormProps = {
    identification?: Identification;
    onSubmit: (identification: CreateIdentificationFormData) => void;
    validationSchema?: unknown;
    isInternalProduction?: boolean;
    explanationText?: string;
    loading?: boolean;
};

export const AddIdentificationForm: FunctionComponent<
    PropsWithChildren<AddIdentificationFormProps>
> = ({
    isInternalProduction,
    validationSchema,
    identification,
    explanationText,
    onSubmit,
    loading = false,
}) => {
    const uploaderRef = useRef();
    const gridFormRef = useRef<HTMLDivElement>();
    const [submitTriggered, setSubmitTriggered] = useState(false);
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});

    const { getTranslated, locale } = useTranslation();
    const matchesMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    const scrollToErrors = (errors) => {
        const dialogTitle = document.getElementById(
            'identification_dialog_title'
        );
        const goToPostion = dialogTitle.offsetHeight + 20;

        let currentY;
        if (!!errors.image) {
            currentY = (
                uploaderRef.current as HTMLDivElement
            )?.getBoundingClientRect().y;
        } else if (Object.values(errors).length) {
            // any other error - scroll to first field

            currentY = gridFormRef.current?.getBoundingClientRect().y;
        }

        let distance;
        if (currentY > 0) {
            distance = currentY - goToPostion;
        } else {
            distance = (Math.abs(currentY) + goToPostion) * -1;
        }

        const dialogContent = document.getElementById(
            'identification_dialog_content'
        );

        dialogContent.scrollBy({
            top: distance,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (
            matchesMobile &&
            submitTriggered &&
            Object.values(formErrors).length
        ) {
            scrollToErrors(formErrors);
            setSubmitTriggered(false);
        }
    }, [submitTriggered, formErrors]);

    return (
        <Formik
            initialValues={buildInitialValues(identification)}
            validationSchema={validationSchema}
            onSubmit={(values, { setFieldError }) => {
                if (values.image === 'uploaded') {
                    setFieldError('image', getTranslated('imageOnlyCropped'));
                    return;
                }

                const identificationFormData = {
                    ...values,
                    image: values.image,
                    gender:
                        values.gender === 'male' ? Gender.MALE : Gender.FEMALE,
                    birthday: moment(values.birthday, DATE_FORMAT),
                };

                onSubmit(identificationFormData);
            }}
        >
            {({
                errors,
                setFieldValue,
                values,
                setFieldError,
                handleSubmit,
            }) => (
                <Form
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                >
                    <ErrorFormContainer
                        onError={(errors) => {
                            setFormErrors(errors);
                        }}
                    >
                        <FlexContainer
                            sx={(theme) => ({
                                display: 'flex',
                                flexWrap: 'wrap',

                                [theme.breakpoints.down('lg')]: {
                                    flexDirection: 'column',
                                },
                            })}
                        >
                            {!isInternalProduction && (
                                <FlexContainer column center>
                                    <Typography
                                        sx={{
                                            p: 3,
                                            mb: 6,
                                        }}
                                    >
                                        {renderAsHtml(explanationText)}
                                    </Typography>
                                </FlexContainer>
                            )}

                            <Grid
                                sx={{
                                    alignItems: 'center',
                                }}
                                spacing={3}
                                container
                            >
                                <Grid
                                    sx={{
                                        px: 3,
                                    }}
                                    xs={12}
                                    md={5}
                                    item
                                >
                                    <FlexContainer
                                        sx={(theme) => ({
                                            width: '100%',
                                            height: '460px',

                                            [theme.breakpoints.down('lg')]: {
                                                height: '360px',
                                            },
                                        })}
                                    >
                                        <UploadAndCropper
                                            onImageUploaded={() =>
                                                setFieldValue(
                                                    'image',
                                                    'uploaded'
                                                )
                                            }
                                            defaultImage={identification?.image}
                                            onImageDelete={() => {
                                                setFieldError('image', null);
                                                setFieldValue('image', '');
                                            }}
                                            onCrop={(imageData) => {
                                                setFieldValue(
                                                    'image',
                                                    imageData
                                                );
                                            }}
                                        />
                                    </FlexContainer>

                                    {errors.image && !matchesMobile && (
                                        <FlexContainer
                                            sx={{
                                                my: 2,
                                            }}
                                            center
                                            fullWidth
                                        >
                                            <Alert severity='error'>
                                                {errors.image}
                                            </Alert>
                                        </FlexContainer>
                                    )}
                                </Grid>

                                {errors.image && matchesMobile && (
                                    <Grid xs={12} md={6} item>
                                        <FlexContainer
                                            sx={{
                                                my: 2,
                                            }}
                                            center
                                            fullWidth
                                        >
                                            <Alert severity='error'>
                                                {errors.image}
                                            </Alert>
                                        </FlexContainer>
                                    </Grid>
                                )}

                                <Grid xs={12} md={7} container spacing={3} item>
                                    <Grid xs={12} md={6} item>
                                        <SelectForm
                                            name='gender'
                                            error={!!errors['gender']}
                                            inputLabel={getTranslated(
                                                'Address.salutation'
                                            )}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                },
                                                error: !!errors['gender'],
                                            }}
                                            options={getTranslatedSalutationsOptions(
                                                locale
                                            )}
                                        />
                                    </Grid>

                                    <Grid xs={12} md={6} item>
                                        <FormTextFieldWrapper
                                            name='title'
                                            label={getTranslated(
                                                'Address.title'
                                            )}
                                        />
                                    </Grid>

                                    <Grid xs={12} md={6} item>
                                        <FormTextFieldWrapper
                                            name='firstname'
                                            label={getTranslated(
                                                'Address.firstname'
                                            )}
                                        />
                                    </Grid>

                                    <Grid xs={12} md={6} item>
                                        <FormTextFieldWrapper
                                            name='lastname'
                                            label={getTranslated(
                                                'Address.lastname'
                                            )}
                                        />
                                    </Grid>

                                    <Grid xs={12} item>
                                        <InputBirthday
                                            value={values.birthday}
                                            onChange={(val) => {
                                                setFieldValue('birthday', val);
                                            }}
                                            error={errors['birthday'] as string}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FlexContainer>
                        <FlexContainer
                            sx={(theme) => ({
                                position: 'absolute',
                                justifyContent: 'flex-end',
                                bottom: '36px',
                                right: '36px',
                                zIndex: 10,

                                [theme.breakpoints.down('lg')]: {
                                    width: '90%',
                                    left: '50%',
                                    transform: 'translate(-50%)',
                                },
                            })}
                        >
                            <IdentificationFormFabButton
                                onClick={() => {
                                    handleSubmit();
                                    setSubmitTriggered(true);
                                }}
                                loading={loading}
                            >
                                {getTranslated('common.save')}
                            </IdentificationFormFabButton>
                        </FlexContainer>
                    </ErrorFormContainer>
                </Form>
            )}
        </Formik>
    );
};
