import { FunctionComponent } from 'react';
import { FlatCard } from '@/components/Card/FlatCard';
import { CheckoutTicketCollection } from '@/models/collection/checkoutTicketCollection';
import { CollectionCardHeader } from '@/components/CollectionCard/common/CollectionCardHeader';
import { CheckoutTicketCollectionQuantityList } from '@/components/CollectionCard/CheckoutTicketCollectionCard/CheckoutTicketCollectionQuantityList';
import { Box, CardContent, Divider, Stack, Typography } from '@mui/material';
import { getMoneyAsString } from '@/utils/general';
import { useTranslation } from '@/hooks/useTranslation';
import {
    useDeleteCheckoutTicketsMutation,
    useGetCurrentCheckoutSession,
} from '@/api/checkout';
import { PersonAssignmentContainer } from '@/components/CollectionCard/CheckoutTicketCollectionCard/PersonAssignmentContainer';
import { CollectionCardContent } from '@/components/CollectionCard/common/CollectionCardContent';
import { CheckoutTicketCollectionBottomInfo } from '@/components/CollectionCard/CheckoutTicketCollectionCard/CheckoutTicketCollectionBottomInfo';
import { useSlice } from '@/hooks/useSlice';
import { cartSlice } from '@/slices/cartSlice';
import { TicketNotRefundableLine } from '@/components/Ticket/TicketNotRefundableLine';
import { PersonInsuranceList } from '@/components/CollectionCard/CheckoutTicketCollectionCard/PersonInsuranceList';
import { InsuranceAddContainer } from '@/components/CollectionCard/CheckoutTicketCollectionCard/InsuranceAddContainer';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { TicketExtraData } from '@/components/Ticket/TicketExtraData';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { PersonAssignmentContainerSingle } from '@/components/CollectionCard/CheckoutTicketCollectionCard/PersonAssignmentContainerSingle';

type CheckoutTicketCardProps = {
    ticketCollection: CheckoutTicketCollection;
};

export const CheckoutTicketCollectionCard: FunctionComponent<
    CheckoutTicketCardProps
> = ({ ticketCollection }) => {
    const { routerConfig, isCurrentlyOnPage } = useCustomRouter();
    const { IS_OEAMTC } = useConfigSlice();
    const isOnCheckout = isCurrentlyOnPage(routerConfig.Cart);
    const insuranceEnabled =
        ticketCollection.aonInsuranceEnabled &&
        !IS_OEAMTC &&
        !ticketCollection.isPackage;

    if (isOnCheckout) {
        return insuranceEnabled ? (
            <CardCheckoutSingleTicket ticketCollection={ticketCollection} />
        ) : (
            <CardCheckoutPackage ticketCollection={ticketCollection} />
        );
    } else {
        return insuranceEnabled ? (
            <CardProductPageWithInsurance ticketCollection={ticketCollection} />
        ) : (
            <CardProductPageWithoutInsurance
                ticketCollection={ticketCollection}
            />
        );
    }
};

const CardProductPageWithInsurance: FunctionComponent<
    CheckoutTicketCardProps
> = ({ ticketCollection }) => {
    const { getTranslated } = useTranslation();
    const {
        actions: { removeOeamtcCode },
    } = useSlice(cartSlice, 'cart');
    const [deleteTicketCollection] = useDeleteCheckoutTicketsMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    return (
        <FlatCard
            sx={{
                width: '100%',
            }}
        >
            <CollectionCardHeader
                productName={ticketCollection.name}
                productType={ticketCollection.type}
                startDay={ticketCollection.startDay}
                duration={ticketCollection.duration}
                liftOperatorName={ticketCollection.liftOperatorName}
                onRemove={() => {
                    const ticketsToDelete =
                        ticketCollection.checkoutTickets.map(
                            (ticket) => ticket.id
                        );

                    ticketsToDelete.forEach((ticketId) => {
                        removeOeamtcCode({ ticketId });
                    });

                    deleteTicketCollection({
                        ticketIds: ticketsToDelete,
                        checkoutId: checkoutSession.id,
                    });
                }}
            />

            <TicketExtraData collection={ticketCollection} />

            <CollectionCardContent>
                {ticketCollection.aonInsuranceEnabled ? (
                    <Stack spacing={2}>
                        <PersonInsuranceList
                            ticketCollection={ticketCollection}
                        />

                        <InsuranceAddContainer
                            ticketCollection={ticketCollection}
                        />
                    </Stack>
                ) : (
                    <CheckoutTicketCollectionQuantityList
                        ticketCollection={ticketCollection}
                    />
                )}

                {ticketCollection.isPackage && (
                    <Box>
                        {ticketCollection.checkoutTickets[0]
                            .cancellationBlocked && (
                            <Box
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                }}
                            >
                                <TicketNotRefundableLine />
                            </Box>
                        )}

                        <Divider
                            sx={{
                                width: '100%',
                                my: 4,
                            }}
                        />
                        <Typography
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            {getTranslated('Cart.total')}:{' '}
                            {getMoneyAsString(
                                ticketCollection.checkoutTickets[0].price
                            )}
                        </Typography>
                    </Box>
                )}
            </CollectionCardContent>
        </FlatCard>
    );
};

const CardProductPageWithoutInsurance: FunctionComponent<
    CheckoutTicketCardProps
> = ({ ticketCollection }) => {
    const { getTranslated } = useTranslation();
    const {
        actions: { removeOeamtcCode },
    } = useSlice(cartSlice, 'cart');
    const [deleteTicketCollection] = useDeleteCheckoutTicketsMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    return (
        <FlatCard
            sx={{
                width: '100%',
            }}
        >
            <CollectionCardHeader
                productName={ticketCollection.name}
                productType={ticketCollection.type}
                startDay={ticketCollection.startDay}
                duration={ticketCollection.duration}
                liftOperatorName={ticketCollection.liftOperatorName}
                onRemove={() => {
                    const ticketsToDelete =
                        ticketCollection.checkoutTickets.map(
                            (ticket) => ticket.id
                        );

                    ticketsToDelete.forEach((ticketId) => {
                        removeOeamtcCode({ ticketId });
                    });

                    deleteTicketCollection({
                        ticketIds: ticketsToDelete,
                        checkoutId: checkoutSession.id,
                    });
                }}
            />

            <TicketExtraData collection={ticketCollection} />

            <CollectionCardContent>
                <CheckoutTicketCollectionQuantityList
                    ticketCollection={ticketCollection}
                />

                {ticketCollection.isPackage && (
                    <Box>
                        {ticketCollection.checkoutTickets[0]
                            .cancellationBlocked && (
                            <Box
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                }}
                            >
                                <TicketNotRefundableLine />
                            </Box>
                        )}

                        <Divider
                            sx={{
                                width: '100%',
                                my: 4,
                            }}
                        />
                        <Typography
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            {getTranslated('Cart.total')}:{' '}
                            {getMoneyAsString(
                                ticketCollection.checkoutTickets[0].price
                            )}
                        </Typography>
                    </Box>
                )}
            </CollectionCardContent>
        </FlatCard>
    );
};

const CardCheckoutSingleTicket: FunctionComponent<CheckoutTicketCardProps> = ({
    ticketCollection,
}) => {
    const {
        actions: { removeOeamtcCode },
    } = useSlice(cartSlice, 'cart');
    const [deleteTicketCollection] = useDeleteCheckoutTicketsMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    return (
        <FlatCard
            sx={{
                width: '100%',
            }}
        >
            <CollectionCardHeader
                productName={ticketCollection.name}
                productType={ticketCollection.type}
                startDay={ticketCollection.startDay}
                duration={ticketCollection.duration}
                liftOperatorName={ticketCollection.liftOperatorName}
                onRemove={() => {
                    const ticketsToDelete =
                        ticketCollection.checkoutTickets.map(
                            (ticket) => ticket.id
                        );

                    ticketsToDelete.forEach((ticketId) => {
                        removeOeamtcCode({ ticketId });
                    });

                    deleteTicketCollection({
                        ticketIds: ticketsToDelete,
                        checkoutId: checkoutSession.id,
                    });
                }}
            />

            <TicketExtraData collection={ticketCollection} />

            <PersonAssignmentContainerSingle
                ticketCollection={ticketCollection}
            />

            <CheckoutTicketCollectionBottomInfo
                ticketCollection={ticketCollection}
            />

            {ticketCollection.aonInsuranceEnabled && (
                <CardContent>
                    <InsuranceAddContainer
                        ticketCollection={ticketCollection}
                    />
                </CardContent>
            )}
        </FlatCard>
    );
};

const CardCheckoutPackage: FunctionComponent<CheckoutTicketCardProps> = ({
    ticketCollection,
}) => {
    const { getTranslated } = useTranslation();
    const {
        actions: { setPersonAssignmentDialogControls, removeOeamtcCode },
    } = useSlice(cartSlice, 'cart');
    const [deleteTicketCollection] = useDeleteCheckoutTicketsMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();

    return (
        <FlatCard
            sx={{
                width: '100%',
            }}
        >
            <CollectionCardHeader
                productName={ticketCollection.name}
                productType={ticketCollection.type}
                startDay={ticketCollection.startDay}
                duration={ticketCollection.duration}
                liftOperatorName={ticketCollection.liftOperatorName}
                onRemove={() => {
                    const ticketsToDelete =
                        ticketCollection.checkoutTickets.map(
                            (ticket) => ticket.id
                        );

                    ticketsToDelete.forEach((ticketId) => {
                        removeOeamtcCode({ ticketId });
                    });

                    deleteTicketCollection({
                        ticketIds: ticketsToDelete,
                        checkoutId: checkoutSession.id,
                    });
                }}
            />

            <TicketExtraData collection={ticketCollection} />

            <CollectionCardContent>
                <CheckoutTicketCollectionQuantityList
                    ticketCollection={ticketCollection}
                />

                {ticketCollection.isPackage && (
                    <Box>
                        {ticketCollection.checkoutTickets[0]
                            .cancellationBlocked && (
                            <Box
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                }}
                            >
                                <TicketNotRefundableLine />
                            </Box>
                        )}

                        <Divider
                            sx={{
                                width: '100%',
                                my: 4,
                            }}
                        />
                        <Typography
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            {getTranslated('Cart.total')}:{' '}
                            {getMoneyAsString(
                                ticketCollection.checkoutTickets[0].price
                            )}
                        </Typography>
                    </Box>
                )}
            </CollectionCardContent>

            <PersonAssignmentContainer
                ticketCollection={ticketCollection}
                onBookingOptionSelection={(checkoutTicket) => {
                    setPersonAssignmentDialogControls({
                        ticket: checkoutTicket,
                        personId:
                            checkoutTicket.persons.length > 1
                                ? null
                                : checkoutTicket.persons[0].id,
                    });
                }}
            />

            <CheckoutTicketCollectionBottomInfo
                ticketCollection={ticketCollection}
            />
        </FlatCard>
    );
};
