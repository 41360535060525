import { FunctionComponent } from 'react';
import { IdentificationType } from '@/models/cart';
import { TranslationKey, useTranslation } from '@/hooks/useTranslation';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const ProsConsListContainer = styled('ul')(({ theme }) => ({
    marginLeft: theme.spacing(),
    paddingLeft: theme.spacing(),
    listStyle: 'none',
}));

const ListLabel = styled('li', {
    shouldForwardProp: (prop) => prop !== 'isLast',
})<{ isLast?: boolean }>(({ theme, isLast }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    color: theme.palette.sjGreyMain,
    ...(isLast && {
        marginBottom: theme.spacing(3),
    }),
}));

const ProIcon = styled(CheckIcon)(({ theme }) => ({
    fontSize: '1.1rem',
    marginRight: theme.spacing(2),
    color: theme.palette.success.main,
}));

const ConIcon = styled(PriorityHighIcon)(({ theme }) => ({
    fontSize: '1.1rem',
    marginRight: theme.spacing(2),
    color: theme.palette.errorRed,
}));

const getAdvantages = (
    variant: IdentificationType
): { pros: TranslationKey[]; cons: TranslationKey[] } => {
    switch (variant) {
        case IdentificationType.KEYCARD:
            return {
                pros: [
                    'productionTypeProDirectUsage',
                    'productionTypeProRechargeable',
                    'productionTypeProFastBooking',
                ],
                cons: ['productionTypeConDeliveryTime'],
            };
        case IdentificationType.INTERNAL_PRODUCTION:
            return {
                pros: [
                    'productionTypeProDirectUsage',
                    'productionTypeProRechargeable',
                    'productionTypeProFastBooking',
                ],
                cons: ['productionTypeConDeliveryTimeInternal'],
            };
        case IdentificationType.PICKUP:
            return {
                pros: ['productionTypeProNoKeycard'],
                cons: ['productionTypeConDeposit'],
            };
        case IdentificationType.BARCODE:
            return {
                pros: [
                    'productionTypeProDirectUsage',
                    'productionTypeProNoKeycard',
                ],
                cons: [],
            };
        case IdentificationType.E_TICKET:
            return {
                pros: ['productionTypeProSmartphone'],
                cons: ['productionTypeConAppRequired'],
            };
    }
};

type ProsConsListProps = {
    variant: IdentificationType;
};

export const ProsConsList: FunctionComponent<ProsConsListProps> = ({
    variant,
}) => {
    const { getTranslated } = useTranslation();
    const { pros, cons } = getAdvantages(variant);
    return (
        <ProsConsListContainer>
            {pros.map((pro, index) => (
                <ListLabel key={index} isLast={index === pros.length - 1}>
                    <ProIcon />
                    {getTranslated(pro)}
                </ListLabel>
            ))}

            {cons.map((con, index) => (
                <ListLabel key={index}>
                    <ConIcon />
                    {getTranslated(con)}
                </ListLabel>
            ))}
        </ProsConsListContainer>
    );
};
