import { FunctionComponent, useMemo, useState } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import { InputEdit } from '@/components/Layout/Input/InputEdit';
import { useAuthentication } from '@/hooks/useAuthentication';
import { EditEmailDialog } from '@/components/Dialogs/EditEmailDialog';
import { EditPasswordDialog } from '@/components/Dialogs/EditPasswordDialog';
import { Formik } from 'formik';
import { FormikTextField } from '@/components/Layout/Form/Field';
import { LoginType, Newsletter, User } from '@/models/user';
import { countryOptionsByLocale } from '@/constants/countries';
import { Select } from '@/components/Layout/Select';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { useTranslation } from '@/hooks/useTranslation';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Typography,
} from '@mui/material';
import { useNotification } from '@/hooks/useNotification';
import * as Yup from 'yup';
import { translate } from '@/utils/locale';
import {
    DEFAULT_STRING_LENGTH,
    ZIP_CODE_LENGTH,
} from '@/constants/formValidation';
import { formatDate } from '@/utils/time';
import { getTranslatedSalutationsOptions } from '@/utils/general';
import { DeleteAccountContainer } from '@/components/PageContent/MyDataPageContent/DeleteAccountContainer';
import { LoginsListContainer } from '@/components/PageContent/MyDataPageContent/LoginsListContainer';
import { styled } from '@mui/material/styles';
import { useDeleteProfileMutation, useGetProfilesQuery } from '@/api/order';
import { LoadingButton } from '@mui/lab';
import {
    useDeleteNewsletterMutation,
    useGetNewslettersQuery,
    useGetUserDataQuery,
    usePostNewsletterMutation,
    usePutUserDataMutation,
} from '@/api/user';
import { useGetLoginsQuery } from '@/api/authentication';

const isSubscribedLike = (option: Newsletter['status']) =>
    option === 'SUBSCRIBED' || option === 'PENDING';

const SectionBlock = styled(FlexContainer)(({ theme }) => ({
    margin: theme.spacing(6, 0),
    width: '100%',

    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1),
    },
}));

const validationSchema = () => {
    return Yup.object().shape({
        firstname: Yup.string()
            .required(
                translate('validation.error.orderAddress.firstname.required')
            )
            .max(
                DEFAULT_STRING_LENGTH,
                translate('validation.error.orderAddress.firstname.long')
            ),
        lastname: Yup.string()
            .required(
                translate('validation.error.orderAddress.lastname.required')
            )
            .max(
                DEFAULT_STRING_LENGTH,
                translate('validation.error.orderAddress.lastname.long')
            ),

        company: Yup.string().length(
            DEFAULT_STRING_LENGTH,
            translate('validation.error.orderAddress.company.long')
        ),

        street: Yup.string().required(
            translate('validation.error.orderAddress.street.required')
        ),

        zipcode: Yup.string()
            .required(
                translate('validation.error.orderAddress.zipcode.required')
            )
            .max(
                ZIP_CODE_LENGTH,
                translate('validation.error.orderAddress.zipcode.long')
            ),

        city: Yup.string()
            .required(translate('validation.error.orderAddress.city.required'))
            .max(
                DEFAULT_STRING_LENGTH,
                translate('validation.error.orderAddress.city.long')
            ),
    });
};

const initial = {
    salutation: '',
    firstname: '',
    lastname: '',
    street: '',
    city: '',
    zipcode: '',
    country: '',
    phone: '',
    marketingAcceptance: false,
};

const pickValuesFromUser = (user: Partial<User>) => ({
    marketingAcceptance: user.marketingAcceptance || false,
    country: user.country || '',
    salutation: user.salutation || '',
    firstname: user.firstname || '',
    lastname: user.lastname || '',
    street: user.street || '',
    city: user.city || '',
    zipcode: user.zipcode || '',
    phone: user.phone || '',
});

export const MyDataPage: FunctionComponent = () => {
    const { email } = useAuthentication();
    const { data: loginInformation = [] } = useGetLoginsQuery();
    const { data: currentUser } = useGetUserDataQuery();
    const [putUserData, { isLoading: putIsLoading }] = usePutUserDataMutation();
    const { data: profiles = [] } = useGetProfilesQuery();
    const [deleteProfile, { isLoading: deleteProfileLoading }] =
        useDeleteProfileMutation();
    const { data: newsLetterOption } = useGetNewslettersQuery();
    const [deleteNewsletter] = useDeleteNewsletterMutation();
    const [postNewsletter] = usePostNewsletterMutation();

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editPasswordDialog, setEditPasswordDialog] = useState(false);
    const [deleteAccount, setDeleteAccount] = useState(false);

    const { addNotification } = useNotification();

    const user = useMemo(
        () => (currentUser ? pickValuesFromUser(currentUser) : initial),
        [currentUser]
    );
    const hasEmailPasswordAccount = useMemo(() => {
        return !!loginInformation.find(
            (lI) => lI.type === LoginType.EMAIL_PASSWORD
        );
    }, [loginInformation]);
    const { getTranslated, locale } = useTranslation();

    const countryOptions = countryOptionsByLocale(locale);

    const handleNewsletterCheckboxChange = (language: 'en' | 'de') => () => {
        const { status, id } = newsLetterOption[language];

        if (isSubscribedLike(status)) {
            deleteNewsletter(id);
        } else {
            postNewsletter(id);
        }
    };

    return (
        <SubPageTemplate title={getTranslated('Profile.myData')}>
            <SectionBlock column fullWidth>
                <FlexContainer sx={{ width: '100%', margin: 2 }}>
                    <InputEdit
                        label={getTranslated('CardTransfer.email')}
                        defaultValue={email}
                        handleIconButtonClick={() => setEditDialogOpen(true)}
                        fullWidth
                    />
                </FlexContainer>

                <FlexContainer sx={{ width: '100%', margin: 2 }}>
                    <InputEdit
                        label={getTranslated('Header.changePassword')}
                        defaultValue={getTranslated('Header.changePassword')}
                        handleIconButtonClick={() =>
                            setEditPasswordDialog(true)
                        }
                        fullWidth
                    />
                </FlexContainer>
            </SectionBlock>

            <SectionBlock column>
                <Formik
                    initialValues={user}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        putUserData({
                            ...currentUser,
                            ...values,
                        })
                            .unwrap()
                            .then(() => {
                                addNotification({
                                    message: getTranslated(
                                        'Profile.accountUpdated'
                                    ),
                                });
                            });
                    }}
                    validateOnChange={false}
                    enableReinitialize
                >
                    {({ setFieldValue, values, submitForm }) => (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                submitForm();
                            }}
                        >
                            <SectionBlock column sx={{ flexFlow: 'wrap' }}>
                                <Typography variant='h5' gutterBottom>
                                    {getTranslated(
                                        'MyAccountNavigation.myData'
                                    )}
                                </Typography>
                                <Select
                                    inputLabel={getTranslated(
                                        'Address.salutation'
                                    )}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'salutation',
                                            e.target.value
                                        );
                                    }}
                                    value={values['salutation']}
                                    formControlProps={{
                                        sx: { width: '100%', margin: 2 },
                                    }}
                                    options={getTranslatedSalutationsOptions(
                                        locale
                                    )}
                                />
                                <FormikTextField
                                    name={'firstname'}
                                    textFieldProps={{
                                        variant: 'outlined',
                                        label: getTranslated(
                                            'Address.firstname'
                                        ),
                                        sx: { width: '100%', margin: 2 },
                                    }}
                                />
                                <FormikTextField
                                    name={'lastname'}
                                    textFieldProps={{
                                        variant: 'outlined',
                                        label: getTranslated(
                                            'Address.lastname'
                                        ),
                                        sx: { width: '100%', margin: 2 },
                                    }}
                                />
                                <FormikTextField
                                    name={'street'}
                                    textFieldProps={{
                                        variant: 'outlined',
                                        label: getTranslated('Address.street'),
                                        sx: { width: '100%', margin: 2 },
                                    }}
                                />
                                <FlexContainer
                                    justifyContent='space-between'
                                    fullWidth
                                >
                                    <FormikTextField
                                        name={'city'}
                                        textFieldProps={{
                                            variant: 'outlined',
                                            label: getTranslated(
                                                'Address.city'
                                            ),
                                            sx: { width: '48%', margin: 2 },
                                        }}
                                    />
                                    <FormikTextField
                                        name={'zipcode'}
                                        textFieldProps={{
                                            variant: 'outlined',
                                            label: getTranslated(
                                                'Address.zipcode'
                                            ),
                                            sx: { width: '48%', margin: 2 },
                                        }}
                                    />
                                </FlexContainer>

                                <Select
                                    name='gender'
                                    inputLabel={getTranslated(
                                        'Address.country'
                                    )}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'country',
                                            e.target.value
                                        );
                                    }}
                                    value={values['country']}
                                    formControlProps={{
                                        sx: { width: '100%', margin: 2 },
                                    }}
                                    options={countryOptions}
                                />

                                <FormikTextField
                                    name={'phone'}
                                    textFieldProps={{
                                        variant: 'outlined',
                                        label: getTranslated('Address.phone'),
                                        sx: { width: '100%', margin: 2 },
                                    }}
                                />

                                <SectionBlock column fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    values.marketingAcceptance
                                                }
                                                onChange={() => {
                                                    setFieldValue(
                                                        'marketingAcceptance',
                                                        !values.marketingAcceptance
                                                    );
                                                }}
                                                color='primary'
                                                name='marketingAcceptance'
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: 'small',
                                                    marginTop: 4,
                                                }}
                                            >
                                                {getTranslated(
                                                    'Address.marketingAcceptance'
                                                )}
                                            </Typography>
                                        }
                                    />
                                </SectionBlock>

                                {!!profiles.length && (
                                    <SectionBlock column fullWidth>
                                        <Typography variant='h5' gutterBottom>
                                            {getTranslated(
                                                'Profile.myCreditCards'
                                            )}
                                        </Typography>

                                        {profiles.map((profile) => (
                                            <Card
                                                key={profile.id}
                                                sx={{
                                                    width: '50%',
                                                    minWidth: 440,
                                                }}
                                            >
                                                <CardContent
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <FlexContainer column>
                                                        <Typography>
                                                            {profile.identifier}
                                                        </Typography>
                                                        <Typography>
                                                            {formatDate(
                                                                profile.cardExpirationDate
                                                            )}
                                                        </Typography>
                                                    </FlexContainer>

                                                    <LoadingButton
                                                        loading={
                                                            deleteProfileLoading
                                                        }
                                                        onClick={() =>
                                                            deleteProfile({
                                                                id: profile.id,
                                                            })
                                                        }
                                                        color='error'
                                                    >
                                                        {getTranslated(
                                                            'MyCardsPage.deleteIt'
                                                        )}
                                                    </LoadingButton>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </SectionBlock>
                                )}

                                <SectionBlock column>
                                    {newsLetterOption && (
                                        <>
                                            <Typography
                                                variant='h5'
                                                gutterBottom
                                            >
                                                {getTranslated(
                                                    'Newsletter.title'
                                                )}
                                            </Typography>

                                            <FormControlLabel
                                                sx={{
                                                    fontSize: 'small',
                                                    marginTop: 4,
                                                }}
                                                control={
                                                    <Checkbox
                                                        checked={isSubscribedLike(
                                                            newsLetterOption.de
                                                                .status
                                                        )}
                                                        onChange={handleNewsletterCheckboxChange(
                                                            'de'
                                                        )}
                                                        color='primary'
                                                        name='newsLetterGerman'
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontSize: 'small',
                                                            marginTop: 4,
                                                        }}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: getTranslated(
                                                                    'Newsletter.de'
                                                                ),
                                                            }}
                                                        />
                                                    </Typography>
                                                }
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isSubscribedLike(
                                                            newsLetterOption.en
                                                                .status
                                                        )}
                                                        onChange={handleNewsletterCheckboxChange(
                                                            'en'
                                                        )}
                                                        color='primary'
                                                        name='newsLetterEnglish'
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontSize: 'small',
                                                            marginTop: 4,
                                                        }}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: getTranslated(
                                                                    'Newsletter.en'
                                                                ),
                                                            }}
                                                        />
                                                    </Typography>
                                                }
                                            />
                                        </>
                                    )}
                                </SectionBlock>
                            </SectionBlock>

                            <SectionBlock column fullWidth>
                                <Typography variant='h5' gutterBottom>
                                    {getTranslated('Profile.manageLogins')}
                                </Typography>
                                <LoginsListContainer />
                            </SectionBlock>

                            <SectionBlock column fullWidth>
                                <LoadingButton
                                    loading={putIsLoading}
                                    type='submit'
                                    sx={{ margin: 4 }}
                                    color='primary'
                                    variant='contained'
                                >
                                    {getTranslated('Profile.saveChanges')}
                                </LoadingButton>
                                <Button
                                    sx={{ margin: 4, color: 'error.main' }}
                                    onClick={() => setDeleteAccount(true)}
                                    fullWidth
                                >
                                    {getTranslated('Profile.deleteAccount')}
                                </Button>
                            </SectionBlock>
                        </form>
                    )}
                </Formik>
            </SectionBlock>

            <DeleteAccountContainer
                confirmWithPassword={hasEmailPasswordAccount}
                isOpen={deleteAccount}
                onClose={() => setDeleteAccount(false)}
            />

            <EditEmailDialog
                isOpen={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
            />

            <EditPasswordDialog
                isOpen={editPasswordDialog}
                onClose={() => setEditPasswordDialog(false)}
            />
        </SubPageTemplate>
    );
};
