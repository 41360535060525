import { useState } from 'react';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { useTranslation } from '@/hooks/useTranslation';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useGetFaqsQuery } from '@/api/faq';
import { transformLocaleToLanguage } from '@/models/general';
import { FaqsDialog } from '@/components/Faqs/FaqsDialog';
import { AppBar } from '@/components/AppBar/AppBar';
import { useSlice } from '@/hooks/useSlice';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';

export const AppBarContainer = () => {
    const { IS_PORTAL, pageConfig } = useConfigSlice();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { currentPageConfig } = useCustomRouter();
    const { locale } = useTranslation();
    const [openFaqDialog, setOpenFaqDialog] = useState(false);

    const showPageFaqs = !IS_PORTAL && selectedLiftOperator;

    let faqsPerPage = [];

    const { data: faqResponse } = useGetFaqsQuery(
        {
            language: transformLocaleToLanguage(locale),
            liftOperatorId: pageConfig?.configuration?.liftOperatorId,
        },
        {
            skip: !pageConfig?.configuration?.liftOperatorId,
        }
    );

    if (!!pageConfig?.configuration?.liftOperatorId) {
        faqsPerPage =
            showPageFaqs && faqResponse?.faqs.length
                ? faqResponse?.faqs.filter((f) =>
                      currentPageConfig.path.includes(f.page)
                  )
                : [];
    }

    return (
        <>
            <AppBar
                onFaqsClick={() => setOpenFaqDialog(true)}
                showFaqsButton={!!faqsPerPage.length}
            />
            {!!faqsPerPage.length && (
                <FaqsDialog
                    open={openFaqDialog}
                    onClose={() => setOpenFaqDialog(false)}
                    faqs={faqsPerPage}
                />
            )}
        </>
    );
};
