import { FunctionComponent, PropsWithChildren } from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
} from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import {
    GetTranslated,
    TranslationKey,
    useTranslation,
} from '@/hooks/useTranslation';
import { FlexContainer } from '@/components/Layout/Container';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { IdentificationType } from '@/models/cart';
import { KeycardProductionTypeIcon } from '@/components/PageContent/CartPageContent/CheckoutItemList/KeycardProductionTypeIcon';
import { styled, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type ViewType = 'list' | 'register' | 'add';

const ProductionTypeIcon = styled(KeycardProductionTypeIcon)(() => ({
    transform: 'scale(0.75)',
}));

const getContentHeader = (
    productionType: IdentificationType,
    viewType: ViewType,
    getTranslated: GetTranslated
) => {
    let title, subTitle;

    if (
        productionType === IdentificationType.KEYCARD ||
        productionType === IdentificationType.INTERNAL_PRODUCTION
    ) {
        if (viewType === 'list') {
            title = getTranslated('choosePersonToUseKeycard');
            subTitle = getTranslated('noSuggestionAvailableKeycard');
        } else if (viewType === 'add') {
            title = getTranslated('common.createNewCard');
        } else {
            title = getTranslated('registerKeycardTitle');
            subTitle = getTranslated('registerKeycardSubtitle');
        }
    } else {
        if (viewType === 'list') {
            title = getTranslated('choosePersonToUse');
            subTitle = getTranslated('noSuggestionAvailable');
        } else {
            title = getTranslated('enterPersonalDataSuggestion');
        }
    }

    return (
        <>
            {!!title && (
                <Typography
                    sx={{
                        fontWeight: 600,
                        mt: 1,

                        fontSize: '0.95rem',
                    }}
                >
                    {title}
                </Typography>
            )}

            {!!subTitle && (
                <Typography
                    sx={{
                        fontSize: viewType === 'list' ? '0.95rem' : '1rem',
                    }}
                >
                    {subTitle}
                </Typography>
            )}
        </>
    );
};

type IdentificationSelectionCardProps = {
    title: string;
    productionType: IdentificationType;
    viewType: 'list' | 'register' | 'add';
    onBack?: () => void;
    backButtonLabel?: TranslationKey;
};

export const IdentificationSelectionCard: FunctionComponent<
    PropsWithChildren<IdentificationSelectionCardProps>
> = ({ children, onBack, productionType, viewType, backButtonLabel }) => {
    const { getTranslated } = useTranslation();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const getCardTitle = (productionType: IdentificationType) => {
        return (
            <Typography
                sx={{
                    fontWeight: 600,
                }}
            >
                {getTranslated(productionType)}
                {!isMobile && (
                    <>
                        {' '}
                        -{' '}
                        {getTranslated(
                            `productionType_${productionType}` as unknown as TranslationKey
                        )}
                    </>
                )}
            </Typography>
        );
    };

    return (
        <Card
            sx={{
                mx: 'auto',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                overflow: 'unset',
            }}
            elevation={1}
        >
            {!!onBack && (
                <FlexContainer
                    sx={(theme) => ({
                        p: 2,
                        width: '100%',
                        borderBottom: `1px solid ${theme.palette.grey[200]}`,

                        [theme.breakpoints.up('lg')]: {
                            display: 'none',
                        },
                    })}
                >
                    <Button
                        sx={{
                            alignSelf: 'center',
                        }}
                        variant='outlined'
                        onClick={onBack}
                    >
                        <KeyboardArrowLeft color='primary' />
                        {getTranslated('back')}
                    </Button>
                </FlexContainer>
            )}

            <CardHeader
                sx={(theme) => ({
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',

                    '.MuiCardHeader-action': {
                        alignSelf: 'center',
                        [theme.breakpoints.down('lg')]: {
                            display: 'none',
                        },
                    },
                })}
                avatar={
                    <Avatar
                        sx={(theme) => ({
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '64px',
                            height: '64px',
                            bgcolor: theme.palette.primary.main,
                            color: '#fff',
                        })}
                        aria-label='recipe'
                    >
                        <ProductionTypeIcon
                            variant={productionType}
                            color='white'
                        />
                    </Avatar>
                }
                title={getCardTitle(productionType)}
                action={
                    onBack ? (
                        <Button
                            sx={{
                                alignSelf: 'center',
                            }}
                            variant='outlined'
                            onClick={onBack}
                        >
                            {getTranslated(backButtonLabel || 'back')}
                        </Button>
                    ) : null
                }
            />
            <CardContent
                sx={(theme) => ({
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    minHeight: '240px',
                    height: '100%',
                    pb: '96px !important',

                    [theme.breakpoints.down('lg')]: {
                        pb: '120px !important',
                        minHeight: `calc(100vh - ${theme.appBar.height})`,
                    },
                })}
            >
                <Box
                    sx={{
                        mb: 2,
                    }}
                >
                    {getContentHeader(productionType, viewType, getTranslated)}
                </Box>
                {children}
            </CardContent>
        </Card>
    );
};
