import { FunctionComponent } from 'react';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { OnePageHeaderMobile } from '@/components/PageContent/ProductsPageContent/ProductsOverviewMobile/OnePageHeaderMobile';
import { ProductFilterStepperWithCatalogContainer } from '@/components/PageContent/ProductsPageContent/ProductFilterStepperWithCatalogContainer';

export const ProductsOverviewContainerMobile: FunctionComponent = () => {
    const { IS_PORTAL } = useConfigSlice();

    return (
        <>
            {!IS_PORTAL && <OnePageHeaderMobile />}

            <ProductFilterStepperWithCatalogContainer />
        </>
    );
};
