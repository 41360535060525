import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '../constants';
import { getCurrentLocale } from '@/utils/locale';

export const formatDate = (date: Moment) => {
    if (!date) return '';
    return moment(date).locale(getCurrentLocale()).format(DATE_FORMAT);
};

export const formatDateWithWeekDay = (date: Moment) => {
    if (!date) return '';
    return moment(date)
        .locale(getCurrentLocale())
        .format(`dddd, ${DATE_FORMAT}`);
};

export const toMomentFromDateArray = (date: number[]) => {
    if (!Array.isArray(date)) {
        return null;
    }

    return moment([date[0], date[1] - 1, date[2]]);
};

export const toMomentFromString = (date: string) => {
    if (!date) return null;

    const dateParsed = moment(date);

    if (!dateParsed.isValid()) return null;

    return dateParsed;
};

export const mapMomentToBackendStirng = (date: Moment) => {
    if (!date) return '';
    return date.format('YYYY-MM-DD');
};

export const getTimeInSeconds = () => moment().unix();
