import { FunctionComponent, useMemo } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import {
    getLiftOperatorProductsPageLink,
    LiftOperator,
} from '@/models/liftOperators';
import { LiftOperatorCard } from '@/components/LiftOperatorSidebar/LiftOperatorCard';
import { useNavigate } from 'react-router-dom';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { getPropByCurrentLocale } from '@/utils/locale';
import { ListItem, ListSubheader, Typography } from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import { useSlice } from '@/hooks/useSlice';
import { configSlice } from '@/slices/configSlice';
import List from '@mui/material/List';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';

const sortLiftOperatorsByName = (liftOperators: LiftOperator[]) =>
    [...liftOperators].sort((lA, lB) =>
        getPropByCurrentLocale(lA.name).localeCompare(
            getPropByCurrentLocale(lB.name)
        )
    );

type LiftOperatorListProps = {
    favorites?: LiftOperator[];

    listTitle: string;
    liftOperators: LiftOperator[];
    resultType: 'fullList' | 'search';
};

export const LiftOperatorList: FunctionComponent<LiftOperatorListProps> = ({
    liftOperators,
    listTitle,
    favorites = [],
    resultType,
}) => {
    const { getTranslated, locale } = useTranslation();
    const {
        actions: { setSelectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { getPagePath, routerConfig } = useCustomRouter();
    const navigate = useNavigate();
    const {
        state: { productEnvironment },
    } = useSlice(configSlice, 'config');

    const onLiftOperatorSelect = (liftOperator: LiftOperator) => {
        setSelectedLiftOperator(liftOperator);
        navigate(
            getPagePath(routerConfig.Products, [
                {
                    param: 'id',
                    value: getLiftOperatorProductsPageLink(
                        liftOperator,
                        locale
                    ),
                },
            ])
        );
    };

    const separateByProductsAvailable = (liftOperators: LiftOperator[]) =>
        liftOperators.reduce(
            (acc, liftOperator) => {
                const envProducts = liftOperator.products.find(
                    (p) => p.environment === productEnvironment
                );
                if (envProducts?.count > 0) {
                    acc.withProducts.push(liftOperator);
                } else {
                    acc.withoutProducts.push(liftOperator);
                }
                return acc;
            },
            { withProducts: [], withoutProducts: [] }
        );

    const favoritesSorted = useMemo(() => {
        const favoritesSeparated = separateByProductsAvailable(favorites);
        return [
            ...sortLiftOperatorsByName(favoritesSeparated.withProducts),
            ...sortLiftOperatorsByName(favoritesSeparated.withoutProducts),
        ];
    }, [favorites]);

    const liftOperatorsSorted = useMemo(() => {
        const liftOperatorsSeparated =
            separateByProductsAvailable(liftOperators);
        return [
            ...sortLiftOperatorsByName(liftOperatorsSeparated.withProducts),
            ...sortLiftOperatorsByName(liftOperatorsSeparated.withoutProducts),
        ];
    }, [liftOperators]);

    const showFavorites = !!favoritesSorted.length && resultType === 'fullList';

    return (
        <List
            sx={(theme) => ({
                width: '100%',
                mt: 1,
                [theme.breakpoints.up('lg')]: {
                    mt: 4,
                },
            })}
            subheader={<li />}
        >
            {showFavorites && (
                <>
                    <ListTitle
                        title={getTranslated('MyAccountNavigation.myFavorites')}
                    />

                    {favoritesSorted.map((liftOperator, index) => (
                        <ListItem
                            sx={{
                                p: 0,
                                width: '100%',
                            }}
                            key={`favorites-entry-${liftOperator.id}`}
                        >
                            <LiftOperatorCard
                                key={index}
                                liftOperator={liftOperator}
                                onLiftOperatorSelect={onLiftOperatorSelect}
                            />
                        </ListItem>
                    ))}
                </>
            )}

            <FlexContainer column>
                <ListTitle title={listTitle} />

                {liftOperatorsSorted.map((liftOperator, index) => (
                    <ListItem
                        sx={{
                            p: 0,
                            width: '100%',
                        }}
                        key={`liftOperator-entry-${index}`}
                    >
                        <LiftOperatorCard
                            key={index}
                            liftOperator={liftOperator}
                            onLiftOperatorSelect={onLiftOperatorSelect}
                        />
                    </ListItem>
                ))}
            </FlexContainer>
        </List>
    );
};

const ListTitle: FunctionComponent<{ title: string }> = ({ title }) => (
    <ListSubheader
        sx={{
            py: 3,
            px: 2,
            my: 2,
            backgroundColor: '#e5e9ea',
            fontWeight: 600,
            borderRadius: 2,
            color: 'unset',
        }}
    >
        <Typography variant='h6'>{title}</Typography>
    </ListSubheader>
);
