import { alpha, SimplePaletteColorOptions } from '@mui/material';

export const withAlphas = (
    color: string = '#000'
): SimplePaletteColorOptions => {
    return {
        dark: alpha(color, 0.16),
        main: alpha(color, 0.08),
        light: alpha(color, 0.04),
    };
};
