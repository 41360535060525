import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme/theme';

export const useAccountRegisterStyles = makeStyles((theme: Theme) => ({
    title: {
        marginBottom: theme.spacing(4),
        alignSelf: 'flex-start',
    },
    field: {
        width: '75%',
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },

    form: {
        width: '100%',
        flexFlow: 'wrap',
    },

    sectionBlock: {
        margin: theme.spacing(6, 0),
    },

    sectionBlockBtn: {
        margin: theme.spacing(6, 0),
        alignItems: 'center',
    },

    button: {
        width: '256px',
    },
    flexContainer: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'block',
        },
    },

    checkbox: {
        fontSize: theme.layout.textual.formControl.fontSize.small,
        marginTop: theme.spacing(4),
    },
}));
