import { FunctionComponent } from 'react';
import { LoginVariant } from '@/components/PageContent/MyDataPageContent/LoginVariant';
import { LoginInformation } from '@/models/user';
import { useNotification } from '@/hooks/useNotification';
import { useTranslation } from '@/hooks/useTranslation';
import {
    useGetLoginsQuery,
    usePostDeleteLoginMutation,
} from '@/api/authentication';

type LoginVariantContainerProps = {
    loginInformationItem: LoginInformation;
    onRemoveEmailAccount: (lI: LoginInformation) => void;
};

export const LoginVariantContainer: FunctionComponent<
    LoginVariantContainerProps
> = ({ loginInformationItem }) => {
    const { data: loginInformation = [] } = useGetLoginsQuery();
    const [postDeleteLoading, { isLoading: deleteLoading }] =
        usePostDeleteLoginMutation();
    const { addNotification } = useNotification();
    const { getTranslated } = useTranslation();

    const onRemove = async (lI: LoginInformation) => {
        postDeleteLoading({
            loginId: lI.id,
        })
            .unwrap()
            .then(() => {
                addNotification({
                    type: 'success',
                    message: getTranslated('loginDeleted'),
                });
            })
            .catch(() => {
                addNotification({
                    type: 'error',
                    message: getTranslated('loginDeleteError'),
                });
            });
    };

    return (
        <LoginVariant
            loginInformation={loginInformationItem}
            onRemove={onRemove}
            isLoading={deleteLoading}
            removable={loginInformation.length > 1}
        />
    );
};
