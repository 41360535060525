import { FunctionComponent, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import { Button } from '@/components/Layout/Button/Button';
import { useNotification } from '@/hooks/useNotification';
import { useTranslation } from '@/hooks/useTranslation';
import ahaCardImg from '@/assets/images/ahaCard.png';
import famCardImg from '@/assets/images/famCard.png';
import { CheckoutTicket } from '@/models/checkout/checkoutTicket';
import { usePostDiscountAhaMutation } from '@/api/promotion';
import {
    useGetCurrentCheckoutSession,
    usePutCheckoutTicketMutation,
} from '@/api/checkout';

type PromotionDialogContentProps = {
    onClose: () => void;
    checkoutTicket: CheckoutTicket;
};

export const PromotionDialogContent: FunctionComponent<
    React.PropsWithChildren<PromotionDialogContentProps>
> = ({ onClose, checkoutTicket }) => {
    const [ahaCard, setAhaCard] = useState('');
    const { addNotification } = useNotification();
    const [postDiscount, { isLoading }] = usePostDiscountAhaMutation();
    const [putCheckoutTicket] = usePutCheckoutTicketMutation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const [familyPass, setFamilyPass] = useState('');
    const { getTranslated } = useTranslation();

    const activate = () => {
        if ((familyPass || ahaCard) && checkoutSession) {
            postDiscount({
                ahaCard,
                familyPass,
                liftoperatorId: checkoutTicket.liftOperatorId,
                productId: checkoutTicket.productId,
                startDate: checkoutTicket.startDate.format('YYYY-MM-DD'),
            })
                .unwrap()
                .then(
                    ({
                        discardContingentConditions,
                        discardPriceConditions,
                        discounts,
                        priceCode,
                        price,
                        priceReason,
                    }) => {
                        putCheckoutTicket({
                            customization: {
                                discounts,
                                discardContingentConditions,
                                discardPriceConditions,
                                price: {
                                    amount: price,
                                    currency: 'EUR',
                                },
                                priceCode,
                                priceReason,
                            },
                            id: checkoutTicket.id,
                            identificationType:
                                checkoutTicket.identificationType,
                            checkoutId: checkoutSession.id,
                        });

                        addNotification({
                            message: getTranslated('Promotion.enter.success'),
                        });
                    }
                )
                .catch(() => {
                    addNotification({
                        message: getTranslated('Promotion.enter.error'),
                        type: 'error',
                    });
                });
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <DialogTitle>{getTranslated('Promotion.enter.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {getTranslated('Promotion.enter.ahaText')}
                    <img
                        alt='aha-card'
                        src={ahaCardImg}
                        style={{ margin: '32px 0', width: '50%' }}
                    />
                </DialogContentText>
                <TextField
                    autoFocus
                    onChange={(e) => {
                        const { value } = e.target;
                        setAhaCard(value);
                    }}
                    margin='dense'
                    variant='outlined'
                    label={getTranslated('Promotion.enter.labelAha')}
                    type='text'
                    fullWidth
                    sx={{ marginBottom: 4 }}
                />
                <DialogContentText
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {getTranslated('Promotion.enter.familyText')}
                    <img
                        alt='family-pass'
                        src={famCardImg}
                        style={{ margin: '32px 0', width: '50%' }}
                    />
                </DialogContentText>
                <TextField
                    autoFocus
                    onChange={(e) => {
                        const { value } = e.target;
                        setFamilyPass(value);
                    }}
                    margin='dense'
                    variant='outlined'
                    label={getTranslated('Promotion.enter.labelFamily')}
                    type='text'
                    fullWidth
                    sx={{ marginBottom: 4 }}
                />
            </DialogContent>
            <DialogActions>
                <Button isLoading={false} onClick={handleClose} color='primary'>
                    Schließen
                </Button>
                <Button
                    isLoading={isLoading}
                    onClick={activate}
                    variant={'contained'}
                    color='primary'
                >
                    {getTranslated('Promotion.enter')}
                </Button>
            </DialogActions>
        </>
    );
};
