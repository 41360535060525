import { FunctionComponent } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material';
import { useTranslation } from '@/hooks/useTranslation';
import { DialogWithTitle } from '@/components/Dialog/DialogWithTitle';
import ReactMarkdown from 'react-markdown';
import { useSlice } from '@/hooks/useSlice';
import { infoSlice } from '@/slices/infoSlice';

export const InfoDialog: FunctionComponent = () => {
    const { getTranslated } = useTranslation();
    const {
        state: { message },
        actions: { removeInfo },
    } = useSlice(infoSlice, 'info');

    const handleClose = () => {
        removeInfo();
    };

    return (
        <DialogWithTitle
            open={!!message}
            onClose={handleClose}
            title={getTranslated('additionalTicketInformation')}
            fullWidth
        >
            <DialogContent>
                <DialogContentText>
                    <ReactMarkdown>{message}</ReactMarkdown>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    {getTranslated('Core.close')}
                </Button>
            </DialogActions>
        </DialogWithTitle>
    );
};
