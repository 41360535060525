import { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import { FlexContainer } from '@/components/Layout/Container';
import { Typography } from '@mui/material';

const ContentContainer = styled(FlexContainer)(({ theme }) => ({
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2, 2),
}));

const ChildrenContainer = styled(FlexContainer)(({ theme }) => ({
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(1, 1),
    overflowX: 'hidden',
}));

export const CheckoutViewMobile: FunctionComponent<
    React.PropsWithChildren<{ title: string }>
> = ({ title, children }) => {
    return (
        <ContentContainer>
            <Typography variant='h6'>{title}</Typography>

            <ChildrenContainer>{children}</ChildrenContainer>
        </ContentContainer>
    );
};
