import Visa from '@/assets/icons/paymentIcons/visa.png';
import Mastercard from '@/assets/icons/paymentIcons/mastercard.png';
import EPS from '@/assets/icons/paymentIcons/eps.png';
import Ideal from '@/assets/icons/paymentIcons/ideal.png';
import GPay from '@/assets/icons/paymentIcons/google-pay.png';
import Sofort from '@/assets/icons/paymentIcons/sofort.png';
import ApplePay from '@/assets/icons/paymentIcons/apple-pay.png';
import { FlexContainer } from '@/components/Layout/Container';
import { styled } from '@mui/material/styles';

const IconWrapper = styled(FlexContainer)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 50,
    transition: 'transform 150ms',

    '&:hover': {
        transform: 'scale(1.2)',
    },
}));

const IconSVGS = [Visa, Mastercard, EPS, Ideal, GPay, Sofort, ApplePay];

export const PaymentIcons = () => {
    return (
        <FlexContainer
            sx={{
                flexWrap: 'wrap',
                width: '240px',
            }}
        >
            {IconSVGS.map((iconUrl, index) => (
                <IconWrapper key={index}>
                    <img src={iconUrl} />
                </IconWrapper>
            ))}
        </FlexContainer>
    );
};
